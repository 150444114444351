<mat-dialog-content #content class="mat-typography">

    <div class="title">{{drone?.drone_type_local}} #{{dialog_data.drone_id}}</div>

    <form [formGroup]="form">

        <div class="drone-parts">

            @for (detail of details; track $index) {

            <div class="drone-part">

                <button mat-mini-fab class="drone-part-remove" *ngIf="detail.model_name"
                    (click)="removeStockItem(detail)">
                    <mat-icon>close</mat-icon>
                </button>

                <div class="drone-part-container" matRipple [mat-menu-trigger-for]="detail.model_name?null:menuDetail">
                    @if(detail.model_name) {
                    <div class="drone-part-details">
                        <img [src]="detail.model_image_url" class="drone-part-img" />
                        <span class="drone-part-name">{{detail.model_name}}</span>
                        <span class="drone-part-warehouse product-available">
                            <mat-icon>warehouse</mat-icon>
                            <span>{{detail.warehouse_name}}</span>
                        </span>
                        <span class="drone-part-price">
                            <span class="product-available">{{detail.detail_price_usd | currency}}</span>
                            <span class="product-available">{{detail.detail_price_uah | currency:'UAH'}}</span>
                        </span>
                    </div>
                    } @else {
                    <div class="drone-part-add">
                        <mat-icon>add</mat-icon>
                        <span>{{detail.detail_name_local}}</span>
                    </div>
                    }
                </div>

            </div>

            <mat-menu #menuDetail>

                @for (stock of detail.stock; track $index) {
                <button mat-menu-item disabled class="drone-part-menu-warehouse">{{stock.warehouse_name}}</button>
                @for (model of stock.stock; track $index) {
                <button mat-menu-item (click)="selectStockItem(detail, stock, model)">
                    <div class="drone-part-menu-item">
                        <div>{{model.model_name}}</div>
                        <div><b>{{model.stock_quantity}}</b> ({{model.stock_price_usd|currency}} /
                            {{model.stock_price_uah|currency:'UAH'}})</div>
                    </div>
                </button>
                }
                }

                <button mat-menu-item disabled *ngIf="!detail.stock.length">
                    На складі немає цієї деталі. Спочатку поповніть склад.
                </button>

            </mat-menu>

            }

        </div>

        <div class="flex">
            <mat-form-field>
                <mat-label>
                    Номер дрона
                </mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Буде присвоєно автоматично, якщо не заповнено'"
                    formControlName="drone_number">
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Імʼя дрона
                </mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Шалена Бджілка'" formControlName="drone_name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Статус дрона
                </mat-label>
                <mat-select formControlName="drone_status">
                    <mat-option [value]="'draft'">Збирається</mat-option>
                    <mat-option [value]="'done'">Готовий</mat-option>
                    <mat-option [value]="'sent'">Переданий</mat-option>
                    <mat-option [value]="'deleted'">Видалений</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex">

            <mat-form-field style="width:calc(33.33% - 4px)">
                <mat-label>
                    Збиральник
                </mat-label>
                <mat-select formControlName="user_id" [disabled]="!data.admin">
                    <mat-option [value]="u.user_id" *ngFor="let u of users">{{u.user_name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="width:calc(66.6% - 4px)">
                <mat-label>Коментар</mat-label>
                <input matInput placeholder="Коментар..." formControlName="drone_comment" />
            </mat-form-field>

        </div>

        <div class="flex" style="margin-bottom: 16px;" *ngIf="form.controls.drone_public.value">

            <mat-form-field style="width:calc(33.33% - 4px)">
                <mat-label>Публічна сторінка SDUA</mat-label>
                <input matInput placeholder="https://sdua.tech/d/aZQOnsbo" formControlName="drone_sdua_url" />
            </mat-form-field>

            <mat-form-field style="width:calc(66.6% - 4px)">
                <mat-label>Додаткова публічна інформація</mat-label>
                <mat-select multiple formControlName="drone_public_flags">
                    <mat-option *ngFor="let f of drone_public_flags"
                        [value]="f">{{drone_public_flags_dict[f]}}</mat-option>
                    <mat-select-trigger>
                        <mat-chip-set>
                            <mat-chip *ngFor="let f of form.controls.drone_public_flags.value">
                                {{drone_public_flags_dict[f]}}
                            </mat-chip>
                        </mat-chip-set>
                    </mat-select-trigger>
                </mat-select>
                <mat-hint>
                    Дрон публічний. Базова інформація буде показана, однак можна вибрати додаткові опції.
                </mat-hint>
            </mat-form-field>

        </div>

        <mat-form-field>
            <mat-label>Публічний коментар</mat-label>
            <textarea matInput cdkTextareaAutosize
                [placeholder]="'Опис дрону, що буде відображений публічній сторінці (підтримує Markdown)'"
                formControlName="drone_public_comment"></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>
                Посилання на публічну сторінку
            </mat-label>
            <input matInput readonly [value]="'https://goods.ua-drones.de/drone/'+drone?.drone_uuid_short" />
            <button mat-icon-button type="button" matSuffix (click)="copyLink()">
                <mat-icon>copy</mat-icon>
            </button>
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <mat-slide-toggle [formControl]="form.controls.drone_public" (change)="onPublicChange($event, content)">
        Публічний дрон
    </mat-slide-toggle>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">
        Оновити інформацію
    </button>
</mat-dialog-actions>
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Rule, RuleDto, RuleTelegram, RuleType, RuleVerification, RuleWebsite } from '../../api.models';
import { ApiService } from '../../api.service';
import { DataService, Detail, Model, ModelFull, ProductFull } from '../../data.service';

type DetailGroup = Detail & { models: ModelFull[] };

const requiredIf = (condition: (form: FormGroup) => boolean): ValidatorFn => {
    return control => {
        if (!control.parent) return null;
        if (!condition(control.parent as FormGroup)) return null;
        return !!control.value ? null : { [`required_if`]: true };
    };
}

@Component({
    selector: 'app-upsert-rule-dialog',
    templateUrl: './upsert-rule-dialog.component.html',
    styleUrl: './upsert-rule-dialog.component.scss'
})
export class UpsertRuleDialogComponent {

    public static open(dialog: MatDialog, rule: Rule | null) {
        return dialog.open<UpsertRuleDialogComponent, Rule | null, Rule | null>(
            UpsertRuleDialogComponent,
            { data: rule, autoFocus: false, width: '720px', panelClass: 'mobile-fullscreen' },
        );
    }

    public loading = false;
    public details: DetailGroup[] = [];
    public models: ModelFull[] = [];
    public models_dict: { [id: number]: ModelFull } = {};

    public sku_units = 'шт';

    public readonly RuleType = RuleType;
    public readonly RuleWebsite = RuleWebsite;
    public readonly RuleTelegram = RuleTelegram;
    public readonly RuleVeriication = RuleVerification;

    public filtered_products: ProductFull[] = [];

    private filterProducts(model_id: number | null | undefined) {
        if (!model_id) {
            this.filtered_products = [];
            return;
        }
        this.data.getFullProducts().subscribe({
            next: products => {
                this.filtered_products = products
                    .filter(p => !p.product_deleted && p.product_status !== 'invalid' && p.model_id === model_id);
            }
        });
    }

    public form = new FormGroup({
        rule_type: new FormControl<RuleType>(RuleType.Price, [Validators.required]),
        rule_website: new FormControl<RuleWebsite>(RuleWebsite.Enabled, [Validators.required]),
        rule_telegram: new FormControl<RuleTelegram>(RuleTelegram.Always, [Validators.required]),
        rule_verification: new FormControl<RuleVerification>(RuleVerification.OnlyVerified, [Validators.required]),
        rule_max_sku_quantity: new FormControl('0', [requiredIf(form => form.controls['rule_type'].value === RuleType.Price), Validators.pattern(/^\d+(\.\d+)?$/)]),
        rule_max_unit_price_usd: new FormControl('0', [requiredIf(form => form.controls['rule_type'].value === RuleType.Price), Validators.pattern(/^\d+(\.\d+)?$/)]),
        model_id: new FormControl<number | null>(null, [Validators.required]),
        product_id: new FormControl<number | null>(null, [requiredIf(form => form.controls['rule_product_specific'].value)]),
        rule_product_specific: new FormControl(false),
    });

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        public readonly data: DataService,
        private readonly api: ApiService,
        public readonly ref: MatDialogRef<UpsertRuleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly rule: Rule | null,
    ) {

        if (rule) {
            this.form.setValue({
                rule_type: rule.rule_type,
                rule_website: rule.rule_website,
                rule_telegram: rule.rule_telegram,
                rule_verification: rule.rule_verification,
                rule_max_sku_quantity: String(rule.rule_max_sku_quantity),
                rule_max_unit_price_usd: String(rule.rule_max_unit_price_usd),
                model_id: rule.model_id,
                product_id: rule.product_id,
                rule_product_specific: !!rule.product_id,
            })
        }

        this.form.controls.model_id.valueChanges.subscribe(model_id => {
            this.filterProducts(model_id);
            this.sku_units = this.models.find(m => m.model_id === model_id)?.sku_units || 'шт';
        });

        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    this.models_dict[m.model_id] = m;
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                })
            }
        });

        this.filterProducts(rule?.model_id || null);

    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        const dto: RuleDto = {
            rule_id: this.rule?.rule_id || 0,
            rule_deleted: false,
            rule_type: value.rule_type!,
            rule_website: value.rule_website!,
            rule_telegram: value.rule_telegram!,
            rule_verification: value.rule_verification!,
            rule_max_sku_quantity: value.rule_type === RuleType.Price ? Number(value.rule_max_sku_quantity) : 0,
            rule_max_unit_price_usd: value.rule_type === RuleType.Price ? Number(value.rule_max_unit_price_usd) : 0,
            model_id: value.model_id || null,
            product_id: value.product_id || null,
        };

        this.api.rulesUpsert(dto).subscribe({
            next: rule => {
                this.ref.close(rule);
                if (this.rule) {
                    this.snackbar.open('Правило збережено.', 'OK');
                } else {
                    this.snackbar.open('Правило створено.', 'OK');
                }
            },
            error: () => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }
}
<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <div class="daterange-wrapper" style="margin-bottom:8px;">
            <mat-form-field>
                <mat-label>Пошук замовлень</mat-label>
                <input matInput [formControl]="controlQuery" placeholder="..." />
                <mat-hint>Номер замовлення, трекінг, модель, магазин</mat-hint>
            </mat-form-field>
        </div>

        <div class="daterange-wrapper">
            <mat-form-field>
                <mat-label>Часовий діапазон</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="controlFrom" placeholder="ДД.ММ.РРРР">
                    <input matEndDate [formControl]="controlTo" placeholder="ДД.ММ.РРРР">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="controlFrom.hasError('matStartDateInvalid')">Некоректна дата початку</mat-error>
                <mat-error *ngIf="controlTo.hasError('matStartDateInvalid')">Некоректна дата кінця</mat-error>
            </mat-form-field>
        </div>

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <div class="orders-stats">
            <div>
                <span>Всього одиниць</span>
                <span>{{orders_quantity}}</span>
            </div>
            <div>
                <span>Сума замовлень</span>
                <span>{{amount_usd | currency}}</span>
            </div>
            <!-- <div>
                <span>Сума замовлень</span>
                <span>{{amount_uah |  currency}}</span>
            </div> -->
            <div>
                <span>Сума донатів</span>
                <span>{{donates_uah |  currency}}</span>
            </div>
            <!-- <div *ngIf="detail_units">
                <span>Грн за одиницю</span>
                <span>{{unit_uah | number: '1.2-2'}} грн</span>
            </div> -->
            <div *ngIf="detail_units">
                <span>€ за одиницю</span>
                <span>{{unit_usd| currency}}</span>
            </div>
        </div>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До замовлень' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_orders.length}}</span>
                    <small>/{{orders.length}}</small>
                </div>
            </div>
        </button>

    </div>

    @switch (view_mode) {
    @case (ViewModes.grid) {
    <cdk-virtual-scroll-viewport [itemSize]="card_height" *ngIf="orders.length" style="overflow-x:hidden">
        <div class="products-scroll">
            <div *cdkVirtualFor="let orders_group of filtered_orders_groups" class="products-row">
                <order-card *ngFor="let o of orders_group" [order]="o" [selectable]="group_actions"
                    (change)="updateSelection()"></order-card>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <div class="products-scroll" *ngIf="loading">
        <div class="products-grid-dummy">
            <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </div>
    </div>
    }
    @case (ViewModes.table) {

    <div class="table-wrapper">
        <table class="table" *ngIf="stats.length">
            <tr>
                <th></th>
                <th>Модель</th>
                <th class="text-end" *ngIf="status_filter.dict['ordered']||status_filter.dict['all']">Замовлено</th>
                <th class="text-end" *ngIf="status_filter.dict['shipped']||status_filter.dict['all']">Відправлено</th>
                <th class="text-end" *ngIf="status_filter.dict['delivered']||status_filter.dict['all']">Доставлено</th>
                <th class="text-end" *ngIf="status_filter.dict['picked']||status_filter.dict['all']">Отримано</th>
                <th class="text-end" *ngIf="status_filter.dict['received']||status_filter.dict['all']">Передано</th>
                <th class="text-end">Всього</th>
                <th class="text-end">Ціна</th>
                <th class="text-end">Донати</th>
                <th class="text-end">Середнє, €</th>
            </tr>
            <ng-container *ngFor="let s of stats">
                <tr>
                    <td class="img"><img class="inline-img product-seller-logo" [src]="s.model_image_url" /></td>
                    <td>{{s.model_name}}, <b>{{s.sku_units}}</b></td>
                    <td class="text-end" *ngIf="status_filter.dict['ordered']||status_filter.dict['all']">
                        {{s.count_ordered}}</td>
                    <td class="text-end" *ngIf="status_filter.dict['shipped']||status_filter.dict['all']">
                        {{s.count_shipped}}</td>
                    <td class="text-end" *ngIf="status_filter.dict['delivered']||status_filter.dict['all']">
                        {{s.count_delivered}}</td>
                    <td class="text-end" *ngIf="status_filter.dict['picked']||status_filter.dict['all']">
                        {{s.count_picked}}</td>
                    <td class="text-end" *ngIf="status_filter.dict['received']||status_filter.dict['all']">
                        {{s.count_received}}</td>
                    <td class="text-end">{{s.count_total}}</td>
                    <th class="text-end"><span *ngIf="s.price_usd * s.count_total">{{s.price_usd * s.count_total | currency}}</span></th>
                    <th class="text-end"><span *ngIf="s.donates_uah * s.count_total">{{s.donates_uah * s.count_total | currency}}</span></th>
                    <td class="text-end average-price-row">{{s.price_usd|currency}}</td>
                </tr>
            </ng-container>
            <tfoot>
                <tr class="total-row">
                    <td></td>
                    <td>ВСЬОГО:</td>
                    <td class="text-end">{{stats_total.total_count_ordered}}</td>
                    <td class="text-end">{{stats_total.total_count_shipped}}</td>
                    <td class="text-end">{{stats_total.total_count_delivered}}</td>
                    <td class="text-end">{{stats_total.total_count_picked}}</td>
                    <td class="text-end">{{stats_total.total_count_received}}</td>
                    <td class="text-end">{{stats_total.total_count_total}}</td>
                    <td class="text-end">{{stats_total.total_price_usd | currency}}</td>
                    <td class="text-end">{{stats_total.total_donates_uah | currency}}</td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>

    }
    }

    <div class="products-empty-message" *ngIf="orders.length && !filtered_orders.length">
        Жодне замовлення не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-empty-message" *ngIf="!loading && !orders.length">
        Замовлень ще немає.
    </div>

    <div class="products-buttons" *ngIf="!group_actions">

        <button mat-fab extended color="accent" *ngIf="orders.length && (layout.is_mobile | async)"
            (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_orders.length}}</b><small>/{{orders.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="enableGroupActions()">
            <mat-icon>library_add_check</mat-icon>
            <span *ngIf="layout.is_desktop | async">Групові дії</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="toggleViewMode()">
            <mat-icon>{{next_view_mode.icon}}</mat-icon>
            <span *ngIf="layout.is_desktop | async">{{next_view_mode.name}}</span>
        </button>

    </div>

    <div class="products-buttons products-bulk-actions" *ngIf="group_actions">

        <div class="products-bulk-actions-dummy" *ngIf="!selected_models.length">Нічого не вибрано</div>

        <div class="products-bulk-actions-selection">
            <div class="product-extra product-seller" *ngFor="let model of selected_models">
                <img class="product-seller-logo" [src]="model.model_image_url" />
                <span class="product-seller-name">{{model.model_name}}</span>
                <span class="product-seller-count">x{{model.selected_quantity}}</span>
            </div>
        </div>

        <div class="spacer"></div>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="selectAll()">
            <mat-icon>check_box</mat-icon>
            <span *ngIf="layout.is_desktop | async">Всі</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="deselectAll()">
            <mat-icon>check_box_outline_blank</mat-icon>
            <span *ngIf="layout.is_desktop | async">Очистити</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="disableGroupActions()">
            <mat-icon *ngIf="layout.is_mobile | async">close</mat-icon>
            <span *ngIf="layout.is_desktop | async">Відміна</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" [mat-menu-trigger-for]="menuBulk">
            <mat-icon>expand_less</mat-icon>
            <span *ngIf="layout.is_desktop | async">Вибрати дію</span>
        </button>

        <mat-menu #menuBulk="matMenu">

            <button mat-menu-item type="button" *ngIf="bulk_allow_pick" (click)="pick()">
                <span>Зазначити отримання</span>
                <mat-icon>inventory</mat-icon>
            </button>

            <button mat-menu-item type="button" *ngIf="bulk_allow_receive" (click)="receive()">
                <span>Зазначити передачу</span>
                <mat-icon>local_shipping</mat-icon>
            </button>

            <button mat-menu-item class="menu-item-warn" type="button" *ngIf="bulk_allow_cancel" (click)="cancel()">
                <mat-icon>cancel</mat-icon>
                <span>Відмінити замовлення</span>
            </button>

        </mat-menu>

    </div>

</app-sidebar>

import { Component, Inject } from '@angular/core';
import { DataService, Membership } from '../../data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-upsert-tenant-dialog',
    templateUrl: './upsert-tenant-dialog.component.html',
    styleUrl: './upsert-tenant-dialog.component.scss'
})
export class UpsertTenantDialogComponent {

    public static open(dialog: MatDialog, membership: Membership | null) {
        dialog.open(UpsertTenantDialogComponent, { data: membership, autoFocus: 'input', width: '400px' });
    }

    public loading = false;

    public form = new FormGroup({
        tenant_name: new FormControl('', [Validators.required]),
    });

    constructor(
        private readonly snackbar: MatSnackBar,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<UpsertTenantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly membership: Membership | null,
    ) {
        this.form.patchValue(membership || {});
    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        this.data.upsertTenant({
            tenant_affiliate: 0,
            tenant_id: this.membership?.tenant_id || 0,
            tenant_name: this.form.value.tenant_name || '',
        }).subscribe({
            next: response => {
                this.ref.close();
                this.data.memberships = response.memberships;
                this.data.tenant_id = response.tenant.tenant_id;
                if (this.membership) {
                    this.snackbar.open('Організацію збережено.', 'OK', { duration: 3000 });
                } else {
                    this.snackbar.open('Організацію створено.', 'OK', { duration: 3000 });
                }
            },
            error: () => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK', { duration: 3000 });
            }
        });

    }
}
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-signin-disabled',
    templateUrl: './signin-disabled-dialog.component.html',
    styleUrl: './signin-disabled-dialog.component.scss'
})
export class SigninDisabledDialogComponent {

    public static open(dialog: MatDialog) {
        return dialog.open(SigninDisabledDialogComponent, { autoFocus: false, width: '680px' });
    }

}

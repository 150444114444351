<div mat-dialog-title>
    <span>{{rule ? 'Редагувати правило' : 'Створити правило'}}</span>
    <button mat-stroked-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form">

        <div class="row">
            <mat-form-field>
                <mat-label>
                    Джерело сповіщення
                </mat-label>
                <mat-select formControlName="rule_type">
                    <mat-option [value]="RuleType.Price">Падіння ціни</mat-option>
                    <mat-option [value]="RuleType.Availability">Поява в наявності</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Статуси товарів
                </mat-label>
                <mat-select formControlName="rule_verification">
                    <mat-option [value]="0">Лише верифіковані</mat-option>
                    <mat-option [value]="1">Верифіковані та невідомі</mat-option>
                    <mat-option [value]="2">Без підозрілих</mat-option>
                    <mat-option [value]="3">Всі</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>
                    Сповіщення на сайті
                </mat-label>
                <mat-select formControlName="rule_website">
                    <mat-option [value]="RuleWebsite.Disabled">Вимкнені</mat-option>
                    <mat-option [value]="RuleWebsite.Enabled">Увімкнені</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Сповіщення в Telegram
                </mat-label>
                <mat-select formControlName="rule_telegram">
                    <mat-option [value]="RuleTelegram.Disabled">Вимкнені</mat-option>
                    <mat-option [value]="RuleTelegram.Silent">Завжди беззвучні</mat-option>
                    <mat-option [value]="RuleTelegram.ActiveHours">Беззвучні вночі</mat-option>
                    <mat-option [value]="RuleTelegram.Always">Завжди зі звуком</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>Модель</mat-label>
            <mat-select formControlName="model_id" (closed)="search.value=''" (opened)="search.focus()">
                <mat-select-trigger>
                    <div class="model-option"
                        *ngIf="form.controls.model_id.value&&models_dict[form.controls.model_id.value]">
                        <span>{{models_dict[form.controls.model_id.value].model_name}}</span>
                        <small>Доступно за
                            {{models_dict[form.controls.model_id.value].model_price_min|currency}}/{{models_dict[form.controls.model_id.value].sku_units}}</small>
                    </div>
                </mat-select-trigger>
                <mat-option class="mat-select-search-option">
                    <input #search (click)="$event.stopPropagation()" (input)="detector.detectChanges()"
                        placeholder="Почніть вводити для пошуку..." />
                </mat-option>
                <ng-container *ngIf="search.value">
                    <ng-container *ngFor="let m of models">
                        <mat-option [value]="m.model_id"
                            *ngIf="m.model_name.toLowerCase().includes(search.value.trim().toLowerCase())">
                            <div class="model-option">
                                <span>{{m.model_name}}</span>
                                <small>Доступно за {{m.model_price_min|currency}}/{{m.sku_units}}</small>
                            </div>
                        </mat-option>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!search.value">
                    <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                        <mat-option *ngFor="let m of d.models" [value]="m.model_id">
                            <div class="model-option">
                                <span>{{m.model_name}}</span>
                                <small>Доступно за {{m.model_price_min|currency}}/{{m.sku_units}}</small>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
            <mat-error>Виберіть модель</mat-error>
        </mat-form-field>

        <div class="row" *ngIf="form.controls.model_id.value">
            <mat-form-field>
                <mat-label>
                    Всі товари чи конкретний?
                </mat-label>
                <mat-select formControlName="rule_product_specific">
                    <mat-option [value]="false">Всі товари вказаної моделі</mat-option>
                    <mat-option [value]="true">Лише конкретний товар</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="form.controls.rule_product_specific.value">
                <mat-label>Конкретний товар</mat-label>
                <mat-select formControlName="product_id">
                    <mat-option [value]="p.product_id" *ngFor="let p of filtered_products">
                        Від <b>{{p.seller_name_short}}</b> за
                        <b>{{p.sku_last_price|currency:p.product_currency}}<span
                                *ngIf="p.sku_quantity!=1">/{{p.sku_quantity}}{{p.sku_units}}</span></b>
                    </mat-option>
                </mat-select>
                <mat-error>Виберіть продукт</mat-error>
            </mat-form-field>
        </div>

        <div class="row" *ngIf="form.controls.rule_type.value==RuleType.Price">
            <mat-form-field>
                <mat-label>Ціна за {{sku_units}}, €</mat-label>
                <input required matInput placeholder="50.00" formControlName="rule_max_unit_price_usd">
                <mat-error>Введіть число</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Максимальний лот, {{sku_units}}</mat-label>
                <input required matInput placeholder="50.00" formControlName="rule_max_sku_quantity">
                <mat-error>Введіть число</mat-error>
                <mat-hint>0 - без обмежень</mat-hint>
            </mat-form-field>
        </div>

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="onSave()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
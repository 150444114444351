import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService, DroneFull, DronePublicFlag, DroneStatus, DroneType, Model, ModelFull, Stock, UserShort, Warehouse } from '../../data.service';
import { dateToLocalDate, dateToLocalTime, getImageUrl } from '../../utils';
import { DetailVm, getAnalogBomberDroneDetailsList, getAnalogDroneDetailsList, getDigitalBomberDroneDetailsList, getDigitalDroneDetailsList, ModelStock } from '../details';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


interface WarehousesData {
    warehouses: Warehouse[];
}

@Component({
    selector: 'app-add-drone-dialog',
    templateUrl: './add-drone-dialog.component.html',
    styleUrl: './add-drone-dialog.component.scss'
})
export class AddDroneDialogComponent {

    public drone_public_flags: DronePublicFlag[] = ['price', 'craftsman', 'organization'];
    public drone_public_flags_dict: { [k: string]: string } = {
        price: 'Ціна',
        craftsman: 'Збиральник',
        organization: 'Організація',
    };

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public models: ModelFull[] = [];
    public users: UserShort[] = [];

    public static open(dialog: MatDialog) {
        return dialog.open<AddDroneDialogComponent, WarehousesData, DroneFull[]>(
            AddDroneDialogComponent,
            { autoFocus: false, width: '960px', maxHeight: '100%', panelClass: 'mobile-fullscreen', },
        );
    }

    public loading = false;

    public form = new FormGroup({
        user_id: new FormControl(this.data.profile?.user_id || 0),
        drone_number: new FormControl(''),
        drone_name: new FormControl(''),
        drone_comment: new FormControl(''),
        drone_public_comment: new FormControl(''),
        drone_status: new FormControl<DroneStatus>('draft'),
        drone_type: new FormControl<DroneType>('drone-analog'),
        drone_public: new FormControl(false),
        drone_public_flags: new FormControl<DronePublicFlag[]>([]),
        drone_sdua_url: new FormControl('', Validators.pattern(/^https:\/\/sdua.tech\/d\/\w{8}$/)),
    });

    public details: DetailVm[] = [];

    public update() {

        switch (this.form.controls.drone_type.value) {
            case 'drone-analog-bomber':
                this.details = getAnalogBomberDroneDetailsList();
                break;
            case 'drone-digital-bomber':
                this.details = getDigitalBomberDroneDetailsList();
                break;
            case 'drone-digital':
                this.details = getDigitalDroneDetailsList();
                break;
            case 'drone-analog':
            default:
                this.details = getAnalogDroneDetailsList();
                break;
        }

        this.data.getDrones().subscribe({
            next: drones => {
                this.users = drones.users;
                this.data.getModels().subscribe({
                    next: models => {
                        this.data.getStock().subscribe({
                            next: stock => this.details.forEach(detail => {
                                detail.stock = [];
                                detail.models = models.filter(m => m.detail_name === detail.detail_name);
                                stock.warehouses.filter(w => !w.warehouse_deleted).forEach(w => {
                                    const curent_stock: Warehouse & { stock: ModelStock[] } = { ...w, stock: [] };
                                    detail.stock.push(curent_stock);
                                    stock.stock.filter(s => s.warehouse_id === w.warehouse_id && s.stock_quantity > 0).forEach(s => {
                                        const model = models.find(m => m.model_id === s.model_id && m.detail_name === detail.detail_name);
                                        if (model) {
                                            curent_stock.stock.push({
                                                ...model, ...s,
                                                stock_price_usd: drones.model_price_sequences_usd[model.model_id]?.[0] || 0,
                                                stock_price_uah: drones.model_price_sequences_uah[model.model_id]?.[0] || 0,
                                            });
                                        }
                                    });
                                });
                                detail.stock = detail.stock.filter(s => s.stock.length);
                            })
                        });
                    }
                });
            }
        });

    }

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<AddDroneDialogComponent>,
    ) {
        this.update();
        this.form.controls.drone_type.valueChanges.subscribe(() => this.update());
    }

    public removeStockItem(detail: DetailVm) {
        detail.model_name = '';
        detail.warehouse_name = '';
        detail.model_image_url = '';
        detail.model_id.setValue(null);
        detail.warehouse_id.setValue(null);
    }

    public selectStockItem(detail: DetailVm, w: Warehouse, s: Stock & Model) {
        detail.model_name = s.model_name;
        detail.warehouse_name = w.warehouse_name;
        detail.model_image_url = getImageUrl(s.model_image_hash || s.model_image_url);
        detail.model_id.setValue(s.model_id);
        detail.warehouse_id.setValue(w.warehouse_id);
    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        this.data.createDrone({
            user_id: value.user_id || 0,
            drone_type: value.drone_type || 'drone-analog',
            drone_name: value.drone_name || '',
            drone_number: value.drone_number || '',
            drone_comment: value.drone_comment || '',
            drone_status: value.drone_status || 'draft',
            drone_public: value.drone_public || false,
            drone_public_flags: value.drone_public_flags || [],
            drone_sdua_url: value.drone_sdua_url || '',
            drone_public_comment: value.drone_public_comment || '',
            transfers: this.details.filter(d => d.warehouse_id.value && d.model_id.value).map(d => ({
                warehouse_id: d.warehouse_id.value || 0,
                model_id: d.model_id.value || 0,
            }))
        }).subscribe({
            next: drones => {
                this.ref.close(drones);
                this.snackbar.open('Дрон створено.', 'OK');
            },
            error: () => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public onPublicChange(e: MatSlideToggleChange, content: HTMLElement) {
        if (!e.checked) return;
        setTimeout(() => {
            content.scrollTo({ top: content.clientHeight, behavior: 'smooth' });
        });
    }

}

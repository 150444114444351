import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PROMOS } from '../promos';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-promo-dialog',
    templateUrl: './promo-dialog.component.html',
    styleUrl: './promo-dialog.component.scss'
})
export class PromoDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {}) { }

    public promos = PROMOS;

    get has_promos() {
        const now = DateTime.now();
        return now.toMillis() <= PROMOS.end.toMillis();
    }
}

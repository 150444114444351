<ng-container *ngIf="request">

    <mat-card [routerLink]="request.request_url" [class.product-soldout]="request.request_quantity<=0"
        (click)="data.click(data.current_click_source, data.CT.OpenRequestPage, request)">
        <div mat-card-image class="product-image-wrapper">

            <img class="product-image" [src]="request.model_image_url" />

            <div class="product-extras">

                <div class="product-extra">
                    {{request.detail_name}}
                </div>

                <div class="product-extra product-future-price">
                    <span>Доступно за: </span>
                    <b>{{ request.min_price | currency }}</b>
                </div>

            </div>

            <div class="product-card-actions-container">
                <button mat-mini-fab class="product-card-action" (click)="onEdit($event)" *ngIf="data.admin">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>

        <mat-card-content>

            <div class="product-name">
                <span>{{request.model_name}}</span>
            </div>

            <div class="product-price-wrapper">

                <span class="product-price">{{ request.request_max_price | currency }}</span>

                <div class="product-price-extras">

                    <small class="product-unit-price">
                        макс
                    </small>

                    <small class="product-available">
                        <b>{{request.request_quantity}}</b> треба
                    </small>

                </div>

            </div>

            <button mat-button color="primary" class="product-url">
                До запиту
            </button>

        </mat-card-content>

    </mat-card>

</ng-container>
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

export interface AlertDialogData {
  title?: string,
  messages: string[],
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertDialogComponent {
  public static open(dialog: MatDialog, data: AlertDialogData): Promise<unknown> {
    const dialogRef = dialog.open(AlertDialogComponent, { data, minWidth: '300px', maxWidth: '600px', maxHeight: '80vh', autoFocus: false, });
    return firstValueFrom(dialogRef.afterClosed());
  }

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  ) { }
}

<ng-container *ngIf="data.profile">

    <button #trigger="matMenuTrigger" mat-button color="primary" class="notifications-button"
        [mat-menu-trigger-for]="menuNotifications" (menuOpened)="menuOpened()">
        <mat-icon [matBadge]="data.profile.user_notifications_new||null"
            matBadgePosition="before">notifications</mat-icon>
    </button>

    <mat-menu #menuNotifications="matMenu" (closed)="menuClosed()">

        <div class="notifications" (click)="$event.stopPropagation()">

            <div class="menu-header">
                <mat-button-toggle-group [formControl]="control_notifications">
                    <mat-button-toggle value="new">
                        <span [matBadge]="data.profile.user_notifications_new" matBadgeOverlap="false">Нові</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="read">
                        <span [matBadge]="data.profile.user_notifications_read" matBadgeOverlap="false">Прочитані</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="skeleton-block" *ngIf="loading"></div>

            <div class="notifications-dummy" *ngIf="!loading&&!filtered_notifications.length">
                <mat-icon>notifications_off</mat-icon>
                <span *ngIf="control_notifications.value=='new'">У вас немає непрочитаних нотифікацій</span>
                <span *ngIf="control_notifications.value=='read'">У вас немає прочитаних нотифікацій</span>
            </div>

            <div class="notifications-list" *ngIf="!loading&&filtered_notifications.length">

                <ng-container *ngFor="let n of filtered_notifications">

                    <div class="notification" *ngIf="n.notification_type==RuleType.Admin">
                        <img class="notification-img" src="https://goods.ua-drones.de/assets/logo.png" />
                        <div class="notification-body">
                            <div class="notification-text small">{{n.notification_text}}</div>
                            <div class="notification-time">{{n.notification_date|dateAgo}}</div>
                        </div>
                    </div>

                    <div mat-ripple class="notification clickable"
                        *ngIf="n.notification_type==RuleType.Price&&n.product" [routerLink]="[]"
                        [queryParams]="n.product.view_query_params">

                        <img class="notification-img" [src]="n.product.model_image_url" />

                        <div class="notification-body">
                            <div class="notification-text">{{n.product.model_name}}</div>
                            <seller-chip [data]="n.product" [clickable]="false"></seller-chip>
                            <div class="notification-subtext">Ціна впала нижче
                                <b>{{n.rule_max_unit_price_usd|currency}}</b>
                            </div>
                            <div class="notification-time">{{n.notification_date|dateAgo}}</div>
                        </div>

                        <div class="notification-price">
                            <div class="notification-price-amount">{{n.sku_min_price | currency}}</div>
                            <div class="notification-price-quantity">
                                <span *ngIf="n.product.sku_quantity!=1">{{n.product.sku_quantity}}</span>
                                <span>{{n.product.sku_units}}</span>
                            </div>
                        </div>

                    </div>

                    <div mat-ripple class="notification clickable"
                        *ngIf="n.notification_type==RuleType.Availability&&n.product" [routerLink]="[]"
                        [queryParams]="n.product.view_query_params">

                        <img class="notification-img" [src]="n.product.model_image_url" />

                        <div class="notification-body">
                            <div class="notification-text">{{n.product.model_name}}</div>
                            <seller-chip [data]="n.product" [clickable]="false"></seller-chip>
                            <div class="notification-subtext">Товар зʼявився в наявності</div>
                            <div class="notification-time">{{n.notification_date|dateAgo}}</div>
                        </div>

                        <div class="notification-price">
                            <div class="notification-price-amount">{{n.sku_min_price | currency}}</div>
                            <div class="notification-price-quantity">
                                <span *ngIf="n.product.sku_quantity!=1">{{n.product.sku_quantity}}</span>
                                <span>{{n.product.sku_units}}</span>
                            </div>
                        </div>

                    </div>

                </ng-container>


            </div>

            <div class="menu-footer">
                <button mat-flat-button color="primary" (click)="openRules();trigger.closeMenu()">
                    Налаштувати персональні сповіщення
                </button>
            </div>

        </div>

    </mat-menu>

</ng-container>
import { AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewContainerRef } from "@angular/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Directive({
    selector: '[buttonLoading]',
})
export class ButtonLoadingDirective implements AfterViewInit {

    private loading = false;
    private spinner?: MatProgressSpinner;

    @Input('buttonLoading') public set active(loading: boolean) {
        this.loading = loading;
        this.updateClass();
    }

    private updateClass() {
        if (this.ref.nativeElement) {
            if (this.loading) {
                this.ref.nativeElement.classList.add('button-loading__active');
            } else {
                this.ref.nativeElement.classList.remove('button-loading__active');
            }
        }
    }

    constructor(
        private readonly ref: ElementRef<HTMLElement>,
        private readonly renderer: Renderer2,
        private readonly container: ViewContainerRef,
    ) { }

    ngAfterViewInit() {

        this.ref.nativeElement.classList.add('button-loading');

        const component = this.container.createComponent(MatProgressSpinner);
        component.instance.mode = 'indeterminate';
        component.instance.color = 'accent';
        component.instance.diameter = 24;

        this.spinner = component.instance;

        this.ref.nativeElement.insertBefore(
            component.location.nativeElement,
            this.ref.nativeElement.firstChild
        );

        this.updateClass();

    }

}

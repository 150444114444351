import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { MotorsComponent } from './motors.component';
import { ModelDialogComponent } from '../model/model.component';
import { PromosModule } from '../promos/promos.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';



@NgModule({
    declarations: [
        MotorsComponent,
    ],
    exports: [
        MotorsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        PromosModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgApexchartsModule,
    ]
})
export class MotorsModule { }

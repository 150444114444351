import { Routes } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { RequestComponent } from './request/request.component';
import { RequestsComponent } from './requests/requests.component';
import { AuthGuard } from './auth.guard';
import { ModelsComponent } from './models/models.component';
import { OrdersComponent } from './orders/orders.component';
import { PayoutsComponent } from './payouts/payouts.component';
import { StockComponent } from './stock/stock.component';
import { AboutComponent } from './about/about.component';
import { TenantGuard } from './tenant.guard';
import { MotorsComponent } from './motors/motors.component';
import { DronesComponent } from './drones/drones.component';
import { TrackComponent } from './track/track.component';
import { SellersComponent } from "./sellers/sellers.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScrapperGuard } from './scrapper.guard';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { LayoutComponent } from './layout.component';
import { DroneComponent } from './drone.component';
import { PrintComponent } from './print/print.component';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
    {
        path: 'drone/:drone_uuid_short',
        component: DroneComponent,
        title: 'Дрон | goods.ua-drones.de',
    },
    {
        path: 'print',
        component: PrintComponent,
        title: 'Друк | goods.ua-drones.de',
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'track',
                component: TrackComponent,
                title: 'Трекінг | goods.ua-drones.de',
            },
            {
                path: 'track/:tracking_number',
                component: TrackComponent,
                title: 'Трекінг | goods.ua-drones.de',
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard, ScrapperGuard],
                title: 'Дошка | goods.ua-drones.de',
            },
            {
                path: 'marketplace',
                component: MarketplaceComponent,
                title: 'Барахолка | goods.ua-drones.de',
            },
            {
                path: 'payouts',
                component: PayoutsComponent,
                canActivate: [AuthGuard, TenantGuard],
                title: 'Виплати | goods.ua-drones.de',
            },
            {
                path: 'drones',
                component: DronesComponent,
                canActivate: [AuthGuard, TenantGuard],
                title: 'Дрони | goods.ua-drones.de',
            },
            {
                path: 'stock',
                component: StockComponent,
                canActivate: [AuthGuard, TenantGuard],
                title: 'Склад | goods.ua-drones.de',
            },
            {
                path: 'orders',
                canActivate: [AuthGuard, TenantGuard],
                component: OrdersComponent,
                title: 'Замовлення | goods.ua-drones.de',
            },
            {
                path: 'orders/all',
                canActivate: [AuthGuard, TenantGuard],
                component: OrdersComponent,
                title: 'Всі замовлення | goods.ua-drones.de',
            },
            {
                path: 'requests',
                component: RequestsComponent,
                canActivate: [AuthGuard, TenantGuard],
                title: 'Запити | goods.ua-drones.de',
            },
            {
                path: 'requests/:model_name',
                component: RequestComponent,
                canActivate: [AuthGuard, TenantGuard],
                title: 'Запити | goods.ua-drones.de',
            },
            {
                path: 'info/motors',
                component: MotorsComponent,
                title: 'Мотори | goods.ua-drones.de',
            },
            {
                path: 'info',
                component: AboutComponent,
                title: 'Інфо | goods.ua-drones.de',
            },
            {
                path: 'info/:sub',
                component: AboutComponent,
                title: 'Інфо | goods.ua-drones.de',
            },
            {
                path: 'models',
                component: ModelsComponent,
                title: 'Моделі | goods.ua-drones.de',
            },
            {
                path: 'sellers',
                component: SellersComponent,
                title: 'Продавці | goods.ua-drones.de',
            },
            {
                path: 'products',
                component: ProductsComponent,
                title: 'Продукти | goods.ua-drones.de',
            },
            {
                path: '',
                component: HomeComponent,
                title: 'Головна | swarm.army',
            },
        ],
    },
];

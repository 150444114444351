import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentFull, DataService, DroneFull, DroneSdua, Tenant } from './data.service';
import { base62ToUuid } from './utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

const main_details = ['Frame', 'Motors', 'Props', 'Stack', 'Camera', 'VTX', 'VTX Antenna', 'RX', 'Digital Video Kit']

@Component({
    selector: 'app-drone',
    templateUrl: './drone.component.html',
    styleUrl: './drone.component.scss'
})
export class DroneComponent {

    public loading = true;
    public drone: DroneFull | null = null;
    public tenant: Tenant | null = null;
    public sdua: DroneSdua | null = null;

    public sdua_statuses: { [k: string]: string } = {
        'new': 'Новий',
        'shipped_for_qa': 'Відправлений на тестування',
        'received': 'Отриманий',
        'hardware_tested': 'Комплектуючі перевірено',
        'problematic': 'Проблемний',
        'flight_tested': 'Облітаний',
        'ready_for_shipping': 'Готовий до відправки',
        'shipped_to_combat_unit': 'Відправлений в підрозділ',
        'shipped_directly': 'Відправлений напряму',
        'removed': 'Видалений',
    };

    public getMainDetails() {
        return this.drone?.details.filter(d => main_details.includes(d.detail_name));
    }

    public getAdditionalDetails() {
        return this.drone?.details.filter(d => !main_details.includes(d.detail_name));
    }

    constructor(
        public readonly data: DataService,
        private readonly route: ActivatedRoute,
        private readonly snackbar: MatSnackBar,
    ) {

        const drone_uuid_short = route.snapshot.params['drone_uuid_short'];

        data.getDrone(base62ToUuid(drone_uuid_short)).subscribe({
            next: response => {
                this.loading = false;
                this.drone = response?.drones?.[0] || null;
                this.tenant = response.tenant || null;
                if (this.drone?.drone_sdua_url) {
                    this.data.getDroneSdua(this.drone).subscribe({ next: sdua => this.sdua = sdua });
                }

                if (this.drone) {
                    this.data.getComments({ drone_id: this.drone.drone_id })
                        .subscribe({
                            next: comments => {
                                this.comments = this.data.superadmin
                                    ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                                this.sortComments();
                            }
                        });
                }
            },
            error: () => this.loading = false,
        });

    }

    public comments: CommentFull[] | null = null;
    public control_comment_text = new FormControl<string>('');
    public comment_loading = false;
    public comment_edited: CommentFull | null = null;

    public commentEdit(comment: CommentFull) {
        this.comment_edited = comment;
        this.control_comment_text.setValue(comment.comment_text);
    }

    public clearComment() {
        this.comment_edited = null;
        this.control_comment_text.setValue('');
    }

    public commentPin(comment_to_pin: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_pin,
            comment_pinned: comment_to_pin.comment_pinned ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_pin, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public commentDelete(comment_to_delete: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_delete,
            comment_deleted: comment_to_delete.comment_deleted ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_delete, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public upsertComment() {

        const drone = this.drone;

        const comment_text = this.control_comment_text.value;

        if (!drone) return;

        if (!comment_text || comment_text.length < 10) {
            this.snackbar.open('Введіть текст коментаря', 'OK');
            return;
        }

        if (comment_text.length >= 4096) {
            this.snackbar.open('Коментар занадто довний', 'OK');
            return;
        }

        if (comment_text.includes('<script') || comment_text.includes('<link') || comment_text.includes('<object')) {
            this.snackbar.open('Некоректний коментар', 'OK');
            return;
        }

        this.comment_loading = true;

        this.data.upsertComment({
            comment_id: this.comment_edited?.comment_id || null,
            drone_id: drone.drone_id,
            model_id: null,
            seller_id: null,
            product_id: null,
            comment_text: comment_text,
            comment_pinned: this.comment_edited?.comment_pinned ?? 0,
            comment_deleted: this.comment_edited?.comment_deleted ?? 0,
        }).subscribe({
            next: comment => {

                this.comment_loading = false;

                if (this.comment_edited) {
                    Object.assign(this.comment_edited, comment);
                    this.comment_edited = null;
                } else {
                    this.comments?.push(comment);
                }

                this.sortComments();

                this.control_comment_text.setValue('');

            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    private sortComments() {
        this.comments?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }



}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService, Payout, UserMoney } from '../data.service';
import { LayoutService } from '../layout.service';
import { UserDialogComponent } from './user-dialog/user-dialog.component';


@Component({
    selector: 'app-payouts',
    templateUrl: './payouts.component.html',
    styleUrl: './payouts.component.scss'
})
export class PayoutsComponent {

    public payouts: Payout[] = [];
    public users: UserMoney[] = [];
    public loading = false;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) {
        this.data.tenant_id_cnange.subscribe(() => {
            this.loading = true;
            this.data.getPayouts().subscribe({
                next: ({ payouts, users }) => {

                    if (data.admin) {
                        this.payouts = payouts.reverse();
                    } else {
                        this.payouts = payouts.filter(p => p.sender_user_id === data.profile?.user_id).reverse();
                    }

                    this.users = users.filter(u => !u.membership_deleted).map(u => {
                        return {
                            ...u,
                            balance: this.getBalance(u)
                        }
                    })
                    this.loading = false;
                },
                error: (e) => {
                    this.loading = false;
                }
            });
        });
    }

    public getBalance(u: UserMoney) {
        const amount = u.order_amount_uah - u.order_donate_amount_uah - u.payout_amount_uah;
        if (Math.abs(amount) < 1) return 0;
        return amount;
    }

    public async onAdd() {
        const result = await UserDialogComponent.open(this.dialog, null);
        if (result != null) {
            this.users = this.updateList(this.users, result);
        }
    }
    public async onEditUser(user: UserMoney) {
        const result = await UserDialogComponent.open(this.dialog, user);
        if (result != null) {
            this.users = this.updateList(this.users, result);
        }
    }

    private updateList(users: UserMoney[], user: UserMoney) {
        if (user.membership_deleted) {
            users.splice(users.indexOf(user), 1);
            return [...users];
        } else {
            return users.some(u => u.user_id === user.user_id)
                ? users.map(u => u.user_id === user.user_id ? { ...u, ...user } : u)
                : [...users, { ...user, payout_amount_uah: 0, order_amount_uah: 0, order_donate_amount_uah: 0, balance: 0 }];
        }
    }

}

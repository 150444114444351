<mat-dialog-content class="mat-typography">

    <form [formGroup]="form">

        <img class="profile-avatar" [src]="user?.user_image_url||'/assets/logo.png'" />

        <div>
            <mat-form-field>
                <mat-label>Пошта</mat-label>
                <input matInput formControlName="user_email" [readonly]="isEdit">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Нік</mat-label>
                <input matInput formControlName="user_nick" placeholder="omelka">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Ім'я</mat-label>
                <input matInput formControlName="user_name" placeholder="Омелько Крутивус">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Роль</mat-label>
                <mat-select formControlName="membership_role"
                    [disabled]="data.profile?.user_id==dialogData.user?.user_id">
                    <mat-option [value]="'buyer'">Закупівельник</mat-option>
                    <mat-option [value]="'accountant'">Бухгалтер</mat-option>
                    <mat-option [value]="'craftsman'">Збиральник</mat-option>
                    <mat-option [value]="'admin'">Адмін</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Номер карти (опціонально)</mat-label>
                <input matInput formControlName="user_card_number" placeholder="1111222233334444">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Назва карти (опціонально)</mat-label>
                <input matInput formControlName="user_card_name" placeholder="Карта Моно">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Рахунок IBAN (опціонально)</mat-label>
                <input matInput formControlName="user_iban_number" placeholder="UA000000000000000000000000000">
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-label>Назва рахунку (опціонально)</mat-label>
                <input matInput formControlName="user_iban_name" placeholder="Рахунок Приват">
            </mat-form-field>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="onDelete()" *ngIf="user&&user.user_id!==this.data.profile?.user_id">
        <span>Видалити</span>
        <mat-icon>delete</mat-icon>
    </button>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close>Закрити</button>
    <button mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
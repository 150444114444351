<mat-tab-group mat-stretch-tabs>
    <mat-tab label="Промокоди" *ngIf="has_promos">

        <div class="tab-content">

            <p style="display:flex;gap:8px;align-items:center;">
                <span class="product-extra">{{promos.start.toFormat('dd.MM.y HH:mm')}}</span>
                <span> - </span>
                <span class="product-extra">{{promos.end.toFormat('dd.MM.y HH:mm')}}</span>
            </p>

            <p>Клікніть на код, щоб скопіювати його.</p>

            <div *ngFor="let group of promos.promocodes" style="margin-top: 16px;">

                <div class="group-title" *ngIf="group.title">{{group.title}}</div>
                <div class="group-subtitle" *ngIf="group.subtitle">{{group.subtitle}}</div>

                <mat-chip-set>
                    <mat-chip *ngFor="let c of group.codes" [clipboardCopy]="c.promo_codes[0]"
                        [matTooltip]="c.promo_codes[0]+' спопійовано'">
                        <b>{{c.promo_codes[0]}}</b>
                        <small> {{group.currency}}{{c.promo_discount}} від
                            {{group.currency}}{{c.promo_amount}}</small>
                    </mat-chip>
                </mat-chip-set>

            </div>

            <p>
                <b>Про бронювання купонів.</b> Купони потрібно забронювати, як тільки вони починають діяти.
                В такому випадку купон буде заброньований за вами до кінця розпродажу, та його можна буде гарантовано використати.
                Алгоритм бронювання залежить від роботи AliExpress. Якщо ви заходите на головну сторінку, і бачите промокоди вгорі,
                з кнопкою "Отримати", "Claim" - то забронювати їх можна ДО початку розпродажу, просто натиснувши на цю кнопку.
                Якщо кнопки не було, потрібно в день початку розпродажу о першій ночі додати будь-які товари в корзину на $600.00, 
                дійти до оплати, та почергово ввести всі купони.
            </p>

        </div>

    </mat-tab>
    <mat-tab label="Графік акцій">
        <div class="tab-content">
            <p>
                <a href="https://s.click.aliexpress.com/e/_Ddv9qRl" target="_blank">Графік розпродажів</a> на листопад місяць нижче.
            </p>
            <img src="https://assets.swarm.army/photos/calendar-11-24.jpg" />
        </div>
    </mat-tab>
</mat-tab-group>

<button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
</button>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsTableComponent } from './products-table/products-table.component';
import { ProductLineComponent } from './product-line/product-line.component';
import { MaterialModule } from '../material/material.module';
import { ProductStarsComponent } from './product-stars/product-stars.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { SharedModule } from '../shared/shared.module';
import { ProductsComponent } from './products.component';
import { PromosModule } from '../promos/promos.module';
import { RouterModule } from '@angular/router';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        SharedModule,
        PromosModule,
        ReactiveFormsModule,
        TableVirtualScrollModule,
    ],
    declarations: [
        ProductsComponent,
        ProductsTableComponent,
        ProductLineComponent,
        ProductStarsComponent,
        ProductCardComponent,
    ],
    exports: [
        ProductsTableComponent,
        ProductLineComponent,
        ProductStarsComponent,
        ProductCardComponent,
    ]
})
export class ProductsModule { }

<div mat-dialog-title *ngIf="data.title">
    {{ data.title }}
</div>

<mat-dialog-content class="mat-typography">
    <p *ngFor="let m of data.messages">
        {{m}}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{data.no||'Відміна'}}</button>
    <button mat-flat-button [mat-dialog-close]="true">{{data.yes||'OK'}}</button>
</mat-dialog-actions>
import { Component, OnDestroy } from '@angular/core';
import { DataService } from '../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm/confirm.component';
import { filter } from 'rxjs';


@Component({
    selector: 'telegram-button',
    templateUrl: './telegram-button.component.html',
    styleUrl: './telegram-button.component.scss'
})
export class TelegramButtonComponent implements OnDestroy {

    public loading = false;

    constructor(public readonly data: DataService, private readonly dialog: MatDialog) { }

    public telegram_bind_interval = 0;

    public ngOnDestroy() {
        clearInterval(this.telegram_bind_interval);
        this.telegram_bind_interval = 0;
    }

    public bindTelegram() {
        clearInterval(this.telegram_bind_interval);
        this.telegram_bind_interval = setInterval(() => {
            this.data.reloadProfile().subscribe(profile => {
                if (profile.profile.user_telegram_id) {
                    clearInterval(this.telegram_bind_interval);
                    this.telegram_bind_interval = 0;
                    if (this.data.profile) Object.assign(this.data.profile, profile.profile);
                    this.data.showMessage('Профіль в Telegram додано.');
                }
            });
        }, 5000) as any as number;
    }

    public unbindTelegram() {

        const user = this.data.profile?.user_id ? this.data.profile : null;

        if (!user || this.loading) {
            return;
        }

        ConfirmDialogComponent.open(this.dialog, {
            messages: [
                'Дійсно бажаєте відкріпити ваш профіль в Telegram?',
                'Ви більше не будете отримувати сповіщення від бота.',
            ],
            yes: 'Відкріпити',
            no: 'Відміна',
        }).afterClosed().pipe(filter(r => !!r)).subscribe(() => {

            this.loading = true;

            this.data.upsertUser({ ...user, user_telegram_id: '', user_telegram_name: '', user_telegram_username: '', }).subscribe({
                next: u => {

                    if (user) {
                        Object.assign(user, u);
                    }

                    this.loading = false;
                    this.data.showMessage('Профіль в Telegram відкріплено.');

                    this.data.reloadProfile();

                }, error: error => {
                    this.loading = false;
                    this.data.showMessage('Сталася помилка!');
                }
            });

        });

    }


}
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { LayoutService } from '../layout.service';
import { Utils, RequestCommandId, ResponseCommandId, NiimbotBluetoothClient, ImageEncoder, PrintTaskVersion } from "./niimlib";
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent } from '../shared/dialogs/alert/alert.component';

const KEY_FONT_SIZE = 'print-font-size';
const KEY_LABEL_SIZE = 'print-label-size';

interface LabelSize {
    height: number;
    width: number;
    label: string;
}

@Component({
    selector: 'app-print',
    templateUrl: './print.component.html',
    styleUrl: './print.component.scss'
})
export class PrintComponent implements AfterViewInit {

    public font_sizes = [
        '7', '8', '9', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
        '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
        '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
    ];

    public label_sizes = [
        { height: 6, width: 22, label: '6 х 22 мм' },
        { height: 7, width: 60, label: '7 х 60 мм' },
        { height: 8, width: 25, label: '8 х 25 мм' },
        { height: 8, width: 20, label: '8 х 20 мм' },
        { height: 8, width: 100, label: '8 х 100 мм' },
        { height: 9, width: 22, label: '9 х 22 мм' },
        { height: 9, width: 60, label: '9 х 60 мм' },
        { height: 10, width: 20, label: '10 х 20 мм' },
        { height: 10, width: 25, label: '10 х 25 мм' },
        { height: 12, width: 6, label: '12 х 6 мм' },
        { height: 12, width: 22, label: '12 х 22 мм' },
        { height: 12, width: 25, label: '12 х 25 мм' },
        { height: 12, width: 28, label: '12 х 28 мм' },
        { height: 12, width: 30, label: '12 х 30 мм' },
        { height: 12, width: 35, label: '12 х 35 мм' },
        { height: 12, width: 40, label: '12 х 40 мм' },
        { height: 12, width: 50, label: '12 х 50 мм' },
        { height: 12, width: 75, label: '12 х 75 мм' },
        { height: 12.5, width: 99, label: '12.5 х 99 мм' },
        { height: 12.5, width: 109, label: '12.5 х 109 мм' },
        { height: 13, width: 30, label: '13 х 30 мм' },
        { height: 13, width: 35, label: '13 х 35 мм' },
        { height: 13, width: 45, label: '13 х 45 мм' },
        { height: 13, width: 85, label: '13 х 85 мм' },
        { height: 14, width: 20, label: '14 х 20 мм' },
        { height: 14, width: 25, label: '14 х 25 мм' },
        { height: 14, width: 28, label: '14 х 28 мм' },
        { height: 14, width: 30, label: '14 х 30 мм' },
        { height: 14, width: 40, label: '14 х 40 мм' },
        { height: 14, width: 50, label: '14 х 50 мм' },
        { height: 14, width: 54, label: '14 х 54 мм' },
        { height: 14, width: 60, label: '14 х 60 мм' },
        { height: 14, width: 70, label: '14 х 70 мм' },
        { height: 14, width: 88, label: '14 х 88 мм' },
        { height: 14, width: 120, label: '14 х 120 мм' },
        { height: 15, width: 26, label: '15 х 26 мм' },
        { height: 15, width: 30, label: '15 х 30 мм' },
        { height: 15, width: 50, label: '15 х 50 мм' },
        { height: 20, width: 30, label: '20 х 30 мм' },
        { height: 20, width: 40, label: '20 х 40 мм' },
        { height: 20, width: 45, label: '20 х 45 мм' },
        { height: 20, width: 170, label: '20 х 170 мм' },
        { height: 21, width: 21, label: '21 х 21 мм' },
        { height: 22, width: 22, label: '22 х 22 мм' },
        { height: 24, width: 24, label: '24 х 24 мм' },
        { height: 25, width: 15, label: '25 х 15 мм' },
        { height: 25, width: 30, label: '25 х 30 мм' },
        { height: 25, width: 40, label: '25 х 40 мм' },
        { height: 25, width: 50, label: '25 х 50 мм' },
        { height: 25, width: 60, label: '25 х 60 мм' },
        { height: 25, width: 75, label: '25 х 75 мм' },
        { height: 25, width: 76, label: '25 х 76 мм' },
        { height: 25, width: 78, label: '25 х 78 мм' },
        { height: 30, width: 15, label: '30 х 15 мм' },
        { height: 30, width: 20, label: '30 х 20 мм' },
        { height: 30, width: 30, label: '30 х 30 мм' },
        { height: 30, width: 40, label: '30 х 40 мм' },
        { height: 30, width: 60, label: '30 х 60 мм' },
        { height: 30, width: 70, label: '30 х 70 мм' },
        { height: 30, width: 130, label: '30 х 130 мм' },
        { height: 30, width: 170, label: '30 х 170 мм' },
        { height: 31, width: 31, label: '31 х 31 мм' },
        { height: 31, width: 40, label: '31 х 40 мм' },
        { height: 33, width: 33, label: '33 х 33 мм' },
        { height: 34, width: 34, label: '34 х 34 мм' },
        { height: 35, width: 60, label: '35 х 60 мм' },
        { height: 40, width: 15, label: '40 х 15 мм' },
        { height: 40, width: 20, label: '40 х 20 мм' },
        { height: 40, width: 30, label: '40 х 30 мм' },
        { height: 40, width: 40, label: '40 х 40 мм' },
        { height: 40, width: 60, label: '40 х 60 мм' },
        { height: 40, width: 80, label: '40 х 80 мм' },
        { height: 41, width: 31, label: '41 х 31 мм' },
        { height: 41, width: 41, label: '41 х 41 мм' },
        { height: 42, width: 21, label: '42 х 21 мм' },
        { height: 44, width: 44, label: '44 х 44 мм' },
        { height: 45, width: 20, label: '45 х 20 мм' },
        { height: 45, width: 30, label: '45 х 30 мм' },
        { height: 45, width: 60, label: '45 х 60 мм' },
        { height: 45, width: 90, label: '45 х 90 мм' },
        { height: 45, width: 70, label: '45 х 70 мм' },
        { height: 45, width: 80, label: '45 х 80 мм' },
        { height: 50, width: 15, label: '50 х 15 мм' },
        { height: 50, width: 20, label: '50 х 20 мм' },
        { height: 50, width: 30, label: '50 х 30 мм' },
        { height: 50, width: 31, label: '50 х 31 мм' },
        { height: 50, width: 40, label: '50 х 40 мм' },
        { height: 50, width: 42, label: '50 х 42 мм' },
        { height: 50, width: 50, label: '50 х 50 мм' },
        { height: 50, width: 60, label: '50 х 60 мм' },
        { height: 50, width: 70, label: '50 х 70 мм' },
        { height: 50, width: 80, label: '50 х 80 мм' },
        { height: 50, width: 170, label: '50 х 170 мм' },
        { height: 60, width: 40, label: '60 х 40 мм' },
        { height: 60, width: 80, label: '60 х 80 мм' },
        { height: 70, width: 40, label: '70 х 40 мм' },
        { height: 70, width: 50, label: '70 х 50 мм' },
        { height: 70, width: 80, label: '70 х 80 мм' },
        { height: 75, width: 40, label: '75 х 40 мм' },
        { height: 75, width: 75, label: '75 х 75 мм' },
    ];

    private getInitialFontSize() {
        const default_font_size = '12';
        try {
            const stored_font_size = localStorage.getItem(KEY_FONT_SIZE);
            if (stored_font_size && this.font_sizes.includes(stored_font_size))
                return stored_font_size;

        } catch (e) {
            console.log(e);
        }
        return default_font_size;
    }

    private getInitialLabelSize(): LabelSize {
        const default_width = 30;
        const default_height = 14;
        try {
            const stored_label_size = localStorage.getItem(KEY_LABEL_SIZE);
            if (stored_label_size) {
                const parsed_label_size: LabelSize = JSON.parse(stored_label_size);
                const found_label_size = this.label_sizes
                    .find(s => s.width === parsed_label_size.width && s.height === parsed_label_size.height);
                if (found_label_size) return found_label_size;
            }

        } catch (e) {
            console.log(e);
        }
        return this.label_sizes
            .find(s => s.width === default_width && s.height === default_height)!;
    }

    public control_text = new FormControl('');
    public control_font_size = new FormControl(this.getInitialFontSize());
    public control_label_size = new FormControl(this.getInitialLabelSize());

    public loading = false;

    public texts: string[] = [];
    public current_index = 0;

    public setIndex(index: number) {
        if (this.texts[index] === undefined) return;
        this.current_index = index;
        this.control_text.setValue(this.texts[this.current_index]);
    }

    constructor(
        public readonly data: DataService,
        public readonly dialog: MatDialog,
        public readonly layout: LayoutService,
        private readonly route: ActivatedRoute,
        private readonly detector: ChangeDetectorRef,
        private readonly router: Router,
    ) {

        const text = this.route.snapshot.queryParams['text'];

        if (Array.isArray(text)) {
            this.texts = text;
            this.control_text.setValue((text[0] || '').split(';').join('\n'));
        } else {
            this.texts = [text];
            this.control_text.setValue(text);
        }

        this.control_text.valueChanges.subscribe(() => {
            this.texts[this.current_index] = this.control_text.value || '';
            this.redraw();
        });
        this.control_font_size.valueChanges.subscribe(s => {
            localStorage.setItem(KEY_FONT_SIZE, s!);
            this.redraw();
        });
        this.control_label_size.valueChanges.subscribe(s => {
            localStorage.setItem(KEY_LABEL_SIZE, JSON.stringify(s));
            this.redraw();
        });
    }

    ngAfterViewInit(): void {
        this.redraw();
    }

    private redraw() {

        const label_size = this.control_label_size.value!;

        const props = {
            width: label_size.width * 8,
            height: label_size.height * 8,
        };

        const canvas = document.getElementById('canvas') as HTMLCanvasElement;
        canvas.width = props.width;
        canvas.height = props.height;


        const ctx = canvas.getContext("2d")!;

        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);


        ctx.fillStyle = "black";
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        ctx.font = (Number(this.control_font_size.value) * 2) + 'px sans-serif';

        const text = this.control_text.value || '';

        const lines = text.split('\n');

        let total_height = 0;

        for (let l of lines) {
            const metrics = ctx.measureText(l);
            total_height += metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        }

        let y = (props.height - total_height) / 2;

        for (let l of lines) {
            const metrics = ctx.measureText(l);
            const x = (props.width - metrics.width) / 2;
            ctx.fillText(l, x, y, props.width);
            y += metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        }

    }

    private debugClient(client: NiimbotBluetoothClient) {

        client.addEventListener("packetsent", (e) => {
            console.log(`>> ${Utils.bufToHex(e.packet.toBytes())} (${RequestCommandId[e.packet.command]})`);
        });

        client.addEventListener("packetreceived", (e) => {
            console.log(`<< ${Utils.bufToHex(e.packet.toBytes())} (${ResponseCommandId[e.packet.command]})`);
        });

        client.addEventListener("connect", () => {
            console.log("connected");
        });

        client.addEventListener("disconnect", () => {
            console.log("disconnected");
        });

        client.addEventListener("printprogress", (e) => {
            console.log(`Page ${e.page}/${e.pagesTotal}, Page print ${e.pagePrintProgress}%, Page feed ${e.pageFeedProgress}%`);
        });

    }

    public async print() {

        if (this.loading) return;

        if (!Utils.isBluetoothSupported()) {
            AlertDialogComponent.open(this.dialog, {
                messages: [
                    this.data.lang === 'en' ? 'It seems loke your browser does not support Bluetooth' : 'Схоже, що ваш браузер не підтимує Bluetooth.',
                    this.data.lang === 'en' ? 'Chech the Bluetooth support and the settings required using the link below' : 'Перевірте підтримку Bluetooth та необхідні налаштування за посиланням',
                    'https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md#chrome',
                ]
            });
            return;
        }

        console.log('print');

        const quantity = 1;
        const client = new NiimbotBluetoothClient();

        console.log('print.client_created');

        this.loading = true;

        try {

            this.debugClient(client);

            await client.connect();

            console.log('print.connected');

            const canvas = document.getElementById('canvas') as HTMLCanvasElement;

            for (let i = 0; i < this.texts.length; ++i) {

                this.current_index = i;
                this.control_text.setValue(this.texts[this.current_index]);
                this.detector.detectChanges();

                await new Promise(r => setTimeout(r, 10));

                const image = ImageEncoder.encodeCanvas(canvas, 'left');

                console.log('print.image_encoded');

                const taskVersion = client.getPrintTaskVersion() ?? PrintTaskVersion.V3;

                console.log('print.taskVersion', taskVersion);

                await client.abstraction.print(taskVersion, image, { quantity });
                console.log('print.print_sent');

                await client.abstraction.waitUntilPrintFinished(taskVersion, quantity, {
                    pollIntervalMs: 100,
                    timeoutMs: 10_000,
                });
                console.log('print.print_finished');
                await client.abstraction.printEnd();
                console.log('print.print_end');

            }

            this.loading = false;
            this.detector.detectChanges();

        } catch (e) {

            console.error(e);

            this.loading = false;
            this.detector.detectChanges();

        } finally {

            await client.disconnect();

        }

    }

}
import { Component, HostListener } from '@angular/core';
import { DataService, Filter, KitFull, ProductFull } from '../data.service';
import { StatsService } from '../services/stats.service';
import { LayoutService } from '../layout.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { KitEditDialogComponent } from '../kit-edit-dialog/kit-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { RequestKitDialogComponent } from '../dialogs/request-kit-dialog/request-kit-dialog.component';
import { ProductCustomEditDialogComponent } from "../product-custom-edit-dialog/product-custom-edit-dialog.component";
import { getKitProductsAndPrice } from '../utils';


const FILTERS_KEY = 'homepage';
const FILTERS_KITS_KEY = 'homepage-kits';
const VIEW_MODE_KEY = 'homepage-viewmode';
const FILTERS_MODE_KEY = 'homepage-filtersmode';

enum ViewModes {
    grid = 'grid',
    table = 'table',
    list = 'list',
}

enum FiltersModes {
    products = 'products',
    kits = 'kits',
}

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrl: './products.component.scss'
})
export class ProductsComponent {

    public card_height = 420;
    public products: ProductFull[] = [];
    public filtered_products: ProductFull[] = [];
    public filtered_products_groups: ProductFull[][] = [];

    public readonly top_price_filter: Filter = {
        key: 'price',
        name: 'Ціна',
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'all': true, 'top': false, },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'top', name: 'TOP-ціна', image: '', tooltip: 'Товари за дійсно вигідною ціною, що вручну підібрана адміністратором ресурсу' },
        ],
    };

    public readonly tags_filter: Filter = {
        key: 'tag',
        name: 'Теги',
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'all': false, '7"': false, '8"': false, '10"': false, 'SD 7"': true, 'SD 8"': false, 'SD 10"': false, 'VD 7"': false, 'VD 8"': false, 'VD 10"': false, 'SOLDERING': false, '3D': false, 'FILAMENT': false, 'WING': false, 'Ukraine': false, 'Tools': false, 'unsorted': false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: '7"', name: '7"', image: '' },
            { id: '8"', name: '8"', image: '' },
            { id: '10"', name: '10"', image: '' },
            { id: 'SD 7"', name: 'SD 7"', image: '/assets/tags/sdua-logo.png' },
            { id: 'SD 8"', name: 'SD 8"', image: '/assets/tags/sdua-logo.png' },
            { id: 'SD 10"', name: 'SD 10"', image: '/assets/tags/sdua-logo.png' },
            { id: 'VD 7"', name: 'VD 7"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'VD 8"', name: 'VD 8"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'VD 10"', name: 'VD 10"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'SOLDERING', name: 'Паяння', image: '' },
            { id: '3D', name: '3D-друк', image: '' },
            { id: 'FILAMENT', name: '3D-друк Філамент', image: '' },
            { id: 'Ukraine', name: 'Ukraine', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/200px-Flag_of_Ukraine.svg.png' },
            { id: 'Tools', name: 'Tools', image: '' },
            { id: 'unsorted', name: 'Без тега', image: '' },
            { id: 'DIGITAL VIDEO', name: 'Цифра', image: '' },
            { id: 'WING', name: 'Крила', image: '' },
            { id: 'BATTERIES', name: 'Батареї', image: '' },
        ],
        // dict: { 'all': false, 'SDUA 7"': true, 'SDUA 8"': false, 'SDUA 10"': false, 'VD 7"': false, 'Soldering': false, '3D': false },
        // options: [
        //     { id: 'all', name: 'Всі', image: '' },
        //     { id: 'SDUA 7"', name: 'SDUA 7"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'SDUA 8"', name: 'SDUA 8"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'SDUA 10"', name: 'SDUA 10"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'VD 7"', name: 'VD 7"', image: 'https://scontent-fra5-1.xx.fbcdn.net/v/t39.30808-1/318043295_145448531581939_6828632755146268235_n.jpg?stp=dst-jpg_p480x480&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ChILHN1nlcMAb6HAfbC&_nc_ht=scontent-fra5-1.xx&oh=00_AfBI53llmoCtGGJO7aWxIVbFOKNzjTcmTr4vZ_sJi1UanQ&oe=6624FD3F' },
        //     { id: 'Soldering', name: 'Паяння', image: '' },
        //     { id: '3D', name: '3D-друк', image: '' },
        // ],
    };

    public readonly kits_tags_filter: Filter = {
        key: 'kit',
        name: 'Набори',
        radio: true,
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'sd-7-optimal-kit': true, },
        options: [],
        // dict: { 'SD 7" OPTIMAL KIT': true, 'SD 7" BUDJET KIT': false, 'SD 7" RISK KIT': false, 'SD 8" OPTIMAL KIT': false, 'SD 10" OPTIMAL KIT': false, 'VD 7" OPTIMAL KIT': false, 'VD 8" OPTIMAL KIT': false, 'VD 10" OPTIMAL KIT': false, },
        // options: [
            // { id: 'SD 7" OPTIMAL KIT', name: 'SD 7" Оптимальний', image: '/assets/tags/sdua-logo.png', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: 'SD 7" BUDJET KIT', name: 'SD 7" Бюджетний', image: '/assets/tags/sdua-logo.png', tooltip: 'Найдешевший набір з адекватних комплектуючих в перевірених продавців' },
            // { id: 'SD 7" RISK KIT', name: 'SD 7" Ризиковий', image: '/assets/tags/sdua-logo.png', tooltip: 'Комплектуючі з рекомендованих, включно з неперевіреними продавцями. Для тих, хто любить ризик. Іноді тут буде дешевше, ніж в перевірених (але не завжди).' },
            // { id: 'SD 8" OPTIMAL KIT', name: 'SD 8" Оптимальний', image: '/assets/tags/sdua-logo.png', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: 'SD 10" OPTIMAL KIT', name: 'SD 10" Оптимальний', image: '/assets/tags/sdua-logo.png', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: 'VD 7" OPTIMAL KIT', name: 'VD 7" Оптимальний', image: '/assets/tags/vd-logo.jpg', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: 'VD 8" OPTIMAL KIT', name: 'VD 8" Оптимальний', image: '/assets/tags/vd-logo.jpg', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: 'VD 10" OPTIMAL KIT', name: 'VD 10" Оптимальний', image: '/assets/tags/vd-logo.jpg', tooltip: 'Оптимальне співвідношеня ціна/якість в перевірених продавців' },
            // { id: '7" TRAMP KIT', name: '7" Бомжоліт', image: '', tooltip: 'З серії "їздить/стріляє ©". Адмін не агітує збирати такий сетап, він наведений в цілях ознайомлення' },
            // { id: '10" TRAMP KIT', name: '10" Бомжоліт', image: '', tooltip: 'З серії "їздить/стріляє ©". Адмін не агітує збирати такий сетап, він наведений в цілях ознайомлення' },
        // ],
    };

    public readonly kits_price_filter: Filter = {
        key: 'kitprice',
        name: 'Ціна',
        radio: true,
        expanded: true,
        update: () => this.updateFilters(),
        dict: { 'current': true, 'future': false, },
        options: [
            { id: 'current', name: 'Поточна', image: '', },
            { id: 'future', name: 'Запланована', image: '', },
        ],
    };

    public readonly kits_bulk_filter: Filter = {
        key: 'kitbulk',
        name: 'Оптові товари',
        radio: true,
        expanded: true,
        update: () => this.updateFilters(),
        dict: { 'single': true, 'bulk': false, },
        options: [
            { id: 'single', name: 'Одиничні', image: '', },
            { id: 'bulk', name: 'Оптові', image: '', },
        ],
    };


    public readonly promos_filter: Filter = {
        key: 'promo',
        name: 'Акції',
        expanded: true,
        update: () => this.updateSellers(),
        dict: { 'all': true, 'choice': false, 'superdeals': false, 'coins': false, },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'choice', name: '', image: 'https://ae01.alicdn.com/kf/S1887a285b60743859ac7bdbfca5e0896Z/154x64.png' },
            { id: 'superdeals', name: '', image: 'https://ae01.alicdn.com/kf/Saa94d6f7f18e4bf5ad94bb3ddb0a7870A/228x64.png' },
            { id: 'limiteddeal', name: 'LimitedDeal', image: '' },
            { id: 'coins', name: 'Монети', image: 'https://assets.swarm.army/coin.webp' },
            { id: 'future', name: 'Заплановані', image: '' },
            { id: 'supercoins', name: 'Суперзнижки з монетами', image: 'https://assets.swarm.army/coin.webp' },
            { id: 'bigsave', name: 'BigSave', image: '' },
            { id: 'bigsavedeal', name: 'BigSaveDeal', image: '' },
        ],
    };

    public readonly availability_filter: Filter = {
        key: 'availability',
        name: 'Наявність',
        expanded: false,
        update: () => this.updateFilters(),
        dict: { 0: false, 10: true, 50: true, 100: true, 101: true },
        options: [
            { id: 0, name: 'Відсутні', image: '' },
            { id: 10, name: '1 - 10', image: '' },
            { id: 50, name: '11 - 50', image: '' },
            { id: 100, name: '51 - 100', image: '' },
            { id: 101, name: '101+', image: '' },
        ],
    };

    public readonly urls_filter: Filter = {
        key: 'url',
        name: 'Посилання',
        dict: { 0: true, 1: true, 2: true, 3: true, 4: true, },
        options: [
            { id: 0, name: 'Відсутні', image: '' },
            { id: 1, name: 'Присутні', image: '' },
            { id: 2, name: 'Пропущені SD', image: '/assets/tags/sdua-logo.png' },
        ],
        expanded: false,
        update: () => this.updateFilters(),
    };

    public readonly deleted_filter: Filter = {
        key: 'deleted',
        name: 'Видалені',
        dict: { active: true, deleted: false, },
        options: [
            { id: 'active', name: 'Активні', image: '' },
            { id: 'deleted', name: 'Видалені', image: '' },
        ],
        expanded: false,
        update: () => this.updateFilters(),
    };

    public readonly status_filter: Filter = {
        key: 'status',
        name: 'Статус',
        dict: { 'all': false, unknown: true, verified: true, suggestion: true, caution: false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'unknown', name: 'Невідомо', image: '' },
            { id: 'verified', name: 'Верифіковані', image: '' },
            { id: 'caution', name: 'Підозрілі', image: '' },
            { id: 'suggestion', name: 'Пропозиції спільноти', image: '' },
            { id: 'invalid', name: 'Помилка', image: '' },
        ],
        expanded: false,
        update: () => this.updateFilters(),
    };

    public readonly product_source_filter: Filter = {
        key: 'source',
        name: 'Джерело',
        expanded: true,
        update: () => this.updateFilters(),
        dict: { 'all': true, 'aliexpress': false, 'custom': false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'aliexpress', name: 'AliExpress', image: '' },
            { id: 'custom', name: 'Custom', image: '' },
        ],
    };

    public readonly details_filter: Filter = { key: 'detail', name: 'Деталі', dict: { 'all': true, }, expanded: true, options: [{ id: 'all', name: 'Всі', image: '' }], update: () => this.updateSellers() };
    public readonly sellers_filter: Filter = { key: 'seller', name: 'Продавці', dict: { 'all': true, }, options: [{ id: 'all', name: 'Всі', image: '' }], expanded: false, update: () => this.updateModels(), avatar: true };
    public readonly models_filter: Filter = { key: 'model', name: 'Моделі', dict: { 'all': true, }, options: [{ id: 'all', name: 'Всі', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };

    public filters: Filter[] = [
        this.top_price_filter,
        this.tags_filter,
        this.promos_filter,
        this.details_filter,
        this.sellers_filter,
        this.models_filter,
        this.status_filter,
        this.availability_filter,
        this.product_source_filter,
    ];

    public filters_kits: Filter[] = [
        this.kits_bulk_filter,
        this.kits_tags_filter,
        this.kits_price_filter,
    ];

    public view_mode = ViewModes.grid;
    public readonly ViewModes = ViewModes;

    public filters_mode_control = new FormControl<'products' | 'kits'>('products');

    public kits: KitFull[] = [];

    private restoreKits() {
        try {
            const kits_json = localStorage.getItem('kits');
            if (!kits_json) return;
            this.kits = JSON.parse(kits_json);
            this.updateFilters();
            this.updateKitsExtra();
        } catch (e) {
            console.log(e);
        }
    }

    private backupKits() {
        localStorage.setItem('kits', JSON.stringify(this.kits.filter(k => !k.tenant_id)));
    }

    private remote_kits_loaded = false;

    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        private readonly statsService: StatsService,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
    ) {


        this.view_mode = localStorage.getItem(VIEW_MODE_KEY) as ViewModes;

        if (!ViewModes[this.view_mode]) {
            this.view_mode = ViewModes.grid;
        }

        this.filters_mode_control.setValue(localStorage.getItem(FILTERS_MODE_KEY) as FiltersModes);

        if (!FiltersModes[this.filters_mode_control.value!]
            || route.snapshot.queryParamMap.keys.some(k => !k.startsWith('kit'))) {
            this.filters_mode_control.setValue(FiltersModes.products);
        }

        if (this.data.superadmin) {
            this.filters.push(this.urls_filter);
            this.filters.push(this.deleted_filter);
        }

        this.data.restoreFilter(FILTERS_KEY, this.filters);
        this.data.restoreFilter(FILTERS_KITS_KEY, this.filters_kits);

        this.restoreKits();

        route.queryParams.subscribe(params => {
            if (params['kit'] !== undefined || params['kitprice'] !== undefined) {
                this.filters_mode_control.setValue(FiltersModes.kits);
                if (params['kit']) {
                    const option = this.kits_tags_filter.options.find(o => o.id === params['kit']);
                    if (option) {
                        Object.keys(this.kits_tags_filter.dict).forEach(k => this.kits_tags_filter.dict[k] = false);
                        this.kits_tags_filter.dict[option.id] = true;
                    }
                }
                if (this.filtered_products.length) this.updateFilters();
            }
        });

        this.filters_mode_control.valueChanges.subscribe(() => this.updateFilters());

        this.data.getKits().subscribe({
            next: kits => {
                this.kits = kits;
                this.remote_kits_loaded = true;
                this.updateKitsOptions();
                this.updateFilters();
                this.updateKitsExtra();
                this.data.restoreFilter(FILTERS_KITS_KEY, this.filters_kits);
                this.backupKits();
            }
        });

        this.data.getFullProducts().subscribe(response => {

            this.products = response.sort((a, b) => a.detail_id === b.detail_id ? a.sku_full_unit_price - b.sku_full_unit_price : a.detail_id - b.detail_id);

            this.updateDetails();

            if (!this.products.some(p => p.sku_future_sale_price)) {
                const index = this.filters_kits.indexOf(this.kits_price_filter);
                if (index > -1) {
                    this.filters_kits.splice(index, 1);
                }
            }

            this.data.restoreFilter(FILTERS_KEY, this.filters);

            this.updateFilters();

            this.updateKitsExtra();

        });

    }

    public current_kit: KitFull | null = null;

    private updateKitsExtra() {

        const future_price = this.kits_price_filter.dict['future'] && this.filters_kits.includes(this.kits_price_filter);

        this.kits_tags_filter.options.forEach(o => {
            if (future_price) {
                o.extra = '€' + this.getKitProductsByTag(String(o.id), future_price).price.toFixed(2);
            } else {
                const current_price = this.getKitProductsByTag(String(o.id), false).price;
                const future_price = this.getKitProductsByTag(String(o.id), true).price;
                if (current_price !== future_price) {
                    o.extra = '€' + current_price.toFixed(2);
                    o.extra = '€' + current_price.toFixed(2) + ' ⌛ ' + future_price.toFixed(2);
                } else {
                    o.extra = '€' + current_price.toFixed(2);
                }
            }
        });

    }

    @HostListener('window:resize', ['$event'])
    private updateGroups() {

        const style = getComputedStyle(document.documentElement);

        const card_gap = Number(style.getPropertyValue('--card-gap').replace('px', ''));
        const card_width = Number(style.getPropertyValue('--card-width').replace('px', ''));

        let group_size = 2;

        function screen_width($n: number) {
            return ($n + 1) * card_width + card_gap * ($n + 1);
        }

        for (let i = 3; i < 10; ++i) {
            if (window.innerWidth >= screen_width(i)) {
                group_size = i;
            } else {
                break;
            }
        }

        this.filtered_products_groups = [...Array(Math.ceil(this.filtered_products.length / group_size))]
            .map((_, i) => this.filtered_products.slice(i * group_size, i * group_size + group_size));

        this.card_height = window.innerWidth > 959.98 ? 420 : (window.innerWidth - 24) / 2 + 136;

    }

    public drone_price = 0;
    public future_drone_price = 0;

    private getCurrentKit() {
        const slug = this.kits_tags_filter.options.find(o => this.kits_tags_filter.dict[o.id])?.id;
        const kit = this.kits.find(k => k.kit_slug === slug);
        return kit;
    }

    public editKit() {
        const kit = this.getCurrentKit();
        if (kit) {
            KitEditDialogComponent.open(this.dialog, kit).afterClosed()
                .pipe(filter(k => k)).subscribe(() => this.updateFilters());
        }
    }
    public addKit() {
        KitEditDialogComponent.open(this.dialog, {
            kit_id: 0,
            user_id: 0,
            tenant_id: null,
            kit_date: 0,
            kit_order: 0,
            kit_deleted: 0,
            kit_risky: 0,
            kit_public: 0,
            kit_full: 0,
            kit_name: '',
            kit_slug: '',
            kit_models: '',
            kit_comment: '',
            kit_description: '',
            kit_description_html: '',
            kit_models_ids: [],
            kit_price: 0,
        }).afterClosed()
            .pipe(filter(k => k)).subscribe(k => {
                this.kits.push(k);
                this.updateFilters();
            });
    }

    public requestKit() {
        const kit = this.getCurrentKit();
        if (kit) {
            RequestKitDialogComponent.open(this.dialog, {
                kit: kit,
                products: this.filtered_products,
            });
        }
    }

    private getKitProductsByTag(slug: string, price_field: boolean) {
        const kit = this.kits.find(k => k.kit_slug === slug);
        return getKitProductsAndPrice(kit, this.products, price_field, this.kits_bulk_filter.dict['bulk']);
    }

    private updateKitsOptions() {
        this.kits.sort((a, b) => a.kit_order - b.kit_order);
        const kit_slug = this.route.snapshot.queryParams['kit']?.toLowerCase() || '';
        this.kits_tags_filter.options = this.kits.filter(k => !k.tenant_id || kit_slug && k.kit_slug.toLowerCase() === kit_slug || this.data.tenant_id && k.tenant_id === this.data.tenant_id)
            .map(k => ({ id: k.kit_slug, name: k.kit_name, image: '', tooltip: k.kit_comment, icon: k.tenant_id ? 'lock' : '' }));
    }

    public onKitsFilterChange() {
        setTimeout(() => {
            if (!this.current_kit) return;
            this.data.click(this.data.CS.KitsPage, this.data.CT.OpenKitsPage, this.current_kit);
        }, 1000);
    }

    public kit_quantities_dict: { [k: number]: number } = {};

    private updateFilters() {

        const future_price = this.kits_price_filter.dict['future'] && this.filters_kits.includes(this.kits_price_filter);

        localStorage.setItem(FILTERS_MODE_KEY, this.filters_mode_control.value!);

        this.current_kit = null;
        this.data.current_kit_id = null;
        this.kit_quantities_dict = {};

        if (this.filters_mode_control.value === 'kits') {

            this.updateKitsOptions();

            this.data.setFilters(FILTERS_KITS_KEY, this.filters_kits);

            let tag = this.kits_tags_filter.options.find(o => this.kits_tags_filter.dict[o.id])?.id;

            this.current_kit = this.kits.find(k => k.kit_slug === tag) || null;

            if (!this.current_kit && this.remote_kits_loaded) {
                this.current_kit = this.kits[0];
                this.kits_tags_filter.dict[this.current_kit?.kit_slug] = true;
                tag = this.current_kit?.kit_slug;
            }
            this.data.current_kit_id = this.current_kit?.kit_id || null;

            const { products, price, kit_quantities_dict } = this.getKitProductsByTag(String(tag), future_price);
            this.kit_quantities_dict = kit_quantities_dict;

            this.filtered_products = products;
            this.drone_price = price;

            if (!future_price) {
                const future_drone_price = this.getKitProductsByTag(String(tag), true).price;
                if (future_drone_price !== price) {
                    this.future_drone_price = future_drone_price;
                }
            }

            this.updateKitsExtra();

        } else {

            this.data.setFilters(FILTERS_KEY, this.filters);
            this.filtered_products = this.products.filter(p => this.productVisible(p));

        }

        this.updateGroups();

    }

    public updateDetails() {

        const start = Date.now();

        const all_tags = this.tags_filter.dict['all'];
        const tags = Object.keys(this.tags_filter.dict).filter(k => k && this.tags_filter.dict[k]);

        this.details_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.products.map(p => ({ id: p.detail_id, name: p.detail_name, image: '' }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.products.some(p => p.detail_id === v.id && (all_tags || tags.some(t => p.tags_dict[t]))
                        && (this.top_price_filter.dict['all'] || this.top_price_filter.dict['top'] && p.model_top_price_usd && (p.sku_last_price && p.sku_last_price < p.model_top_price_usd || p.sku_last_sd_price && p.sku_last_sd_price < p.model_top_price_usd))))
        ];

        const all_options_selected = this.details_filter.options.every(o => this.details_filter.dict[o.id]);
        const no_options_selected = this.details_filter.options.every(o => !this.details_filter.dict[o.id]);

        if (all_options_selected || no_options_selected) {
            Object.keys(this.details_filter.dict).forEach(k => this.details_filter.dict[k] = false);
            this.details_filter.dict['all'] = true;
        }

        this.updateSellers();

        const end = Date.now();
        console.log('Filtering, ms: ', end - start);
    }

    public updateSellers() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);
        const details_all = this.details_filter.dict['all'];

        const all_tags = this.tags_filter.dict['all'];
        const tags = Object.keys(this.tags_filter.dict).filter(k => k && this.tags_filter.dict[k]);

        const now = Date.now() / 1000;
        const all_promos = this.promos_filter.dict['all'];
        const promos_dict = this.promos_filter.dict;

        const all_old_options_selected = this.sellers_filter.options.length && this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);

        this.sellers_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.products.map(p => ({ id: p.seller_id, name: p.seller_name_short, image: p.seller_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.products.some(p => p.seller_id === v.id && (details_all || detail_ids.includes(p.detail_id))
                        && (all_tags || tags.some(t => p.tags_dict[t]))
                        && (all_promos || p.item_big_save && promos_dict['bigsave'] || p.sku_last_bs_price && promos_dict['bigsavedeal'] || p.item_is_choice && promos_dict['choice'] || p.sku_full_sd_price && promos_dict['superdeals'] || p.sku_full_lo_price && p.sku_full_lo_price < p.sku_full_sd_price && promos_dict['limiteddeal'] || p.item_coins_discount && promos_dict['coins'] || p.sku_future_sale_date && p.sku_future_sale_date > now && promos_dict['future'])
                        && (this.top_price_filter.dict['all'] || this.top_price_filter.dict['top'] && p.model_top_price_usd && (p.sku_last_price && p.sku_last_price < p.model_top_price_usd || p.sku_last_sd_price && p.sku_last_sd_price < p.model_top_price_usd))))
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);
        const no_options_selected = this.sellers_filter.options.every(o => !this.sellers_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.sellers_filter.dict).forEach(k => this.sellers_filter.dict[k] = false);
            this.sellers_filter.dict['all'] = true;
        }

        this.updateModels();

    }

    public updateModels() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);
        const seller_ids = Object.keys(this.sellers_filter.dict).map(Number).filter(id => this.sellers_filter.dict[id]);
        const details_all = this.details_filter.dict['all'];
        const sellers_all = this.sellers_filter.dict['all'];

        const all_tags = this.tags_filter.dict['all'];
        const tags = Object.keys(this.tags_filter.dict).filter(k => k && this.tags_filter.dict[k]);

        const now = Date.now() / 1000;
        const all_promos = this.promos_filter.dict['all'];
        const promos_dict = this.promos_filter.dict;

        const all_old_options_selected = this.models_filter.options.length && this.models_filter.options.every(o => this.models_filter.dict[o.id]);

        this.models_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.products.map(p => ({ id: p.model_id, name: p.model_name, image: p.model_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.products.some(p => p.model_id === v.id && (details_all || detail_ids.includes(p.detail_id)) && (sellers_all || seller_ids.includes(p.seller_id))
                        && (all_tags || tags.some(t => p.tags_dict[t]))
                        && (all_promos || p.item_big_save && promos_dict['bigsave'] || p.sku_last_bs_price && promos_dict['bigsavedeal'] || p.item_is_choice && promos_dict['choice'] || p.sku_full_sd_price && promos_dict['superdeals'] || p.sku_full_lo_price && p.sku_full_lo_price < p.sku_full_sd_price && promos_dict['limiteddeal'] || p.item_coins_discount && promos_dict['coins'] || p.sku_future_sale_date && p.sku_future_sale_date > now && promos_dict['future'])
                        && (this.top_price_filter.dict['all'] || this.top_price_filter.dict['top'] && p.model_top_price_usd && (p.sku_last_price && p.sku_last_price < p.model_top_price_usd || p.sku_last_sd_price && p.sku_last_sd_price < p.model_top_price_usd))))
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = this.models_filter.options.every(o => this.models_filter.dict[o.id]);
        const no_options_selected = this.models_filter.options.every(o => !this.models_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.models_filter.dict).forEach(k => this.models_filter.dict[k] = false);
            this.models_filter.dict['all'] = true;
        }

        this.updateFilters();

    }

    public productVisible(p: ProductFull) {

        const avail_count = p.sku_last_available;
        const avail_dict = this.availability_filter.dict;
        const available = avail_dict[0] && avail_count === 0
            || avail_dict[10] && avail_count >= 1 && avail_count <= 10
            || avail_dict[50] && avail_count >= 11 && avail_count <= 50
            || avail_dict[100] && avail_count >= 51 && avail_count <= 100
            || avail_dict[101] && avail_count >= 101;

        const url_dict = this.urls_filter.dict;
        const url = this.data.admin
            ? url_dict[0] && !p.product_url_hash
            || url_dict[1] && p.product_url_hash
            || url_dict[2] && !p.product_sd_url_hash
            : true;

        const tags_dict = this.tags_filter.dict;
        const tags = tags_dict['all'] ? true : p.model_tags && p.tags_array.some(t => tags_dict[t]);

        const now = Date.now() / 1000;

        const promos_dict = this.promos_filter.dict;
        const promos = promos_dict['all']
            || p.item_is_choice && promos_dict['choice']
            || p.item_big_save && promos_dict['bigsave']
            || p.sku_full_bs_price && promos_dict['bigsavedeal']
            || p.sku_full_sd_price && promos_dict['superdeals']
            || p.sku_full_lo_price && promos_dict['limiteddeal']
            || p.item_coins_discount && promos_dict['coins']
            || p.item_coins_superdiscount && promos_dict['supercoins']
            || p.sku_future_sale_price && (!p.sku_future_sale_date || p.sku_future_sale_date && p.sku_future_sale_date > now) && promos_dict['future'];

        const top_price_dict = this.top_price_filter.dict;
        const top_price = top_price_dict['all'] || top_price_dict['top'] && p.model_top_price_usd && p.sku_min_unit_price <= p.model_top_price_usd;

        const status_dict = this.status_filter.dict;
        const status = status_dict['all'] || status_dict[p.product_status];

        const deleted_dict = this.deleted_filter.dict;

        const deleted = this.data.admin
            ? deleted_dict['active'] && !p.product_deleted
            || deleted_dict['deleted'] && p.product_deleted
            : !p.product_deleted;

        const source_dict = this.product_source_filter.dict;
        const source = source_dict['all'] || source_dict[p.product_source];

        return status && top_price && promos && tags && available && url && deleted && source
            && (this.details_filter.dict['all'] || this.details_filter.dict[p.detail_id])
            && (this.sellers_filter.dict['all'] || this.sellers_filter.dict[p.seller_id])
            && (this.models_filter.dict['all'] || this.models_filter.dict[p.model_id]);
    }

    private view_modes = [
        { mode: ViewModes.grid, icon: 'grid_view', name: 'Переглянути картками' },
        { mode: ViewModes.table, icon: 'table_rows', name: 'Переглянути таблицею' },
        { mode: ViewModes.list, icon: 'view_agenda', name: 'Переглянути списком' }
    ]

    public toggleViewMode() {
        this.view_mode = this.next_view_mode.mode;
        localStorage.setItem(VIEW_MODE_KEY, this.view_mode);
    }

    public get next_view_mode() {
        const curr_index = this.view_modes.findIndex(m => m.mode === this.view_mode);
        const next_index = (curr_index + 1) % this.view_modes.length;
        return this.view_modes[next_index];
    }

    public addNew() {
        if (this.data.superadmin) {
            ProductCustomEditDialogComponent.open(this.dialog, {
                product_id: 0,
                model_id: 0,
                seller_id: 0,
                user_id: 0,
                product_url: '',
                product_url_hash: '',
                product_sd_url_hash: '',
                product_ld_url_hash: '',
                product_cd_url_hash: '',
                product_bs_url_hash: '',
                product_last_updated: 0,
                product_status: 'unknown',
                product_orders_failed: 0,
                product_orders_completed: 0,
                product_orders_avg_price_usd: 0,
                product_deleted: 0,
                product_update_interval: 0,
                product_source: 'custom',
                product_comments: 0,
                product_clicks_daily: 0,
                product_clicks_weekly: 0,
                product_clicks_monthly: 0,
                item_id: '',
                item_name: '',
                item_sold: '',
                item_rating: 0,
                item_reviews: 0,
                item_image_hash: '',
                item_image_url: '',
                item_delivery: '',
                item_shipping_price: 0,
                item_coins_discount: 0,
                item_coins_superdiscount: 0,
                item_is_choice: 0,
                item_big_save: 0,
                item_max_limit: 0,
                sku_id: '',
                sku_name: '',
                sku_attr: '',
                sku_prop_ids: '',
                sku_quantity: 0,
                sku_extra_tips: '',
                sku_image_hash: '',
                sku_image_url: '',
                sku_last_price: 0,
                sku_last_sd_price: 0,
                sku_last_lo_price: 0,
                sku_last_available: 0,
                sku_future_sale_date: 0,
                sku_future_sale_price: 0,
                sku_last_bs_price: 0,
            });
        }
    }

}

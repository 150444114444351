<div mat-dialog-title>
    <span>Персональні нотифікації</span>
    <button mat-stroked-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <div class="skeleton-block" *ngIf="loading"></div>

    <div class="dummy" *ngIf="!loading&&!rules.length">Правил не знайдено</div>

    <div mat-ripple class="rule" *ngFor="let rule of rules" (click)="updateRule(rule)">
        <img class="model-img" [src]="rule.model?.model_image_url" />
        <div class="rule-body">

            <div class="model-name">{{rule.model?.model_name}}</div>

            <seller-chip *ngIf="rule.product" [data]="rule.product" [clickable]="false"></seller-chip>

            <div class="product-attrs">

                <small class="product-extra product-future-price">
                    <b>{{rule.rule_type_local}}</b>
                </small>

                <div class="product-extra product-seller">
                    <img class="product-seller-logo" src="https://goods.ua-drones.de/assets/logo.png" />
                    <span class="product-seller-name">{{rule.rule_website_local}}</span>
                </div>

                <div class="product-extra product-seller">
                    <img class="product-seller-logo" src="https://desktop.telegram.org/img/website_icon.svg?4" />
                    <span class="product-seller-name">{{rule.rule_telegram_local}}</span>
                </div>

                <div class="product-extra">
                    <mat-icon>check</mat-icon>
                    <span>{{rule.rule_verification_local}}</span>
                </div>

            </div>

        </div>
        <div class="rule-price" *ngIf="rule.rule_max_unit_price_usd">
            <div>{{rule.rule_max_unit_price_usd|currency}}/{{rule.model?.sku_units}}</div>
            <small *ngIf="rule.rule_max_sku_quantity">
                Не більше <b>{{rule.rule_max_sku_quantity}} {{rule.model?.sku_units}}</b>/лот
            </small>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <telegram-button></telegram-button>
    <div class="spacer"></div>
    <button mat-flat-button color="primary" (click)="createRule()">Створити правило</button>
</mat-dialog-actions>
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, OrderFull, TrackingData, TrackingFull, TrackingSource } from '../data.service';
import { LayoutService } from '../layout.service';
import { getNumberLabel, timestampToLocalDate, timestampToLocalShortTime } from '../utils';
import { animate, style, transition, trigger } from '@angular/animations';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable } from 'rxjs';
import { CaptchaDialogComponent } from './captcha-dialog/captcha-dialog.component';
import { PREFIXES_MEESTEXPRESS, PREFIXES_NOVAPOSHTA, PREFIXES_UKRPOSHTA } from '../consts';

interface TrackingVm extends TrackingFull {
    tracking_comment_control: FormControl;
    loading: boolean;
}

interface Carrier {
    source: TrackingSource;
    name: string;
    logo: string;
}

@Component({
    selector: 'app-track',
    templateUrl: './track.component.html',
    styleUrl: './track.component.scss',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(75%)', opacity: 0 }),
                animate('400ms ease-in', style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate('400ms ease-in', style({ transform: 'translateX(-75%)', opacity: 0 }))
            ])
        ])
    ]
})
export class TrackComponent implements OnDestroy {

    public error = false;
    public loading = false;
    public control_number = new FormControl<string>('', [Validators.required, Validators.minLength(10), Validators.maxLength(30), Validators.pattern(/^[A-Z0-9]+$/)]);

    public orders: OrderFull[] = [];
    public tracking: TrackingFull | null = null;
    public tdata: TrackingData | null = null;
    public comment_editing = false;
    public comment_loading = false;
    public comment_control = new FormControl('');

    public commentEdit() {
        this.comment_editing = true;
        this.comment_loading = false;
        this.comment_control.setValue(this.tracking?.tracking_comment || '');
    }

    public commentCancel() {
        this.comment_editing = false;
    }

    public commentSave() {
        const tracking = this.tracking;
        if (!tracking) return;
        this.comment_loading = true;
        this.data.trackingComment({
            tracking_id: tracking.tracking_id,
            tracking_comment: this.comment_control.value || '',
        }).subscribe({
            next: () => {
                this.comment_editing = false;
                this.comment_loading = false;
                tracking.tracking_comment = this.comment_control.value || '';
                this.snackbar.open('Коментар збережено', 'OK');
            }
        })
    }

    public timestampToLocalDate = timestampToLocalDate;
    public timestampToLocalShortTime = timestampToLocalShortTime;
    public getTrackingCompany = this.data.getTrackingCompany;

    public trackings: TrackingVm[] = [];

    private all_carriers: Carrier[] = [
        {
            source: TrackingSource.cainiao,
            name: 'Cainiao',
            logo: 'https://assets.swarm.army/carriers/cainiao.png',
        },
        // {
        //     source: TrackingSource.ukrposhta,
        //     name: 'Укрпошта',
        //     logo: 'https://assets.swarm.army/carriers/ukrposhta.png',
        // },
        // {
        //     source: TrackingSource.novaint,
        //     name: 'Нова Пошта',
        //     logo: 'https://assets.swarm.army/carriers/novaposhta.png',
        // },
        // {
        //     source: TrackingSource.meestukr,
        //     name: 'Meest Пошта',
        //     logo: 'https://assets.swarm.army/carriers/meestukr.png',
        // },
        // {
        //     source: TrackingSource.meestint,
        //     name: 'Meest Global',
        //     logo: 'https://assets.swarm.army/carriers/meestint.png',
        // },
    ];

    public loading_carriers: Carrier[] = [this.all_carriers[0]];
    public parcel_carriers: Carrier[] = [];

    private loading_interval = 0;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        public readonly router: Router,
        private readonly route: ActivatedRoute,
        public readonly layout: LayoutService,
        private readonly detector: ChangeDetectorRef,
        private readonly recaptcha3: ReCaptchaV3Service,
    ) {

        const was_on_track = router.lastSuccessfulNavigation?.finalUrl?.toLocaleString().startsWith('/track');

        if (this.data.profile) this.data.getTrackings(!was_on_track).subscribe({
            next: trackings => {
                trackings.sort((a, b) => b.tracking_date_updated - a.tracking_date_updated);
                this.trackings = trackings.map(t => ({
                    ...t, loading: false,
                    tracking_comment_control: new FormControl(t.tracking_comment),
                }));
            }
        });

        this.route.params.subscribe({
            next: params => {
                if (params['tracking_number']) {
                    this.control_number.setValue(params['tracking_number']);
                    this.sendTrackingRequest();
                }
            }
        });
    }

    private parseTrackingData(tracking: TrackingFull, $orders: Observable<OrderFull[]>) {

        this.tracking = tracking;

        console.log("❗ tracking:", tracking);

        this.tdata = tracking.tracking_data || null;

        const found_tracking = this.trackings.find(t => t.tracking_id === tracking.tracking_id);

        if (found_tracking) Object.assign(found_tracking, tracking);

        else this.trackings.unshift({
            ...tracking, loading: false,
            tracking_comment_control: new FormControl(tracking.tracking_comment),
        });

        $orders.subscribe({
            next: orders => {
                this.orders = orders.filter(o => o.order_tracking_number_array.some(t => t.tracking_number === this.control_number.value || t.tracking_number_alt === this.control_number.value));
            }
        });

        const data = this.tdata;
        if (!data) return;

        this.parcel_carriers = data.events.map(e => e.source)
            .filter((v, i, s) => s.indexOf(v) === i).reverse()
            .map(s => this.all_carriers.find(c => c.source === s)!)
            .filter(c => c).reverse();

        const meest_global_index = this.parcel_carriers.findIndex(c => c.source === 'meestint');

        if (meest_global_index > -1) {
            if (this.parcel_carriers.some(c => c.source === 'meestint')) {
                this.parcel_carriers.splice(meest_global_index, 1);
            } else {
                this.parcel_carriers[meest_global_index] = this.all_carriers.find(c => c.source === 'meestukr')!;
            }
        }

        if (PREFIXES_UKRPOSHTA.some(p => tracking.tracking_number.startsWith(p))
            || PREFIXES_UKRPOSHTA.some(p => tracking.tracking_number_alt.startsWith(p))) {
            if (!this.parcel_carriers.some(c => c.source === 'ukrposhta')) {
                this.parcel_carriers.unshift(this.all_carriers.find(c => c.source === 'ukrposhta')!);
            }
        }

        if (PREFIXES_NOVAPOSHTA.some(p => tracking.tracking_number.startsWith(p))
            || PREFIXES_NOVAPOSHTA.some(p => tracking.tracking_number_alt.startsWith(p))) {
            if (!this.parcel_carriers.some(c => c.source === 'novaint')) {
                this.parcel_carriers.unshift(this.all_carriers.find(c => c.source === 'novaint')!);
            }
        }

        if (PREFIXES_MEESTEXPRESS.some(p => tracking.tracking_number.startsWith(p))
            || PREFIXES_MEESTEXPRESS.some(p => tracking.tracking_number_alt.startsWith(p))) {
            if (!this.parcel_carriers.some(c => c.source === 'meestukr')) {
                this.parcel_carriers.unshift(this.all_carriers.find(c => c.source === 'meestukr')!);
            }
        }

    }

    public getDaysLabel(days: string) {
        return getNumberLabel(
            Number(days),
            { one: 'день', few: 'дні', many: 'днів' }
        );
    }

    public ngOnDestroy() {
        clearInterval(this.loading_interval);
    }

    public sendTrackingRequest(force = false) {

        if (!this.validate()) {
            this.router.navigate(['/track']);
            return;
        }

        this.tdata = null;
        this.loading = true;

        clearInterval(this.loading_interval);

        this.loading_interval = setInterval(() => {
            this.loading_carriers = [this.all_carriers[this.all_carriers.indexOf(this.loading_carriers[0]) + 1] || this.all_carriers[0]]
            this.detector.detectChanges();
        }, 1600) as any;

        const $orders = this.data.profile
            ? this.data.admin
                ? this.data.getAllOrders(false)
                : this.data.getMyOrders(false)
            : new BehaviorSubject([]);

        debugger;

        this.recaptcha3.execute('track').subscribe(token_v3 => {
            this.data.getTracking({
                force: force,
                token_v3: token_v3,
                tracking_visible: true,
                tracking_number: this.control_number.value!,
            }).subscribe({
                next: res => {
                    // TODO: fix recaptcha
                    if (false && res.is_bot) {
                        CaptchaDialogComponent.open(this.dialog).afterClosed().subscribe(token_v2 => {

                            if (!token_v2) {
                                this.loading = false;
                                this.error = true;
                                clearInterval(this.loading_interval);
                                return;
                            }

                            this.data.getTracking({
                                force: force,
                                token_v2: token_v2,
                                tracking_visible: true,
                                tracking_number: this.control_number.value!,
                            }).subscribe({
                                next: res => {

                                    this.loading = false;
                                    clearInterval(this.loading_interval);

                                    if (res.is_bot) {
                                        this.error = true;
                                        return;
                                    }

                                    this.parseTrackingData(res.tracking!, $orders);

                                }
                            });

                        });
                    } else {
                        this.parseTrackingData(res.tracking!, $orders);
                        this.loading = false;
                        clearInterval(this.loading_interval);
                    }
                }
            });

        });

    }

    private validate() {
        // if (this.control_number.invalid) {
        //     if (this.control_number.value) {
        //         this.snackbar.open('Введений трекінговий номер не схожий на дійсний номер поштового відправлення!', 'OK');
        //     } else {
        //         this.snackbar.open('Введіть трекінговий номер поштового відправлення!', 'OK');
        //     }
        //     return false;
        // }
        return true;
    }

    public track(tracking_number = '') {
        if (tracking_number) {
            this.control_number.setValue(tracking_number);
        }
        if (this.validate()) {
            this.router.navigate(['/track/' + this.control_number.value]);
        }
    }

    public saveComment(vm: TrackingVm) {
        vm.loading = true;
        this.data.trackingComment({ tracking_id: vm.tracking_id, tracking_comment: vm.tracking_comment_control.value || '' }).subscribe({
            next: () => {
                vm.loading = false;
                vm.tracking_comment = String(vm.tracking_comment_control.value || '').trim();
                vm.tracking_comment_control.setValue(vm.tracking_comment);
            }
        });
    }

    public archiveTracking(vm: TrackingVm) {
        this.data.trackingArchive(vm.tracking_id).subscribe({ next: () => console.log('archiveTracking') });
        const index = this.trackings.indexOf(vm);
        if (index > -1) this.trackings.splice(index, 1);
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { DronesComponent } from './drones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddDroneDialogComponent } from './add-drone-dialog/add-drone-dialog.component';
import { RouterModule } from '@angular/router';
import { EditDroneDialogComponent } from './edit-drone-dialog/edit-drone-dialog.component';



@NgModule({
  declarations: [
    DronesComponent,
    AddDroneDialogComponent,
    EditDroneDialogComponent,
  ],
  exports: [
    DronesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
  ]
})
export class DronesModule { }

import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router, RouterLink } from "@angular/router";
import { Subscription, filter } from "rxjs";

@Directive({
    selector: '[routeActive]',
})
export class RouteActiveDirective implements OnInit, OnDestroy {

    @Input('routeActive') public route_active_class = '';

    private subs: Subscription[] = [];

    constructor(
        private readonly router: Router,
        private readonly router_link: RouterLink,
        private readonly ref: ElementRef<HTMLElement>) { }

    ngOnInit() {
        this.subs.push(this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe({
            next: () => {
                setTimeout(() => {
                    if (!this.route_active_class) return;
                    if (this.router_link.href === location.pathname) {
                        this.ref.nativeElement.classList.add(this.route_active_class);
                    } else {
                        this.ref.nativeElement.classList.remove(this.route_active_class);
                    }
                });
            }
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService, Filter, FilterOption } from '../../../data.service';


@Component({
    selector: 'app-filters-panel',
    templateUrl: './filters-panel.component.html',
    styleUrl: './filters-panel.component.scss',
})
export class FiltersPanelComponent {

    @Input()
    public filters: Filter[] = [];

    @Input()
    public count_filtered = 0;

    @Input()
    public count_total = 0;

    @Output() filtersChanged = new EventEmitter<Filter>();

    constructor(public readonly data: DataService) { }


    public filterCount(filter: Filter) {
        return Object.keys(filter.dict).filter(k => filter.dict[k]).length;
    }

    public availableFiltersCount(filter: Filter): number {
        const exclude = ['all', ''];
        return filter.options.filter(o => !exclude.includes(o.id as string)).length;
    }

    public selectAll(filter: Filter) {
        Object.keys(filter.dict).forEach(k => filter.dict[k] = false);
        filter.options.forEach(o => filter.dict[o.id] = true);
        filter.update();
    }

    public deselectAll(filter: Filter) {
        Object.keys(filter.dict).forEach(k => filter.dict[k] = false);
        filter.options.forEach(o => filter.dict[o.id] = false);
        filter.update();
    }

    public selectedCount(filter: Filter): number {
        const exclude = ['all', ''];
        return Object.entries(filter.dict)
            .filter(([key, val]) => !exclude.includes(key))
            .filter(([key, val]) => val === true).length
    }

    public hasFilterAll(filter: Filter) {
        return filter.dict['all'] || filter.dict[null!];
    }

    public onOptionChange(filter: Filter, option: FilterOption) {

        filter.dict[option.id] = !filter.dict[option.id];

        if (filter.dict[option.id]) {
            if (option.id === null || option.id === 'all' || filter.radio) {
                filter.options.filter(o => o !== option).forEach(o => filter.dict[o.id] = false);
            } else {
                const empty_option = filter.options.find(o => o.id === null || o.id === 'all');
                if (empty_option) {
                    filter.dict[empty_option.id] = false;
                }
            }
        } else {
            // If all options are deselected, select 'all' option
            const empty_option = filter.options.find(o => o.id === null || o.id === 'all');
            if (empty_option) {
                const none_selected = filter.options.every(o => !filter.dict[o.id]);
                if (none_selected) {
                    filter.dict[empty_option.id] = true;
                }
            }
        }

        filter.update()
        this.filtersChanged.emit(filter);
    }

    public onSelectOption(filter: Filter, option: FilterOption) {

        filter.options.forEach(o => filter.dict[o.id] = false);
        filter.dict[option.id] = true;

        filter.update()
        this.filtersChanged.emit(filter);

    }

}

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DataService, Detail, Model, Product, ProductFull, ProductUpdateDto, Seller, SellerFull} from '../data.service';

type DetailGroup = Detail & { models: Model[] };

@Component({
  selector: 'app-product-custom-edit-dialog',
  templateUrl: './product-custom-edit-dialog.component.html',
  styleUrl: './product-custom-edit-dialog.component.scss'
})
export class ProductCustomEditDialogComponent {
    public static open(dialog: MatDialog, product: Product) {
        return dialog.open(ProductCustomEditDialogComponent, { width: '520px', data: product, autoFocus: false, restoreFocus: false });
    }

    public loading = false;
    public models: Model[] = [];
    public details: DetailGroup[] = [];
    public sellers: SellerFull[] = [];

    constructor(
        public readonly data: DataService,
        @Inject(MAT_DIALOG_DATA) public readonly product: ProductFull,
        private readonly snackbar: MatSnackBar,
        private readonly ref: MatDialogRef<ProductCustomEditDialogComponent>,
    ) {
        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                })
            }
        });

        this.data.getSellers().subscribe({
            next: sellers => {
                this.sellers = sellers.filter(s => s.seller_source === 'custom');
            }
        });

        this.form.patchValue(this.product);
        this.form.controls.item_image_url.valueChanges
            .subscribe(url => url ? fetch(url, {cache: 'reload', mode: 'no-cors'}) : null);
    }

    public form = new FormGroup({
        product_status: new FormControl(this.product.product_status || '', Validators.required),
        product_url: new FormControl(this.product.product_url || '', Validators.required),
        model_id: new FormControl(this.product.model_id || 0, Validators.required),
        sku_last_price: new FormControl(this.product.sku_last_price || 0, Validators.required),
        item_name: new FormControl(this.product.item_name || '', Validators.required),
        item_image_url: new FormControl(this.product.item_image_url || '', Validators.required),
        seller_id: new FormControl(this.product.seller_id || 0, Validators.required),
        product_deleted: new FormControl(this.product.product_deleted),
    });

    public save() {

        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        this.data.upsertCustomProduct({...this.form.value as any, product_id: this.product?.product_id || 0}).subscribe(product => {
            if (this.product) {
                this.product.product_status = product.product_status;
                this.product.product_url = product.product_url;
                this.product.model_id = product.model_id;
                this.product.item_name = product.item_name;
                this.product.item_image_url = product.item_image_url;
                this.product.product_deleted = product.product_deleted;
                this.product.sku_last_price = product.sku_last_price;
                this.product.seller_id = product.seller_id;
            } else {
                this.data.postInsertProduct({
                    ...product
                });
            }
            this.snackbar.open('Зміни збережено.', '', {duration: 1000});
            this.loading = false;
            this.ref.close();
        }, error => {
            this.loading = false;
            this.data.showMessage('Сталася помилка!');
        });
    }
}

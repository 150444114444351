<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" class="flex column">

        <div class="product-name">
            <span class="product-quantity" *ngIf="product.sku_quantity!=1">{{product.sku_quantity}}x</span>
            <span>{{product.model_name}}</span>
        </div>

        <div class="product-attrs">

            <div class="product-price-wrapper">

                <span class="product-price">{{ product.sku_full_price | currency }}</span>

                <div class="product-price-extras" *ngIf="product.sku_quantity!=1">
                    <small class="product-unit-price">
                        {{ product.sku_full_unit_price | currency }}/шт
                    </small>
                </div>

            </div>

            <small class="product-available">
                {{product.detail_name}}
            </small>

            <seller-chip [data]="product"></seller-chip>

        </div>

        <mat-form-field>
            <mat-label>Модель</mat-label>
            <mat-select formControlName="model_id">
                <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                    <mat-option *ngFor="let m of d.models" [value]="m.model_id">{{m.model_name}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Кількість</mat-label>
                <mat-select formControlName="sku_quantity">
                    <mat-option [value]="m" *ngFor="let m of quantities">{{m}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="spacer">
                <mat-label>Статус</mat-label>
                <mat-select formControlName="product_status">
                    <mat-option [value]="'unknown'">Невідомий</mat-option>
                    <mat-option [value]="'verified'">Верифікований</mat-option>
                    <mat-option [value]="'suggestion'">Пропозиція спільноти</mat-option>
                    <mat-option [value]="'caution'">Підозрілий</mat-option>
                    <mat-option [value]="'invalid'">Помилка</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>SKU</mat-label>
                <input matInput formControlName="sku_id" placeholder="1234567890" />
            </mat-form-field>

            <mat-form-field class="spacer">
                <mat-label>Видалення</mat-label>
                <mat-select formControlName="product_deleted">
                    <mat-option [value]="0">Активний</mat-option>
                    <mat-option [value]="1">Видалений</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-form-field class="spacer">
            <mat-label>Інтервал оновлення, с</mat-label>
            <input matInput formControlName="product_update_interval" placeholder="1800" />
        </mat-form-field>

    </form>


    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Відміна</button>
        <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">Зберегти</button>
    </mat-dialog-actions>

</mat-dialog-content>

import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService, TenantStat } from '../../data.service';

interface TenantStatFull extends TenantStat {
    user_name: string;
    user_email: string;
    user_image_url: string;
}

@Component({
    selector: 'app-tenants-dialog',
    templateUrl: './tenants-dialog.component.html',
    styleUrl: './tenants-dialog.component.scss'
})
export class TenantsDialogComponent implements AfterViewInit {

    public static open(dialog: MatDialog) {
        return dialog.open(TenantsDialogComponent, { width: '960px', autoFocus: false });
    }

    public displayd_columns = ['tenant_id', 'tenant_name', 'user', 'users_count', 'drones_count', 'orders_count', 'orders_amount'];

    public tenants: TenantStatFull[] = [];
    public data_source = new MatTableDataSource<TenantStatFull>([]);

    @ViewChild(MatSort) sort!: MatSort;
    ngAfterViewInit() { this.data_source.sort = this.sort; }

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<TenantsDialogComponent>,
    ) {

        this.data.getTenants().subscribe({
            next: response => {
                this.tenants = response.tenants.map(t => ({ ...t, ...response.users.find(u => u.user_id === t.user_id)! }));
                this.data_source.data = this.tenants;
            }
        });

    }

    public getTotal(key: keyof TenantStatFull) {
        return this.data_source.data.reduce((a, c) => a + Number((c as any)[key]), 0);
    }

}

import { Component, OnDestroy } from '@angular/core';
import { DashboardResponse, DataService, ProductFull } from '../data.service';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrl: './marketplace.component.scss'
})
export class MarketplaceComponent {

  

}

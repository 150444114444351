import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService, Payout, UserMoney } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { dateToLocalDate, dateToLocalTime, stringsToDate, timestampToLocalDate } from '../utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-payout',
    templateUrl: './payout.component.html',
    styleUrl: './payout.component.scss',
})
export class PayoutComponent implements OnInit {

    public static open(dialog: MatDialog, data: {}) {
        return dialog.open<PayoutComponent, any, Payout>(PayoutComponent, { data: { user: data }, width: '480px' });
    }

    public loading = false;
    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    @Input()
    public user: UserMoney;

    public payout_amount_uah = 0;

    constructor(
        public readonly data: DataService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        @Inject(MAT_DIALOG_DATA) dialog_data: { user: UserMoney },
        private readonly ref: MatDialogRef<PayoutComponent>,
        private readonly snackbar: MatSnackBar,
    ) {
        this.user = dialog_data.user;
    }
    public ngOnInit(): void {
        if (this.user) {
            this.payout_amount_uah = this.user.order_amount_uah - this.user.order_donate_amount_uah - this.user.payout_amount_uah;
            this.form.controls.payout_amount_uah.setValue(this.payout_amount_uah.toFixed(2));
        }
    }

    public form = new FormGroup({
        payout_amount_uah: new FormControl('', [Validators.required, Validators.pattern(/^\-?\d+(\.\d+)?$/)]),
        payout_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        payout_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        payout_comment: new FormControl(''),
    });

    public save() {

        const user = this.user;

        if (!this.form.valid || this.loading || !user) {
            this.form.markAsTouched();
            return;
        }

        this.loading = true;

        this.data.createPayout({
            receiver_user_id: user.user_id,
            payout_date: stringsToDate(this.form.value.payout_date!, this.form.value.payout_time!).getTime() / 1000,
            payout_amount_uah: Number(this.form.value.payout_amount_uah),
            payout_comment: this.form.value.payout_comment || '',
        }).subscribe({
            next: payout => {
                user.payout_amount_uah += payout.payout_amount_uah;
                user.balance -= payout.payout_amount_uah;
                this.loading = false;
                this.snackbar.open('Виплату збережено!', 'OK');
                this.ref.close({
                    ...user, ...payout,
                    sender_user_id: this.data.profile!.user_id,
                    sender_user_name: this.data.profile!.user_name,
                });
            }, error: error => {
                this.loading = false;
                this.data.showMessage('Сталася помилка!');
            }
        });

    }

}
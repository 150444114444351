import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductButtonsDirective } from './products/product-card/product-card.component';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RequestComponent } from './request/request.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { DataService } from './data.service';
import { ReportComponent } from './report/report.component';
import { AuthGuard } from './auth.guard';
import { OrderComponent } from './order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { ProductsModule } from './products/products.module';
import { PromosModule } from './promos/promos.module';
import { ProfileModule } from './profile/profile.module';
import { RequestsModule } from './requests/requests.module';
import { SharedModule } from './shared/shared.module';
import { MatIconRegistry } from '@angular/material/icon';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TenantModule } from './tenant/tenant.module';
import { PayoutsModule } from './payouts/payouts.module';
import { StockModule } from './stock/stock.module';
import { ModelsModule } from './models/models.module';
import { OrdersModule } from './orders/orders.module';
import { LayoutService } from './layout.service';
import { AboutModule } from './about/about.module';
import { ModelEditDialogComponent } from './model-edit-dialog/model-edit-dialog.component';
import { environment } from './../environments/environment';
import { MotorsModule } from './motors/motors.module';
import { TenantGuard } from './tenant.guard';
import { ProductEditDialogComponent } from './product-edit-dialog/edit-product-dialog.component';
import { ReportDoneDialogComponent } from './report/report-done-dialog/report-done-dialog.component';
import { OnboardingSignupDialogComponent } from './dialogs/onboarding-dialog/onboarding-signup-dialog.component';
import { UpsertWarehouseDialogComponent } from './dialogs/upsert-warehouse-dialog/upsert-warehouse-dialog.component';
import { AddStockDialogComponent } from './dialogs/add-stock-dialog/add-stock-dialog.component';
import { DronesModule } from './drones/drones.module';
import { TrackModule } from './track/track.module';
import { SellerDialogComponent } from './seller-dialog/seller-dialog.component';
import { KitEditDialogComponent } from './kit-edit-dialog/kit-edit-dialog.component';
import { RequestKitDialogComponent } from './dialogs/request-kit-dialog/request-kit-dialog.component';
import { SellersModule } from "./sellers/sellers.module";
import { SellerEditDialogComponent } from "./seller-edit-dialog/seller-edit-dialog.component";
import { ProductCustomEditDialogComponent } from "./product-custom-edit-dialog/product-custom-edit-dialog.component";
import { RequestAlertDialogComponent } from './request/request-alert/request-alert.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ScrapperGuard } from './scrapper.guard';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { LayoutComponent } from './layout.component';
import { DroneComponent } from './drone.component';
import { AffiliatesDialogComponent } from './dialogs/affiliates-dialog/affiliates-dialog.component';
import { PrintModule } from './print/print.module';
import { HomeComponent } from './home/home.component';
import { SigninDisabledDialogComponent } from './dialogs/signin-disabled-dialog/signin-disabled-dialog.component';
import { UpsertRuleDialogComponent } from './dialogs/upsert-rule-dialog/upsert-rule-dialog.component';
import { ApiService } from './api.service';
import { RulesDialogComponent } from './dialogs/rules-dialog/rules-dialog.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

const COMPONENTS = [
    ToolbarComponent,
    ProductDialogComponent,
    ModelEditDialogComponent,
];

const DIALOGS = [
    SellerDialogComponent,
    SellerEditDialogComponent,
    AddStockDialogComponent,
    UpsertWarehouseDialogComponent,
    OnboardingSignupDialogComponent,
    KitEditDialogComponent,
    RequestKitDialogComponent,
    ReportDoneDialogComponent,
    ProductEditDialogComponent,
    ProductCustomEditDialogComponent,
    RequestAlertDialogComponent,
    AffiliatesDialogComponent,
    SigninDisabledDialogComponent,
    UpsertRuleDialogComponent,
    RulesDialogComponent,
];


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterOutlet,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgApexchartsModule,
        RouterModule.forRoot(routes),
        MaterialModule,
        ProductsModule,
        PromosModule,
        ProfileModule,
        RequestsModule,
        SharedModule,
        TenantModule,
        PayoutsModule,
        StockModule,
        ModelsModule,
        OrdersModule,
        AboutModule,
        MotorsModule,
        DronesModule,
        TrackModule,
        SellersModule,
        DashboardModule,
        MarketplaceModule,
        PrintModule,
    ],
    declarations: [
        AppComponent,
        ProductButtonsDirective,
        RequestComponent,
        ReportComponent,
        OrdersComponent,
        HomeComponent,
        OrderComponent,
        LayoutComponent,
        DroneComponent,
        ...COMPONENTS,
        ...DIALOGS,
    ],
    providers: [
        ApiService,
        DataService,
        AuthGuard,
        TenantGuard,
        ScrapperGuard,
        LayoutService,
        provideAnimationsAsync(),
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: environment.defaultCurrency
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    }
}

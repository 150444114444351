<ng-container *ngIf="order">

    <mat-card [class.product-soldout]="order.order_status=='cancelled'" [class.nolink]="!order.order_tracking_number"
        routerLink="." [queryParams]="order.view_query_params">

        <div mat-card-image class="product-image-wrapper">

            <img class="product-image" [src]="order.sku_image_url || order.item_image_url || order.model_image_url" />

            <div class="product-extras product-extras-top">

                <small class="product-extra product-seller" [class.product-future-price]="!order.order_number">
                    <a mat-ripple class="product-extra product-seller order-number" target="_blank"
                    [href]="'https://www.aliexpress.com/p/order/detail.html?&orderId='+order.order_number">
                    <img class="product-seller-logo" src="https://ae01.alicdn.com/images/eng/wholesale/icon/aliexpress.ico" />
                        <span class="product-seller-name">#{{order.order_number || 'без номера'}}</span>
                        <mat-icon *ngIf="order.order_number">open_in_new</mat-icon>
                    </a>
                    <!-- <span *ngIf="!order.order_number">Order ID відсутній</span> -->
                </small>

                <small class="product-extra product-seller product-future-price" *ngIf="!order.order_tracking_number">
                    <img class="product-seller-logo" src="/assets/logo.png" />
                    <span class="product-seller-name">Трекінг відсутній</span>
                </small>

                <small class="product-extra product-seller" *ngFor="let track of order.order_tracking_number_array">
                    <img class="product-seller-logo" [src]="track.tracking_image_url" />
                    <span class="product-seller-name order-tracking-wrapper">
                        <span class="order-tracking-number">
                            <span class="order-tracking-number-orig">{{track.tracking_number}}</span>
                            <span class="order-tracking-number-alt"
                                *ngIf="track.tracking_number_alt">{{track.tracking_number_alt}}</span>
                        </span>
                        <span> | {{track.tracking_status_local}}</span>
                    </span>
                </small>

                <!-- <small>
                    <a mat-ripple class="product-extra product-seller order-number" *ngFor="let num of order.order_tracking_number_array" target="_blank"
                [href]="'https://posylka.net/uk/parcel/'+num">
                        <small class="product-extra product-seller" [class.product-future-price]="!order.order_tracking_number">
                            <img class="product-seller-logo" src="https://posylka.net/favicon/favicon-196x196.png" />
                            <span class="product-seller-name">{{num}}</span>
                            <mat-icon>open_in_new</mat-icon>
                        </small>
                    </a>
                </small> -->

                <div class="product-extra donate" *ngIf="order.order_donate_amount_uah">
                    Донат {{order.order_donate_amount_uah | currency }}
                </div>

            </div>

            <div class="product-extras">

                <div class="product-extra">
                    {{order.order_local_date}}
                </div>

                <div class="product-extra product-seller">
                    <img class="product-seller-logo" [src]="order.user_image_url||'/assets/logo.png'" />
                    <span class="product-seller-name">{{order.user_name}}</span>
                </div>

                <seller-chip [data]="order"></seller-chip>

                <div class="product-extra product-seller" *ngIf="!order.seller_name_short">
                    <img class="product-seller-logo" src="/assets/logo.png" />
                    <span class="product-seller-name">Не AliExpress</span>
                </div>

                <div class="product-extra" *ngIf="order.order_recipient" matTooltip="Отримувач посилки">
                    <mat-icon>person_pin</mat-icon>
                    <span>{{order.order_recipient}}</span>
                </div>

            </div>

            <div class="product-status-icon" *ngIf="order.order_problematic"
                matTooltip="Замовлення позначено як проблемне">
                <mat-icon color="primary">error</mat-icon>
            </div>

            <mat-checkbox (click)="$event.stopPropagation()" *ngIf="selectable" [checked]="order.selected"
                (change)="order.selected=$event.checked;change.emit($event.checked)"></mat-checkbox>

        </div>

        <mat-card-content>

            <div class="product-name">
                <span class="product-quantity">{{order.order_quantity}}x</span>
                <span>{{order.model_name}}</span>
            </div>

            <div class="product-price-wrapper">

                <span class="product-price">{{ order.order_amount_usd | currency }}</span>

                <div class="product-price-extras">

                    <small class="product-unit-price" *ngIf="order.order_quantity>1">
                        {{ order.order_unit_price_usd | currency }}/шт
                    </small>

                    <!-- <small class="product-available">
                        {{ order.order_amount_uah | number: '1.2-2' }} грн
                    </small> -->

                </div>

            </div>

            <button mat-button color="primary" class="product-url" [ngClass]="order.order_status">
                <span>{{order.order_local_status}}</span>
            </button>

        </mat-card-content>

    </mat-card>




    <!-- posylka.net -->

    <!-- <input mat-button color="primary" type="button" id="{{order.order_tracking_number_array}}" class="widget-pkge-net-button" value="{{ order.order_tracking_number_array }}" onclick="widgetTrack('LP00647727086193')"  /> -->
    <!-- <input mat-button color="primary" type="button" id="{{order.order_tracking_number_array}}" class="widget-pkge-net-button" value="{{ order.order_tracking_number_array }}" onclick="widgetTrack('LP00648034891164')" /> -->


    <!-- <a (click)="widgetTrack($event)">Link</a> -->

    <!--Елементи, після яких буде створено фрейм з результатами відстеження посилки.-->
    <!-- <button id="pkge-track-1-test" mat-button color="primary" class="product-url">
        {{ order.order_number }}
    </button> -->

    <!-- <script type="text/javascript" src="https://posylka.net/externalwidget.min.js"></script> -->

</ng-container>

import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService, UserShortExtended } from '../../data.service';

interface UserShortExtendedFull extends UserShortExtended {
    tenants: string;
}

@Component({
    selector: 'app-all-users-dialog',
    templateUrl: './users-dialog.component.html',
    styleUrl: './users-dialog.component.scss'
})
export class AllUsersDialogComponent implements AfterViewInit {

    public static open(dialog: MatDialog) {
        return dialog.open(AllUsersDialogComponent, { width: '960px' });
    }

    public displayd_columns = ['user_id', 'user', 'tenants', 'impersonate'];

    public users: UserShortExtendedFull[] = [];
    public data_source = new MatTableDataSource<UserShortExtendedFull>([]);

    @ViewChild(MatSort) sort!: MatSort;
    ngAfterViewInit() {
        this.data_source.sort = this.sort;
        this.data_source.filterPredicate = (data, filter) => {
            filter = filter.toLowerCase();
            return data.user_name.toLowerCase().includes(filter)
                || data.user_email.toLowerCase().includes(filter)
                || String(data.user_id).includes(filter);
        };
    }

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<AllUsersDialogComponent>,
    ) {

        this.data.getTenants().subscribe({
            next: response => {
                this.users = response.users.map(u => ({
                    ...u,
                    tenants: response.tenants
                        .filter(t => t.users_ids.split(',').includes(String(u.user_id)))
                        .map(t => t.tenant_name).join(', '),
                }));
                this.data_source.data = this.users;
            }
        });

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.data_source.filter = filterValue.trim().toLowerCase();
      }

    public impersonate(user: UserShortExtendedFull) {
        this.data.impersonate(user.user_id);
    }

}

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { OrderRequest } from '../../data.service';
import { FormControl } from '@angular/forms';
import { STORAGE_KEY_REQUEST_ALERT_DONT_SHOW } from '../../consts';


@Component({
    selector: 'app-request-alert',
    templateUrl: './request-alert.component.html',
    styleUrl: './request-alert.component.scss'
})
export class RequestAlertDialogComponent {
    public static open(dialog: MatDialog, request: OrderRequest): Promise<unknown> {
        const dialogRef = dialog.open(RequestAlertDialogComponent, { data: request, minWidth: '300px', maxWidth: '600px', maxHeight: '80vh', autoFocus: false, });
        return firstValueFrom(dialogRef.afterClosed());
    }

    public control_dont_show = new FormControl(false);

    constructor(
        public dialogRef: MatDialogRef<RequestAlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public request: OrderRequest,
    ) { }

    public close() {
        if (this.control_dont_show.value) {
            localStorage.setItem(STORAGE_KEY_REQUEST_ALERT_DONT_SHOW, 'true');
        }
        this.dialogRef.close();
    }

}

<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <app-payouts-table #payoutsTable [loading]="loading" [payouts]="payouts"></app-payouts-table>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До користувачів' : 'Виплат')}}</span>
                <div>
                    <span>{{payouts.length}}</span>
                    <small> всього</small>
                </div>
            </div>
        </button>

    </div>

    <app-users-table [users]="users" [payouts]="payouts" (edit)="onEditUser($event)" (paid)="payoutsTable.update()" (add)="onAdd()"></app-users-table>

    <div class="products-buttons">

        <button mat-fab color="accent" *ngIf="layout.is_mobile | async" (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
        </button>

        <div class="spacer"></div>

    </div>

</app-sidebar>

<mat-dialog-content>

    <div class="mat-subtitle-1">
        {{title}}
    </div>

    <form [formGroup]="statusForm">
        <div class="flex column">

            <ng-container [ngSwitch]="dialog_data.status">
                <ng-container *ngSwitchCase="'cancelled'">
                    <mat-form-field>
                        <mat-label>Причина відміни</mat-label>
                        <input matInput formControlName="event_comment" placeholder="Опишіть причину відміни" />
                    </mat-form-field>

                </ng-container>

                <ng-container *ngSwitchCase="'picked'">
                    <mat-form-field>
                        <mat-label>Коментар</mat-label>
                        <input matInput formControlName="event_comment" placeholder="Залиште коментар за потреби" />
                    </mat-form-field>

                </ng-container>

                <ng-container *ngSwitchCase="'received'">
                    <mat-form-field>
                        <mat-label>Склад на який йде передача</mat-label>
                        <mat-select formControlName="warehouse_id">
                            <mat-option [value]="w.warehouse_id" *ngFor="let w of warehouses">
                                {{w.warehouse_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Коментар</mat-label>
                        <input matInput formControlName="event_comment" placeholder="Залиште коментар за потреби" />
                    </mat-form-field>

                </ng-container>

            </ng-container>
        </div>

    </form>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Закрити</button>
    <button mat-flat-button color="accent" [disabled]="!canSave" (click)="updateStatus()" [buttonLoading]="loading">
        {{actionText}}
    </button>
</mat-dialog-actions>
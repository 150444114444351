<main>

    <h3 *ngIf="data.lang=='uk'">Друк з принтерами Niimbot</h3>
    <h3 *ngIf="data.lang=='en'">Print with Niimbot printers</h3>
    <p *ngIf="data.lang=='uk'">
        Формуйте посилання в форматі <code>https://swarm.army/print?text=???</code>
        для автоматизації друку з Google Sheets, тощо.<br />
        Коректність роботи залежить від операційної системи та браузера.
        Більше інформації про системні вимоги та додаткові налаштування
        <a target="_blank"
            href="https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md#chrome">тут</a>.
    </p>
    <p *ngIf="data.lang=='en'">
        Create links in the <code>https://swarm.army/print?text=???</code> format
        to automate printing with Google Sheets etc.<br />
        Correct work depends on OS and browser.
        More info about system requirements
        <a target="_blank"
            href="https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md#chrome">here</a>.
    </p>

    <div class="flex">

        <mat-form-field>
            <mat-label *ngIf="data.lang=='uk'">Розмір шрифту</mat-label>
            <mat-label *ngIf="data.lang=='en'">Font Size</mat-label>
            <mat-select [formControl]="control_font_size" [disabled]="loading">
                <mat-option [value]="s" *ngFor="let s of font_sizes">{{s}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label *ngIf="data.lang=='uk'">Розмір наліпки</mat-label>
            <mat-label *ngIf="data.lang=='en'">Label Size</mat-label>
            <mat-select [formControl]="control_label_size" [disabled]="loading">
                <mat-option [value]="s" *ngFor="let s of label_sizes">{{s.label}}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <mat-form-field>
        <mat-label *ngIf="data.lang=='uk'">Текст для друку</mat-label>
        <mat-label *ngIf="data.lang=='en'">Text to Print</mat-label>
        <textarea matInput cdkTextareaAutosize [formControl]="control_text" [disabled]="loading"></textarea>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="print()" [buttonLoading]="loading">
        <span *ngIf="data.lang=='uk'">{{texts.length>1?'Друкувати всі':'Друкувати'}}</span>
        <span *ngIf="data.lang=='en'">{{texts.length>1?'Print All':'Print'}}</span>
    </button>

</main>

<div id="container">
    <canvas id="canvas"></canvas>
</div>

<div class="paginator" *ngIf="texts.length>1">

    <button mat-icon-button (click)="setIndex(current_index-1)" [disabled]="loading||current_index==0">
        <mat-icon>chevron_left</mat-icon>
    </button>

    <div>
        {{current_index+1}}<small>/{{texts.length}}</small>
    </div>

    <button mat-icon-button (click)="setIndex(current_index+1)" [disabled]="loading||current_index==texts.length-1">
        <mat-icon>chevron_right</mat-icon>
    </button>

</div>
<ng-container *ngIf="data">

    <a class="product-extra product-seller" (click)="onClick($event)" [routerLink]="[]"
        [queryParams]="data.seller_view_query_params">
        <img class="product-seller-logo" [src]="data.seller_image_url" />
        <span class="product-seller-name">{{data.seller_name_short||data.seller_name}}</span>
        <img class="product-seller-choice" *ngIf="data.seller_flag_url" [src]="data.seller_flag_url" />

        <div class="product-seller-attrs" *ngIf="clickable">

            <div class="product-seller-attr">
                <div>Позитивних відгуків</div>
                <b>{{(data.seller_positive_rate||0).toFixed()}}<small>%</small></b>
            </div>

            <div class="product-seller-attr">
                <div>Підписники</div>
                <b>{{data.seller_followers}}</b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_buyers">
                <div>Покупці</div>
                <b>{{data.seller_buyers}}</b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_sold">
                <div>Продано магазином</div>
                <b>{{data.seller_sold}}</b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_rating">
                <div>Рейтинг</div>
                <b>{{data.seller_rating.toFixed(1)}}<small>/5</small></b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_communication">
                <div>Комунікація</div>
                <b>{{data.seller_communication.toFixed(1)}}<small>/5</small></b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_match">
                <div>Згідно опису</div>
                <b>{{data.seller_match.toFixed(1)}}<small>/5</small></b>
            </div>

            <div class="product-seller-attr" *ngIf="data.seller_open_time">
                <div>Засновано</div>
                <b>{{timestampToLocalDate(data.seller_open_time)}}</b>
            </div>

        </div>

    </a>

</ng-container>
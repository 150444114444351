import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { DataService, Filter, OrderRequest, Product, ProductFull } from '../data.service';
import { UpsertRequestDialogComponent } from '../dialogs/upsert-request-dialog/upsert-request-dialog.component';
import { LayoutService } from '../layout.service';
import { OrderRequestVm } from './models/index';

const FILTERS_KEY = 'requests';

@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrl: './requests.component.scss'
})
export class RequestsComponent {

    public requests: OrderRequestVm[] = [];
    public filtered_requests: OrderRequestVm[] = [];
    public loading = true;

    public readonly status_filter: Filter = {
        key: 'status',
        name: 'Статус',
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'active': true, 'inactive': false, },
        options: [
            { id: 'active', name: 'Активні', image: '' },
            { id: 'inactive', name: 'Неактивні', image: '' },
        ],
    };

    public readonly details_filter: Filter = { key: 'detail', name: 'Деталі', dict: { all: true }, expanded: true, options: [], update: () => this.updateFilters() };

    public filters: Filter[] = [
        this.status_filter,
        this.details_filter,
    ];

    constructor(public readonly data: DataService, private readonly dialog: MatDialog, public readonly layout: LayoutService) {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.data.tenant_id_cnange.subscribe(() => this.reload());
    }

    private reload() {

        const updatePrices = (products: ProductFull[]) => {

            if (!products.length) return;

            this.requests.forEach(r => {
                const min_product = products.filter(p => p.model_id === r.model_id && p.product_status === 'verified' && p.sku_last_available)
                    .sort((a, b) => a.sku_min_unit_price - b.sku_min_unit_price)[0];
                if (min_product) {
                    r.min_price = min_product.sku_min_unit_price;
                }
            });

        };

        this.data.getRequests(true).subscribe(reqs => {
            this.requests = reqs.sort((a, b) => b.request_quantity - a.request_quantity).map(r => ({
                ...r,
                min_price: 0,
                request_url: '/requests/' + this.data.makeUrl(r.model_name),
            }));
            this.updateDetails();
            this.data.getFullProducts().subscribe(products => {
                updatePrices(products);
            });
            this.loading = false;
        });

    }

    public updateDetails() {

        const status_dict = this.status_filter.dict;

        this.details_filter.options = [
            {
                id: 'all',
                name: 'Всі',
                image: ''
            },
            ...this.requests.map(r => ({ id: r.detail_id, name: r.detail_name, image: r.detail_image_key }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.requests.some(r => r.detail_id === v.id
                        && (status_dict['active'] && r.request_quantity > 0 || status_dict['inactive'] && r.request_quantity <= 0)))
        ];

        const all_options_selected = this.details_filter.options.every(o => this.details_filter.dict[o.id]);
        const no_options_selected = this.details_filter.options.every(o => !this.details_filter.dict[o.id]);

        if (all_options_selected || no_options_selected) {
            Object.keys(this.details_filter.dict).forEach(k => this.details_filter.dict[k] = false);
            this.details_filter.dict['all'] = true;
        }

        this.updateFilters();

    }

    private updateFilters() {
        this.data.setFilters(FILTERS_KEY, this.filters);
        this.filtered_requests = this.requests.filter(r => this.requestVisible(r));
    }

    private requestVisible(r: OrderRequest) {
        if (!this.data.admin) return true;
        const status_dict = this.status_filter.dict;
        return (this.details_filter.dict['all'] || this.details_filter.dict[r.detail_id])
            && (status_dict['active'] && r.request_quantity > 0 || status_dict['inactive'] && r.request_quantity <= 0);
    }

    public getUrl(r: OrderRequest) {
        return '/requests/' + r.model_name.replace(/ /g, '-').replace(/[^\w\-]/g, '').toLowerCase();
    }

    public upsertRequest(membership: OrderRequest | null = null) {
        UpsertRequestDialogComponent.open(this.dialog, membership).afterClosed().pipe(filter(r => r)).subscribe(() => this.reload());
    }

}

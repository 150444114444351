import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { PromosModule } from '../promos/promos.module';
import { SharedModule } from '../shared/shared.module';
import { MarketplaceComponent } from './marketplace.component';



@NgModule({
    declarations: [
        MarketplaceComponent,
    ],
    exports: [
        MarketplaceComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        PromosModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
    ]
})
export class MarketplaceModule { }

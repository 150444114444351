import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ClickIds, ClickSource, ClickType, CommentFull, DataService, ModelFull, ProductFull } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from '../layout.service';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexLegend,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ChartComponent
} from "ng-apexcharts";
import { ProductEditDialogComponent } from '../product-edit-dialog/edit-product-dialog.component';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductCustomEditDialogComponent } from "../product-custom-edit-dialog/product-custom-edit-dialog.component";


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: any;
    yaxis: ApexYAxis | ApexYAxis[];
    dataLabels: ApexDataLabels;
    title: ApexTitleSubtitle;
    legend: ApexLegend;
    tooltip: ApexTooltip;
    labels: any[];
};


export interface StatsDialogData {
    product_id: number;
}

@Component({
    selector: 'app-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrl: './product-dialog.component.scss'
})
export class ProductDialogComponent {

    public static open(dialog: MatDialog, data: StatsDialogData) {
        return dialog.open(ProductDialogComponent, { data, width: '848px', autoFocus: false, restoreFocus: false, panelClass: 'mobile-fullscreen', });
    }

    public product?: ProductFull;

    @ViewChild("chart") chart?: ChartComponent;
    public chartOptions?: ChartOptions;

    public model: ModelFull | null = null;
    public products: ProductFull[] = [];

    public products_data_source?: MatTableDataSource<ProductFull>;

    public readonly products_columns = ['seller_name', 'sku_full_unit_price', 'sku_last_available', 'urls'];

    public comments: CommentFull[] | null = null
    public comments_swarm: CommentFull[] | null = null

    @ViewChild(MatSort) set sort(sort: MatSort) {
        if (sort && this.products_data_source) {
            this.products_data_source.sort = sort;
            sort.sort({
                id: 'price',
                start: 'asc',
                disableClear: false
            });
        }
    }

    public control_comment_target = new FormControl<'model' | 'seller' | 'product'>('product');
    public control_comment_text = new FormControl<string>('');
    public comment_loading = false;
    public comment_edited: CommentFull | null = null;

    public commentEdit(comment: CommentFull) {
        this.comment_edited = comment;
        this.control_comment_text.setValue(comment.comment_text);
        this.control_comment_target.setValue(comment.seller_id ? 'seller' : comment.model_id ? 'model' : 'product');
    }

    public clearComment() {
        this.comment_edited = null;
        this.control_comment_text.setValue('');
        this.control_comment_target.setValue('product');
    }

    public commentPin(comment_to_pin: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_pin,
            comment_pinned: comment_to_pin.comment_pinned ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_pin, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public commentDelete(comment_to_delete: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_delete,
            comment_deleted: comment_to_delete.comment_deleted ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_delete, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public upsertComment() {

        const product = this.product;
        const comment_text = this.control_comment_text.value;

        if (!product) return;

        if (!comment_text || comment_text.length < 10) {
            this.snackbar.open('Введіть текст коментаря', 'OK');
            return;
        }

        if (comment_text.length >= 4096) {
            this.snackbar.open('Коментар занадто довний', 'OK');
            return;
        }

        if (comment_text.includes('<script') || comment_text.includes('<link') || comment_text.includes('<object')) {
            this.snackbar.open('Некоректний коментар', 'OK');
            return;
        }

        const comment_target = this.control_comment_target.value || 'product';

        this.comment_loading = true;

        this.data.upsertComment({
            comment_id: this.comment_edited?.comment_id || null,
            drone_id: null,
            model_id: comment_target === 'model' ? product.model_id : null,
            seller_id: comment_target === 'seller' ? product.seller_id : null,
            product_id: comment_target === 'product' ? product.product_id : null,
            comment_text: comment_text,
            comment_pinned: this.comment_edited?.comment_pinned ?? 0,
            comment_deleted: this.comment_edited?.comment_deleted ?? 0,
        }).subscribe({
            next: comment => {

                this.comment_loading = false;

                if (this.comment_edited) {
                    Object.assign(this.comment_edited, comment);
                    this.comment_edited = null;
                } else {
                    this.comments?.push(comment);
                }

                this.sortComments();

                this.control_comment_text.setValue('');
                this.control_comment_target.setValue('product');

            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    constructor(
        public readonly data: DataService,
        @Inject(MAT_DIALOG_DATA) public dialog_data: StatsDialogData,
        private readonly route: ActivatedRoute,
        public readonly layout: LayoutService,
        public readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
    ) {

        this.initProduct();
        this.initModel();
        this.initChart();

    }

    private initModel() {
        this.data.getModels().subscribe({
            next: models => {
                const model_name = this.route.snapshot.queryParams['model'];
                this.model = models.find(m => m.model_id === this.product?.model_id)
                    || models.find(m => this.data.makeUrl(m.model_name) === model_name)
                    || null;
            }
        });
    }

    private sortComments() {
        this.comments?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }

    private sortCommentsSWARM() {
        this.comments_swarm?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }

    private initProduct() {

        this.data.getComments({ product_id: this.dialog_data.product_id })
            .subscribe({
                next: comments => {
                    this.comments = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortComments();
                }
            });

        this.data.getCommentsSWARM({ product_id: this.dialog_data.product_id })
            .subscribe({
                next: comments => {
                    this.comments_swarm = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortCommentsSWARM();
                }
            });

        this.data.getFullProducts().subscribe({
            next: products => {

                this.product = products.find(p => p.product_id === this.dialog_data.product_id);

                this.products = products.filter(p => p.model_id === this.product?.model_id && !p.product_deleted)
                    .sort((a, b) => a.sku_last_available && !b.sku_last_available ? -1 : b.sku_last_available && !a.sku_last_available ? 1 : a.sku_min_unit_price_usd - b.sku_min_unit_price_usd);

                this.products_data_source = new MatTableDataSource(this.products);

                this.initModel();

            }
        });

    }

    private initChart() {

        this.data.getUpdates(this.dialog_data.product_id).subscribe(updates => {

            if (!updates.length) return;

            const min_available = updates.map(u => u.sku_available).reduce((a, c) => c < a ? c : a, Number.POSITIVE_INFINITY);

            let min_price = Math.min(
                updates.map(u => u.sku_price).reduce((a, c) => c < a ? c : a, Number.POSITIVE_INFINITY),
                updates.map(u => u.sku_sd_price).reduce((a, c) => c ? c < a ? c : a : a, Number.POSITIVE_INFINITY),
            );

            let max_price = updates.map(u => u.sku_price).reduce((a, c) => c > a ? c : a, Number.NEGATIVE_INFINITY);

            min_price = Math.floor(Math.max(0, min_price - min_price * 0.1));
            max_price = Math.ceil(max_price + max_price * 0.1);

            const offset = - new Date().getTimezoneOffset() * 60 * 1000;

            this.chartOptions = {
                title: {},
                series: [
                    {
                        name: "Ціна",
                        type: "line",
                        data: updates.map(u => u.sku_price),
                        color: '#333',
                    },
                    {
                        name: "SuperDeals",
                        type: "line",
                        data: updates.map(u => u.sku_sd_price || null),
                        color: '#fd384f',
                    },
                    {
                        name: "Наявність",
                        type: "area",
                        data: updates.map(u => u.sku_available),
                        color: '#5be96c',
                    },
                ],
                chart: {
                    height: 256,
                    type: "line",
                    stacked: false,
                    toolbar: {
                        tools: {
                            download: false,
                            selection: true,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: true,
                            customIcons: []
                        },
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2, 4, 2]
                },
                xaxis: {
                    type: "datetime",
                    tooltip: {
                        enabled: false
                    }
                },
                labels: updates.map(u => new Date(u.update_date * 1000 + offset).toISOString()),
                yaxis: [
                    {
                        seriesName: 'Ціна',
                        min: min_price,
                        max: max_price,
                        decimalsInFloat: 2,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#333"
                        },
                        labels: {
                            style: {
                                colors: "#333"
                            }
                        },
                        title: {
                            text: "Ціна, €",
                            style: {
                                color: "#333"
                            }
                        },
                        tooltip: {
                            enabled: false,
                        }
                    },
                    {
                        min: min_price,
                        max: max_price,
                        seriesName: 'SuperDeals',
                        show: false,
                    },
                    {
                        seriesName: 'Наявність',
                        min: Math.max(0, min_available - min_available * 0.1),
                        decimalsInFloat: 0,
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#5be96c"
                        },
                        labels: {
                            style: {
                                colors: "#5be96c"
                            }
                        },
                        title: {
                            text: "Наявність, шт",
                            style: {
                                color: "#5be96c"
                            }
                        }
                    }
                ],
                tooltip: {
                    y: [
                        {
                            formatter: (y: number) => y === null ? '' : '€' + y.toFixed(2),
                        },
                        {
                            formatter: (y: number) => y === null ? null as any as string : '€' + y.toFixed(2),
                        },
                        {
                            formatter: (y: number) => y === null ? '' : y.toString(),
                        }
                    ]
                },
                legend: {
                    show: true,
                    horizontalAlign: "center",
                    position: 'top',
                    offsetX: 40,
                },
            };

            if (!updates.some(u => u.sku_sd_price)) {
                this.chartOptions.series.splice(1, 1);
                if (Array.isArray(this.chartOptions.tooltip.y))
                    this.chartOptions.tooltip.y.splice(1, 1);
                if (Array.isArray(this.chartOptions.yaxis))
                    this.chartOptions.yaxis.splice(1, 1);
            }

        });

    }

    public edit() {
        if (!this.product)
            return;

        if (this.product.product_source === 'aliexpress') {
            ProductEditDialogComponent.open(this.dialog, this.product);
        }
        else {
            ProductCustomEditDialogComponent.open(this.dialog, this.product);
        }
    }

}

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" class="flex column">

        <div class="product-image-wrapper">
            <img class="product-image" [src]="form.controls.item_image_url.value">
        </div>

        <div class="product-name">
            <span>{{product.model_name}}</span>
        </div>

        <div class="product-attrs">

            <small class="product-available">
                {{product.detail_name}}
            </small>

        </div>

        <mat-form-field>
            <mat-label>Продавець</mat-label>
            <mat-select formControlName="seller_id">
                <mat-option *ngFor="let s of sellers" [value]="s.seller_id">
                    {{s.seller_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Модель</mat-label>
            <mat-select formControlName="model_id">
                <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                    <mat-option *ngFor="let m of d.models" [value]="m.model_id">{{m.model_name}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Статус</mat-label>
                <mat-select formControlName="product_status">
                    <mat-option [value]="'unknown'">Невідомий</mat-option>
                    <mat-option [value]="'verified'">Верифікований</mat-option>
                    <mat-option [value]="'suggestion'">Пропозиція спільноти</mat-option>
                    <mat-option [value]="'caution'">Підозрілий</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Видалення</mat-label>
                <mat-select formControlName="product_deleted">
                    <mat-option [value]="0">Активний</mat-option>
                    <mat-option [value]="1">Видалений</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-form-field class="spacer">
            <mat-label>Назва продукта</mat-label>
            <input matInput formControlName="item_name" placeholder="1234567890" />
        </mat-form-field>

        <mat-form-field class="spacer">
            <mat-label>Посилання на продукт</mat-label>
            <input matInput formControlName="product_url" placeholder="https://anyurl.com" />
        </mat-form-field>

        <mat-form-field class="spacer">
            <mat-label>Ціна</mat-label>
            <input matInput formControlName="sku_last_price" placeholder="123.45" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання на зображення</mat-label>
            <input matInput [placeholder]="'Напр., https://___.png'"
                   formControlName="item_image_url" />
        </mat-form-field>

    </form>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Відміна</button>
        <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">Зберегти</button>
    </mat-dialog-actions>

</mat-dialog-content>

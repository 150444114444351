<app-sidebar #sidebar>

    <div role="sidebar" class="sidebar">

        <header>

            <mat-button-toggle-group [formControl]="sidebar_mode_control">
                <mat-button-toggle value="cart">Кошик</mat-button-toggle>
                <mat-button-toggle value="transfers">Передачі</mat-button-toggle>
            </mat-button-toggle-group>

            <mat-slide-toggle [formControl]="empty_rows" class="my-4">
                Показати пусті баланси
            </mat-slide-toggle>

        </header>

        @if(sidebar_mode_control.value=='cart') {

        <div class="products-empty-message" *ngIf="!cart">
            Кошик порожній
        </div>

        <ng-container *ngIf="cart">

            <main>
                <app-stock-table [stock]="cart.stock" [simplified]="true" (stockChanged)="updateCart($event)">
                </app-stock-table>
            </main>

            <form [formGroup]="form" (submit)="transfer()">

                <div class="flex">

                    <mat-form-field>
                        <mat-label>Склад списання</mat-label>
                        <input matInput disabled [value]="cart.warehouse_name" />
                        <mat-icon matSuffix>east</mat-icon>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Призначення</mat-label>
                        <mat-select formControlName="dst_warehouse_id">

                            <ng-container *ngFor="let w of warehouses">

                                <mat-option *ngIf="w.warehouse_id!=cart.warehouse_id" [value]="w.warehouse_id">
                                    {{w.warehouse_name}}
                                </mat-option>

                            </ng-container>

                            <mat-divider></mat-divider>

                            <mat-option [value]="0">
                                Списати
                            </mat-option>

                        </mat-select>
                    </mat-form-field>

                </div>

                <div class="flex">
                    <mat-form-field>
                        <mat-label>
                            Дата
                            {{ !form.controls.dst_warehouse_id.value ? 'списання' : 'передачі' }}
                        </mat-label>
                        <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_date"
                            formControlName="transfer_date">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>
                            Час
                            {{ !form.controls.dst_warehouse_id.value ? 'списання' : 'передачі' }}
                        </mat-label>
                        <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_time"
                            formControlName="transfer_time">
                    </mat-form-field>
                </div>

                <mat-form-field>
                    <mat-label>Коментар</mat-label>
                    <input matInput placeholder="Коментар..." formControlName="transfer_comment">
                </mat-form-field>

                <button mat-flat-button color="accent" type="submit" style="width:100%;" [buttonLoading]="loading">
                    {{ !form.controls.dst_warehouse_id.value ? 'Списати' : 'Створити передачу' }}
                </button>

            </form>

        </ng-container>

        }
        @else if(sidebar_mode_control.value=='transfers') {

        <div class="products-empty-message" *ngIf="!transfers.length">
            Передач ще немає.
        </div>

        <div class="transfers">
            <div class="transfer" *ngFor="let transfer of transfers">

                <div class="transfer-header">

                    <div class="product-attrs flex">

                        <div class="product-extra product-seller">
                            <img class="product-seller-logo" [src]="transfer.user_image_url" />
                            <span class="product-seller-name">{{transfer.user_name}}</span>
                        </div>

                        <div class="product-available">{{transfer.transfer_date_string}}</div>

                    </div>

                    <div class="flex">

                        @if(transfer.order_id && !transfer.drone_id) {
                        <div class="product-extra transfer-order" matRipple routerLink="."
                            [queryParams]="{view:'order',order_id:transfer.order_id}">
                            <span>Замовлення #{{transfer.order_id}}</span>
                            <mat-icon>open_in_new</mat-icon>
                        </div>
                        }
                        @else if(transfer.src_warehouse_name) {
                        <div class="product-extra">
                            {{transfer.src_warehouse_name}}
                        </div>
                        }
                        @else if(transfer.drone_id && transfer.dst_warehouse_id) {
                        <div class="product-extra green transfer-order" matRipple routerLink="."
                            [queryParams]="{view:'drone',drone_id:transfer.drone_id}">
                            <mat-icon>settings</mat-icon>
                            <span>Дрон #{{transfer.drone_id}}</span>
                        </div>
                        }
                        @else {
                        <div class="product-extra green">
                            Зарахування
                        </div>
                        }

                        <mat-icon>arrow_right_alt</mat-icon>

                        @if(transfer.dst_warehouse_name) {
                        <div class="product-extra">
                            {{transfer.dst_warehouse_name}}
                        </div>
                        }
                        @else if(transfer.drone_id && transfer.src_warehouse_id) {
                        <div class="product-extra red transfer-order" matRipple routerLink="."
                            [queryParams]="{view:'drone',drone_id:transfer.drone_id}">
                            <mat-icon>settings</mat-icon>
                            <span>Дрон #{{transfer.drone_id}}</span>
                        </div>
                        }
                        @else {
                        <div class="product-extra red">
                            Списання
                        </div>
                        }

                    </div>

                    <div class="product-available transfer-comment" *ngIf="transfer.transfer_comment.length">
                        <mat-icon>comment</mat-icon>
                        <span>{{transfer.transfer_comment.join('; ')}}</span>
                    </div>

                </div>

                <div class="transfer-body">
                    @for (item of transfer.transfers; track $index) {
                    <div class="product-name">
                        <span class="product-quantity">{{ item.transfer_quantity | number : '1.0-2' }}
                            {{item.sku_units}}</span>
                        <span>{{item.model_name}}</span>
                    </div>
                    }
                </div>

            </div>
        </div>

        }

    </div>

    <main>
        <section>
            <mat-expansion-panel *ngFor="let w of warehouses" [expanded]="!!w.warehouse_default">

                <mat-expansion-panel-header>
                    <mat-panel-title>

                        <div class="flex" style="gap:0">
                            <span>{{w.warehouse_name}}</span>
                            <button mat-icon-button color="primary"
                                (click)="upsertWarehouse(w);$event.stopPropagation()">
                                <mat-icon style="transform:translateY(-2px);">edit</mat-icon>
                            </button>
                        </div>

                    </mat-panel-title>
                </mat-expansion-panel-header>

                <app-stock-table [stock]="w.stock" [emptyRows]="empty_rows.value ?? false" [transfers]="transfers"
                    (stockChanged)="updateCart($event)"></app-stock-table>

            </mat-expansion-panel>
        </section>
    </main>

    <div class="products-buttons">

        <button mat-fab extended color="accent" (click)="sidebar.openSidebar()" *ngIf="layout.is_mobile | async">
            <mat-icon>filter_list</mat-icon>
            <span>Кошик / Передачі</span>
        </button>

        <div class="spacer"></div>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="upsertWarehouse(null)">
            <mat-icon>add_home</mat-icon>
            <span *ngIf="layout.is_desktop | async">Створити склад</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="addGoods()">
            <mat-icon>add_shopping_cart</mat-icon>
            <span *ngIf="layout.is_desktop | async">Внести товар</span>
        </button>

    </div>

</app-sidebar>
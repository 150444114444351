import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, EventType, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from './data.service';
import { OnboardingSignupDialogComponent } from './dialogs/onboarding-dialog/onboarding-signup-dialog.component';
import { EditDroneDialogComponent } from './drones/edit-drone-dialog/edit-drone-dialog.component';
import { LayoutService } from './layout.service';
import { ModelDialogComponent } from './model/model.component';
import { OrderComponent } from './order/order.component';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { ReportComponent } from './report/report.component';
import { SellerDialogComponent } from './seller-dialog/seller-dialog.component';
import { MenuService } from './shared/services/menu.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {

    @ViewChild('drawer', { static: true }) drawer!: MatDrawer;

    private view_dialog: MatDialogRef<any> | null = null;
    private view_dialog_sub: Subscription | null = null;

    private initOnboarding() {
        const key = 'onboarding-first-shown';
        if (localStorage.getItem(key)) return;
        localStorage.setItem(key, 'true');
        let navigations_count = 0;
        const sub = this.router.events.pipe(filter(e => e.type === EventType.NavigationEnd)).subscribe(() => {
            navigations_count++;
            if (navigations_count === 3) {
                sub.unsubscribe();
                OnboardingSignupDialogComponent.open(this.dialog);
            }
        });
    }

    constructor(
        public readonly data: DataService,
        private readonly router: Router,
        private readonly menu: MenuService,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) {

        this.initOnboarding();

        const product_path_regex = /\/([\w-]+)\/([\w-]+)\/([\w-]+)\/(\d+)\/(\d+)\/(\d+)/;
        const product_path_array = product_path_regex.exec(location.pathname);

        if (product_path_array) {
            data.getFullProducts().subscribe({
                next: products => {
                    const product = products.find(p => p.item_id === product_path_array[4] && p.sku_id === product_path_array[5]);
                    if (product) {
                        router.navigate(['/'], { queryParams: product.view_query_params });
                    } else {
                        location.assign('/');
                    }
                }
            });
        }

        this.route.queryParams.subscribe({
            next: params => {

                if ((params['view'])) {
                    this.view_dialog_sub?.unsubscribe();
                }

                this.view_dialog?.close();
                this.view_dialog = null;

                switch (params['view']) {

                    case 'product':
                        this.view_dialog = ProductDialogComponent.open(dialog, { product_id: Number(params['product_id']) });
                        break;

                    case 'model':
                        this.view_dialog = ModelDialogComponent.open(dialog, { model_id: Number(params['model_id']), extra: params['extra'] });
                        break;

                    case 'order':
                        this.view_dialog = OrderComponent.open(dialog, { order_id: Number(params['order_id']) });
                        break;

                    case 'report':
                        this.view_dialog = ReportComponent.open(dialog, { request_id: Number(params['request_id']), product_id: Number(params['product_id']) });
                        break;

                    case 'drone':
                        this.view_dialog = EditDroneDialogComponent.open(dialog, { drone_id: Number(params['drone_id']), });
                        break;

                    case 'seller':
                        this.view_dialog = SellerDialogComponent.open(dialog, { seller_id: Number(params['seller_id']), seller_swarm_id: Number(params['seller_swarm_id']), });
                        break;
                }

                this.view_dialog_sub = this.view_dialog?.beforeClosed().subscribe({
                    next: () => this.router.navigate([location.pathname], { queryParams: {} })
                }) ?? null;

            }
        });

        this.updateHeight();

    }

    @HostListener('window:resize', ['$event'])
    private updateHeight() {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    // isDark = signal(this.document.body.classList.contains('dark'));

    // toggleTheme() {
    //   if (this.isDark()) {
    //     this.document.body.classList.remove('dark');
    //     this.isDark.set(false);
    //   } else {
    //     this.document.body.classList.add('dark');
    //     this.isDark.set(true);
    //   }
    // }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        ).subscribe(_ => this.drawer.close());
    }

    get menuItems() {
        return this.menu.menu_items.filter(m => m.visibility());
    }

}

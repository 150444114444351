<mat-toolbar>

    <a routerLink="/" class="swarm-home">
        <img class="swarm-logo" src="/assets/logo.png" />
        <div class="swarm-name">

            <span>{{(layout.is_desktop | async) ? 'ua-drones.de' : title}}</span>

            <a mat-button href="https://t.me/+jPtYcbbg-t1mZmYy" color="primary" target="_blank"
                *ngIf="layout.is_desktop | async">
                <div class="tg-url-wrapper">
                    <span>Канал</span>
                    <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                </div>
            </a>

        </div>
    </a>

    <ng-container *ngIf="layout.is_mobile | async; else topMenu">
        <button mat-button color="primary" class="search-button" (click)="openSearch();searchInput.focus()">
            <mat-icon>search</mat-icon>
        </button>
        <notifications-button></notifications-button>
        <button mat-icon-button (click)="menuClick.emit()">
            <mat-icon>menu</mat-icon>
        </button>
    </ng-container>

    <div class="search-header" [class.shown]="search_shown">

        <input #searchInput class="search-input" placeholder="Шукайте будь-що..." [formControl]="search_control" />

        <button mat-button color="primary" class="search-button" (click)="search_shown=false">
            <mat-icon>close</mat-icon>
        </button>

    </div>

</mat-toolbar>

<ng-template #topMenu>

    <ng-container *ngFor="let m of menu_items">
        <a mat-button [routerLink]="m.url" color="primary" routeActive="toolbar-link-selected">
            {{m.name}}
        </a>
    </ng-container>

    <a mat-button href="https://chromewebstore.google.com/detail/ua-dronesde/pmijhibgbgkjmmccojjkohnhigkamjkk"
        color="primary" target="_blank" *ngIf="data.europe">
        <div class="tg-url-wrapper">
            <span>Розширення</span>
            <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg" />
        </div>
    </a>

    <a mat-button href="https://swarm.army" color="primary" target="_blank" class="mx-1" matTooltip="Для української спільноти (ціни в $, доставка в Україну)">
        <div class="tg-url-wrapper">
            <span>swarm.army</span>
            <img src="https://assets.swarm.army/swarm-black-t.png" />
        </div>
    </a>

    <div class="spacer"></div>

    <button mat-button color="primary" class="search-button" (click)="openSearch();searchInput.focus()">
        <mat-icon>search</mat-icon>
    </button>

    <ng-content></ng-content>

</ng-template>

<div class="search-overlay" [class.shown]="search_shown">

    <div class="search-container" *ngIf="filtered_products.length || search_control.value">

        <mat-icon class="search-placeholder" *ngIf="!filtered_products.length">search_off</mat-icon>

        <product-card [product]="p" *ngFor="let p of filtered_products"></product-card>

    </div>

    <div class="search-container" *ngIf="!filtered_products.length && !search_control.value">

        <mat-icon class="search-placeholder" *ngIf="!kits_public.length">search</mat-icon>

        <h2 *ngIf="kits_public.length">Може бути цікаво...</h2>

        <mat-card class="kit-card" *ngFor="let k of kits_public" routerLink="/products" [queryParams]="{kit:k.kit_slug}"
            (click)="data.click(data.CS.HomePage, data.CT.OpenAliexpress, k);search_control.setValue('');search_shown=false">
            <mat-card-content>

                <div class="product-name">
                    <span>{{ k.kit_name }}</span>
                </div>

                <div class="product-price-wrapper">
                    <span class="product-price">{{ k.kit_price | currency }}</span>
                </div>

                <a mat-button color="primary" class="product-url" routerLink="/products"
                    [queryParams]="{kit:k.kit_slug}">
                    До набору
                </a>

                <img class="kit-logo" *ngIf="k.kit_name.includes('SD')"
                    src="https://static.wixstatic.com/shapes/e9fa2c_c6b15243f678441fbd329962ab915d0b.svg" />

                <img class="kit-logo" *ngIf="k.kit_name.includes('VD')"
                    src="https://images.squarespace-cdn.com/content/v1/639ad8ad2dfb705d7768c20b/199e82c7-bf86-4397-8719-cc50ce4f8412/favicon.ico?format=100w" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('soldering')"
                    src="https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQz6_t_sLDcVpGg7IOlI3JlFHQp5fwMdOxl7sisC_AF1YjSyQpUAqDMrZP97anKCVmBIpPtRyiNxu7E8afUIKEuBCfcfVXltXdD7eDvGDGb2iMAMRWhNZxXcALUi_l-a8UUhmxApw&usqp=CAc" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('tools')"
                    src="https://ae-pic-a1.aliexpress-media.com/kf/S9f0c560b53574cd98912a31578f45772M/4Pcs-1-5-2-2-5-3-0mm-Black-Hex-Drivers-Allen-Wrench-Repair-Tool-Set.jpeg_.webp" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('pilot')"
                    src="https://ae-pic-a1.aliexpress-media.com/kf/S5cdd0122fd5b4032b5bdce755ad084ccd/HappyModel-Mobula8-1-2S-85mm-Micro-FPV-Whoop-Drone-X12-AIO-FC-400mW-OPENVTX-ELRS-FRSLY.jpg_.webp" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('batteries')"
                    src="https://modelistam.com.ua/images/female-amass-xt-60h.jpg" />

            </mat-card-content>
        </mat-card>

    </div>

</div>
import { Component } from '@angular/core';
import { DataService } from '../data.service';


interface PromoCode {
    promo_code: string;
    promo_amount: number;
    promo_discount: number;
}

interface PromoGroup {
    promo_codes: PromoCode[];
}


@Component({
    selector: 'app-promos',
    templateUrl: './promos.component.html',
    styleUrl: './promos.component.scss'
})
export class PromosComponent {

    public promocodes: PromoGroup[] = [
        {
            promo_codes:
                [
                    { promo_code: 'GOODS3', promo_amount: 29, promo_discount: 3 },
                    { promo_code: 'GOODS8', promo_amount: 69, promo_discount: 8 },
                    { promo_code: 'GOODS20', promo_amount: 169, promo_discount: 20 },
                    { promo_code: 'GOODS30', promo_amount: 239, promo_discount: 30 },
                    { promo_code: 'GOODS50', promo_amount: 369, promo_discount: 50 },
                ],
        },
        {
            promo_codes:
                [
                    { promo_code: 'ODF3', promo_amount: 29, promo_discount: 3 },
                    { promo_code: 'ODF8', promo_amount: 69, promo_discount: 8 },
                    { promo_code: 'ODF20', promo_amount: 169, promo_discount: 20 },
                    { promo_code: 'ODF30', promo_amount: 239, promo_discount: 30 },
                    { promo_code: 'ODF50', promo_amount: 369, promo_discount: 50 },
                ],
        },
        {
            promo_codes:
                [
                    { promo_code: 'ODF3', promo_amount: 29, promo_discount: 3 },
                    { promo_code: 'ODF8', promo_amount: 69, promo_discount: 8 },
                    { promo_code: 'ODF20', promo_amount: 169, promo_discount: 20 },
                    { promo_code: 'ODF30', promo_amount: 239, promo_discount: 30 },
                    { promo_code: 'ODF50', promo_amount: 369, promo_discount: 50 },
                ],
        },
        {
            promo_codes:
                [
                    { promo_code: 'OUTDOOR3', promo_amount: 29, promo_discount: 3 },
                    { promo_code: 'OUTDOOR8', promo_amount: 69, promo_discount: 8 },
                    { promo_code: 'OUTDOOR20', promo_amount: 169, promo_discount: 20 },
                    { promo_code: 'OUTDOOR30', promo_amount: 239, promo_discount: 30 },
                    { promo_code: 'OUTDOOR50', promo_amount: 369, promo_discount: 50 },
                ],
        },
        {
            promo_codes:
                [
                    { promo_code: 'ARMY3', promo_amount: 29, promo_discount: 3 },
                    { promo_code: 'ARMY8', promo_amount: 69, promo_discount: 8 },
                    { promo_code: 'ARMY20', promo_amount: 169, promo_discount: 20 },
                    { promo_code: 'ARMY30', promo_amount: 239, promo_discount: 30 },
                    { promo_code: 'ARMY50', promo_amount: 369, promo_discount: 50 },
                ],
        },
    ];

    public coupons: PromoGroup[] = [
    ];


    constructor(public readonly data: DataService) { }

    onCode(code: string) {
        navigator.clipboard.writeText(code).then(() => {
            console.log('Content copied to clipboard');
        }, () => {
            console.error('Failed to copy');
        });
    }
}
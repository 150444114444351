<ng-container *ngIf="item">

    <mat-card [routerLink]="[]" [queryParams]="item.seller_view_query_params"
        (click)="data.click(data.current_click_source, data.CT.OpenSellerDialog, item)">
        <div mat-card-image class="product-image-wrapper">

            <img class="product-image" [src]="item.seller_image_url" />

            <div class="product-extras">

                <!--                <div class="product-extra">-->
                <!--                    {{ item.seller_name }}-->
                <!--                </div>-->

            </div>

            <div class="product-card-actions-container">
                <button mat-mini-fab class="product-card-action" (click)="onEdit($event)"
                    *ngIf="item.seller_source === 'custom' && data.superadmin||data.sdua">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>

            <mat-card-content>

                <div class="product-name">
                    <span>{{ item.seller_name }}</span>
                </div>

            </mat-card-content>

        </div>

    </mat-card>

</ng-container>
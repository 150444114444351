<mat-accordion multi>
    <mat-expansion-panel class="mat-elevation-z0 m-0" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Промокоди
            </mat-panel-title>
        </mat-expansion-panel-header>


        <div>
            Наступні промокоди потрібно забронювати, як тільки вони починають діяти.
            В такому випадку більше шансів використати промокод, якщо купівлю робити не в перші хвилини.
            Для бронбвання потрібно додати будь-які товари в корзину на відповідну суму,
            дійти до оплати, та почергово ввести всі промокоди.
            Промокоди поповнюються щоранку о 10 годині за Києвом. Якщо не вийшло застосувати промокод,
            можна спробувати наступного ранку, поширена практика, що вони стають знову доступні.
        </div>

        <div *ngFor="let g of promocodes" class="mt-3">
            <div *ngFor="let c of g.promo_codes" class="mt-1">
                <mat-chip (click)="onCode(c.promo_code)">{{c.promo_code}}</mat-chip>
                <span> знижка </span>
                <span>{{c.promo_discount | currency: 'USD': 'symbol': '1.0-0'}}</span>
                <span> з </span>
                <span>{{c.promo_amount | currency: 'USD': 'symbol': '1.0-0'}}</span>
            </div>
        </div>

    </mat-expansion-panel>

</mat-accordion>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, OrderFull, TrackingData } from '../../data.service';
import { timestampToLocalDate, timestampToLocalShortTime } from '../../utils';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ReCaptchaV3Service } from 'ng-recaptcha';

export interface TrackDialogData {
    tracking_number: string;
    order_id?: number;
}

@Component({
    selector: 'app-track-dialog',
    templateUrl: './track-dialog.component.html',
    styleUrl: './track-dialog.component.scss',
})
export class TrackDialogComponent {

    public static open(dialog: MatDialog, data: TrackDialogData) {
        return dialog.open(TrackDialogComponent, { data, width: '720px', autoFocus: false, panelClass: 'mobile-fullscreen' })
    }

    public loading = false;

    public orders: OrderFull[] = [];
    public tdata: TrackingData | null = null;

    public timestampToLocalDate = timestampToLocalDate;
    public timestampToLocalShortTime = timestampToLocalShortTime;
    public getTrackingCompany = this.data.getTrackingCompany;

    constructor(
        public readonly data: DataService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        public dialogRef: MatDialogRef<TrackDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog_data: TrackDialogData,
        private readonly recaptcha3: ReCaptchaV3Service,
    ) {

        this.tdata = null;
        this.loading = true;

        const $orders = this.data.admin
            ? this.data.getAllOrders(false)
            : this.data.getMyOrders(false);

        this.recaptcha3.execute('track').subscribe(token_v3 => {
            this.data.getTracking({ ...this.dialog_data, tracking_visible: false, force: false, token_v3 }).subscribe({
                next: res => {
                    this.tdata = res.tracking?.tracking_data || null;
                    $orders.subscribe({
                        next: orders => {
                            this.orders = orders.filter(o => o.order_tracking_number_array.some(t => t.tracking_number === this.dialog_data.tracking_number || t.tracking_number_alt === this.dialog_data.tracking_number));
                        }
                    });
                    this.loading = false;
                }
            });
        });

    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent, StatsDialogData } from '../product-dialog/product-dialog.component';
import { Observable } from 'rxjs';
import { ProductFull } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private dialog: MatDialog) { }

  showStats(product: ProductFull): Observable<unknown> {
    const data: StatsDialogData = {
      itemId: product.item_id,
      skuId: product.sku_id,
      skuPropIds: product.sku_prop_ids
    } as any;

    const ref = this.dialog.open(ProductDialogComponent, { data, autoFocus: false, });

    return ref.afterClosed()
  }
}

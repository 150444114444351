import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Notification, RuleType } from '../../../api.models';
import { ApiService } from '../../../api.service';
import { DataService, ProductFull } from '../../../data.service';
import { RulesDialogComponent } from '../../../dialogs/rules-dialog/rules-dialog.component';


interface NotificationVm extends Notification {
    product: ProductFull | null;
}

@Component({
    selector: 'notifications-button',
    templateUrl: './notifications-button.component.html',
    styleUrl: './notifications-button.component.scss'
})
export class NotificationsButtonComponent {

    public loading = false;
    public notifications: Notification[] = [];
    public filtered_notifications: NotificationVm[] = [];
    public control_notifications = new FormControl<'new' | 'read'>('new');

    public readonly RuleType = RuleType;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
        private readonly api: ApiService,
    ) {
        this.control_notifications.valueChanges.subscribe(() => {
            if (!this.loading)
                this.filterNotifications();
        });
    }

    private filterNotifications() {
        this.data.getFullProducts().subscribe({
            next: products => {
                this.loading = false;
                this.filtered_notifications = (this.control_notifications.value === 'read'
                    ? this.notifications.filter(n => n.notification_read)
                    : this.notifications.filter(n => !n.notification_read))
                    .map(n => ({ ...n, product: products.find(p => p.product_id === n.product_id) || null }))
                    .sort((a, b) => b.notification_id - a.notification_id);
            }
        })
    }

    public openRules() {
        if (!this.data.profile) return;
        RulesDialogComponent.open(this.dialog);
    }

    public menuOpened() {
        this.loading = true;
        if (this.data.profile?.user_notifications_new) {
            this.control_notifications.setValue('new');
        } else {
            this.control_notifications.setValue('read');
        }
        this.api.notificationsList().subscribe({
            next: notifications => {
                this.notifications = notifications;
                this.filterNotifications();
            },
        });
    }

    public menuClosed() {
        if (this.control_notifications.value !== 'new'
            || !this.filtered_notifications.length) return;
        this.api.notificationsRead().subscribe({
            next: () => {
                this.data.reloadProfile();
            },
        })
    }

}

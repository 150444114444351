import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutsComponent } from './payouts.component';
import { MaterialModule } from '../material/material.module';
import { PayoutComponent } from '../payout/payout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayoutsTableComponent } from './payouts-table/payouts-table.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    PayoutsComponent,
    PayoutComponent,
    PayoutsTableComponent,
    UsersTableComponent,
    CommentDialogComponent,
    UserDialogComponent,
  ],
  exports: [
    PayoutsComponent,
    PayoutComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ]
})
export class PayoutsModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService, OrderFull, OrderStatus, Warehouse } from '../../data.service';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface OrderUpdateStatusDialogData {
    status: OrderStatus;
    orders: OrderFull[];
}

@Component({
    selector: 'app-order-update-status-dialog',
    templateUrl: './order-update-status-dialog.component.html',
    styleUrl: './order-update-status-dialog.component.scss'
})
export class OrderUpdateStatusDialogComponent implements OnInit {

    public static open(dialog: MatDialog, data: OrderUpdateStatusDialogData): Promise<string> {
        const ref = dialog.open(OrderUpdateStatusDialogComponent, { data, minWidth: '300px', maxWidth: '400px', autoFocus: false, });

        return firstValueFrom(ref.afterClosed());
    }

    public loading = false;
    public warehouses: Warehouse[] = [];

    public title: string | undefined;
    public actionText: string | undefined;

    public statusForm = new FormGroup({
        event_comment: new FormControl('', Validators.required),
        warehouse_id: new FormControl<number | null>(null, Validators.required),
    });

    constructor(
        private snackbar: MatSnackBar,
        public data: DataService,
        public dialogRef: MatDialogRef<OrderUpdateStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog_data: OrderUpdateStatusDialogData,
    ) {
    }

    ngOnInit(): void {

        this.updateValidators();
        this.updateTitle();

        this.data.getWarehouses().subscribe({
            next: warehouses => {
                this.warehouses = warehouses.filter(w => !w.warehouse_deleted);
                const def = warehouses.find(w => w.warehouse_default);
                if (def) {
                    this.statusForm.controls.warehouse_id.setValue(def.warehouse_id);
                }
            },
        });
    }

    public updateValidators() {
        switch (this.dialog_data.status) {
            case 'cancelled': {
                this.statusForm.controls.event_comment.setValue('');
                this.statusForm.controls.event_comment.setValidators(Validators.required);
                this.statusForm.controls.warehouse_id.clearValidators();
                break;
            }
            case 'picked': {
                this.statusForm.controls.event_comment.setValue('');
                this.statusForm.controls.event_comment.clearValidators();
                this.statusForm.controls.warehouse_id.clearValidators();
                break;
            }
            case 'received': {
                this.statusForm.controls.event_comment.setValue('');
                this.statusForm.controls.event_comment.clearValidators();
                this.statusForm.controls.warehouse_id.setValidators(Validators.required);
                break;
            }
        }
    }

    public updateTitle() {
        switch (this.dialog_data.status) {
            case 'cancelled': {
                this.title = 'Ви збираєтесь позначити замовлення як відмінене. Продовжити?';
                this.actionText = 'Відмінити замовлення';
                break;
            }
            case 'picked': {
                this.title = 'Ви збираєтесь зазначити отримання замовлення. Продовжити?';
                this.actionText = 'Зазначити отримання';
                break;
            }
            case 'received': {
                this.title = 'Ви збираєтесь зазначити передачу замовлення на склад. Продовжити?';
                this.actionText = 'Зазначити передачу';
                break;
            }
        }
    }

    public async updateStatus() {

        const status = this.dialog_data.status;

        if (!status || !this.dialog_data.orders?.length || !this.statusForm.valid) {
            this.statusForm.markAllAsTouched();
            return;
        }

        this.loading = true;

        try {

            for (const order of this.dialog_data.orders) {
                await new Promise((res, rej) => this.data.updateOrderStatus({
                    order_id: order.order_id,
                    order_status: status,
                    event_comment: this.statusForm.value.event_comment || '',
                    warehouse_id: Number(this.statusForm.value.warehouse_id || 0),
                }).subscribe({
                    next: updated_order => {
                        order.order_status = updated_order.order_status;
                        this.data.processOrder(order);
                        res(order);
                    }, error: rej,
                }));
            }

            this.dialogRef.close(status);

            if (this.dialog_data.orders.length > 1) {
                this.snackbar.open('Вибрані замовлення оновлені', 'OK');
            } else {
                this.snackbar.open('Замовлення оновлене', 'OK');
            }

        } catch (e) {
            console.log(e);
            this.snackbar.open('Сталася помилка!', 'OK');
        }

        this.loading = false;

    }

    get canSave(): boolean {
        return this.statusForm.valid && !this.loading;
    }

}

<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До моделей' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_models.length}}</span>
                    <small>/{{models.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div class="products-scroll">
        <div class="flex-view">

            <ng-container *ngIf="!models.length">
                <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
            </ng-container>

            <ng-container *ngIf="models.length">
                <model-card *ngFor="let m of filtered_models" [item]="m"></model-card>
            </ng-container>

        </div>
    </div>

    <div class="products-empty-message" *ngIf="models.length && !filtered_models.length">
        Жодна модель не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-buttons">

        <button mat-fab extended color="accent" *ngIf="models.length && (layout.is_mobile | async)"
            (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_models.length}}</b><small>/{{models.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <app-promo-button></app-promo-button>

        <button mat-fab *ngIf="data.superadmin||data.sdua" (click)="addNew()">
            <mat-icon>add</mat-icon>
        </button>

        <!-- <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="toggleViewMode()">
            <mat-icon>{{next_view_mode.icon}}</mat-icon>
            <span *ngIf="layout.is_desktop | async">{{next_view_mode.name}}</span>
        </button> -->

    </div>

</app-sidebar>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { PromosModule } from '../promos/promos.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { TenantsDialogComponent } from './tenants-dialog/tenants-dialog.component';
import { AllUsersDialogComponent } from './users-dialog/users-dialog.component';



@NgModule({
    declarations: [
        DashboardComponent,
        TenantsDialogComponent,
        AllUsersDialogComponent,
    ],
    exports: [
        DashboardComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        PromosModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
    ]
})
export class DashboardModule { }

import { Component, Inject } from '@angular/core';
import { DataService, Membership } from '../../data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-onboarding-signup-dialog',
    templateUrl: './onboarding-signup-dialog.component.html',
    styleUrl: './onboarding-signup-dialog.component.scss'
})
export class OnboardingSignupDialogComponent {

    public static open(dialog: MatDialog) {
        dialog.open(OnboardingSignupDialogComponent, {
            width: '680px', autoFocus: false, restoreFocus: false,
            panelClass: 'mobile-fullscreen', disableClose: true,
        });
    }

    public gallery_shown = false;

    constructor(
        public readonly data: DataService,
        public readonly ref: MatDialogRef<OnboardingSignupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly membership: Membership | null,
    ) {
        if (data.tenant_id === 1 || !data.europe) ref.close();
    }
}

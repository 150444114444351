import { Component, Inject } from '@angular/core';
import { DataService } from './data.service';
import { LayoutService } from './layout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        // @Inject(DOCUMENT) private document: Document
    ) {

        layout.is_mobile.subscribe(is_mobile => is_mobile
            ? document.body.classList.add('is-mobile')
            : document.body.classList.remove('is-mobile'));
    }

}

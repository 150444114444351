import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataService, Filter, OrderRequest, OrderRequestFull, ProductFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { ReportComponent } from '../report/report.component';
import { Subscription } from 'rxjs';
import { RequestAlertDialogComponent } from './request-alert/request-alert.component';
import { STORAGE_KEY_REQUEST_ALERT_DONT_SHOW } from '../consts';

const FILTERS_KEY = 'request';

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrl: './request.component.scss'
})
export class RequestComponent implements OnInit, OnDestroy {

    public readonly top_price_filter: Filter = {
        key: 'price',
        name: 'Ціна',
        expanded: true,
        update: () => this.updateSellers(),
        dict: { 'all': false, 'top': true, },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'top', name: 'TOP-ціна', image: '', tooltip: 'Товари за дійсно вигідною ціною, що вручну підібрана адміністратором ресурсу' },
        ],
    };

    public readonly promos_filter: Filter = {
        key: 'promo',
        name: 'Акції',
        expanded: true,
        update: () => this.updateSellers(),
        dict: { 'all': true, 'choice': false, 'superdeals': false, 'coins': false, },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'choice', name: '', image: 'https://ae01.alicdn.com/kf/S1887a285b60743859ac7bdbfca5e0896Z/154x64.png' },
            { id: 'superdeals', name: '', image: 'https://ae01.alicdn.com/kf/Saa94d6f7f18e4bf5ad94bb3ddb0a7870A/228x64.png' },
            { id: 'limiteddeal', name: 'LimitedDeal', image: '' },
            { id: 'coins', name: 'Монети', image: '' },
            { id: 'future', name: 'Заплановані', image: '' },
        ],
    };

    public readonly availability_filter: Filter = {
        key: 'availability',
        name: 'Наявність',
        expanded: false,
        update: () => this.updateSellers(),
        dict: { 0: false, 10: true, 50: true, 100: true, 101: true },
        options: [
            { id: 0, name: 'Відсутні', image: '' },
            { id: 10, name: '1 - 10', image: '' },
            { id: 50, name: '11 - 50', image: '' },
            { id: 100, name: '51 - 100', image: '' },
            { id: 101, name: '101+', image: '' },
        ],
    };

    public readonly status_filter: Filter = {
        key: 'status',
        name: 'Статус',
        dict: { 'all': false, verified: true, suggestion: false, caution: false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'verified', name: 'Верифіковані', image: '' },
            { id: 'caution', name: 'Підозрілі', image: '' },
            { id: 'suggestion', name: 'Пропозиції спільноти', image: '' },
        ],
        expanded: false,
        update: () => this.updateSellers(),
    };

    public readonly sellers_filter: Filter = { key: 'seller', name: 'Продавці', dict: { 'all': true, }, options: [{ id: 'all', name: 'Всі', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };

    public filters: Filter[] = [
        this.top_price_filter,
        this.promos_filter,
        this.sellers_filter,
        this.status_filter,
        this.availability_filter,
    ];

    public products: ProductFull[] = [];
    public filtered_products: ProductFull[] = [];
    public request: OrderRequestFull | null = null;

    public subs: Subscription[] = [];

    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,) {
    }

    private updateFilters() {
        this.data.setFilters(FILTERS_KEY, this.filters);
        this.filtered_products = this.products.filter(p => this.productVisible(p));
    }

    public updateSellers() {

        const now = Date.now() / 1000;
        const all_promos = this.promos_filter.dict['all'];
        const promos_dict = this.promos_filter.dict;

        const all_old_options_selected = this.sellers_filter.options.length && this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);

        this.sellers_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.products.map(p => ({ id: p.seller_id, name: p.seller_name_short, image: p.seller_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.products.some(p => p.seller_id === v.id
                        && (all_promos || p.item_is_choice && promos_dict['choice'] || p.sku_full_sd_price && promos_dict['superdeals'] || p.sku_full_lo_price && promos_dict['limiteddeal'] || p.item_coins_discount && promos_dict['coins'] || p.sku_future_sale_date && p.sku_future_sale_date > now && promos_dict['future'])
                        && (this.top_price_filter.dict['all'] || this.top_price_filter.dict['top'] && p.model_top_price_usd && (p.sku_last_price && p.sku_last_price < p.model_top_price_usd || p.sku_last_sd_price && p.sku_last_sd_price < p.model_top_price_usd))))
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);
        const no_options_selected = this.sellers_filter.options.every(o => !this.sellers_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.sellers_filter.dict).forEach(k => this.sellers_filter.dict[k] = false);
            this.sellers_filter.dict['all'] = true;
        }

        this.updateFilters();

    }


    public productVisible(p: ProductFull) {

        const avail_count = p.sku_last_available;
        const avail_dict = this.availability_filter.dict;
        const available = avail_dict[0] && avail_count === 0
            || avail_dict[10] && avail_count >= 1 && avail_count <= 10
            || avail_dict[50] && avail_count >= 11 && avail_count <= 50
            || avail_dict[100] && avail_count >= 51 && avail_count <= 100
            || avail_dict[101] && avail_count >= 101;

        const now = Date.now() / 1000;

        const promos_dict = this.promos_filter.dict;
        const promos = promos_dict['all']
            || p.item_is_choice && promos_dict['choice']
            || p.sku_full_sd_price && promos_dict['superdeals']
            || p.sku_full_lo_price && promos_dict['limiteddeal']
            || p.item_coins_discount && promos_dict['coins']
            || p.sku_future_sale_price && (!p.sku_future_sale_date || p.sku_future_sale_date && p.sku_future_sale_date > now) && promos_dict['future'];

        const top_price_dict = this.top_price_filter.dict;
        const top_price = top_price_dict['all'] || top_price_dict['top'] && p.model_top_price_usd && p.sku_min_unit_price <= p.model_top_price_usd;

        const status_dict = this.status_filter.dict;
        const status = status_dict['all'] || status_dict[p.product_status];

        return status && top_price && promos && available
            && (this.sellers_filter.dict['all'] || this.sellers_filter.dict[p.seller_id]);
    }

    private updatePriceFilter() {
        if (this.top_price_filter.dict['top'] && !this.filtered_products.length) {
            this.top_price_filter.dict['all'] = true;
            this.top_price_filter.dict['top'] = false;
            this.updateFilters();
        } else if (this.top_price_filter.dict['all']
            && this.filtered_products.some(p => p.sku_last_available && p.sku_min_unit_price <= p.model_top_price_usd)) {
            this.top_price_filter.dict['all'] = false;
            this.top_price_filter.dict['top'] = true;
            this.updateFilters();
        }
    }

    public ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

    public ngOnInit() {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.route.paramMap.forEach(params => {

            const model_name = params.get('model_name');

            this.subs.push(this.data.getRequests().subscribe(requests => {

                this.request = requests.find(r => this.data.makeUrl(r.model_name) === model_name) || null;

                if (this.request) {

                    const request = this.request;

                    if (localStorage.getItem(STORAGE_KEY_REQUEST_ALERT_DONT_SHOW) !== 'true') {
                        RequestAlertDialogComponent.open(this.dialog, request);
                    }

                    this.subs.push(this.data.getFullProducts().subscribe(response => {

                        this.products = response
                            .filter(p => this.data.makeUrl(p.model_name) === model_name && !p.product_deleted
                                && (request.request_rule === 'all' || request.request_rule === 'only' && request.request_products_ids.includes(p.product_id) || request.request_rule === 'except' && !request.request_products_ids.includes(p.product_id)))
                            .sort((a, b) => a.detail_id === b.detail_id ? a.sku_full_unit_price - b.sku_full_unit_price : a.detail_id - b.detail_id);

                        this.updateSellers();

                        this.data.restoreFilter(FILTERS_KEY, this.filters);
                        this.updateFilters();

                        this.updatePriceFilter();

                    }));
                }

            }));

        })

    }
}

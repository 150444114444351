import { Component, ViewChild } from '@angular/core';
import { DataService, Filter, ModelFull } from '../data.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../layout.service';

import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid,
    NgApexchartsModule,
    ApexYAxis,
    ApexFill
} from "ng-apexcharts";
import { MOTORS, Motor } from '../motors';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    fill: ApexFill;
};

const FILTERS_KEY = 'motors';

interface MotorVm {
    model: ModelFull;
    motor: Motor;
}


@Component({
    selector: 'app-motors',
    templateUrl: './motors.component.html',
    styleUrl: './motors.component.scss'
})
export class MotorsComponent {

    public readonly tags_filter: Filter = {
        key: 'tag',
        name: 'Теги',
        expanded: true,
        update: () => this.updateModels(),
        dict: { 'all': true, '7"': false, '8"': false, '10"': false, 'SD 7"': false, 'SD 8"': false, 'SD 10"': false, 'VD 7"': false, 'VD 8"': false, 'VD 10"': false, 'PILOTIX 10"': false, 'SOLDERING': false, '3D': false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'SD 7"', name: 'SD 7"', image: '/assets/logo/sdua-logo.png' },
            { id: 'SD 8"', name: 'SD 8"', image: '/assets/logo/sdua-logo.png' },
            { id: 'SD 10"', name: 'SD 10"', image: '/assets/logo/sdua-logo.png' },
            { id: 'VD 7"', name: 'VD 7"', image: '/assets/logo/vd-logo.jpg' },
            { id: 'VD 8"', name: 'VD 8"', image: '/assets/logo/vd-logo.jpg' },
            { id: 'VD 10"', name: 'VD 10"', image: '/assets/logo/vd-logo.jpg' },
            { id: 'PILOTIX 10"', name: 'PILOTIX 10"', image: '/assets/logo/pilotix-logo.jpg' },
        ],
    };

    public readonly models_filter: Filter = { key: 'model', name: 'Моделі', dict: { all: true }, expanded: true, options: [{ id: 'all', name: 'Всі', image: '' },], update: () => this.updateFilters() };

    public filters: Filter[] = [
        this.tags_filter,
        this.models_filter,
    ];

    public models: ModelFull[] = [];
    public motors: MotorVm[] = [];
    public filtered_motors: MotorVm[] = [];

    constructor(public readonly data: DataService, private readonly dialog: MatDialog, public readonly layout: LayoutService) {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.data.getModels().subscribe(models => {

            this.models = models;
            this.motors = MOTORS.map(m => ({ motor: m, model: models.find(mo => mo.model_id === m.model_id)! })).filter(m => m.model);

            this.updateModels();
            this.data.restoreFilter(FILTERS_KEY, this.filters);
            this.updateModels();

        });

    }

    public updateModels() {

        const all_tags = this.tags_filter.dict['all'];
        const tags = Object.keys(this.tags_filter.dict).filter(k => k && this.tags_filter.dict[k]);

        this.models_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.models.map(m => ({ id: m.model_id, name: m.model_name, image: m.model_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.motors.some(m => m.model.model_id === v.id && (all_tags || tags.some(t => m.model.model_tags.includes(t)))))
        ];

        const all_options_selected = this.models_filter.options.every(o => this.models_filter.dict[o.id]);
        const no_options_selected = this.models_filter.options.every(o => !this.models_filter.dict[o.id]);

        if (this.models_filter.dict['all'] && all_options_selected || no_options_selected) {
            Object.keys(this.models_filter.dict).forEach(k => this.models_filter.dict[k] = false);
            this.models_filter.dict['all'] = true;
        }

        this.updateFilters();

    }

    private updateFilters() {
        this.data.setFilters(FILTERS_KEY, this.filters);
        this.filtered_motors = this.motors.filter(m => this.motorVisible(m));
        this.redraw();
    }

    private motorVisible(m: MotorVm) {

        const tags_dict = this.tags_filter.dict;
        const tags = tags_dict['all'] ? true : m.model.model_tags && m.model.model_tags.split(';').some(t => tags_dict[t]);

        return tags && (this.models_filter.dict['all'] || this.models_filter.dict[m.model.model_id]);
    }

    @ViewChild("chart") chart?: ChartComponent;
    public chartOptions?: ChartOptions;

    public lines: {
        motor: Motor;
        points: { x: any, y: any }[];
    }[] = [];

    public redraw() {

        const colors = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];

        this.chartOptions = {

            fill: {
                colors: colors,
            },

            series: this.filtered_motors.map((m, i) => ({
                color: colors[i],
                name: m.motor.brand + ' ' + m.motor.model,
                data: this.graph === 'fi'
                    ? m.motor.stats.map(s => ({ x: s.i, y: s.f }))
                    : m.motor.stats.map(s => ({ x: s.t, y: this.graph === 'q' ? (s.p ? s.f / s.p : 0) : s[this.graph] })),
            })),

            chart: {
                toolbar: {
                    show: false,
                },
                animations: {
                    enabled: false,
                },
                height: 400,
                type: "line",
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "straight",
                colors: colors,
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5
                }
            },
            xaxis: this.graph === 'fi'
                ? {
                    type: 'numeric',
                    title: {
                        text: 'Струм, А'
                    }
                }
                : {
                    type: 'numeric',
                    min: 1000,
                    max: 2000,
                    title: {
                        text: 'Ширина пульсу PWM, мкс'
                    }
                },
            yaxis: {
                decimalsInFloat: 1,
                title: {
                    text: ({
                        f: 'Тяга, г',
                        i: 'Струм, А',
                        p: 'Потужність, Вт',
                        q: 'Ефективність, г/Вт',
                        fi: 'Тяга, г',
                    }[this.graph] || '')
                }
            },
        };

        this.lines = this.filtered_motors.map(m => ({
            motor: m.motor,
            points: m.motor.stats.map(s => ({ x: s.t, y: s.f })),
        }));

    }

    public graph: 'f' | 'i' | 'p' | 'q' | 'fi' = 'f';

    public apply(graph: 'f' | 'i' | 'p' | 'q' | 'fi') {
        this.graph = graph;
        this.redraw();
    }

    getViewParams(p: Record<string, any>) {
        return { ...p, extra: 'motor-tests', };
    }

}

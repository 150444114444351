import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { debounceTime, zip } from 'rxjs';
import { DataService, KitFull, ProductFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { MenuService } from '../shared/services/menu.service';
import { getKitProductsAndPrice } from '../utils';


@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {

    public title = '';

    public search_shown = false;
    public search_control = new FormControl('');
    public kits_public: KitFull[] = [];
    public filtered_products: ProductFull[] = [];

    @Output()
    public menuClick = new EventEmitter();

    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        private readonly menu: MenuService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {

        this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                this.title = this.getLastRouteTitle(route);// (data.state.root.firstChild?.routeConfig?.title as string)?.split(' | ')?.[0] || '';
            }
        });

        this.search_control.valueChanges.pipe(debounceTime(300)).subscribe(() => this.search());

    }

    private getLastRouteTitle(route: ActivatedRoute): string {
        let currentRoute = route;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        const symbol = Object.getOwnPropertySymbols(currentRoute.snapshot.data)?.[0];
        return currentRoute.snapshot.data[symbol]?.split(' | ')?.[0] || '';
    }

    get menu_items() {
        return this.menu.menu_items.filter(m => m.visibility());
    }

    @HostListener('keydown.escape')
    public onEscape() {
        this.search_shown = false;
    }

    public openSearch() {
        this.search_shown = true;
        zip(this.data.getKits(), this.data.getFullProducts()).subscribe({
            next: ([kits, products]) => {
                this.kits_public = kits.filter(k => !k.tenant_id);
                this.kits_public.forEach(k => k.kit_price = getKitProductsAndPrice(k, products, false, false).price);
            },
        });
    }

    private search() {

        const text = this.search_control.value?.trim().toLowerCase() || '';
        console.log('search', text);

        if (!text) {
            this.filtered_products = [];
            return;
        }

        this.data.getFullProducts().subscribe({
            next: products => {
                this.filtered_products = products
                    .filter(p => p.model_name_search.includes(text) || p.detail_name_search.includes(text) || p.detail_name_local_search.includes(text))
                    .sort((a, b) => a.sku_last_available && !b.sku_last_available ? -1 : !a.sku_last_available && b.sku_last_available ? 1 : a.sku_full_unit_price_usd - b.sku_full_unit_price_usd)
                    .filter((v, i, s) => s.findIndex(p => p.model_id === v.model_id) === i)
                    .slice(0, 200)
                    .sort((a, b) => b.sku_last_available - a.sku_last_available)
                    .sort((a, b) => b.model_clicks_monthly - a.model_clicks_monthly);
                console.log('search', this.filtered_products);
            },
        });

    }

}

import { FormControl } from "@angular/forms";
import { Model, Stock, Warehouse } from "../data.service";

export type ModelStock = Stock & Model & { stock_price_usd: number; stock_price_uah: number; };

export interface DetailVm {
    detail_name: string;
    detail_name_local: string;
    stock: (Warehouse & { stock: ModelStock[] })[];
    models: Model[];
    model_id: FormControl<number | null>;
    warehouse_id: FormControl<number | null>;
    model_name: string;
    model_image_url: string;
    warehouse_name: string;
    detail_price_usd: number;
    detail_price_uah: number;
}

export function getAnalogDroneDetailsList() {
    return [
        {
            detail_name: 'Frame',
            detail_name_local: 'Рама',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Motors',
            detail_name_local: 'Мотори',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Props',
            detail_name_local: 'Пропелери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Stack',
            detail_name_local: 'Стек',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera',
            detail_name_local: 'Камера',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'RX',
            detail_name_local: 'RX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX',
            detail_name_local: 'VTX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Capacitor',
            detail_name_local: 'Конденсатор',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: '3D Print',
            detail_name_local: '3D друк',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Battery',
            detail_name_local: 'Батарея',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
    ] as DetailVm[];
}

export function getDigitalDroneDetailsList() {
    return [
        {
            detail_name: 'Frame',
            detail_name_local: 'Рама',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Motors',
            detail_name_local: 'Мотори',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Props',
            detail_name_local: 'Пропелери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Stack',
            detail_name_local: 'Стек',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Digital Video Kit',
            detail_name_local: 'Набір цифрового відео',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'RX',
            detail_name_local: 'RX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Capacitor',
            detail_name_local: 'Конденсатор',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: '3D Print',
            detail_name_local: '3D друк',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Battery',
            detail_name_local: 'Батарея',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
    ] as DetailVm[];
}

export function getAnalogBomberDroneDetailsList() {
    return [
        {
            detail_name: 'Frame',
            detail_name_local: 'Рама',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Motors',
            detail_name_local: 'Мотори',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Props',
            detail_name_local: 'Пропелери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Stack',
            detail_name_local: 'Стек',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera',
            detail_name_local: 'Камера',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'RX',
            detail_name_local: 'RX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX',
            detail_name_local: 'VTX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Downthrow System',
            detail_name_local: 'Система скиду',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera Rotator',
            detail_name_local: 'Поворотний механізм камери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera',
            detail_name_local: 'Камера #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera Switcher',
            detail_name_local: 'Перемикач камери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'BEC',
            detail_name_local: 'Перетворювач напруги живлення',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Capacitor',
            detail_name_local: 'Конденсатор',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: '3D Print',
            detail_name_local: '3D друк',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Battery',
            detail_name_local: 'Батарея',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
    ] as DetailVm[];
}

export function getDigitalBomberDroneDetailsList() {
    return [
        {
            detail_name: 'Frame',
            detail_name_local: 'Рама',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Motors',
            detail_name_local: 'Мотори',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Props',
            detail_name_local: 'Пропелери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Stack',
            detail_name_local: 'Стек',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Digital Video Kit',
            detail_name_local: 'Набір цифрового відео',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX #1',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'VTX Antenna',
            detail_name_local: 'Антена VTX #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'RX',
            detail_name_local: 'RX',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'ELRS Antenna',
            detail_name_local: 'Антена ELRS #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Downthrow System',
            detail_name_local: 'Система скиду',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera Rotator',
            detail_name_local: 'Поворотний механізм камери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera',
            detail_name_local: 'Камера #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Camera Switcher',
            detail_name_local: 'Перемикач камери',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'BEC',
            detail_name_local: 'Перетворювач напруги живлення',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Capacitor',
            detail_name_local: 'Конденсатор',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: '3D Print',
            detail_name_local: '3D друк',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Battery',
            detail_name_local: 'Батарея',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл #1',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
        {
            detail_name: 'Pigtail',
            detail_name_local: 'Пігтейл #2',
            stock: [],
            models: [],
            model_id: new FormControl(),
            warehouse_id: new FormControl(),
            model_name: '',
            model_image_url: '',
            warehouse_name: '',
            detail_price_usd: 0,
            detail_price_uah: 0,
        },
    ] as DetailVm[];
}

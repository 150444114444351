import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardResponse, DataService, ProductFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { TenantsDialogComponent } from './tenants-dialog/tenants-dialog.component';
import { AllUsersDialogComponent } from './users-dialog/users-dialog.component';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnDestroy {

    constructor(
        public readonly data: DataService,
        public readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) {
        this.updateDash();
    }

    public ngOnDestroy() {
        clearTimeout(this.timeout);
    }

    public dash: DashboardResponse | null = null;

    public now = 0;
    public timeout = 0;

    public products_queue: ProductFull[] = [];

    private updateDash() {
        this.data.getDashboard().subscribe({
            next: response => {
                this.now = Math.round(Date.now() / 1000);
                this.dash = response;
                this.timeout = setTimeout(() => this.updateDash(), 10_000) as any as number;
                this.data.getFullProducts().subscribe({
                    next: products => {
                        this.products_queue = response.products_queue
                            .map(q => products.find(p => p.product_id === q.product_id)!).filter(p => p);
                    },
                    error: () => {
                        this.timeout = setTimeout(() => this.updateDash(), 10_000) as any as number;
                    }
                });
            }
        });
    }

    public openTenants() {
        if (!this.data.superadmin) return;
        TenantsDialogComponent.open(this.dialog);
    }

    public openUsers() {
        if (!this.data.superadmin) return;
        AllUsersDialogComponent.open(this.dialog);
    }

}

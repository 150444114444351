import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../data.service';


@Component({
    selector: 'app-captcha-dialog',
    templateUrl: './captcha-dialog.component.html',
    styleUrl: './captcha-dialog.component.scss'
})
export class CaptchaDialogComponent {

    public static open(dialog: MatDialog) {
        return dialog.open<CaptchaDialogComponent, any, string>(CaptchaDialogComponent, { width: '352px' });
    }

    constructor(
        public readonly data: DataService,
        public readonly ref: MatDialogRef<CaptchaDialogComponent, string>,
    ) { }

    public resolved(token_v2: string | null) {
        this.ref.close(token_v2 || '');
    }

}
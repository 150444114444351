<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <mat-button-toggle-group [formControl]="filters_mode_control">
            <mat-button-toggle value="products">Товари</mat-button-toggle>
            <mat-button-toggle value="kits">Набори</mat-button-toggle>
        </mat-button-toggle-group>

        <app-filters-panel [filters]="filters" *ngIf="filters_mode_control.value=='products'"></app-filters-panel>
        <app-filters-panel [filters]="filters_kits" *ngIf="filters_mode_control.value=='kits'"
            (filtersChanged)="onKitsFilterChange()"></app-filters-panel>

        <div *ngIf="filters_mode_control.value=='kits'" class="info">
            <mat-icon>info</mat-icon>
            <span>
                Ціна за дрон не включає жодну з можливих знижок, як от знижка з монетами (1-3%),
                купони магазинів, офіційні промокоди та купони від AliExpress (10-16%).
                Тож, <b>в період розпродажів, ціна за дрон буде в середньому на 15% нижча</b> за указану на сайті.
            </span>
        </div>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()" *ngIf="filters_mode_control.value=='products'">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До продуктів' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_products.length}}</span>
                    <small>/{{products.length}}</small>
                </div>
            </div>
        </button>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()" *ngIf="filters_mode_control.value=='kits'">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>Ціна за дрон</span>
                <div>
                    <span>{{drone_price|currency}}</span>
                    <span *ngIf="future_drone_price" matTooltip="Запланована ціна"> ⌛
                        {{future_drone_price|currency}}</span>
                </div>
            </div>
        </button>

    </div>

    @switch (view_mode) {
    @case (ViewModes.grid) {
    <cdk-virtual-scroll-viewport [itemSize]="card_height" *ngIf="products.length" style="overflow-x:hidden">
        <div class="products-scroll">
            <div class="products-kit-description" *ngIf="current_kit?.kit_description"
                [innerHtml]="current_kit?.kit_description_html"></div>
            <div *cdkVirtualFor="let products_group of filtered_products_groups" class="products-row">
                <product-card *ngFor="let p of products_group" [product]="p"
                    [kit_quantity]="kit_quantities_dict[p.product_id]"></product-card>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <div class="products-scroll" *ngIf="!products.length">
        <div class="products-grid-dummy">
            <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </div>
    </div>
    }
    @case (ViewModes.list) {
    <cdk-virtual-scroll-viewport [itemSize]="(layout.is_desktop | async) ? 122 : 189" *ngIf="products.length"
        style="overflow-x:hidden">
        <div class="products-scroll list">
            <div class="products-kit-description" *ngIf="current_kit?.kit_description"
                [innerHtml]="current_kit?.kit_description_html"></div>
            <app-product-line *cdkVirtualFor="let p of filtered_products" [product]="p"></app-product-line>
        </div>
    </cdk-virtual-scroll-viewport>
    <div class="products-scroll list" *ngIf="!products.length">
        <div class="products-list-dummy">
            <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </div>
    </div>
    }
    @case (ViewModes.table) {
    <app-products-table [skeleton]="!products.length" [products]="filtered_products">
        <div class="products-kit-description" *ngIf="current_kit?.kit_description"
            [innerHtml]="current_kit?.kit_description_html" style="margin-top: 16px;"></div>
    </app-products-table>
    }
    }

    <div class="products-empty-message" *ngIf="products.length && !filtered_products.length">
        Жоден продукт не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-buttons">

        <ng-container *ngIf="products.length && (layout.is_mobile | async)">

            <button mat-fab extended color="accent" (click)="sidebar.openSidebar()"
                *ngIf="filters_mode_control.value=='products'">
                <mat-icon>filter_list</mat-icon>
                <span><b>{{filtered_products.length}}</b><small>/{{products.length}}</small></span>
            </button>

            <button mat-fab extended color="accent" (click)="sidebar.openSidebar()"
                *ngIf="filters_mode_control.value=='kits'">
                <mat-icon>filter_list</mat-icon>
                <span>{{drone_price|currency}}</span>
            </button>

        </ng-container>

        <div class="spacer"></div>

        <app-promo-button
            [compact]="!!(current_kit&&(data.superadmin||data.sdua||data.tenant_id&&data.admin))"></app-promo-button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="toggleViewMode()">
            <mat-icon>{{next_view_mode.icon}}</mat-icon>
            <span *ngIf="layout.is_desktop | async">{{next_view_mode.name}}</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="editKit()"
            *ngIf="current_kit&&(data.superadmin||data.sdua||data.tenant_id&&current_kit.tenant_id==data.tenant_id&&data.admin)">
            <mat-icon>edit</mat-icon>
            <span *ngIf="layout.is_desktop | async">Редагувати набір</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="addKit()"
            *ngIf="data.superadmin||data.sdua||data.tenant_id&&data.admin">
            <mat-icon>add</mat-icon>
            <span *ngIf="layout.is_desktop | async">Створити набір</span>
        </button>

        <button mat-fab color="accent" (click)="requestKit()" *ngIf="current_kit&&data.tenant_id&&data.admin"
            matTooltip="Додати набір до запитів">
            <mat-icon>shopping_cart</mat-icon>
        </button>

        <button mat-fab *ngIf="data.superadmin||data.sdua" (click)="addNew()">
            <mat-icon>add</mat-icon>
        </button>

    </div>

</app-sidebar>
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService, ProductFull } from '../../../data.service';

@Component({
    selector: 'report-order-action-button',
    templateUrl: './report-order-action-button.component.html',
    styleUrl: './report-order-action-button.component.scss'
})
export class ReportOrderActionButtonComponent {

    @Input()
    public product: ProductFull | null = null;

    @Input() @HostBinding('class.clickable') public clickable = false;

    public loading = false;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
    ) { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        event.stopPropagation();
        const product = this.product;
        if (!product) return;
        this.loading = true;
        this.data.getRequests().subscribe({
            next: requests => {
                const request = requests.find(r => r.model_id === product.model_id);
                if (request) {
                    this.loading = false;
                    this.router.navigate([], {
                        queryParams: {
                            view: 'report',
                            product_id: product.product_id,
                            request_id: request.request_id,
                        }
                    });
                } else {
                    this.data.upsertRequest({
                        model_id: product.model_id,
                        request_quantity: 1,
                        request_max_price: product.sku_full_unit_price,
                        request_rule: 'all', request_products: '[]'
                    }).subscribe(request => {
                        this.loading = false;
                        this.router.navigate([], {
                            queryParams: {
                                view: 'report',
                                product_id: product.product_id,
                                request_id: request.request_id,
                            }
                        });
                    });
                }
            }
        });
    }

}

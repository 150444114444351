import { KitFull, ProductFull } from "./data.service";

export function dateToLocalTime(date: Date) {
    return String(date.getHours()).padStart(2, '0')
        + ':' + String(date.getMinutes()).padStart(2, '0')
        + ':' + String(date.getSeconds()).padStart(2, '0');
}

export function dateToLocalShotTime(date: Date) {
    return String(date.getHours()).padStart(2, '0')
        + ':' + String(date.getMinutes()).padStart(2, '0');
}


export function dateToLocalDate(date: Date) {
    return String(date.getDate()).padStart(2, '0')
        + '.' + String(date.getMonth() + 1).padStart(2, '0')
        + '.' + String(date.getFullYear()).padStart(4, '0');
}

export function timestampToLocalTime(ts: number) {
    return dateToLocalTime(new Date(ts * 1000));
}

export function timestampToLocalShortTime(ts: number) {
    return dateToLocalShotTime(new Date(ts * 1000));
}

export function timestampToLocalDate(ts: number) {
    return dateToLocalDate(new Date(ts * 1000));
}

export function stringsToDate(date: string, time: string) {
    const [day, month, year] = date.split('.').map(Number);
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
}

const base62chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

function toBase62(bytes: any) {

    let num = BigInt("0x" + [...bytes].map(b => b.toString(16).padStart(2, '0')).join(''));
    let base62 = '';

    while (num > 0n) {
        base62 = base62chars[num % 62n as any] + base62;
        num = num / 62n;
    }

    return base62;
}

export function uuidToBase62(uuid: string) {
    const hex = uuid.replace(/-/g, '');
    const bytes = new Uint8Array(hex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    return toBase62(bytes);
}

function base62ToBigInt(base62: string) {
    let num = 0n;
    for (const char of base62) {
        num = num * 62n + BigInt(base62chars.indexOf(char));
    }
    return num;
}


function bigIntToBytes(num: bigint, byteLength: number) {
    const bytes = new Uint8Array(byteLength);
    for (let i = byteLength - 1; i >= 0; i--) {
        bytes[i] = Number(num & 0xFFn);
        num >>= 8n;
    }
    return bytes;
}


function bytesToUuid(bytes: Uint8Array) {
    const hex = [...bytes].map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hex;
}


export function base62ToUuid(base62: string) {
    const bigIntValue = base62ToBigInt(base62);
    const bytes = bigIntToBytes(bigIntValue, 16); // 16 bytes for a UUID
    return bytesToUuid(bytes);
}

export function getNumberLabel(d: number, dict: { one: string, few: string, many: string }) {
    if (d % 100 > 10 && d % 100 < 20) return dict.many;
    if (d % 10 === 1) return dict.one;
    if (d % 10 === 2) return dict.few;
    if (d % 10 === 3) return dict.few
    if (d % 10 === 4) return dict.few
    return dict.many;
}

export function getKitProductsAndPrice(kit: KitFull | null | undefined, all_products: ProductFull[], future_price: boolean, bulk: boolean) {

    if (!all_products.length) return { products: [], price: 0, kit_quantities_dict: {} };

    const details_names = [
        'Stack', 'Frame', 'Props', 'Motors', 'Camera', 'VTX Antenna', 'RX', 'VTX', 'FC', 'ESC', 'PDB',
        'TX', 'VRX', "Digital Video Kit", 'Pigtail', 'Radio Connector', 'Power Terminal',
        'Battery Case Holder', 'Battery Percentage Indicator', 'Pins', 'Balancer Cable', 'Prototype PCB',
        'ELRS Antenna', 'Strap', "Soldering Iron", "3D Printer", "3D Printer Enclosure", "Filament Dryer",
        "Smoke Stopper", "Remote Control", "Goggles", "Whoop", "Meter", "Tools", "Patch",
        "SDR", "SDR Controller", "SDR and Controller", "Training Board", "Battery", "Capacitor",
        "3D Print", "Downthrow System", "Camera Rotator", "Camera Switcher", "BEC", "SDR", "SBC", "Servo",
        "Soldering Iron Stand", "Soldering Iron Tip", "Solder Wire", "Flux", "Isopropyl Alcohol", "Soldering Mat",
        'Kapton Tape', 'Reinforced Tape', 'PVC Heat Shrink Tube', 'Battery Gasket', 'Copper Cable', 'Power Connector',
        'Charger'
    ];

    const products: ProductFull[] = [];

    const kit_quantities_dict: { [k: number]: number } = {};

    if (kit) {
        details_names.forEach(detail_name => {

            const detail_products = all_products.filter(p => p.detail_name === detail_name
                && (p.sku_quantity === 1 || p.detail_units !== 'pcs' && p.detail_units !== 'set' || bulk || p.detail_bulk)
                && !p.product_deleted && (p.product_status === 'verified' || kit.kit_risky)
                && p.sku_last_available && kit.kit_models_ids.includes(p.model_id))
                .sort((a, b) => (future_price && a.sku_future_unit_price ? a.sku_future_unit_price : a.sku_min_unit_price)
                    - (future_price && b.sku_future_unit_price ? b.sku_future_unit_price : b.sku_min_unit_price));

            if (kit.kit_full) {
                const unique_products = detail_products.filter((v, i, s) => i === s.findIndex(p => p.model_id === v.model_id));
                for (let prod of unique_products) {
                    kit_quantities_dict[prod.product_id] = kit.kit_models_ids.filter(id => id === prod.model_id).length;
                    products.push(prod);
                }
            } else {
                if (detail_products.length) products.push(detail_products[0]);
            }

        });
    }

    const price = products.filter(p => p.product_source === 'aliexpress')
        .reduce((a, c) => a + (future_price && c.sku_future_unit_price
            ? (c.detail_units === 'pcs' || c.detail_units === 'set' ? c.sku_future_unit_price : c.sku_future_sale_price)
            : (c.detail_units === 'pcs' || c.detail_units === 'set' ? c.sku_min_unit_price : c.sku_min_price)), 0);

    return { products, price, kit_quantities_dict };

}

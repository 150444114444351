<table class="table" *ngIf="stock.length">
    <tr>
        <th>Модель</th>
        <th class="text-end" *ngIf="!simplified">Прийшло</th>
        <th class="text-end" *ngIf="!simplified">Пішло</th>
        <th class="text-end" *ngIf="!simplified">Дрони</th>
        <th class="text-end" *ngIf="!simplified">Баланс</th>
        <!-- <th class="text-end" *ngIf="!simplified">Ревізія</th> -->
        <th width="120" class="text-end">Кошик</th>
    </tr>
    <ng-container *ngFor="let s of stock">
        <tr *ngIf="s.stock_quantity || emptyRows" [class.selected]="!simplified&&s.cart_quantity">
            <td>{{s.model_name}}, {{s.sku_units}}</td>
            <td class="text-end" *ngIf="!simplified" (click)="showIn(s)">{{s.income_quantity | number : '1.0-2'}}</td>
            <td class="text-end" *ngIf="!simplified" (click)="showOut(s)">{{s.outcome_quantity | number : '1.0-2'}}</td>
            <td class="text-end" *ngIf="!simplified">{{s.drones_quantity | number : '1.0-2'}}</td>
            <td class="text-end" *ngIf="!simplified"><b>{{s.stock_quantity | number : '1.0-2'}}</b></td>
            <!-- <td class="text-end" *ngIf="!simplified">
                <mat-checkbox [(ngModel)]="revision[s.warehouse_id+'-'+s.model_id]" (change)="update()" (click)="update()"></mat-checkbox>
            </td> -->
            <td>
                <div class="cart-counter">
                    <div class="cart-counter-sub" (click)="sub(s)">-</div>
                    <input class="cart-counter-input" [value]="s.cart_quantity | number : '1.0-2'" (input)="input(s, $event)" />
                    <div class="cart-counter-add" (click)="add(s)">+</div>
                </div>
            </td>
        </tr>
    </ng-container>
</table>

<div class="products-empty-message" *ngIf="!stock.length">
    Тут пусто.
</div>
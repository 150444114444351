<ng-container *ngIf="product">

    <mat-card [class.product-soldout]="!product.sku_last_available"
        [class.nolink]="!product.product_url&&data.superadmin" routerLink="." [queryParams]="product.view_query_params"
        (click)="data.click(data.current_click_source, data.CT.OpenProductDialog, product)">
        <div mat-card-image class="product-image-wrapper">

            <div class="product-kit-quantity" *ngIf="kit_quantity&&kit_quantity!=1">
                Треба: {{kit_quantity}}{{product.sku_units}}
            </div>

            <img class="product-image" [src]="product.model_image_url" />

            <div class="product-extras">


                <div class="product-extra red" *ngIf="product.sku_bulk_quantity && product.sku_bulk_quantity > 1">
                    Оптом: {{ product.sku_bulk_unit_price | currency }}/{{product.sku_units}}
                    за {{ product.sku_bulk_quantity }} {{product.sku_units}}
                </div>

                <div class="product-extra red" *ngIf="product.sku_bulk_quantity && product.sku_bulk_quantity == 1">
                    Є дешевше:
                    {{ product.sku_bulk_unit_price | currency: product.product_currency}}/{{product.sku_units}}
                </div>

                <div class="product-extra red" *ngIf="product.product_orders_failed"
                    matTooltip="Кількість проблемних замовлень даної позиції спільнотою ua-drones.de">
                    Проблем: {{ product.product_orders_failed }}
                </div>

                <div class="product-extra green" *ngIf="product.product_orders_completed"
                    matTooltip="Кількість успішних замовлень даної позиції спільнотою ua-drones.de">
                    Замовлень: {{ product.product_orders_completed }} {{ product.sku_units }}
                </div>

                <div class="product-extra red" *ngIf="product.product_orders_avg_price_usd"
                    matTooltip="Середня ціна замовлень даної позиції спільнотою ua-drones.de">
                    Середня ціна: {{ product.product_orders_avg_price_usd | currency: product.product_currency }}
                </div>

                <div class="product-extra" *ngIf="product.item_max_limit&&product.item_max_limit<10">
                    Ліміт: {{ product.item_max_limit }} шт
                </div>

                <div class="product-extra" *ngIf="product.item_shipping_price">
                    Доставка: {{ product.item_shipping_price | currency: product.product_currency }}
                </div>

                <div class="product-extra" *ngIf="product.product_comments">
                    {{ product.product_comments }} {{product.product_comments_label}}
                </div>

                <div class="product-extra" *ngIf="product.item_coins_discount">
                    -{{ product.item_coins_discount }}% з монетами
                </div>

                <div class="product-extra" *ngIf="product.sku_extra_tips">
                    {{ product.sku_extra_tips }}
                </div>

                <div class="product-extra">
                    Оновлено {{product.product_last_updated | dateAgo}}
                </div>

                <seller-chip [data]="product"
                    (open)="data.click(data.current_click_source, data.CT.OpenSellerDialog, product)"></seller-chip>

                <div class="product-extra product-future-price" *ngIf="product.sku_future_sale_price">
                    <ng-container *ngIf="product.sku_future_sale_price < product.sku_last_price">
                        <b>{{ product.sku_future_sale_price | currency: product.product_currency }}</b>
                        <span *ngIf="product.sku_future_sale_datetime"> з {{product.sku_future_sale_datetime}}</span>
                    </ng-container>
                    <ng-container *ngIf="product.sku_future_sale_price >= product.sku_last_price">
                        <b>Розпродаж</b>
                        <span *ngIf="product.sku_future_sale_datetime"> з {{product.sku_future_sale_datetime}}</span>
                    </ng-container>
                </div>

            </div>

            <div class="product-top-buttons">

                <a mat-flat-button color="warn" [href]="product.sku_sd_url" class="product-sd-button" target="_blank"
                    *ngIf="product.sku_full_sd_price && (!product.sku_full_lo_price || product.sku_full_sd_price <= product.sku_full_lo_price)"
                    (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenSuperDeals, product)">
                    <mat-icon>sell</mat-icon>
                    <i>SuperDeals</i>
                    <span> за </span>
                    <strong>{{ product.sku_full_sd_price | currency: product.product_currency }}</strong>
                </a>

                <a mat-flat-button color="warn" [href]="product.sku_lo_url" class="product-sd-button" target="_blank"
                    *ngIf="product.sku_full_lo_price && (!product.sku_full_sd_price || product.sku_full_lo_price < product.sku_full_sd_price)"
                    (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenLimidetDeal, product)">
                    <i>LimitedDeal</i>
                    <span> за </span>
                    <strong>{{ product.sku_full_lo_price | currency: product.product_currency }}</strong>
                </a>

                <a mat-flat-button color="warn" [href]="product.sku_bs_url" class="product-sd-button" target="_blank"
                    *ngIf="product.sku_full_bs_price && (!product.sku_full_sd_price || product.sku_full_bs_price < product.sku_full_sd_price)"
                    (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenBigSave, product)">
                    <i>BigSave</i>
                    <span> за </span>
                    <strong>{{ product.sku_full_bs_price | currency: product.product_currency }}</strong>
                </a>

                <a mat-flat-button color="warn" *ngIf="product.item_coins_superdiscount" class="product-sd-button"
                    target="_blank" [href]="product.sku_cd_url"
                    (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenCoinsDeal, product)">
                    <div class="flex">
                        <i>З монетами</i>
                        <span class="spacer"> </span>
                        <strong> -{{ product.item_coins_superdiscount }}%</strong>
                    </div>
                </a>

            </div>

            <div class="product-card-actions-container">

                <button mat-mini-fab class="product-card-action" (click)="onEdit($event)"
                    *ngIf="data.superadmin||data.sdua">
                    <mat-icon>edit</mat-icon>
                </button>

                <button mat-mini-fab class="product-card-action" (click)="onEditModel($event)"
                    *ngIf="data.superadmin||data.sdua">
                    <mat-icon>edit_note</mat-icon>
                </button>

                <upsert-request-action-button [model_id]="product.model_id"
                    [default_request_max_price]="product.sku_min_unit_price" />

                <report-order-action-button [product]="product"></report-order-action-button>

                <button mat-mini-fab class="product-card-action" (click)="onAffiliate($event)"
                    *ngIf="data.admin&&data.tenant?.tenant_affiliate&&product.product_source=='aliexpress'"
                    matTooltip="Редагувати афіліейтні посилання">
                    <mat-icon>add_link</mat-icon>
                </button>

            </div>

            <div class="product-status-icon" *ngIf="product.product_status=='caution'"
                matTooltip="Цей товар підозрілий, чи має негативні відгуки">
                <mat-icon color="primary">error</mat-icon>
            </div>

            <div class="product-status-icon" *ngIf="product.product_status=='suggestion'"
                matTooltip="Цей товар було додано учасником спільноти">
                <mat-icon color="primary">group</mat-icon>
            </div>

            <div class="product-status-icon" *ngIf="product.product_status=='unknown'"
                matTooltip="Адмін не може нічого сказати з приводу надійності товару">
                <mat-icon color="primary">help_outline</mat-icon>
            </div>

            <div class="product-status-icon green" *ngIf="product.product_status=='verified'"
                matTooltip="Товар надійний, АЛЕ зберігайте обачність, все може статися 😊">
                <mat-icon color="primary">check_circle</mat-icon>
            </div>

            <div class="product-status-icon" *ngIf="product.product_status=='invalid'"
                matTooltip="Товар більше недоступний на Ali, чи має помилки оновлення">
                <mat-icon color="primary">bug_report</mat-icon>
            </div>

        </div>

        <mat-card-content>

            <div class="product-name">
                <span class="product-quantity" *ngIf="product.sku_quantity!=1">{{ product.sku_quantity
                    }}{{product.sku_units}}</span>
                <span>{{ product.model_name }}</span>
            </div>

            <div class="product-stats" *ngIf="product.product_source=='aliexpress'">
                <app-product-stars [product]="product"></app-product-stars>
                <small>|</small>
                <small>{{ product.item_reviews }} відгуків</small>
                <small>|</small>
                <small>{{ product.item_sold }} продано</small>
            </div>

            <div class="product-stats" *ngIf="product.product_source!='aliexpress'">
                <app-product-stars [product]="product"></app-product-stars>
                <small>|</small>
                <small>продукт не з AliExpress</small>
            </div>

            <div class="tags-stats">
                <div class="tags">
                    <div class="tag" *ngIf="product.model_top_price_usd">
                        <b>{{product.model_top_price_usd.toFixed(2) | currency }}</b> TOP-ціна
                    </div>
                    <small *ngFor="let tag of product.model_tags.split(';')" >
                        <div class="tag"  *ngIf="!tag.endsWith('KIT')">
                            <span>
                                {{ tag }}
                            </span>
                        </div>
                    </small>
                    <div class="tag" *ngIf="product.product_status">{{product.product_status}}</div>
                    <div class="tag" *ngIf="product.detail_name">
                        <a [href]="data.getModelUrl(product)">{{product.detail_name}}</a>
                    </div>
                </div>
            </div>

            <div class="product-price-wrapper">

                <span class="product-price">{{ product.sku_full_price | currency: product.product_currency }}</span>

                <div class="product-price-extras" *ngIf="product.product_source==='aliexpress'">

                    <small class="product-available" [class.product-available-running-out]="product.sku_running_out">
                        {{ product.sku_last_available > 1000 ? '1K+' : product.sku_last_available }} лишилося
                    </small>

                    <small class="product-unit-price" *ngIf="product.sku_quantity!=1">
                        {{ product.sku_full_unit_price | currency: product.product_currency }}/{{product.sku_units}}
                    </small>

                </div>

            </div>

            <a mat-stroked-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                *ngIf="product.product_source==='aliexpress'"
                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenAliexpress, product)">
                <mat-icon>link</mat-icon>
                Відкрити AliExpress
            </a>

            <a mat-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                *ngIf="product.product_source==='custom'"
                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenCustomProduct, product)">
                Відкрити магазин
            </a>

            <ng-content></ng-content>

        </mat-card-content>

    </mat-card>

</ng-container>

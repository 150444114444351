<mat-dialog-content class="mat-typography" *ngIf="!tdata">
    <div class="skeleton-block"></div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="tdata&&!tdata.success">
    <div class="tdata-error">
        Поштове відправлення з номером <b>{{tdata.parcel_number}}</b> не знайдено
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="tdata&&tdata.success">

    <div class="product-attrs">

        <div class="tdata-number">
            <span>{{tdata.parcel_number}}</span>
            <mat-icon *ngIf="tdata.parcel_number_alt">trending_flat</mat-icon>
            <span>{{tdata.parcel_number_alt}}</span>
        </div>

        <div class="tdata-number">
            <span>{{tdata.src_country}}</span>
            <mat-icon>trending_flat</mat-icon>
            <span>{{tdata.dst_country}}</span>
        </div>

        <div class="product-extra product-seller">
            <img class="product-seller-logo"
                [src]="getTrackingCompany(tdata.parcel_number_alt||tdata.parcel_number, tdata.company_name).icon" />
            <span
                class="product-seller-name">{{getTrackingCompany(tdata.parcel_number_alt||tdata.parcel_number, tdata.company_name).name}}</span>
        </div>

        <div class="product-available">Статус: {{tdata.parcel_status_desc}}</div>
        <div class="product-available">Днів в дорозі: {{tdata.parcel_days}}</div>

    </div>

    <div class="tdata-orders" *ngIf="orders.length">
        <div class="product-attrs" *ngFor="let o of orders">
            <div class="product-quantity">{{o.order_quantity}}x</div>
            <div class="product-extra">{{o.model_name}}</div>
            <seller-chip [data]="o"></seller-chip>
            <div class="product-available">{{o.order_local_date}}</div>
            <div class="product-available">{{o.order_amount_usd | currency}}</div>
        </div>
    </div>

    <!-- <div class="product-attrs" *ngIf="tdata.customs_info">

        <a mat-ripple class="product-extra product-seller order-number" target="_blank"
            href="https://cabinet.customs.gov.ua/post">
            <img class="product-seller-logo" src="https://cabinet.customs.gov.ua/images/gerb.png" />
            <span class="product-seller-name">Дані митниці</span>
            <mat-icon>open_in_new</mat-icon>
        </a>

        <div class="product-extra">{{timestampToLocalDate(tdata.customs_info.time)}}
            {{timestampToLocalShortTime(tdata.customs_info.time)}}</div>

        <div>{{CUSTOMS_STATUSES[tdata.customs_info.status] || tdata.customs_info.status}}</div>

    </div> -->

    <div class="event" *ngFor="let e of tdata.events">
        <mat-icon>place</mat-icon>
        <div class="event-content">
            <div class="product-extra">{{timestampToLocalDate(e.time)}} {{timestampToLocalShortTime(e.time)}}</div>
            <div class="event-desc">{{e.desc}}</div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions>

    <div style="flex: 1;"></div>

    <button mat-flat-button color="accent" mat-dialog-close>
        Закрити
    </button>

</mat-dialog-actions>

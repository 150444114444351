import { Component, HostBinding, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService, OrderRequest } from '../../../data.service';
import { UpsertRequestDialogComponent } from '../../../dialogs/upsert-request-dialog/upsert-request-dialog.component';

@Component({
    selector: 'upsert-request-action-button',
    templateUrl: './upsert-request-action-button.component.html',
    styleUrl: './upsert-request-action-button.component.scss'
})
export class UpsertRequestActionButtonComponent {

    @Input()
    public model_id: number | null = null;

    @Input()
    public default_request_max_price: number = 0;

    public request: OrderRequest | null = null;

    @Input() @HostBinding('class.clickable') public clickable = false;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model_id'] && this.data.admin) {
            this.data.getRequests().subscribe(requests => {
                this.request = requests.find(r => r.model_id === this.model_id) || null;
            });
        }
    }

    onClick(event: Event) {
        event.stopPropagation();
        if (!this.model_id) return;
        const request: OrderRequest = this.request || {
            model_id: this.model_id,
            request_max_price: this.default_request_max_price,
        } as OrderRequest;
        UpsertRequestDialogComponent.open(this.dialog, request);
    }

    get tooltip() {
        return this.request?.request_id ? 'Редагувати запит' : 'Створити запит';
    }

}

import { Component, Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService, ProductFull } from '../../data.service';
import { LayoutService } from '../../layout.service';
import { ProductEditDialogComponent } from '../../product-edit-dialog/edit-product-dialog.component';
import { ProductCustomEditDialogComponent } from "../../product-custom-edit-dialog/product-custom-edit-dialog.component";
import { AffiliatesDialogComponent } from '../../dialogs/affiliates-dialog/affiliates-dialog.component';
import { ModelEditDialogComponent } from '../../model-edit-dialog/model-edit-dialog.component';

@Directive({ selector: 'product-buttons' })
export class ProductButtonsDirective { }

@Component({
    selector: 'product-card',
    templateUrl: './product-card.component.html',
    styleUrl: './product-card.component.scss'
})
export class ProductCardComponent {

    @Input()
    public product: ProductFull | null = null;

    @Input() @HostBinding('class.clickable') public clickable = false;

    @Output() stats = new EventEmitter();
    public productRate: string[] = [];

    @Input() public kit_quantity = 0;

    @HostBinding('class.nolink') get nolink() { return this.product && !this.product.product_url && this.data.admin; }

    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        private readonly dialog: MatDialog,
    ) { }

    onEdit(event: Event) {
        event.stopPropagation();
        if (!this.product) return;

        if (this.product.product_source === 'aliexpress') {
            ProductEditDialogComponent.open(this.dialog, this.product);
        }
        else {
            ProductCustomEditDialogComponent.open(this.dialog, this.product);
        }
    }

    onEditModel(event: Event) {
        event.stopPropagation();
        if (!this.product) return;
        this.data.getModels().subscribe({
            next: models => {
                const model = models.find(m => m.model_id === this.product?.model_id);
                if (!model) return;
                ModelEditDialogComponent.open(this.dialog, model);
            }
        });
    }

    onAffiliate(event: Event) {
        event.stopPropagation();
        if (!this.product) return;
        if (this.product.product_source === 'aliexpress') {
            AffiliatesDialogComponent.open(this.dialog, this.product);
        }
    }

}

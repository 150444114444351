<div class="mb-2 d-flex align-items-center justify-content-center">
    <img src="https://desktop.telegram.org/img/website_icon.svg?4" class="me-2 tg-icon" />
    <span class="me-1">Telegram-канал:</span>
    <a href="https://t.me/+jPtYcbbg-t1mZmYy" target="_blank">&#64;ua-drones.de</a>
</div>
<!-- TODO: add link to plugin -->
<!-- <div>
    <a mat-raised-button color="accent" class="w-100" [attr.href]="offerProductUrl" target="_blank">
        Запропонувати товар
    </a>
</div> -->
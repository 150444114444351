
export type UnixTimestamp = number;
export type Float = number;
export type Uint = number;
export type UintId = number;

export enum RuleType {
    Admin = 0,
    Price = 1,
    Availability = 2,
}

export enum RuleWebsite {
    Disabled = 0,
    Enabled = 1,
}

export enum RuleTelegram {
    Disabled = 0,
    Silent = 1,
    ActiveHours = 2,
    Always = 3,
}

export enum RuleVerification {
    OnlyVerified = 0,
    VerifiedUnknown = 1,
    NotCaution = 2,
    All = 3,
}

export interface Rule {

    rule_id: UintId;
    rule_created: UnixTimestamp;
    rule_updated: UnixTimestamp;
    rule_deleted: UnixTimestamp;
    rule_type: RuleType;
    rule_website: RuleWebsite;
    rule_telegram: RuleTelegram;
    rule_verification: RuleVerification;
    rule_max_sku_quantity: Float;
    rule_max_unit_price_usd: Float;
    rule_notifications: Uint;
    user_id: UintId;
    model_id: UintId | null;
    product_id: UintId | null;

}

export interface RuleDto {
    rule_id: UintId;
    rule_deleted: boolean;
    rule_type: RuleType;
    rule_website: RuleWebsite;
    rule_telegram: RuleTelegram;
    rule_verification: RuleVerification;
    rule_max_sku_quantity: Float;
    rule_max_unit_price_usd: Float;
    model_id: UintId | null;
    product_id: UintId | null;
}

export interface Notification {
    notification_id: UintId;
    notification_date: UnixTimestamp;
    notification_read: UnixTimestamp;
    notification_type: RuleType;
    notification_website: RuleWebsite;
    notification_telegram: RuleTelegram;
    notification_verification: RuleVerification;
    notification_text: string;
    rule_id: UintId;
    user_id: UintId;
    model_id: UintId | null;
    product_id: UintId | null;
    sku_available: Uint;
    sku_price: Float;
    sku_sd_price: Float;
    sku_lo_price: Float;
    sku_bs_price: Float;
    sku_min_price: Float;
    rule_max_sku_quantity: Float;
    rule_max_unit_price_usd: Float;
}

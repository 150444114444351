<div mat-dialog-title>
    {{ warehouse ? 'Редагувати склад' : 'Створити склад' }}
</div>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>Назва складу</mat-label>
            <input matInput placeholder="Склад БФ Пес Патрон" formControlName="warehouse_name">
            <mat-error>Поле обов'язкове до заповнення</mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="warehouse_default">Склад за замовчуванням</mat-checkbox>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="onDelete()" *ngIf="warehouse">
        <span>Видалити</span>
        <mat-icon>delete</mat-icon>
    </button>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StockVm } from '../models/index';
import { DataService, Stock, Transfer } from '../../data.service';
import { TransferVm } from '../stock.component';

@Component({
    selector: 'app-stock-table',
    templateUrl: './stock-table.component.html',
    styleUrl: './stock-table.component.scss'
})
export class StockTableComponent implements OnInit {

    @Input() stock: StockVm[] = [];
    @Input() emptyRows: boolean = false;
    @Input() simplified: boolean = false;
    @Output() stockChanged = new EventEmitter<StockVm>();

    @Input() transfers: TransferVm[] = [];

    public revision: { [k: string]: boolean } = {};

    constructor(private data: DataService) {

    }

    public showIn(stock: Stock) {

        const relevant_transfers = this.transfers.filter(t => t.dst_warehouse_id === stock.warehouse_id && t.transfers.some(s => s.model_id === stock.model_id));

        this.data.showMessage(relevant_transfers.map(tt => ({ ...tt, transfer: tt.transfers.find(t => t.model_id === stock.model_id)! }))
            .map(t => `[${t.transfer.transfer_id}] [${t.transfer_date_string}] [${t.transfer.transfer_quantity}] ${t.user_name}: ${t.src_warehouse_name || 'Зарахування'} -> ${t.dst_warehouse_name || (t.drone_id ? 'Дрон №' + t.drone_id : 'Списання')}`));

    }

    public showOut(stock: Stock) {

        const relevant_transfers = this.transfers.filter(t => t.src_warehouse_id === stock.warehouse_id && t.transfers.some(s => s.model_id === stock.model_id));

        this.data.showMessage(relevant_transfers.map(tt => ({ ...tt, transfer: tt.transfers.find(t => t.model_id === stock.model_id)! }))
            .map(t => `[${t.transfer.transfer_id}] [${t.transfer_date_string}] [${t.transfer.transfer_quantity}] ${t.user_name}: ${t.src_warehouse_name || 'Зарахування'} -> ${t.dst_warehouse_name || (t.drone_id ? 'Дрон №' + t.drone_id : 'Списання')}`));

    }


    public ngOnInit() {
        this.revision = localStorage.getItem('revision') ? JSON.parse(localStorage.getItem('revision') || '') : {};
    }

    public update() {
        setTimeout(() => {
            localStorage.setItem('revision', JSON.stringify(this.revision));
        }, 10);
    }

    public add(s: StockVm) {
        const new_quantity = Math.min(s.stock_quantity, s.cart_quantity + 1);
        const old_quantity = s.cart_quantity;
        s.cart_quantity = new_quantity;
        if (!old_quantity && new_quantity) {
            this.stockChanged.emit(s);
        }
    }

    public sub(s: StockVm) {
        const new_quantity = Math.max(0, s.cart_quantity - 1);
        const old_quantity = s.cart_quantity;
        s.cart_quantity = new_quantity;
        if (old_quantity && !new_quantity) {
            this.stockChanged.emit(s);
        }
    }

    public input(s: StockVm, e: Event) {

        if (!(e.target instanceof HTMLInputElement)) return;

        const old_quantity = s.cart_quantity;

        let new_quantity = Math.floor(Number(e.target.value));
        new_quantity = Math.min(s.stock_quantity, Math.max(0, new_quantity));

        if (isNaN(new_quantity)) new_quantity = s.cart_quantity;

        s.cart_quantity = new_quantity;

        const val = new_quantity.toFixed(2).replace(/0+$/g, '').replace(/\.$/g, '');

        if (val !== e.target.value) e.target.value = val;

        if (old_quantity && !new_quantity || !old_quantity && new_quantity) {
            this.stockChanged.emit(s);
        }

    }
}

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" (submit)="save()">

        <div class="product-info-wrapper">

            <div class="product-info">

                <div class="product-name">
                    <span>{{kit.kit_name || 'Новий набір'}}</span>
                </div>

            </div>

        </div>

        <div class="flex">

            <mat-form-field>
                <mat-label>Назва набору</mat-label>
                <input matInput [placeholder]="'Напр., SD 7&quot; Оптимальний'" formControlName="kit_name" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Скорочене посилання</mat-label>
                <input matInput [placeholder]="'Напр., sd-7-optimal-kit'" formControlName="kit_slug" />
            </mat-form-field>

        </div>

        <!-- <mat-form-field>
            <mat-label>Моделі в цьому наборі</mat-label>
            <mat-select multiple formControlName="kit_models_ids">
                <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                    <mat-option *ngFor="let m of d.models" [value]="m.model_id">{{m.model_name}}</mat-option>
                </mat-optgroup>
                <mat-select-trigger>
                    <mat-chip-set>
                        <mat-chip *ngFor="let id of form.controls.kit_models_ids.value">
                            {{models_dict[id]?.model_name||''}}
                        </mat-chip>
                    </mat-chip-set>
                </mat-select-trigger>
            </mat-select>
        </mat-form-field> -->

        <mat-form-field class="example-form-field">
            <mat-label>Моделі в цьому наборі</mat-label>

            <mat-chip-grid #modelsChipGrid formControlName="kit_models_ids">
                @for (id of form.controls.kit_models_ids.value; track id; let index = $index) {
                <mat-chip-row (removed)="removeModel(index)" [value]="id">
                    <span>{{models_dict[id]?.model_name}}</span>
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                }
            </mat-chip-grid>

            <input #modelInput placeholder="Пошук по моделях..." [(ngModel)]="current_model" (input)="filterModels()"
                [ngModelOptions]="{standalone: true}" [matChipInputFor]="modelsChipGrid" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separator_keys_codes" (matChipInputTokenEnd)="addModel(modelInput)" />

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectModel(modelInput, $event)">
                @for (model of filtered_models; track model) {
                <mat-option [value]="model">{{model.model_name}}</mat-option>
                }
            </mat-autocomplete>

        </mat-form-field>

        <mat-form-field>
            <mat-label>Підказка до набору</mat-label>
            <textarea matInput cdkTextareaAutosize [placeholder]="'Коротний опис набору...'"
                formControlName="kit_comment"></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Опис набору</mat-label>
            <textarea matInput cdkTextareaAutosize
                [placeholder]="'Опис набору, що буде відображений на сторінці набору (підтримує Markdown)'"
                formControlName="kit_description"></textarea>
        </mat-form-field>

        <div class="checkbox-wrapper" *ngIf="data.superadmin||data.sdua">
            <mat-checkbox formControlName="kit_verified">
                Верифікований набір (видимий в списку)
            </mat-checkbox>
        </div>
        <div class="checkbox-wrapper">
            <mat-checkbox formControlName="kit_public">
                Публічний набір (доступний для всіх за посиланням)
            </mat-checkbox>
        </div>
        <div class="checkbox-wrapper">
            <mat-checkbox formControlName="kit_full">
                Повний набір (без групування)
            </mat-checkbox>
        </div>
        <div class="checkbox-wrapper">
            <mat-checkbox formControlName="kit_risky">Ризиковий набір (не тільки верифіковані товари)</mat-checkbox>
        </div>

        <mat-form-field>
            <mat-label>Порядок набору в списку</mat-label>
            <input matInput [placeholder]="'Напр., 1, 10, 100, тощо'" formControlName="kit_order" />
            <mat-hint>Менший порядок - вище набір в списку</mat-hint>
        </mat-form-field>

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Відміна</button>
    <button mat-button (click)="copyLink()" *ngIf="form.controls.kit_slug.value">
        <span>Посилання</span>
        <mat-icon>copy</mat-icon>
    </button>
    <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">
        <span>Зберегти</span>
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Payout } from '../../data.service';
import { timestampToLocalDate } from '../../utils';
import { CommentDialogComponent, CommentDialogData } from '../comment-dialog/comment-dialog.component';

@Component({
    selector: 'app-payouts-table',
    templateUrl: './payouts-table.component.html',
    styleUrl: './payouts-table.component.scss'
})
export class PayoutsTableComponent implements OnChanges {

    @Input() public loading = false;
    @Input() public payouts: Payout[] = [];

    public timestampToLocalDate = timestampToLocalDate;
    public displayedColumns = ['payout_date', 'user_name', 'payout_amount_uah'];
    public dataSource: MatTableDataSource<Payout> = new MatTableDataSource();

    constructor(
        private readonly dialog: MatDialog,
    ) { }

    public update() {
        this.dataSource.data = this.payouts;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['payouts']) {
            this.dataSource.data = this.payouts;
        }
    }

    onClick(payout: Payout) {
        const data: CommentDialogData = { comment: payout.payout_comment };
        CommentDialogComponent.open(this.dialog, data);
    }

}

<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <a routerLink="/info" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>waving_hand</mat-icon>
            <span>Вступ</span>
        </a>

        <a routerLink="/info/project" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>info</mat-icon>
            <span>Про проєкт</span>
        </a>

        <a routerLink="/info/purchase" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon matTooltip="свиня_джакузі.gif">savings</mat-icon>
            <span>Закупівлі на AliExpress</span>
        </a>
        <ng-container *ngIf="(sub|async)=='purchase'">
            <a routerLink="/info/purchase" fragment="stores" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Магазини</span>
            </a>
            <a routerLink="/info/purchase" fragment="reliability" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Надійність магазинів</span>
            </a>
            <a routerLink="/info/purchase" fragment="wholesales" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Choice Day & Розпродажі</span>
            </a>
            <a routerLink="/info/purchase" fragment="promocodes" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Промокоди</span>
            </a>
            <a routerLink="/info/purchase" fragment="coins" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Монети</span>
            </a>
            <a routerLink="/info/purchase" fragment="superdeals" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>SuperDeals & LimitedDeal</span>
            </a>
            <a routerLink="/info/purchase" fragment="toll" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Мито</span>
            </a>
            <a routerLink="/info/purchase" fragment="ban" class="lvl2">
                <mat-icon>remove</mat-icon>
                <span>Проблеми з акаунтом</span>
            </a>
        </ng-container>

        <a routerLink="/info/erp" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>warehouse</mat-icon>
            <span>Система обліку</span>
        </a>

        <a routerLink="/info/contribute" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>data_object</mat-icon>
            <span>Контрибуція</span>
        </a>

        <a routerLink="/info/motors" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>attractions</mat-icon>
            <span>Тести моторів</span>
        </a>

    </div>

    <mat-card *ngIf="!(sub|async)">

        <p>Вітаю на <b>ua-drones.de</b>!</p>

        <p>
            Цей сайт є адаптованою для Німеччини версією
            <a target="_blank" href="https://goods.swarm.army">
                <img src="https://goods.swarm.army/assets/swarm-black-t.png" />
                <span>goods.swarm.army</span>
            </a>.

            Подальша мова йде від <b>адміна</b> спільноти <b>swarm.army</b>
        </p>

        <p>
            На зв'язку ваш адмін. Хочу трохи розказати про проєкт, та про те, що тут можна знайти.
            На сайті розміщені кращі пропозиції комплектуючих з АліЕкспресу для ФПВ дронів та не тільки,
            дбайливо відібрані як мною особисто, так і додані учасниками спільноти.
            Ціни та наявність періодично оновлюються,
            тож можна зекономити трохи часу на моніторинг, і зайнятися збіркою, чи іншими корисними справами.
        </p>

        <p>
            Деякі ресурси та матеріали від адміна, про які ви могли не знати:
        </p>

        <ul>

            <li>
                <a target="_blank" href="https://t.me/+jPtYcbbg-t1mZmYy">
                    <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                    <span>Telegram-канал</span>
                </a>
                <span>
                    з нотифікаціями про падіння цін на товари з Алі,
                    <!-- та з іноді корисними публікаціями на тему ФПВ та закупівель -->
                </span>
            </li>

            <li>
                <a target="_blank"
                    href="https://chromewebstore.google.com/detail/ua-dronesde/pmijhibgbgkjmmccojjkohnhigkamjkk">
                    <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg" />
                    <span>Chrome-розширення</span>
                </a>
                <span>
                    з виправленням в'єтнамської мови, додатковою статистикою по товарам та продавцям,
                    та можливістю пропонувати товари для ресурсу
                </span>
            </li>

            <li>
                <a target="_blank" href="/info/erp">
                    <mat-icon>warehouse</mat-icon>
                    <span>Система обліку замовлень</span>
                </a>
                <span>
                    що дозволяє залучати до закупівель з Алі більше людей (а значить, більше промокодів),
                    трекати замовлення, провадити базовий складський облік комплектуючих та зібраних дронів
                </span>
            </li>

            <li>
                <a target="_blank" href="/info/purchase">
                    <mat-icon>savings</mat-icon>
                    <span>Велика інструкція з закупівель на AliExpress</span>
                </a>
                <span>
                    що є систематизацією досвіду вашого адміна з роботи з AliExpress
                </span>
            </li>

            <li>
                <a target="_blank" href="/info/motors">
                    <mat-icon>settings</mat-icon>
                    <span>Тести моторів</span>
                </a>
                <span>
                    на стенді
                    <a target="_blank" href="https://www.aliexpress.com/item/32997870524.html">Mayatech MT10 Pro</a>,
                    багато 2807 1300KV, і трішки - інших розмірів
                </span>
            </li>

            <li>
                <a target="_blank" href="/?kit">
                    <mat-icon>construction</mat-icon>
                    <span>Набори комплектуючих</span>
                </a>
                <span>
                    для збірки, зокрема <a target="_blank" href="/?kit=sd-7-optimal-kit">SD 7" Оптимальний</a>
                    та <a target="_blank" href="/?kit=sd-7-budjet-kit">SD 7" Бюджетний</a>
                </span>
            </li>

            <li>
                <a target="_blank" href="https://t.me/swarm_army">
                    <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                    <span>Майстерня</span>
                </a>
                <span>
                    де адмін та його близькі люди збирають трохи дронів (тут тихо, та, зазвичай, нічого цікавого,
                    стопки дронів, багато коробок, та раз на місяць - відео з бойовою роботою)
                </span>
            </li>

            <li>
                <a target="_blank" routerLink="/info">
                    <mat-icon>info</mat-icon>
                    <span>Розділ "Довідка"</span>
                </a>
                <span>
                    де можна почитати історію виникнення проєкту, знайти інструкції з використання даного ресурсу,
                    та де я дуже хочу, але не дуже можу (бо доба мала), публікувати корисні матеріали по закупівлям
                    (монети, суперділси, і оце от усе)
                </span>
            </li>

        </ul>

        <p>
            Сайт містить афілійовані посилання - купуючи товари за посиланнями звідси, невеликий відсоток з
            купівлі відраховується вашому адміну. Це не додає додаткової вартості до покупок; купуючи напряму з Алі -
            Алі просто забирає цей відсоток собі. Планую використовувати ці кошти на підтримку роботи нашої майстерні,
            а також на підтримку спільноти Social Drone запасними комплектуючими для ремонту проблемних дронів
            (звіти за <a target="_blank" href="https://t.me/swarmalerts/299">березень</a>,
            <a target="_blank" href="https://t.me/swarmalerts/422">квітень</a>).
            Варто зазначити, що за принципом афілійованих посилань працюють сервіси типу LetyShops чи Фішка,
            якщо користуєтесь такими - <b>ua-drones.de</b> не буде їх заміняти собою, тож ваш кешбек нікуди не дівається.
        </p>

        <p>
            Ваш адмін старався зробити цей ресурс зручним та корисним.
            Якщо маєте зауваження, ідеї, пропозиції, покращення, або ж хочете долучитися до розробки ресурсу -
            в описі телеграм-каналу можна знайти мої контакти.
        </p>

        <p>
            Бонус для тих, хто дочитав аж сюди: головний інженер в нашій майстерні, та тримач кукухи вашого адміна
            [<a
                (click)="gallery_shown=true;sidebar.scroll.scrollTo({top: sidebar.scroll.scrollHeight, behavior: 'smooth'})">показати</a>]
        </p>

        <p>
            Дякую, що дочитали всю писанину, та дякую що небайдужі до долі нашої країни.
            Дякуємо Богу та ЗСУ що живі, та підтримуємо чим можемо наших хлопців.
        </p>

        <div class="gallery" [class.shown]="gallery_shown"
            (click)="gallery_shown=true;sidebar.scroll.scrollTo({top: sidebar.scroll.scrollHeight, behavior: 'smooth'})">

            <img src="https://assets.swarm.army/photos/cat1.jpg" />
            <img src="https://assets.swarm.army/photos/cat2.jpg" />
            <img src="https://assets.swarm.army/photos/cat3.jpg" />
            <img src="https://assets.swarm.army/photos/cat4.jpg" />

        </div>

    </mat-card>

    <mat-card *ngIf="(sub|async)=='project'">

        <p>
            Цей сайт є адаптованою для Німеччини версією
            <a target="_blank" href="https://goods.swarm.army">
                <img src="https://goods.swarm.army/assets/swarm-black-t.png" />
                <span>goods.swarm.army</span>
            </a>.

            Подальша мова йде від <b>адміна</b> спільноти <b>swarm.army</b>
        </p>

        <p>
            Почну з передумов створення цього сайту.
        </p>

        <p>
            З кінця 2023 року ваш адмін, члени родини, та добрі друзі долучилися до виробництва FPV,
            в тих масштабах, в яких дозволять власні фінанси.
            Так ми дійшли до >50 вже переданих, та ~200 замовлених чи на різних етапах збірки дронів.
        </p>

        <p>
            Яким чином організували закупівлі компонентів: зібрали групу друзів та знайомих ближнього кола спілкування
            (називатиму їх далі "закупівельники"), щоб кожен купляв товари особисто, а ми повертали за закупівлі гроші
            (таким чином, можна вирішити проблему митниці, та зібрати більше знижок на товари).
        </p>

        <p>
            Для організації цього процесу та обліку спочатку використовували GSuite (Google Forms + Google Sheets),
            однак з ростом числа замовлень таким інструментарієм ставало користуватися все важче. Крім того, було важко
            організовувати зростаючу групу закупівельників, оскільки товари, особливо дефіцитні, часто міняють ціну,
            зʼявляються і зникають, тощо. Тобто, прийти до групи і сказати: купіть 20 стеків - не працює, не всі
            розібралися, не всі хочуть розбиратися, не всі можуть знайти найкращу ціну тощо.
        </p>

        <p>
            Відповідно, саме для цих людей першочергово був написаний цей ресурс.
            Моніторинг цін і вибірка продуктів з Алі - тільки складова частина цього процесу.
            Про мотивацію викласти це назагал: а чому б власне і ні, якщо проблема моніторингу
            АліЕкспресу є не тільки у нас?
        </p>

        <p>
            З того часу проект розрісся, пережив редизайн, та набув невелику, але все ж кількість користувачів
            (якщо комусь цікаво, то протягом 30 хвилинного вікна станом на кінець травня 2024 сайтом стабільно
            користуються
            близько 5 людей). До розробки сайту долучилося декілька членів спільноти, за що їм велике дякую,
            в планах - ще більше корисних функцій. Та варто розуміти, що даний ресурс розвивається виключно в вільний
            час вашого адміна, тож не варто очікувати тут чогось екстраординарного.
        </p>

        <p>
            На
            <a
                (click)="gallery_shown=true;sidebar.scroll.scrollTo({top: sidebar.scroll.scrollHeight, behavior: 'smooth'})">фото</a>
            - ваш адмін, і його молода й не дуже команда трохи працюють.
        </p>

        <div class="gallery" [class.shown]="gallery_shown"
            (click)="gallery_shown=true;sidebar.scroll.scrollTo({top: sidebar.scroll.scrollHeight, behavior: 'smooth'})">

            <img src="https://assets.swarm.army/photos/me.jpg" />
            <img src="https://assets.swarm.army/photos/boxes.jpg" />
            <img src="https://assets.swarm.army/photos/drones.jpg" />

        </div>

    </mat-card>

    <mat-card *ngIf="(sub|async)=='erp'">

        <p><b>Система обліку замовлень та комплектуючих (ERP)</b></p>

        <p>
            Основна причина розробки <b>swarm.army</b> - це необхідність замінити Google Sheets для обліку замовлень
            комплектуючих для дронів. Певний час цим функціоналом користувалися виключно адмін та його спільнота,
            але наразі обʼєм системи, та її функціональні можливості напевно достатні,
            щоб приносити користь більш широкій аудиторії.
        </p>

        <div class="gallery shown border">

            <img src="https://assets.swarm.army/photos/requests.jpg" />
            <img src="https://assets.swarm.army/photos/report.jpg" />
            <img src="https://assets.swarm.army/photos/orders.jpg" />
            <img src="https://assets.swarm.army/photos/payouts.jpg" />
            <img src="https://assets.swarm.army/photos/stock.jpg" />
            <img src="https://assets.swarm.army/photos/drone.jpg" />

        </div>

        <p>
            Нижче наведений алгоритм роботи, який пропонує система.
        </p>

        <ul class="padded">

            <li>
                <mat-icon>remove</mat-icon>
                Будь-який користувач авторизується на сайті, та створює <b>організацію</b>. Всі ресурси, про які
                йтиме мова далі, існують всередині вашої організації. Можна створювати багато організацій, та бути
                членом багатьох організацій. Користувач, що створює організацію, стає її <b>адміністратором</b>.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                <b>Адміністратор</b> організації може створювати <b>запити</b>. Запит - опис необхідності закупити певну
                кількість комплектуючих певної моделі. Запит включає в себе кількість, та граничну ціну покупки. Запит
                автоматично підтягує найкращі пропозиції з сайту, тож закупівельникам не треба буде зйматися пошуком та
                підбором.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                <b>Адміністратор</b> організації може запрошувати до своєї організації інших користувачів. Користувачі
                можуть мати різні права доступу - <b>Закупівельник, Бухгалтер, Збиральник, Адміністратор</b>.
            </li>

            <li>
                Будь-які користувачі організації можуть бачити <b>запити</b>, проглядати, де найвигідніше "закрити" ті
                чи інші запити, робити покупки, та вносити їх в систему. При внесенні покупки зазначається її вартість
                та номер замовлення з AliExpress, що полегшує трекінг покупки в майбутньому.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                Користувачі можуть прогдядати та керувати своїми замовленнями, додавати трекінговий номер, коли
                замолення відправлено, відміняти, тощо.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                Адміністратор організації може проглядати внесені замовлення всіх користувачів організації, керувати
                ними, проглядати статистику. <b>Адміністратор та Бухгалтер</b> також мають доступ до <b>Виплат</b> -
                можна подивитися, на яку суму кожен користувач робив замовлення, та якщо вони не були записані як
                донати,
                записувати виплати по цих замовленнях по кожному користувачу. Для цього - кожен користувач може вказати
                номер карти, на яку варто компенсовувати вартість замовлення.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                По прибуттю замовлення відбувається акт передачі, коли адміністратор отримує замовлення від
                закупівельника, та заносить його отримання на склад.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                Адміністратор може створити довільну кількість складів в системі, та проводити передачі комплектуючих
                між складами. Також йому доступна статистика, скільки тих чи інших комплектуючих знаходиться на кожному
                складі.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                <b>Адміністратор та Збиральник</b> мають змогу списати комплектуючі зі складу та створити <b>Дрон</b>.
                Система автоматично присвоїть йому номер (який можна перевизначити), та запамʼятає, з яких комплектуючих
                дрон було зібрано.
            </li>

            <li>
                <mat-icon>remove</mat-icon>
                Товари на складі обліковуються, можна проводити базові операції предачі між складами, списання та
                зарахування, та комплектацію дрона позиціями зі складу.
            </li>

        </ul>

        <p>
            Нижче - коротка відеоінструкція з користування системою (вона трохи застаріла, й не включає роботу зі
            складом та дронами).
        </p>

        <div style="position: relative; padding-bottom: 62.5%; height: 0;">
            <iframe
                src="https://www.loom.com/embed/e6803ba339354008bcd772a1fef9fab7?sid=010a256d-246d-4374-afdd-e95ba4b3cf4f"
                frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
        </div>


    </mat-card>

    <mat-card *ngIf="(sub|async)=='contribute'">

        <p><b>Відкритий код та контрибуція до проекту</b></p>

        <p>
            Подальша мова йде від <b>адміна</b> спільноти <b>swarm.army</b>
        </p>

        <p>
            Хоча ваш адмін і несе основний тягар розробки даного ресурсу, в нас є невеличка спільнота контрибуторів, які
            вносять пропозиції та покращення. Насамперед, хочу їм подякувати за вклад в розвиток того, що ви бачите на
            екрані:
            <a target="_blank" href="https://github.com/Kraigo">&#64;Kraigo</a>,
            <a target="_blank" href="https://github.com/mekhovov">&#64;mekhovov</a>,
            <a target="_blank" href="https://github.com/dmytrokyrychuk">&#64;dmytrokyrychuk</a>.
        </p>

        <p>
            Доступ до коду ресурсу відкритий, однак в цілях непотрапляння його самі знаєте кому - ваш адмін особисто
            верифікує контрибуторів. Якщо хочете долучитися - стукайте в ПП, з радістю приймемо ваші ідеї та допрацювання.
        </p>

        <p>
            Технологічний стек, що використовується на проекті:
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Angular / TypeScript
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                NodeJS / TypeScript
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                AWS / Lambda
            </li>
        </ul>

        <p>
            Даний ресурс моніторить ціни при доставці в Україну, однак є версія ресурсу з доставкою в Німеччину та з
            цінами в Євро <a target="_blank" href="https://goods.ua-drones.de/">goods.ua-drones.de</a>.
            В планах також підняття версії моніторингу для Польші з цінами в Злотих.
        </p>

        <div class="gallery shown border">

            <img src="https://assets.swarm.army/photos/github.jpg" />
            <img src="https://assets.swarm.army/photos/commits.jpg" />

        </div>

    </mat-card>

    <mat-card *ngIf="(sub|async)=='purchase'">

        <p><b>ВЕЛИКА інструкція з закупівель на АліЕкспрес</b></p>

        <p>
            Цей сайт є адаптованою для Німеччини версією
            <a target="_blank" href="https://goods.swarm.army">
                <img src="https://goods.swarm.army/assets/swarm-black-t.png" />
                <span>goods.swarm.army</span>
            </a>.

            Подальша мова йде від <b>адміна</b> спільноти <b>swarm.army</b>
        </p>

        <p>
            Вітаю, на звʼязку ваш адмін. Враховуючи, що майже щодня бачу в когось виникають запитання, як працюють ті чи
            інші механізми АліЕкспрес, вирішив написати цей текст та поділитися власним досвідом та напрацюваннями. Для
            когось - це будуть очевидні речі, для когось - знахідка.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="alert">УВАГА</a></p>

        <p>
            Насамперед, хочу прояснити, що я в жодному випадку не являюсь якогось роду експертом, та ні в якому разі не
            закликаю робити виключно так, як тут написано. Цією статею я
            хочу поділитися власним досвідом, а як його використати - це вже особиста справа кожного. За плечима в
            мене - близько півтора мільйона потрачених на Алі гривень, та більше 700 замовлень. Багато це чи мало -
            вирішувати вам, я ж просто вирішив все систематизувати, та надіятися, що комусь це буде корисно.
            Тож, до справи.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="stores">МАГАЗИНИ</a></p>

        <p>
            Всі магазини на АліЕкспрес діляться на дві категорії: Choice та звичайні. Choice магазини мають відповідну
            позначку біля своєї назви на жовтому тлі. Всі інші магазини - не Choice.
        </p>

        <div class="pictures">

            <div class="pictures-wrapper">
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/seller-choice.png" />
                    <div>Це Choice магазин</div>
                </div>
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/seller-regular.png" />
                    <div>Це звичайний магазин</div>
                </div>
            </div>

        </div>

        <p>
            Choice - це такі магазини, які не мають свого складу, офісу, чи будь-яких інших фізичних ресурсів в
            звичайному розумінні. Вони закуповують товар, пакують його в готові поштові пакунки, клеять на нього ту
            ціну, за яку будуть його продавати, і відправляють готові пакунки на склад АліЕкспрес. Коли ви робите
            замовлення, то цей товар вже фізично знаходиться на складі АліЕкспресу. За рахунок цього, Алі відправить
            його мало не в той же день, і дійде воно до України за 8-12 днів в середньому, за рахунок добре вибудованої
            логістики. Але, враховуючи, що ціна на посилках вказується ще до продажу, ця ціна завжди буде РЕАЛЬНА (це
            важливо в контексті мита).
        </p>

        <p>
            Тут варто розуміти, що всі історії, де Choice магазин не відправляє товар - це не вина власне магазину, а
            збій гігантської логістичної машини Алі, таке теж трапляється. І так само, коли від Choice магазину в пакеті
            приходять стяжки - це значить, що продавець ще до продажу товару вам, запакував в пакет стяжки, і відправив
            їх на склад Алі. Або ж Алі наплутав (таке трапляється, і частіше ніж хотілося б).
        </p>

        <p>
            Комунікація з Choice магазинами затруднена, можна не намагатися писати їм повідомлення з якимись проханнями.
            Будь які диспути вирішуються так само в основному напряму з Алі.
        </p>

        <p>
            Звичайні магазини - це такі, де є власний склад, і такі які самотужки організовують логістику. За рахунок
            меншого масштабу відправка триває довше, але з такими магазинами можна комунікувати, і вони з радістю йдуть
            на зустріч зі всякими проханнями (наприклад - з заниженням митної вартості). Крім того, більшість звичайних
            магазинів і так занижують митну вартість.
        </p>

        <p>
            Варто зазначити, що звичайні магазини можуть продавати Choice товар - для таких товарів діють всі ті ж
            правила, що і для Choice-магазинів.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="reliability">НАДІЙНІСТЬ МАГАЗИНІВ</a></p>

        <p>
            На Алі - тисячі магазинів. Деякі з них обʼєднані в так звані сімейства (в них один власник), деякі магазини
            мають звичайний магазин, та Choice магазин, а деякі - десятки магазинів зі схожими назвами та тими самими
            товарами (привіт, U-Angel). Всі мають якісь рейтинги, відгуки, і так далі, але, на жаль, не завжди на них
            можна покладатися.
        </p>

        <p>
            З власного досвіду, сформулював 4+ кити, на яких стоїть основа закупівель
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Сімейство FPVKING
                (<a target="_blank" href="https://fpvking.aliexpress.com/store/421227">FPVKING</a>,
                <a target="_blank" href="https://www.aliexpress.com/store/1102512410">FPVKING RC</a>,
                <a target="_blank" href="https://uuurc.aliexpress.com/store/911181">UUUDrone</a>)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Сімейство U-Angel
                (<a target="_blank" href="https://www.aliexpress.com/store/1102517263">HobbyModel Factory</a>,
                <a target="_blank" href="https://www.aliexpress.com/store/1102605341">Flyhike</a>,
                <a target="_blank" href="https://www.aliexpress.com/store/603538">U-Angel Official</a>, ...)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Сімейство Mitoot
                (<a target="_blank" href="https://www.aliexpress.com/store/515319">Mitoot</a>, ...)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Сімейство ReadyToSky
                (<a target="_blank" href="https://www.aliexpress.com/store/727847">ReadyToSky</a>,
                <a target="_blank" href="https://www.aliexpress.com/store/1102525035">UltraFPV</a>)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <a target="_blank" href="https://www.aliexpress.com/store/2342305">Physital</a> (окремо, бо не кит)
            </li>
        </ul>

        <p>
            Це не значить, що ці магазини продають кращі товари за інших. Вони так само продають окремі товари, які я би
            купувати не радив (рами в FPVKING). Але якщо б мене питали, де варто дивитися пропозиції спочатку, то саме
            тут.
        </p>

        <p>
            Щодо інших магазинів. Варто звертати увагу на декілька факторів:
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Розмір магазину. Кількість підписників, кількість продажів. Менше 1000 підписників і менше 1000 продажів
                - привід задуматися.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Рейтинг магазину. Менше 95% - привід задуматися. Алі заохочує писати відгуки відразу після отримання
                товару, відповідно більшість відгуків оцінюють лише доставку. Тож раджу ділити на два всі ці хороші
                рейтинги.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Дата заснування. Магазини, засновані умовно вчора - лотерея, хоч буває і дуже дешево.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Відгуки до товару, що хочете купити. Менше 4.7 - дуже багато питань. Раджу відфільтрувати відгуки по
                Україні, та читати в хронологічному порядку, а що ж там коїться.Не обовʼязково, якщо щось з факторів
                вище не виконується - магазин поганий. Це лише додатковий привід бути обережним при покупці.
            </li>
        </ul>

        <p><a class="anchor" [routerLink]="[]" fragment="wholesales">РОЗПРОДАЖІ</a></p>

        <p>
            Першого тижня щомісяця, починаючи з весни минулого року (дата не точна), Алі проводить розпродаж Choice Day.
            Власне, до Choice магазинів це наразі вже має мало відношення (хоча починалося як я розумію з цього), в цей
            період багато магазинів опускають ціни на свої товари, а Алі випускає промокоди, які дають ще більше знижок
            (загалом, відсоток знижок з промокодами може доходити до 15%).
        </p>

        <p>
            Аналогічні до Choice Day розпродажі Алі може проводити й в інші проміжки часу, по одному додатковому
            розпродажу зимою, весною, влітку, та восени, й часом - з більшими скидками.
        </p>

        <p>
            Раджу планувати свої покупки таким чином, щоб закуповувати основну частину товарів саме в період
            розпродажів, а між ними - тільки якщо щось зʼявляється за душе хорошою ціною.
        </p>

        <div class="pictures bigger">
            <div class="pictures-wrapper">
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/calendar-06-24.jpg" />
                    <div>Календар розпродажів на червень 2024</div>
                </div>
            </div>
        </div>

        <p><a class="anchor" [routerLink]="[]" fragment="promocodes">ПРОМОКОДИ</a></p>

        <p>
            На АліЕкспресі існують декілька категорій промокодів, з допомогою яких іноді вдається зекономити до 20% на
            покупках (зазвичай, це 12-15%). Деякі коди зʼявляються та діють лише на період розпродажів, деякі діють весь
            час, але дають куди меншу знижку. Промокоди треба закріплювати за собою - їх розбирають інші люди, вони
            вичерпуються, і з часом - ними скористатися вже не вийде. Детальніше про це буде нижче.
        </p>

        <p>
            Всі промокоди на Алі діють за наступною схемою: треба додати в корзину товар на певну суму, щоб промокод
            застосувався. Нприклад, знижка $8 при покупці на $69. В суму покупки враховуються лише кінцева вартість
            товарів. Сума доставки не враховується при обрахунку промокоду.
        </p>

        <p>
            Доступні наступні категорії промокодів:
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                <b>Купонні коди.</b> Ці коди зʼявляються в період розпродажів, та доступні до закріплення на головній
                сторінці додатку Алі. Для цього потрібно просто натиснути "Отримати" біля кожного з кодів, - і все -
                коди будуть закріплені за вашим обліковим записом, та застосуються при покупці автоматично.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <b>Промо коди.</b> Ці коди також зʼявляються в період розпродажів, але їх треба вводити вручну. Їх також
                треба закріплювати! Для закріплення промокодів, треба додати в корзину товарів на макимальну суму
                промокоду, дійти до етапу оплати, та почергово застосувти всі промокоди з серії. За такою ж схемою можна
                бронювати і купонні коди, але там є зручні кнопки. Тут же - лише вручну.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <b>Купонні коди магазинів.</b> Магазини можуть випускати власні купонні коди, що діють виключно на
                покупки в цих магазинах. Скидки за такими кодами зазчиай незначні, але чому б ними не користатися, коли
                вони є? Часом, такі коди будуть доступні прямо на сторінці товару, а часом треба перейти на сторінку
                магазину, і пошукати їх там. Їх, як і звичайні купонні коди, потрібно "Отримувати" - для ціього в
                інтерфейсі буде відповідна кнопка.
            </li>
        </ul>

        <div class="pictures height">
            <div class="pictures-wrapper">
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/codes-promo.png" />
                    <span>АліЕкспрес сам пропонує зібрати купонні коди при заході в додаток</span>
                </div>
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/codes-seller.png" />
                    <span>Купонні коди при заході на сторінку магазину</span>
                </div>
            </div>
        </div>


        <p>
            Купонні та промокоди випускаються АліЕкспресом, та діють на певній території. Сам Алі не рекомендувватиме
            вам коди, що не діятимуть з вашим акаунтом, але в інтернеті можна знайти різні, тож треба уважно дивитися,
            щоб ці коди працювали саме для України.
        </p>

        <p>
            Існують також афілійовані промокоди, які можуть випускати партнери AliExpress (ваш адмін, наприклад). Ці
            коди діють додатково до звичайних кодів - тож, за один розпродаж можна застосувати декілька промокодів
            одного номіналу (але кожен унікальний промокод - лише один раз).
        </p>

        <p>
            Додатково наголошу за бронювання промокодів та купонів. Це треба робити, як тільки це стає доступно. Для
            купонних кодів - це зазвичай два дні до початку розпродажу, для промокодів - одночасно з початком. Так ви
            максимізуєте свої шанси скористатися бажаним промокодом, коли діло дійде до покупки.
        </p>

        <p>
            Промокоди великих номіналів зазвичай закінчуються дуже швидко (бо вони якраз і дають найбільшу знижку). Тож
            якщо хочете купити на велику суму, і застосувати великий промокод, раджу це робити якнайраніше з початком
            розпродажу.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="coins">МОНЕТИ</a></p>

        <p>
            На алі є Монети. 100 монет - це $1 скидки при покупках. Є багато умов, але це основне, що треба знати. Без
            жодних зусиль протягом дня, з одного облікового запису можна заробити 110 монет (еквівалент $1.10), на це
            треба потратити від сили 30 секунд. За місяць так накопичується більше $30, що враховуючи мінімальні
            затрачені зусилля, вже не злається абсолютно безглуздим.
        </p>

        <p>
            Основний спосіб отримати монети - просто заходити щодня в АліЕкспрес. Окремо можна заходити з мобільного
            дотатку, та з компʼютера. З мобільного Алі дає 70 монет на день, з компʼютера - 40. Важливо заходити щодня,
            якщо пропустити хоча б день - то видача монет почнеться з 40 та 20 на день відповідно. Крім того, Алі
            начисляє монети за покупки. В середньому, близько одної монети за коджен потрачений долар. Існують інші
            способи заробити монети, як от ігри, всякі квести від Алі, тощо, але лишимо то для дітей, та людей, у кого
            багато часу (не в образу таким людям, щиро їм заздрю).
        </p>

        <p>
            Як потратити монети? Це і просто, і складно одночасно, і є багато нюансів. На дуже багато категорій товарів
            монети і так застосовуються автоматично, але скидка незначна (1-2%). В картці товару видно, якщо на даний
            товар застосовується скидка з монетами.
        </p>

        <p>
            Основний же розділ для того, щоб потратити монети - розділ "Монети" в додатку. На компʼютері він теж є, але
            знижки там значно менші. Далі по пунктам.
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Більшість недорогих супутніх товарів, ціна яких не перевищує ~$5, можна купити зі скидкою ~50% (стрепи,
                болтики, гаєчки, всяка дрібнота).
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                На більш дорожчі товари, ~$10 (антени, викрутки, іноді RX, тощо) часом трапляються скидки 5-10%. Але
                порівнюйте ціни зі звичайними покупками не через розділ "Монети" - часто скидка з монетами нівелюється
                додатвокою націнкою, яку додає Алі.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Іноді зʼявляються суперскидки з монетами на дорогі товари (по типу VTX зі скидкою 55%), але як то працює
                ніхто не бачив, ходять лише легенди.
            </li>
        </ul>

        <p>
            Для того щоб скористатися монетами достатньо
        </p>


        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Додати товар в корзину, іноді лайкнути.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Зайти в розділ з монетами.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Поскролити та пошукати цей товар в розділі. Якщо товар не зʼявився - скористайтеся пошуком, який
                доступний з розділу "Монети"
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Подивитися яка буде скидка, купити товар зі скидкою. Важливо: при покупці з монетами додати товар в
                корзину неможливо, зразу оплачуємо товар прямо зі сторінки товару.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                За один раз з монетами можна купити 1-2-3 одиниці тоару, далі скидка не накопичується (натискаєте
                "Купити", й додаєте по одному товару, дивитеся, коли скидка з монетами перестане рости пропорційно).
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                АЛЕ: ніхто не забороняє оформити багато замовлень, я так скуповую пропи по $1.8, всякі гаєчки чи болти,
                стрепи, і тд. Виглядає це потім як 100+ замовлень в кабінеті, але приходить найчастіше одною посилкою.
            </li>
        </ul>

        <p>
            Алі з незрозумілих причин може давати бан на використання монет - тоді ціни в розділі стають жовті замість
            червоних, а скидки - менші. Наразі немає єдиного алгоритму як вирішити цю проблему. Детальніше про бани Алі
            розкажу нижче.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="superdeals">SuperDeals & LimitedDeal</a></p>

        <p>
            SuperDeals - промокампанія від АліЕкспрес, яка діє постійно. Суть кампанії - платформа пропонує купити
            невелику кількість (1-3шт в одні руки) товару за зниженою ціною через спеціальний розділ. Потрапити в розділ
            можна з головної сторінки додатку чи сайту, або за
            <a target="_blank" href="https://s.click.aliexpress.com/e/_Dk0BSGB">посиланням</a>. Через цей розділ, як і
            через монети, покласти в корзину неможливо, можна лише купити товар одразу. На товари з розділу SuperDeals
            не застосовуються промокоди, але ІНОДІ застосовуються купонні коди та купонні коди магазинів.
        </p>

        <p>
            Важливо, що Алі накладає обмеження на кількість, але це обмеження не завжди очевидно відображається в
            додатку. Наприклад, може бути ситуація, що Алі пропонує купити вам якийсь товар за ціною $10 за одиницю, і
            дозволяє додати в корзину три одиниці товару. Але вже після оплати ви побачите, що з карти списалося не $30,
            а наприклад, $36, а вартість одиниці, відповідно, вийшла $12 за штуку. Тож рекомендація по розділу - купуйте
            по одному товару за раз, так буде точна гарантія, що ціна не зміниться вже після оплати, а в ви не
            переплатите.
        </p>

        <p>
            Товари в розділі зʼявляються в залежності від ваших інтересів на Алі, але можна перевірити будь-який товар
            на учать в промо-кампанії SuperDeals. Для цього треба відкрити товар з компʼютера, та дописати до адреси
            товару параметр <b>sourceType=562</b>. Наше розширення вміє робити дану перевірку (а також перевірку на
            LimitedDeal) автоматично, тож можна скористатися ним, якщо неохота розбиратися з тонкощами формування
            URL-адреси. [<a href="https://chromewebstore.google.com/detail/ua-dronesde/pmijhibgbgkjmmccojjkohnhigkamjkk"
                target="_blank">Посилання на розширення</a>]
        </p>

        <p>
            Як підтип SuperDeals, існує промо-кампанія LimitedDeal. Товари з цієї кампанії теж потрапляють в розділ
            SuperDeals, але позначені відповідною позначкою. Алгоритм роботи з ними аналогічний, єдина відмінність -
            таких товарів на розпродаж виставляють буквально одну чи декілька одиниць, тож побачивши товар за такою
            пропозицією, варто розуміти, що він буде доступний недовго. Розширення також перевіряє товари на
            LimitedDeal, також перевірити можна вручну, додавши до адреси товару в браузері параметр
            <b>sourceType=561</b>.
        </p>

        <p>
            В розділі SuperDeals діє доволі нестандартна для Алі логіка: вам можуть не продати певний товар, якщо ви до
            цього купували подібний товар навіть в інших продавців. Однак, навіть такі товари показуються в списку, тому
            треба вручну перевіряти, чи доступний товар для покупки, чи ні.
        </p>

        <p><a class="anchor" [routerLink]="[]" fragment="toll">МИТО</a></p>

        <p class="alert">
            <b>УВАГА!</b> Інформація про відносно успішні замовлення на суму, що перевищують безмитний ліміт застаріла.
            Останнім часом були введені певні "покращення" на митниці/пошті, тепер посилки ДУЖЕ довго затримуються на
            вʼїзді в Україну, та заїжджають великими пачками. Тож наразі - порада не перевищувати митний ліміт, та
            робити максимальні вікна між замовленнями. Надіюся, що ситуація зміниться в кращу сторону згодом, та це
            інформаційне повідомлення можна буде з чистою душею видалити з цієї інструкції. Заради справедливості,
            відмічу, що адмін не потрапив до числа тих, чиї посилки останнім часом застрягали, чи на які було накладено
            мито, АЛЕ, кількість таких випадків останнім часом значно побільшала.
        </p>

        <p>
            Почнемо з митних зборів. Згідно митного законодавства України, правом на безмитне ввезення можуть
            користуватися поштові відправлення на суму ДО 150€ на день на одну особу. В доларах це трохи більше $160.
            Під “днем” мається на увазі той день, коли відправлення потрапляє на митну територію України (а не коли воно
            було зроблене, також не коли приходить нотифікація в АліЕкспресі).
        </p>

        <p>
            Ще трохи законодавства: всі наші замовлення на комплектуючі з Алі не підпадають під постанову про безмитне
            ввезення комплектуючих до БПЛА та готових БПЛА. Чому - питання не до мене. Митниця думає саме так, а саме
            вона в даному випадку приймає рішення.
        </p>

        <p>
            Якщо ж в один день на одну особу сумарна вартість посилок перевищує 150€, такі посилки мають потрапити на
            митний догляд, та з них мають стягнути митний збір (трохи більше 30% з вартості замовлення мінус 150€).
        </p>

        <p>
            Тепер трохи про те, звідки митники знають вартість посилки. За міжнародним законодавством, на всіх
            міжнародних посилках вказують їх митну вартість. Продавці з АліЕкспресу ж можуть як вказувати повну
            вартість, так і суттєво занижувати її.
        </p>

        <p>
            Треба памʼятати наступне: на Алі є Choice та не Choice магазини. Choice магазини ЗАВЖДИ
            вказуватимуть повну вартість посилок (вона може відрізнятися від тої вартості, за яку безпосередньо
            купували, але не значно). Не Choice магазини зазвичай занижують вартість посилок. Замість 100 доларів,
            вказують, наприклад, 20. Це роблять більшість, але не всі. Але, не Choice магазинам можна написати перед
            покупкою, і попросити вартість занизити. З практики - всі йдуть на контакт.
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Чи потрапляють всі такі посилки на митний догляд - НІ.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Чи потрапляють на митний догляд посилки, що не перевищують 150€ - ТАК.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Чи з усіх посилок, які потрапляють на митний догляд, та чия сума замовлення перевищує 150€ буде стягнуте
                мито - НІ.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Чи можуть стягнути мито з посилки, яка не перевищує 150€ - ТАК, в адміна був негативний досвід ще до
                повномасштабного вторгнення.
            </li>
        </ul>

        <p> Тепер трохи статистики: </p>

        <p>
            За всю історію з 700 замовлень, мито довелося сплатити рівно один раз. Були різні за вартістю посилки аж до
            1000 доларів, митники брали такі посилки на митний догляд, але в результаті все доїжджало без проблем. Про
            що говорить така статистика:
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Натрапити на мито цілком реально
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Коли митники в доброму гуморі - навіть великі посилки доїжджають без проблем
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Проблема мита трохи перебільшена, якщо не нахабніти
            </li>
        </ul>

        <p>
            Звісно, є купа випадків, коли люди розказують про свій негативний досвід з митницею, але тут, як на мене,
            працює працює класична “помилка вцілілого” - ніхто не розказує про позитивний досвід. Прошу не сприймати це
            як заклик щоразу перевищувати митний ліміт, але накручувати себе стосовно того, а чи не потрапить моя
            посилка за 170 доларів на мито - теж не варто.
        </p>

        <p> Трохи порад, як ще максимально убезпечити себе від митних зборів:</p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                Замовляйте товари в різних магазинах. Якщо робити замовлення не в Choice магазинах, майже гарантовано,
                що вони приїдуть різними посилками в різні дні. Якщо замовляти в Choice - вони будуть обʼєднані в одну
                посилку в 50% випадків. Варто це враховувати.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Замовляйте товари з інтервалом хоча б в один день. Якщо це Choice - майже гарантовано вони з таким
                інтервалом і заїдуть в Україну. Зі звичайними магазинами це скоріше рулетка.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                Не варто нахабніти. Фортуна любить сміливих, а Бог - скромних. Правда - посередині. Перевищуйте ліміт,
                якщо це справді необхідно (наприклад, на більшій кількості замовлення продавець дає хорошу скидку, чи
                дуже хочеться використати великий промокод). В інших випадках - купляйте в різних магазинах, розділяйте
                замовлення по днях, залучайте до закупівель жінку/куму/кращого друга/троюрідного племінника.
            </li>
        </ul>

        <!-- <p><a class="anchor" [routerLink]="[]" fragment="delivery">ДОСТАВКА</a></p>

        <p>
            Достака з Алі може тривати дуже довго, а може - дуже швидко. Розберемося. Доставка з Choice магазинів станом
            на кінець травня 2024 року триває в середньому 10 днів від моменту замовлення (діапазон - 8-15 днів).
            Доставка зі звичайних мазагинів - не так прогнозована. Є магазини, які показують схожий результат з Choice.
            Більшість же доставляють в середньому за 20 днів.
        </p> -->

        <p><a class="anchor" [routerLink]="[]" fragment="ban">ПРОБЛЕМИ З АКАУНТОМ</a></p>

        <div class="pictures border">

            <div class="pictures-wrapper">
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/account-error.jpg" />
                    <div>Неможливо здійснити замовлення</div>
                </div>
                <div class="picture">
                    <img src="https://assets.swarm.army/photos/order-error.jpg" />
                    <div>А тут рефанд, якого ніхто не хотів</div>
                </div>
            </div>

        </div>

        <p>
            <i> <b>Стан: Скасовано</b> Це замовлення закрито, оскільки ви використали той самий купон/код або придбали
                той самий товар за зниженою ціною за допомогою іншого зареєстрованого облікового запису. Такі дії
                порушують правила проведення транзакцій на AliExpress. Зробіть замовлення ще раз без раніше
                використаного купона/коду або не купуйте той самий товар за зниженою ціною.</i>
        </p>

        <p>
            <i><b>Не вдалося здійснити замовлення.</b> Наразі неможливо опрацювати ваш запит з міркувань захисту вашого
                облікового запису.</i>
        </p>

        <p>
            Декому з АліЕкспресом дуже не щастить, і тоді є шанс натрапити на от такі повідомлення при оформленні
            замовлення. А існуючі замовлення можуть бути відмінені без пояснення причин (ніби-то через використаний два
            рази купон). Що це таке, чому виникає, і що з цим робити - давайте розбиратися.
        </p>

        <p>
            Найперше, і основне: точних причин, і стовідсоткового рішення даної проблеми немає. Я лише постараюся
            згрупувати власний досвід, досвід людей, з якими доводилося спілкуваися з даного питання, та спостереження в
            інтернеті.
        </p>

        <p>
            Почнемо з червоних прапорців, за які АліЕкспрес відразу (чи трохи згодом) з радістю вас забанить. Звичайно,
            можуть бути виключення, але це однозначно те, чого я ні в якому випадку <b>не раджу</b> робити.
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                <b>Використання одної платіжної картки для двох і більше акаунтів.</b> Платиш сам, і даєш свою карту
                коханій жинці - чекай бану від Алі.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <b>Вхід та використання двох і більше акаунтів на одному пристрої.</b> Ситуація аналогічна. Якщо Алі
                розуміє, що на одному пристрої (чи то телефон, чи то один браузер) почергово купляють декілька акаунтів
                - майже гарантовано бан.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <b>Використання програм-автоклікерів для ігор чи монет.</b> Тут думаю очевидно, Алі не любить, коли його
                обманюють.
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                <b>Зловживання відміною замовлень.</b> Робимо багато відмін (не рефандів, коли щось не так, а саме
                відмін, поки товар ще не надіслано) - чекаємо бан. Сюди ж - і сценарій, коли кладемо товари в корзину на
                велику суму, щоб застосувати жирний промокод, оплачуємо картою без грошей, а далі - йдемо в список
                замовленнь, і оплачуємо тільки ті що треба. Знижка від жирного промокоду лишиться, так само як і образа
                Алі за зловживання скидками.
            </li>
        </ul>

        <p>
            <b>А тепер до найболючішого.</b> Є купа випадків, коли люди не роблять нічого з вищезазначеного, а бан все
            одно
            прилітає. Тут лише можна здогадуватися, і мої здогадки наступні. Наша спільнота, зазвичай, люди просунуті.
            Крім того, особливо використовуючи <b>swarm.army</b>, ми майже завжди купуємо НАЙДЕШЕВШИЙ товар в категорії,
            який дуже часто є акційним, обмеженим в кількості, тощо. Відповідно, АліЕкспрес сприймає це як зловживання
            системою знижок, і превентивно блокує такі акаунти, бо "дуже багато собі дозволяють". Знову ж, це лище
            здогадки, але адекватного і обʼєктивного пояснення проблеми поки що знайти не вдалося.
        </p>

        <p>
            <b>Як це можна виправити?</b> Коротка відповідь - ніяк. Є випадки (одиничні), коли після довготривалого
            спілкування з підтримкою АліЕкспрес акаунт розблоковували, і зʼявлялася можливість нормально робити
            замовлення з промокодами. Ключове - ці випадки одиничні. Здебільшого, спілкування з підтримкою позбавлене
            будь-якого сенсу, і зводиться до того, бо чат-бот радить робити замовлення без промокодів.
        </p>

        <p>
            <b>То що робити?</b> Коротка відповідь - реєструвати новий акаунт. Забігаючи наперед, одразу скажу, що немає
            гарантій, що це стовідсотково допоможе. Але це найбільш дієвий спосіб виправити ситуацію. Цей новий акаунт
            треба максимально ізолювати від попереднього:
        </p>

        <ul class="padded">
            <li>
                <mat-icon>remove</mat-icon>
                новий номер телефону
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                нова електронна адреса
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                нова карта для оплати (елетронні картки від Привату в допомогу)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                в ідеалі - новий пристрій (на компʼютері - зміна браузера, на телефоні - користувачі андроїда можуть
                створити новий обліковий запис, якщо простіше - треба обовʼязково видалити додаток чи очистити його
                дані, а потім лише заходити в новостворений акаунт)
            </li>
            <li>
                <mat-icon>remove</mat-icon>
                нова IP адреса теж не буде зайвою (не можу сказати що це стовідсотково впливає, бо маю різні відгуки,
                однак якщо є можливість користуватися VPN, чи перемикатися на іншу мережу - краще це робити)
            </li>
        </ul>

        <p>
            Ще є один нюанс, який варто зазначити, щоб цей новий акаунт не відлетів в бан по тій же причині. Зробіть
            перше замовлення без промокодів, виберіть неакційний товар (буквально будь-яку непотрібну річ за один
            долар). Оплатіть, і дочекайтеся опрацювання оплати. Це збільшить шанси на перемогу великого червоного
            дракона.
        </p>

        <p>
            На останок, маю прохання - якщо хтось на особистому досвіді має приклад розбану власного акаунта, чи знає
            більше про причини такої поведінки АліЕкспресу - прохання написати андміністратору в особисті, він з радістю
            агрегує ваш досвід, щоб в інших учасників спільноти було більше шансів безболісно робити більше покупок.
        </p>

    </mat-card>

    <div class="products-buttons" *ngIf="layout.is_mobile | async">

        <button mat-fab extended color="accent" (click)="sidebar.openSidebar()">
            <mat-icon>toc</mat-icon>
            <span>Зміст</span>
        </button>

        <div class="spacer"></div>

    </div>

</app-sidebar>

<div mat-dialog-title>
    Додати набір до запитів
</div>

<mat-dialog-content class="mat-typography">

    <div class="kit-title">
        <div class="kit-title-name">{{dialog_data.kit.kit_name}}</div>
        <button mat-mini-fab (click)="addAll(-1)">
            <mat-icon>remove</mat-icon>
        </button>
        <button mat-mini-fab (click)="addAll(1)">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div class="request-model" *ngFor="let m of models">
        <div class="request-model-name">{{m.model_name}}</div>
        <div class="request-model-request-quantity" matTooltip="Поточна потреба">{{m.request_quantity}}</div>
        <div class="request-model-request-price" matTooltip="Ціна в запиті">{{m.request_price | currency}}</div>
        <div class="request-model-additional-quantity">
            <div class="request-model-additional-quantity-amount" matTooltip="Скільки треба докупити">
                {{m.additional_quantity}}
            </div>
            <button mat-mini-fab (click)="addModel(m, -1)">
                <mat-icon>remove</mat-icon>
            </button>
            <button mat-mini-fab (click)="addModel(m, 1)">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">
        Додати товари до запитів
    </button>
</mat-dialog-actions>
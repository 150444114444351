import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SellerFull } from '../../../data.service';
import { timestampToLocalDate } from '../../../utils';

@Component({
    selector: 'seller-chip',
    templateUrl: './seller-chip.component.html',
    styleUrl: './seller-chip.component.scss',
})
export class SellerChipComponent {

    @Input()
    public data: Partial<SellerFull> | null = null;

    @Input()
    public clickable = true;

    @Output()
    public open = new EventEmitter();

    public timestampToLocalDate = timestampToLocalDate;

    public onClick(event: Event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (!this.clickable) {
            event.preventDefault();
            return;
        }
        this.open.emit();
    }

}

export const OAUTH_ERROR = 'auth-error';
export const OAUTH_JWT = 'auth-jwt';
export const OAUTH_PROFILE = 'auth-profile';

export const PREFIXES_UKRPOSHTA = [
    'RK',
    'SA',
    'SK',
    'LL',
    'LM',
    'LO',
    'RF',
    'RP',
    'RV',
    'RX',
    'SYUA',
    'RH',
    'RO',
    'UV',
    'UU',
    'UB',
    'YY',
    'UC',
    'BB',
    'EU',
    'EX',
];

export const PREFIXES_NOVAPOSHTA = [
    'AENM',
    'AEPD',
    'AE',
    'SF',
    'AEBT',
    'NK',
];

export const PREFIXES_MEESTEXPRESS = [
    'MGRAE',
    'RT',
    'UP',
    'JNTCU',
    'UA',
    'CR',
    'MGRMY',
];

export const PREFIXES_CHINA = [
    'CN',
];

export const PREFIXES_ALI = [
    'LP',
];

export const STORAGE_KEY_REQUEST_ALERT_DONT_SHOW = 'request-alert-dont-show';

export const UK_EN_TRANSLITERATION: { [k: string]: string } = {
    'А': 'A',
    'а': 'a',
    'Б': 'B',
    'б': 'b',
    'В': 'V',
    'в': 'v',
    'Г': 'H',
    'г': 'h',
    'Ґ': 'G',
    'ґ': 'g',
    'Д': 'D',
    'д': 'd',
    'Е': 'E',
    'е': 'e',
    'Є': 'Ye',
    'є': 'ie',
    'Ж': 'Zh',
    'ж': 'zh',
    'З': 'Z',
    'з': 'z',
    'И': 'Y',
    'и': 'y',
    'І': 'I',
    'і': 'i',
    'Ї': 'Yi',
    'ї': 'i',
    'Й': 'Y',
    'й': 'i',
    'К': 'K',
    'к': 'k',
    'Л': 'L',
    'л': 'l',
    'М': 'M',
    'м': 'm',
    'Н': 'N',
    'н': 'n',
    'О': 'O',
    'о': 'o',
    'П': 'P',
    'п': 'p',
    'Р': 'R',
    'р': 'r',
    'С': 'S',
    'с': 's',
    'Т': 'T',
    'т': 't',
    'У': 'U',
    'у': 'u',
    'Ф': 'F',
    'ф': 'f',
    'Х': 'Kh',
    'х': 'kh',
    'Ц': 'Ts',
    'ц': 'ts',
    'Ч': 'Ch',
    'ч': 'ch',
    'Ш': 'Sh',
    'ш': 'sh',
    'Щ': 'Shсh',
    'щ': 'shсh',
    'Ю': 'Yu',
    'ю': 'iu',
    'Я': 'Ya',
    'я': 'ia',
    '': '',
    'ь': '',
    '’': ''
};

export const DRONE_DETAILS_ORDER = [
    'Frame', 'Motors', 'Props', 'Stack', 'Camera', 'Digital Video Kit',
    'VTX', 'VTX Antenna', 'RX', 'ELRS Antenna', 'Downthrow System', 'Camera Rotator',
    'Capacitor', '3D Print', 'Battery', 'Pigtail',
];

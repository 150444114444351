<script src="https://www.google.com/recaptcha/api.js?render=6Lc1IlEqAAAAAMmf1MoN9WqcLo-f0-k2q1q6gsvt"></script>

<main #main class="auth">

    <header>
        <div class="track-wrapper">
            <input class="track-input" [formControl]="control_number" (keyup.enter)="track()"
                [placeholder]="(layout.is_desktop|async)?'AENM123456, MGRAE123456, RK123456LV, LP123456, тощо':'LP12345678, тощо'" />
            <button mat-button class="track-clear" *ngIf="control_number.value"
                (click)="control_number.setValue('');router.navigate(['/track'])">
                <mat-icon style="margin:0;">cancel</mat-icon>
            </button>
        </div>
        <button mat-flat-button color="primary" class="track-button" (click)="track()" [buttonLoading]="loading">
            Відслідкувати
        </button>
    </header>

    <div class="track-desc" *ngIf="!loading && !tdata && !error">
        <div>Введіть номер поштового відправлення,<br />щоб дізнатися його стан.</div>
        <mat-icon>view_in_ar</mat-icon>
    </div>

    <div class="track-desc" *ngIf="!loading && !tdata && error">
        <div>Сталася помилка.<br />Перезавантажте сторінку, це може допомогти.</div>
        <mat-icon>emoji_nature</mat-icon>
    </div>

    <div class="loading" *ngIf="loading && !tdata">

        <div class="loading-title">
            Шукаємо відправлення <b>{{control_number.value}}</b>...<br />
            Це може зайняти трохи часу...
        </div>

        <div class="slider">
            <div class="slide" [@slideInOut] *ngFor="let c of loading_carriers">
                <img [src]="c.logo" />
                <div>{{c.name}}</div>
            </div>
        </div>

    </div>

    <div *ngIf="tracking&&tdata&&tdata.success" class="tdata">

        <div class="flex" style="flex-wrap: wrap;">

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    Трекінгові номери
                </div>
                <div class="tracking-attr-value">
                    <span>{{tdata.parcel_number}}</span>
                    <mat-icon *ngIf="tdata.parcel_number_alt">chevron_right</mat-icon>
                    <span>{{tdata.parcel_number_alt}}</span>
                    <mat-icon *ngIf="tdata.parcel_number_final">chevron_right</mat-icon>
                    <span>{{tdata.parcel_number_final}}</span>
                </div>
            </div>

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    Статус
                </div>
                <div class="tracking-attr-value">
                    <div class="tracking-attr-status" [class]="tracking.tracking_status">
                        <span>{{tdata.parcel_status_desc}}</span>
                        <mat-icon class="created">mark_email_read</mat-icon>
                        <mat-icon class="shipped">route</mat-icon>
                        <mat-icon class="customs">account_balance</mat-icon>
                        <mat-icon class="cleared">assured_workload</mat-icon>
                        <mat-icon class="failed">gavel</mat-icon>
                        <mat-icon class="delivered">schedule</mat-icon>
                        <mat-icon class="picked">check_box</mat-icon>
                        <mat-icon class="anomaly">report</mat-icon>
                    </div>
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.parcel_source">
                <div class="tracking-attr-label">
                    Відправник
                </div>
                <div class="tracking-attr-value tracking-attr-carriers">
                    <div class="tracking-attr-carrier">
                        <!-- TODO: fix -->
                        <img
                            [src]="'https://assets.swarm.army/carriers/'+tdata.parcel_source.toLocaleLowerCase()+'.png'" />
                        <span>{{tdata.parcel_source}}</span>
                    </div>
                </div>
            </div>

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    Поштові оператори
                </div>
                <div class="tracking-attr-value tracking-attr-carriers">
                    <div class="tracking-attr-carrier" *ngFor="let c of parcel_carriers">
                        <img [src]="c.logo" />
                        <span>{{c.name}}</span>
                    </div>
                    <!-- TODO: fix company_name. maybe add to parcel_carriers -->
                    <div class="tracking-attr-carrier" >
                        <img [src]="getTrackingCompany(tdata.parcel_number_alt||tdata.parcel_number, tdata.company_name).icon" />
                        <span>{{getTrackingCompany(tdata.parcel_number_alt||tdata.parcel_number, tdata.company_name).name}}</span>
                    </div>
                </div>
            </div>

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    Маршрут
                </div>
                <div class="tracking-attr-value">
                    <div class="tracking-attr-route">
                        <img *ngIf="tdata.src_country_code"
                            [src]="'https://flagcdn.com/h40/'+tdata.src_country_code.toLocaleLowerCase()+'.png'" />
                        <span>
                            <span *ngIf="tdata.src_city">{{tdata.src_city}}, </span>
                            <span>{{tdata.src_country}}</span>
                        </span>
                        <mat-icon>chevron_right</mat-icon>
                        <img *ngIf="tdata.dst_country_code"
                            [src]="'https://flagcdn.com/h40/'+tdata.dst_country_code.toLocaleLowerCase()+'.png'" />
                        <span>
                            <span *ngIf="tdata.dst_city">{{tdata.dst_city}}, </span>
                            <span>{{tdata.dst_country}}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    В дорозі
                </div>
                <div class="tracking-attr-value">
                    {{tdata.parcel_days}} {{getDaysLabel(tdata.parcel_days)}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.parcel_weight">
                <div class="tracking-attr-label">
                    Вага
                </div>
                <div class="tracking-attr-value">
                    {{tdata.parcel_weight}} кг
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.parcel_dimensions">
                <div class="tracking-attr-label">
                    Розміри
                </div>
                <div class="tracking-attr-value">
                    {{tdata.parcel_dimensions}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.parcel_cost">
                <div class="tracking-attr-label">
                    Вартість
                </div>
                <div class="tracking-attr-value">
                    {{tdata.parcel_cost | currency: 'UAH'}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.dst_address||tdata.dst_zip">
                <div class="tracking-attr-label">
                    Адреса доставки
                </div>
                <div class="tracking-attr-value">
                    <span>
                        <span *ngIf="tdata.dst_address">{{tdata.dst_address}}</span>
                        <span *ngIf="tdata.dst_address&&tdata.dst_zip">, </span>
                        <span *ngIf="tdata.dst_zip">{{tdata.dst_zip}}</span>
                    </span>
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.dst_name">
                <div class="tracking-attr-label">
                    Отримувач
                </div>
                <div class="tracking-attr-value">
                    {{tdata.dst_name}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.dst_phone">
                <div class="tracking-attr-label">
                    Телефон отримувача
                </div>
                <div class="tracking-attr-value">
                    {{tdata.dst_phone}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.src_name">
                <div class="tracking-attr-label">
                    Мазазин
                </div>
                <div class="tracking-attr-value">
                    {{tdata.src_name}}
                </div>
            </div>

            <div class="tracking-attr" *ngIf="tdata.info_content">
                <div class="tracking-attr-label">
                    Додаткова інформація
                </div>
                <div class="tracking-attr-value">
                    {{tdata.info_content}}
                </div>
            </div>

            <div class="tracking-attr">
                <div class="tracking-attr-label">
                    Востаннє оновлено
                </div>
                <div class="tracking-attr-value tracking-attr-time">
                    <span>{{tdata.time | dateAgo}}</span>
                    <a (click)="sendTrackingRequest(true)">Оновити зараз</a>
                </div>
            </div>

            <label class="tracking-attr" *ngIf="data.profile">
                <div class="tracking-attr-label">
                    Коментар
                </div>
                <div class="tracking-attr-value tracking-attr-time" *ngIf="!comment_editing">
                    <span *ngIf="!tracking.tracking_comment" style="color:#999;">Невідома посилка...</span>
                    <span *ngIf="tracking.tracking_comment">{{tracking.tracking_comment}}</span>
                    <a (click)="commentEdit()">Редагувати</a>
                </div>

                <div class="tracking-attr-value tracking-attr-time tracking-comment-wrapper" *ngIf="comment_editing">
                    <input class="tracking-comment-input" placeholder="Невідома посилка..."
                        [formControl]="comment_control" />
                    <button mat-icon-button class="tracking-comment-cancel" (click)="commentCancel()"
                        *ngIf="!comment_loading">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-icon-button class="tracking-comment-save" (click)="commentSave()"
                        *ngIf="!comment_loading">
                        <mat-icon>save</mat-icon>
                    </button>
                    <mat-progress-spinner [diameter]="16" mode="indeterminate"
                        *ngIf="comment_loading"></mat-progress-spinner>
                </div>

            </label>

        </div>

        <ng-container *ngIf="orders.length">

            <hr />

            <div class="tdata-orders">
                <div class="tdata-order" *ngFor="let o of orders" routerLink="." [queryParams]="o.view_query_params">

                    <img class="tdata-order-img" [src]="o.sku_image_url||o.item_image_url||o.model_image_url" />

                    <div style="display: flex;flex-direction: column;gap: 4px;">

                        <div class="product-name">
                            <span class="product-quantity">{{o.order_quantity}}x</span>
                            <span>{{o.model_name}}</span>
                        </div>

                        <div class="flex">
                            <seller-chip [data]="o" [clickable]="false"></seller-chip>
                            <div class="product-extra product-seller">
                                <img class="product-seller-logo"
                                    [src]="o.user_image_url||'/assets/logo.png'" />
                                <span class="product-seller-name">{{o.user_name}}</span>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="product-extra" *ngIf="o.order_recipient" matTooltip="Отримувач посилки">
                                <mat-icon>person_pin</mat-icon>
                                <span>{{o.order_recipient}}</span>
                            </div>
                            <div class="product-available">{{o.order_local_date}}</div>
                            <div class="product-available">{{o.order_amount_usd | currency}}</div>
                        </div>

                    </div>

                </div>
            </div>


            <!-- <div class="product-attrs" *ngIf="tdata.customs_info">

            <a mat-ripple class="product-extra product-seller order-number" target="_blank"
                href="https://cabinet.customs.gov.ua/post">
                <img class="product-seller-logo" src="https://cabinet.customs.gov.ua/images/gerb.png" />
                <span class="product-seller-name">Дані митниці</span>
                <mat-icon>open_in_new</mat-icon>
            </a>

            <div class="product-extra">{{timestampToLocalDate(tdata.customs_info.time)}}
                {{timestampToLocalShortTime(tdata.customs_info.time)}}</div>

            <div>{{CUSTOMS_STATUSES[tdata.customs_info.status] || tdata.customs_info.status}}</div>

        </div> -->

        </ng-container>

    </div>

    <div *ngIf="tdata&&tdata.success" class="tdata" style="padding: 0;overflow: hidden;min-height: fit-content;">

        <table class="events-table">
            <tr>
                <th>ОПЕРАТОР / ЛОКАЦІЯ</th>
                <th>СТАТУС</th>
                <th>ДАТА</th>
            </tr>
            <tr *ngFor="let e of tdata.events">

                <td>

                    <div class="flex">
                        <!-- TODO: fix -->
                        <img class="event-carrier" [src]="'https://assets.swarm.army/carriers/'+e.source+'.png'" />

                        <div class="event-location flex" *ngIf="e.city||e.country">
                            <img class="country-flag" *ngIf="e.country_code"
                                [src]="'https://flagcdn.com/h40/'+e.country_code.toLocaleLowerCase()+'.png'" />
                            <span>
                                <span *ngIf="e.city">{{e.city}}</span>
                                <span *ngIf="e.city&&e.country">, </span>
                                <span *ngIf="e.country">{{e.country}}</span>
                            </span>
                        </div>

                    </div>

                </td>

                <td>
                    <div class="event-content">
                        <div class="event-desc">{{e.desc}}</div>
                        <div class="event-more" *ngIf="e.more">{{e.more}}</div>
                        <div class="event-more" *ngIf="e.info">{{e.info}}</div>
                    </div>
                </td>

                <td>
                    <div class="event-time">
                        {{timestampToLocalDate(e.time)}} {{timestampToLocalShortTime(e.time)}}
                    </div>
                </td>

            </tr>
        </table>

    </div>

    <div *ngIf="tdata&&!tdata.success" class="tdata">
        <div class="tdata-error">
            <div>Поштове відправлення з номером<br /><b>{{tdata.query}}</b> не знайдено</div>
            <mat-icon>local_shipping</mat-icon>
        </div>
    </div>

    <div class="track-desc" *ngIf="!data.profile" style="height:auto;">
        <div>Для збереження історії пошуку<br />авторизуйтеся на сайті.</div>
        <button mat-flat-button color="primary" (click)="data.signin()">
            Авторизуватися
        </button>
    </div>

    <ng-container *ngIf="data.profile">

        <div *ngIf="trackings.length" class="tracking-title">
            <h2>
                <span>Нещодавні запити</span>
                <span class="tracking-title-chip">{{trackings.length}}</span>
            </h2>
            <p>Статус по неотриманим відправленням з цього розділу оновлюєьться раз в декілька годин.</p>
        </div>

        <div class="tracking-cards">

            <div class="tracking-card" *ngFor="let tracking of trackings"
                (click)="track(tracking.tracking_number);main.scroll({top:0,behavior:'smooth'})">

                <button mat-mini-fab class="tracking-archive"
                    (click)="$event.stopPropagation();archiveTracking(tracking)">
                    <mat-icon>close</mat-icon>
                </button>

                <div class="tracking-number">

                    <img class="tracking-number-img" [src]="tracking.tracking_image_url" />

                    <div>
                        <div class="tracking-number-value">{{tracking.tracking_number}}</div>
                        <div class="tracking-number-alt" *ngIf="tracking.tracking_number_alt">
                            {{tracking.tracking_number_alt}}</div>
                    </div>

                </div>

                <div class="tracking-status">
                    <mat-icon matTooltip="Останній статус">route</mat-icon>
                    <div>{{tracking.tracking_local_status}}</div>
                </div>

                <div class="tracking-updated">
                    <mat-icon matTooltip="Час останнього оновлення">update</mat-icon>
                    <div>{{tracking.tracking_local_date_updated}}</div>
                </div>

                <label for="tracking-comment" class="tracking-comment" (click)="$event.stopPropagation()">

                    <ng-container *ngIf="!tracking.loading">
                        <mat-icon
                            *ngIf="tracking.tracking_comment_control.value==tracking.tracking_comment">edit</mat-icon>
                        <mat-icon mat-ripple class="tracking-comment-save" (click)="saveComment(tracking)"
                            *ngIf="tracking.tracking_comment_control.value!=tracking.tracking_comment">save</mat-icon>
                    </ng-container>

                    <mat-progress-spinner [diameter]="16" mode="indeterminate"
                        *ngIf="tracking.loading"></mat-progress-spinner>

                    <input [formControl]="tracking.tracking_comment_control" placeholder="Невідома посилка..."
                        name="tracking-comment" />

                </label>

            </div>

        </div>

    </ng-container>

    <div class="track-desc disclaimer">
        <span>
            Цей сайт під захистом reCAPTCHA та Google.<br />Застосовуються
            <a target="_blank" href="https://policies.google.com/privacy" style="color:inherit;">Політика
                приватності</a><br />та
            <a target="_blank" href="https://policies.google.com/terms" style="color:inherit;">Правила використання</a>.
        </span>
    </div>

</main>

<mat-dialog-content>

    <form (ngSubmit)="save()" [formGroup]="form">

        <div class="order-name">Виплата для {{user.user_name}}</div>

        <mat-form-field>

            <mat-label>Номер карти<span *ngIf="user.user_card_name"> ({{user.user_card_name}})</span></mat-label>

            <input matInput readonly id="user_card_number" [value]="user.user_card_number||'Карту не вказано'" />

            <button matSuffix mat-icon-button type="button"
                onclick="document.getElementById('user_card_number').select();document.execCommand('copy')">
                <mat-icon>copy</mat-icon>
            </button>

        </mat-form-field>

        <mat-form-field>

            <mat-label>Сума виплати</mat-label>

            <input matInput inputmode="numeric" [placeholder]="'Напр., '+payout_amount_uah.toFixed(2)+''"
                formControlName="payout_amount_uah" />

        </mat-form-field>

        <div class="row">

            <mat-form-field>

                <mat-label>Дата виплати</mat-label>

                <input matInput inputmode="numeric" [placeholder]="'Напр., ' + now_date"
                    formControlName="payout_date" />

            </mat-form-field>

            <mat-form-field>

                <mat-label>Час виплати</mat-label>

                <input matInput inputmode="numeric" [placeholder]="'Напр., ' + now_time"
                    formControlName="payout_time" />

            </mat-form-field>

        </div>

        <mat-form-field>

            <mat-label>Коментар</mat-label>

            <input matInput inputmode="numeric" [placeholder]="'...'" formControlName="payout_comment" />

        </mat-form-field>

    </form>

</mat-dialog-content>



<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Відміна</button>
    <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">Записати виплату</button>
</mat-dialog-actions>
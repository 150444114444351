<mat-dialog-content class="mat-typography">

    <div class="product-info-wrapper">

        <div class="product-info">

            <div class="product-name">
                <span class="product-quantity">{{order.order_quantity}}x</span>
                <span>{{order.model_name}}</span>
            </div>

            <div class="product-attrs">

                <small class="product-available">
                    <b>{{timestampToLocalDate(order.order_date)}}</b>
                </small>

                <small class="product-available">
                    <b>{{order.detail_name}}</b>
                </small>

                <seller-chip [data]="order"></seller-chip>

                <div class="product-extra product-seller">
                    <img class="product-seller-logo" [src]="order.user_image_url" />
                    <span class="product-seller-name">{{order.user_name}}</span>
                </div>

            </div>

            <div class="product-attrs">
                <a mat-ripple class="product-extra product-seller order-number" target="_blank"
                    [href]="'https://www.aliexpress.com/p/order/detail.html?&orderId='+order.order_number">
                    <img class="product-seller-logo"
                        src="https://ae01.alicdn.com/images/eng/wholesale/icon/aliexpress.ico" />
                    <span class="product-seller-name">Замовлення #{{order.order_number||'без номера'}}</span>
                    <mat-icon *ngIf="order.order_number">open_in_new</mat-icon>
                </a>
                <a mat-ripple class="product-extra product-seller order-number"
                    *ngFor="let track of order.order_tracking_number_array" target="_blank"
                    [href]="'/track/'+track.tracking_number">
                    <img class="product-seller-logo" [src]="track.tracking_image_url" />
                    <span class="product-seller-name">{{track.tracking_number}}<span *ngIf="track.tracking_number_alt"> → {{track.tracking_number_alt}}</span></span>
                    <mat-icon>open_in_new</mat-icon>
                </a>
            </div>

            <div class="product-price-wrapper">

                <span class="product-price">{{ order.order_amount_usd | currency }}</span>

                <div class="product-price-extras" *ngIf="order.order_quantity!=1">
                    <small class="product-unit-price">
                        <b>{{ order.order_unit_price_usd | currency }}</b>/шт
                    </small>
                </div>

                <small class="product-available">
                    <b>{{(order.order_amount_uah) | number: '1.2-2'}}&nbsp;грн</b>
                </small>

                <small class="product-available" *ngIf="order.order_quantity!=1">
                    <b>{{(order.order_unit_price_uah) | number: '1.2-2'}}&nbsp;грн</b>/шт
                </small>

                <div class="product-available status donate" *ngIf="order.order_donate_amount_uah">
                    Донат <b>{{(order.order_donate_amount_uah) | number: '1.2-2'}}&nbsp;грн</b>
                </div>

                <div class="product-available status" [ngClass]="order.order_status">
                    {{order.order_local_status}}
                </div>

            </div>

        </div>

        <button mat-mini-fab mat-dialog-close class="close-button">
            <mat-icon>close</mat-icon>
        </button>

    </div>

</mat-dialog-content>

<mat-dialog-actions>

    <div style="flex:1;"></div>

    <a mat-flat-button mat-dialog-close routerLink="requests">
        <span>Запити</span>
    </a>

    <a mat-flat-button mat-dialog-close [routerLink]="[]" [state]="data.form_value"
        [queryParams]="{view:'report',request_id:order.request_id,product_id:order.product_id}">
        <mat-icon>restart_alt</mat-icon>
        <span>Внести повторно</span>
    </a>

    <a mat-flat-button mat-dialog-close color="primary" [routerLink]="[]"
        [queryParams]="{view:'order',order_id:order.order_id}">
        <mat-icon>description</mat-icon>
        <span>Переглянути замовлення</span>
    </a>

</mat-dialog-actions>
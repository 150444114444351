import { Component, Input, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LayoutService } from '../../../layout.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

    title$: Observable<string>;

    @Input() pageTitle: string | undefined;

    public openSidebar() {
        this.app_drawer?.open();
    }

    public closeSidebar() {
        this.app_drawer?.close();
    }

    isExpanded = true;

    toggleSidenav() {
        if (this.isExpanded) {
            this.closeSidebar();
            this.isExpanded = false;
        } else {
            this.openSidebar();
            this.isExpanded = true;
        }
    }

    @ViewChild(MatDrawer, { static: true }) app_drawer?: MatDrawer;
    @ViewChild(MatDrawerContent, { static: true }) app_content?: MatDrawerContent;

    public get scroll() { return this.app_content?.getElementRef()?.nativeElement!; }

    constructor(
        private route: ActivatedRoute,
        public readonly layout: LayoutService,
    ) {
        this.title$ = this.route.data.pipe(
            startWith(this.route.snapshot.data),
            map(d => d['title'])
        );
    }

}
<div mat-dialog-title>
    Комментар
</div>

<mat-dialog-content class="mat-typography">
    <p>
        {{ comment }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Закрити</button>
</mat-dialog-actions>
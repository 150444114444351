<table mat-table [dataSource]="dataSource" class="mat-elevation-z0 mat-table-condensed">

    <ng-container matColumnDef="payout_date">
        <th mat-header-cell *matHeaderCellDef>Дата</th>
        <td mat-cell *matCellDef="let element">
            {{timestampToLocalDate(element.payout_date)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef>Закупівельник</th>
        <td mat-cell *matCellDef="let element">
            <div class="flex">
                <img class="user-pic" [src]="element.user_image_url||'/favicon.ico'" />
                <span>{{element.user_name}}</span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="payout_amount_uah">
        <th mat-header-cell *matHeaderCellDef class="text-end">Сума</th>
        <td mat-cell *matCellDef="let element" class="text-end">
            {{element.payout_amount_uah | currency }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClick(row)"></tr>

    <tr *matNoDataRow>
        <ng-container *ngIf="loading">
            <td *ngFor="let _ of [].constructor(displayedColumns.length)">
                <div *ngFor="let _ of [].constructor(3)">
                    <div class="skeleton-text"></div>
                </div>
            </td>
        </ng-container>
        <ng-container *ngIf="!loading">
            <td [attr.colspan]="displayedColumns.length">
                <div class="products-empty-message">Виплат ще не було</div>
            </td>
        </ng-container>
    </tr>

</table>
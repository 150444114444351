import { Directive, HostBinding, HostListener, Input } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";


@Directive({
    selector: '[clipboardCopy]',
})
export class ClipboardCopyDirective {

    @Input('clipboardCopy') text: string | undefined;

    private timeout = 0;

    constructor(private readonly tooltip: MatTooltip) {
        console.log(tooltip)
    }

    @HostListener('click') onClick() {
        if (!this.text) return;
        navigator.clipboard.writeText(this.text);
        this.tooltip.show();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.tooltip.hide(), 1000) as any as number;
    }

    @HostListener('mouseenter', ['$event']) onMouseenter(event: Event) {
        event.stopImmediatePropagation();
    }

    @HostListener('mouseleave', ['$event']) onMouseleave(event: Event) {
        event.stopImmediatePropagation();
    }
}

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService, Detail, ModelFull } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getImageUrl } from '../utils';


@Component({
    selector: 'app-model-edit-dialog',
    templateUrl: './model-edit-dialog.component.html',
    styleUrl: './model-edit-dialog.component.scss',
})
export class ModelEditDialogComponent {

    public details: Detail[] = [];
    public loading = false;

    public static open(dialog: MatDialog, data: ModelFull) {
        return dialog.open(ModelEditDialogComponent, { data, minWidth: '400px', autoFocus: false, restoreFocus: false, panelClass: 'mobile-fullscreen', });
    }

    constructor(
        public readonly data: DataService,
        public ref: MatDialogRef<ModelEditDialogComponent>,
        private readonly snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public readonly model: ModelFull,
    ) {
        this.form.patchValue(this.model);
        this.data.getDetails().subscribe(details => this.details = details);
        this.form.controls.model_image_url.valueChanges
            .subscribe(url => url ? fetch(url, { cache: 'reload', mode: 'no-cors' }) : null);
    }

    public form = new FormGroup({
        detail_id: new FormControl<number | null>(null, Validators.required),
        model_name: new FormControl('', Validators.required),
        model_image_url: new FormControl('', Validators.required),
        model_tags: new FormControl(''),
        model_top_price_usd: new FormControl(0, [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
    });

    public save() {

        if (!this.form.valid || this.loading) {
            this.form.markAsTouched();
            return;
        }

        this.loading = true;

        this.data.upsertModel({ ...this.form.value as any, model_id: this.model?.model_id || 0 }).subscribe(model => {
            const detail = this.details.find(d => d.detail_id === model.detail_id)!;
            if (this.model) {
                this.model.detail_id = model.detail_id;
                this.model.model_name = model.model_name;
                this.model.model_image_url = getImageUrl(model.model_image_hash || model.model_image_url);
                this.model.model_tags = model.model_tags;
                this.model.model_top_price_usd = model.model_top_price_usd;
                this.model.detail_name = detail.detail_name;
                this.data.postUpdateModel(this.model);
            } else {
                this.data.postInsertModel({
                    ...detail, ...model,
                    model_count_total: 0,
                    model_count_available: 0,
                    model_price_min: 0,
                    model_price_max: 0,
                    model_price_average: 0,
                    model_future_price_min: 0,
                    model_future_price_max: 0,
                    model_future_price_average: 0,
                    tags_array: [],
                    tags_dict: {},
                    view_query_params: {},
                    sku_units: '',
                    model_image_url: '',
                    model_image_hash: '',
                });
            }
            this.snackbar.open('Зміни збережено.', '', { duration: 1000 });
            this.loading = false;
            this.ref.close();
        }, error => {
            this.loading = false;
            this.data.showMessage('Сталася помилка!');
        });

    }

}

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" (submit)="save()">

        <div class="product-info-wrapper">

            <div class="product-image-wrapper">

                <img class="product-image" [src]="form.controls.model_image_url.value">

            </div>

            <div class="product-info">

                <div class="product-name">
                    <span>{{model.model_name || 'Нова модель'}}</span>
                </div>

                <mat-chip-set *ngIf="model.tags_array.length">
                    <mat-chip *ngFor="let tag of model.tags_array">{{tag}}</mat-chip>
                </mat-chip-set>

                <div class="product-price-wrapper" *ngIf="model.model_id">

                    <span class="product-price">
                        {{ model.model_price_min | currency }} - {{ model.model_price_max | currency }}
                    </span>

                    <small class="product-available">
                        <b>{{model.model_count_available}}</b>/{{model.model_count_total}} лишилося
                    </small>
                </div>

            </div>

        </div>

        <mat-form-field>
            <mat-label>Деталь</mat-label>
            <mat-select formControlName="detail_id">
                <mat-option [value]="d.detail_id" *ngFor="let d of details">{{d.detail_name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Назва моделі</mat-label>
            <input matInput [placeholder]="'Напр., SpeedyBee F405 V3 50A'" formControlName="model_name" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання на зображення</mat-label>
            <input matInput [placeholder]="'Напр., https://cdn.aliexpress.com/___.webp'"
                formControlName="model_image_url" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Теги</mat-label>
            <input matInput placeholder="SD 7&quot;;SD 10&quot;" formControlName="model_tags" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>TOP-ціна, €</mat-label>
            <input matInput placeholder="50.00" formControlName="model_top_price_usd" />
        </mat-form-field>

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Відміна</button>
    <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
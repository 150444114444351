<mat-dialog-content class="mat-typography" *ngIf="!product">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="product">

    <form [formGroup]="form" class="flex column">

        <div class="product-name">
            <span class="product-quantity" *ngIf="product.sku_quantity!=1">{{product.sku_quantity}}x</span>
            <span>{{product.model_name}}</span>
        </div>

        <div class="product-attrs">

            <div class="product-price-wrapper">

                <span class="product-price">{{ product.sku_full_price | currency }}</span>

                <div class="product-price-extras" *ngIf="product.sku_quantity!=1">
                    <small class="product-unit-price">
                        {{ product.sku_full_unit_price | currency }}/шт
                    </small>
                </div>

            </div>

            <small class="product-available">
                {{product.detail_name}}
            </small>

            <div class="product-extra product-seller">
                <img class="product-seller-logo" [src]="product.seller_image_url" />
                <span class="product-seller-name">{{product.seller_name_short}}</span>
                <img class="product-seller-choice" *ngIf="product.item_is_choice"
                    src="https://ae01.alicdn.com/kf/S1887a285b60743859ac7bdbfca5e0896Z/154x64.png" />
            </div>

        </div>

        <div class="product-urls">

            <a mat-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                (click)="data.click(data.CS.ReportDialog, data.CT.OpenAliexpress, product)">
                <span>Купити за </span>
                <strong>{{product.sku_last_price | currency}}</strong>
            </a>

            <a mat-flat-button color="primary" *ngIf="product.sku_full_sd_price" target="_blank"
                [href]="product.sku_sd_url" (click)="data.click(data.CS.ReportDialog, data.CT.OpenSuperDeals, product)">
                <i>SuperDeals</i>
                <span> за </span>
                <strong>{{product.sku_full_sd_price | currency}}</strong>
            </a>

            <a mat-flat-button color="primary" *ngIf="product.sku_full_lo_price" target="_blank"
                [href]="product.sku_lo_url"
                (click)="data.click(data.CS.ReportDialog, data.CT.OpenLimidetDeal, product)">
                <i>LimitedDeal</i>
                <span> за </span>
                <strong>{{product.sku_full_lo_price | currency}}</strong>
            </a>

        </div>

        <mat-form-field [class.has-hint]="quantity_hint">

            <mat-label>Кількість в замовленні</mat-label>

            <input matInput inputmode="numeric" [placeholder]="'Напр., ' + product.sku_quantity"
                formControlName="order_quantity" />
            <mat-hint *ngIf="quantity_hint">{{quantity_hint}}</mat-hint>

            <div matSuffix style="display: flex;align-items: center;">
                <button mat-icon-button type="button" (click)="setQuantity(1, $event)">
                    <mat-icon>filter_1</mat-icon>
                </button>
                <button mat-icon-button type="button" (click)="setQuantity(2, $event)">
                    <mat-icon>filter_2</mat-icon>
                </button>
                <button mat-icon-button type="button" (click)="setQuantity(3, $event)">
                    <mat-icon>filter_3</mat-icon>
                </button>
            </div>

        </mat-form-field>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Сума<span *ngIf="order_quantity"> за {{order_quantity}}</span>, грн</mat-label>
                <input matInput inputmode="decimal"
                    [placeholder]="'Напр., ' + ((order_quantity||1)*product.sku_last_price*data.usd_uah_rate).toFixed(2)"
                    formControlName="order_amount_uah" oninput="this.value=this.value.replace(',','.')" />
            </mat-form-field>

            <mat-form-field class="spacer">
                <mat-label>Сума<span *ngIf="order_quantity"> за {{order_quantity}}</span>, €</mat-label>
                <input matInput inputmode="decimal"
                    [placeholder]="'Напр., ' + ((order_quantity||1)*product.sku_last_price).toFixed(2)"
                    formControlName="order_amount_usd" oninput="this.value=this.value.replace(',','.')" />
            </mat-form-field>

        </div>

        <mat-button-toggle-group formControlName="order_cex_src" *ngIf="cex">
            <mat-button-toggle value="mono_usd">Mono <small>{{cex.mono_usd.toFixed(2)}}</small></mat-button-toggle>
            <mat-button-toggle value="privat_usd">Privat
                <small>{{cex.privat_usd.toFixed(2)}}</small></mat-button-toggle>
            <mat-button-toggle value="custom_usd">Власний</mat-button-toggle>
        </mat-button-toggle-group>

        <div class="hint">
            <p>Суми в полях мають враховувати всі знижки, та бути рівними сумам списання з карти.</p>
            <p>Якщо в замовленні більше одного товару - суми потрібно перерахувати пропорційно.</p>
        </div>

        <div class="checkbox-wrapper">
            <mat-checkbox class="donate-checkbox" formControlName="order_donate">
                <div class="donate-checkbox-wrapper">
                    <span>Записати як донат</span>
                    <span style="font-size:20px;">🥰</span>
                </div>
            </mat-checkbox>
        </div>

        <mat-form-field *ngIf="form.controls.order_donate.value">
            <mat-label>Сума донату, EUR</mat-label>
            <input matInput [placeholder]="'Від 0.00 грн до суми замовлення в EUR'"
                formControlName="order_donate_amount_uah" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Номер замовлення з AliExpress</mat-label>
            <input matInput inputmode="numeric" [placeholder]="'Напр., 3032101200073774'"
                formControlName="order_number" />
            <button matSuffix mat-icon-button type="button" (click)="paste('order_number', $event)">
                <mat-icon>content_paste</mat-icon>
            </button>
        </mat-form-field>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Дата замовлення</mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_date" formControlName="order_date" />
            </mat-form-field>

            <mat-form-field class="spacer">
                <mat-label>Час замовлення</mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_time" formControlName="order_time" />
            </mat-form-field>

        </div>

        <mat-form-field>
            <mat-label>Отримувач</mat-label>
            <input matInput [placeholder]="'...'" formControlName="order_recipient" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let rec of filtered_recipients" [value]="rec">{{rec}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
            <mat-label> Коментар</mat-label>
            <input matInput [placeholder]="'...'" formControlName="order_comment" />
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="product">
    <button mat-button mat-dialog-close>Відміна</button>
    <button mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Надіслати</button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromoDialogComponent } from '../promo-dialog/promo-dialog.component';
import { PROMOS } from '../promos';
import { DateTime } from 'luxon';
import { LayoutService } from '../../layout.service';

@Component({
    selector: 'app-promo-button',
    templateUrl: './promo-button.component.html',
    styleUrl: './promo-button.component.scss'
})
export class PromoButtonComponent {
    constructor(
        private readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) { }

    @Input()
    public compact = false;

    showPromoDialog() {
        this.dialog.open(PromoDialogComponent, { width: '600px', autoFocus: false, panelClass: 'mobile-fullscreen' });
    }

    get has_promos() {
        const now = DateTime.now();
        return now.toMillis() <= PROMOS.end.toMillis();
    }

}

import { Component, Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DataService, OrderFull, ProductFull } from '../../data.service';
import { timestampToLocalDate, timestampToLocalShortTime } from '../../utils';
import { LayoutService } from '../../layout.service';

@Directive({ selector: 'product-buttons' })
export class ProductButtonsDirective { }

@Component({
    selector: 'order-card',
    templateUrl: './order-card.component.html',
    styleUrl: './order-card.component.scss'
})
export class OrderCardComponent {

    @Input()
    public order: OrderFull | null = null;

    @Input() @HostBinding('class.clickable') public clickable = false;
    @Input() public selectable = false;

    @Output() public readonly change = new EventEmitter<boolean>();

    @HostBinding('class.nolink') get nolink() { return this.order && !this.order.order_number; }

    constructor(public readonly data: DataService, public readonly layout: LayoutService,) { }

    public convertTimestamp(t: number) {
        return timestampToLocalDate(t) + ' ' + timestampToLocalShortTime(t);
    }


    // ngAfterViewInit() {
    //     document.addEventListener('DOMContentLoaded', (event) => {
    //         PKGEExtWidget.trackButton({        //Обов'язково. Трек-номер для відстеження.
    //         'trackNumber': '{{ order.order_number }}',
    //         //Обов'язково. ID елемента, після якого в структурі DOM, при наведенні / натисканні, буде додано iframe з результатами відстеження посилки.
    //         'elementId': 'pkge-track-1-test',
    //         //Не обов'язково. Ширина фрейма, в якому будуть відображатися результати відстеження посилки. За замовчуванням розтягується на всю ширину контейнера.
    //         'width': 460,
    //         //Не обов'язково. Висота фрейма, в якому будуть відображатися результати відстеження посилки. Значення за замовчуванням - 370.
    //         'height': 370,
    //         //Не обов'язково. Мова інтерфейсу користувача , якщо не задано - буде визначена згідно з налаштуваннями браузера користувача.
    //         'language': 'uk'
    //     });
    //     });
    //   }



    // <script type="text/javascript">
    // document.addEventListener('DOMContentLoaded', function () {
    //     PKGEExtWidget.trackButton({        //Обов'язково. Трек-номер для відстеження.
    //         'trackNumber': '{{ order.order_number }}',
    //         //Обов'язково. ID елемента, після якого в структурі DOM, при наведенні / натисканні, буде додано iframe з результатами відстеження посилки.
    //         'elementId': 'pkge-track-1-test',
    //         //Не обов'язково. Ширина фрейма, в якому будуть відображатися результати відстеження посилки. За замовчуванням розтягується на всю ширину контейнера.
    //         'width': 460,
    //         //Не обов'язково. Висота фрейма, в якому будуть відображатися результати відстеження посилки. Значення за замовчуванням - 370.
    //         'height': 370,
    //         //Не обов'язково. Мова інтерфейсу користувача , якщо не задано - буде визначена згідно з налаштуваннями браузера користувача.
    //         'language': 'uk'
    //     });
    // });
    // </script>


    onEdit(event: Event) {
        event.stopPropagation();
        // const data: RequestEditModel = {
        //     model_name: this.request?.model_name || '',
        //     model_id: this.request?.model_id || 0,
        //     request_quantity: this.request?.request_quantity || 0,
        //     request_max_price: this.request?.request_max_price || 0,
        // }
        // this.dialog.open(RequestEditDialogComponent, { data, minWidth: '300px', autoFocus: false, });
    }

}
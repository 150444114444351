import { Component, Inject } from '@angular/core';
import { DataService, Warehouse } from '../../data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm/confirm.component';
import { filter } from 'rxjs';


@Component({
    selector: 'app-upsert-warehouse-dialog',
    templateUrl: './upsert-warehouse-dialog.component.html',
    styleUrl: './upsert-warehouse-dialog.component.scss'
})
export class UpsertWarehouseDialogComponent {

    public static open(dialog: MatDialog, warehouse: Warehouse | null) {
        return dialog.open<UpsertWarehouseDialogComponent, Warehouse, Warehouse>(UpsertWarehouseDialogComponent, { data: warehouse, autoFocus: 'input', width: '480px' });
    }

    public loading = false;

    public form = new FormGroup({
        warehouse_name: new FormControl('', [Validators.required]),
        warehouse_default: new FormControl(false),
        warehouse_deleted: new FormControl(false),
    });

    constructor(
        private readonly snackbar: MatSnackBar,
        public readonly data: DataService,
        public readonly dialog: MatDialog,
        public readonly ref: MatDialogRef<UpsertWarehouseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly warehouse: Warehouse | null,
    ) {
        this.form.patchValue(warehouse || {});
    }

    public onDelete() {

        const warehouse = this.warehouse;
        if (!warehouse) return;

        ConfirmDialogComponent.open(this.dialog, { messages: ['Видалити склад?', 'Відновити видалений склад можна лише за зверненням до адміністратора swarm.army.'], yes: 'Видалити', no: 'Відміна' })
            .afterClosed().pipe(filter(r => !!r)).subscribe(() => {

                this.loading = true;

                this.data.getStock(false, false).subscribe({
                    next: stock => {

                        if (stock.stock.some(s => s.stock_quantity && s.warehouse_id === warehouse.warehouse_id)) {
                            this.snackbar.open('Склад не пустий, видалення неможливе.');
                            this.loading = false;
                            return;
                        }

                        const dto = { ...warehouse, warehouse_deleted: true, };

                        this.data.upsertWarehouse(dto).subscribe({
                            next: warehouse => {
                                this.ref.close(warehouse);
                                warehouse.warehouse_deleted = true;
                                this.snackbar.open('Склад видалено.', 'OK');
                            },
                            error: () => {
                                this.loading = false;
                                this.snackbar.open('Сталася помилка!', 'OK');
                            }
                        });
                    }
                });
                
            });

    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const dto = {
            warehouse_id: this.warehouse?.warehouse_id || 0,
            warehouse_name: this.form.value.warehouse_name || '',
            warehouse_default: this.form.value.warehouse_default || false,
            warehouse_deleted: this.form.value.warehouse_deleted || false,
        };

        this.data.upsertWarehouse(dto).subscribe({
            next: warehouse => {
                this.ref.close(warehouse);
                if (this.warehouse) {
                    this.warehouse.warehouse_name = warehouse.warehouse_name;
                    this.warehouse.warehouse_default = warehouse.warehouse_default;
                    this.warehouse.warehouse_deleted = warehouse.warehouse_deleted;
                    this.snackbar.open('Склад збережено.', 'OK');
                } else {
                    this.data.getWarehouses().subscribe({ next: warehouses => warehouses.push(warehouse) });
                    this.snackbar.open('Склад створено.', 'OK');
                }
            },
            error: () => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService, UserMoney } from '../../data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm/confirm.component';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrl: './user-dialog.component.scss'
})
export class UserDialogComponent implements OnInit {

    public loading = false;

    public form = new FormGroup({
        user_email: new FormControl('', [Validators.required, Validators.email]),
        user_name: new FormControl('', Validators.required),
        user_nick: new FormControl('', Validators.required),
        membership_role: new FormControl('', Validators.required),
        user_card_name: new FormControl('', []),
        user_card_number: new FormControl('', [Validators.pattern(/^\d{16}$/)]),
        user_iban_name: new FormControl('', []),
        user_iban_number: new FormControl('', [Validators.pattern(/^[A-Z]{2}\d{16,28}$/)]),
        membership_deleted: new FormControl(false),
    });

    public static open(dialog: MatDialog, user: UserMoney | null): Promise<UserMoney | undefined> {
        const data = { user };
        const dialogRef = dialog.open(UserDialogComponent, { data, minWidth: '400px', autoFocus: false, });

        return firstValueFrom(dialogRef.afterClosed());
    }

    constructor(
        public dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: { user: UserMoney | null },
        public readonly data: DataService,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
    ) { }

    public ngOnInit() {
        const u = this.dialogData.user;
        if (u) {
            this.form.patchValue({
                user_email: u.user_email,
                user_name: u.user_name,
                user_nick: u.user_nick,
                membership_role: u.membership_role,
                user_card_name: u.membership_card_name || u.user_card_name,
                user_card_number: u.membership_card_number || u.user_card_number,
                user_iban_name: u.membership_iban_name || u.user_iban_name,
                user_iban_number: u.membership_iban_number || u.user_iban_number,
            });
        }
    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAsTouched();
            return;
        }

        this.loading = true;
        const saveData = {
            ...this.dialogData.user,
            ...this.form.value
        } as UserMoney;

        this.data.upsertUser(saveData).subscribe({
            next: u => {
                this.loading = false;
                this.dialogRef.close(u);
                this.snackbar.open('Дані збережено!', 'OK');
            }, error: error => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public onDelete() {

        const user = this.dialogData.user;
        if (!user) return;

        ConfirmDialogComponent.open(this.dialog, { messages: ['Видалити користувача?', 'Ви зможете додати його повторно за потреби.'], yes: 'Видалити', no: 'Відміна' })
            .afterClosed().pipe(filter(r => !!r)).subscribe(() => {
                this.loading = true;
                const dto = { ...user, membership_deleted: true, } as UserMoney;
                this.data.upsertUser(dto).subscribe({
                    next: u => {
                        this.loading = false;
                        user.membership_deleted = true;
                        this.dialogRef.close(user);
                        this.snackbar.open('Користувача видалено!', 'OK');
                    }, error: error => {
                        this.loading = false;
                        this.snackbar.open('Сталася помилка!', 'OK');
                    }
                });
            });

    }

    get canSave(): boolean {
        return this.form.valid && this.form.dirty && !this.loading;
    }

    get user(): UserMoney | null {
        return this.dialogData.user;
    }

    get isEdit(): boolean {
        return this.user?.user_id != null;
    }
}

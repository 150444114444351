import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {PromosModule} from '../promos/promos.module';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgApexchartsModule} from 'ng-apexcharts';
import {SellersComponent} from "./sellers.component";
import {SellerCardComponent} from "./seller-card/seller-card.component";


@NgModule({
    declarations: [
        SellersComponent,
        SellerCardComponent
    ],
    exports: [
        SellersComponent,
        SellerCardComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        PromosModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgApexchartsModule,
    ]
})

export class SellersModule {
}

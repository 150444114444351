import { Component, Input } from '@angular/core';
import { DataService, ModelFull } from '../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { ModelEditDialogComponent } from '../../model-edit-dialog/model-edit-dialog.component';

@Component({
    selector: 'model-card',
    templateUrl: './model-card.component.html',
    styleUrl: './model-card.component.scss'
})
export class ModelCardComponent {

    @Input()
    public item: ModelFull | null = null;

    constructor(public readonly data: DataService, private readonly dialog: MatDialog) { }

    onEdit(event: Event) {
        event.stopPropagation();
        if (!this.item) return;
        ModelEditDialogComponent.open(this.dialog, this.item);
    }

}

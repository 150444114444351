
export enum LabelType {
    Invalid = 0,
    /** Default for D11 and similar */
    WithGaps = 1,
    Black = 2,
    Continuous = 3,
    Perforated = 4,
    Transparent = 5,
    PvcTag = 6,
    BlackMarkGap = 10,
    HeatShrinkTube = 11
}

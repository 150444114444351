<app-sidebar #sidebar>

    <div class="products-sidebar" role="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
                (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До моделей' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_sellers.length}}</span>
                    <small>/{{sellers.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div class="products-scroll">
        <div class="flex-view">

            <ng-container *ngIf="!sellers.length">
                <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
            </ng-container>

            <ng-container *ngIf="sellers.length">
                <seller-card *ngFor="let m of filtered_sellers" [item]="m"></seller-card>
            </ng-container>

        </div>
    </div>

    <div class="products-empty-message" *ngIf="sellers.length && !filtered_sellers.length">
        Жодний продавець не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-buttons">

        <button mat-fab extended color="accent" *ngIf="sellers.length && (layout.is_mobile | async)"
                (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_sellers.length}}</b><small>/{{sellers.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <app-promo-button></app-promo-button>

        <button mat-fab *ngIf="data.superadmin||data.sdua" (click)="addNew()">
            <mat-icon>add</mat-icon>
        </button>

    </div>

</app-sidebar>

<div mat-dialog-title>
    Увага!
</div>

<mat-dialog-content class="mat-typography">
    <p>
        Максимальна ціна за одиницю товару по даному запиту:
        <b class="price">{{ request.request_max_price | currency }}</b>.
    </p>
    <p>
        Товари, ціна яких більша за максимальну, з врахуванням всіх скидок,
        промокодів, та пропозицій SuperDeals <b>купувати НЕ ПОТРІБНО!</b>
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <mat-checkbox [formControl]="control_dont_show">Більше не показувати</mat-checkbox>
    <div class="spacer"></div>
    <button mat-button (click)="close()">OK</button>
</mat-dialog-actions>
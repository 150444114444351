import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CexRates, DataService, OrderCreateDto, OrderRequest, ProductFull } from '../data.service';
import { dateToLocalDate, dateToLocalTime, stringsToDate } from '../utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportDoneDialogComponent } from './report-done-dialog/report-done-dialog.component';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface ReportData {
    product_id: number;
    request_id: number;
}

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrl: './report.component.scss',
})
export class ReportComponent {

    public quantity_hint = '';

    public loading = false;
    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public static open(dialog: MatDialog, data: ReportData) {
        return dialog.open(ReportComponent, { data, minWidth: '300px', maxWidth: '500px', autoFocus: false, panelClass: 'mobile-fullscreen' })
    }

    public old_order_amount_uah = '';
    public old_order_amount_usd = '';

    public form = new FormGroup({
        order_quantity: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
        order_amount_usd: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_amount_uah: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_delivery_usd: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_delivery_uah: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        order_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        order_number: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
        order_comment: new FormControl('', []),
        order_donate: new FormControl(false, []),
        order_donate_amount_uah: new FormControl('0.00', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_recipient: new FormControl('', []),
        order_cex: new FormControl(String(this.data.usd_uah_rate), [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_cex_src: new FormControl<'custom_usd' | 'mono_usd' | 'privat_usd'>('custom_usd', []),
    });


    public product: ProductFull | null = null;
    public request: OrderRequest | null = null;

    public recepients: string[] = [];
    public filtered_recipients: string[] = [];

    private updateRecipients() {
        const value = this.form.controls.order_recipient.value?.trim()?.toLowerCase() || '';
        if (!value) this.filtered_recipients = this.recepients;
        else this.filtered_recipients = this.recepients.filter(r => r.toLowerCase().startsWith(value));
    }

    public cex: CexRates | null = null;

    constructor(public readonly data: DataService,
        public dialogRef: MatDialogRef<ReportComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ReportData,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly snackbar: MatSnackBar,
    ) {

        this.data.getCex().subscribe({
            next: cex => {
                this.cex = cex;
                this.form.controls.order_cex_src.setValue('mono_usd', { emitEvent: true });
            }
        });

        this.form.controls.order_cex_src.valueChanges.subscribe(src => {
            if (this.cex && src)
                this.form.controls.order_cex.setValue(String(this.cex[src]), { emitEvent: true });
        });

        const updateDonate = () => {
            setTimeout(() => {
                const value = this.form.value;
                const order_donate = value.order_donate;

                if (order_donate) {

                    const order_amount_uah = Number(value.order_amount_uah);
                    const order_delivery_uah = Number(value.order_delivery_uah);
                    const order_donate_amount_uah = order_amount_uah + order_delivery_uah;

                    if (isNaN(order_donate_amount_uah)) {
                        this.form.controls['order_donate_amount_uah'].setValue('0.00');
                    } else {
                        this.form.controls['order_donate_amount_uah'].setValue(order_donate_amount_uah.toFixed(2));
                    }

                } else {
                    this.form.controls['order_donate_amount_uah'].setValue('0.00');
                }
            });
        };

        this.form.controls['order_amount_uah'].valueChanges.subscribe(updateDonate);
        this.form.controls['order_delivery_uah'].valueChanges.subscribe(updateDonate);
        this.form.controls['order_quantity'].valueChanges.subscribe(updateDonate);
        this.form.controls['order_donate'].valueChanges.subscribe(updateDonate);

        const getCex = () => !isNaN(Number(this.form.controls.order_cex.value))
            ? Number(this.form.controls.order_cex.value) || this.data.usd_uah_rate
            : this.data.usd_uah_rate;

        this.form.controls.order_amount_uah.valueChanges.subscribe({
            next: order_amount_uah => {
                if (this.form.controls.order_cex_src.value === 'custom_usd') return;
                const old_order_amount_usd = (Number(this.old_order_amount_uah.replace(/(^\.)|(\.$)/g, '')) / getCex()).toFixed(2);
                if (old_order_amount_usd === this.form.value.order_amount_usd || !this.form.value.order_amount_usd) {
                    const order_amount_usd = Number(order_amount_uah?.replace(/(^\.)|(\.$)/g, '') || 0) / getCex();
                    if (!isNaN(order_amount_usd)) {
                        this.form.controls.order_amount_usd.setValue(order_amount_usd.toFixed(2), { emitEvent: false });
                    }
                }
                this.old_order_amount_uah = order_amount_uah || '';
            }
        });

        const updateUah = () => {
            if (this.form.controls.order_cex_src.value === 'custom_usd') {
                updateDonate();
                return;
            }
            const order_amount_usd = this.form.controls.order_amount_usd.value;
            const order_amount_uah = Number(order_amount_usd?.replace(/(^\.)|(\.$)/g, '') || 0) * getCex();
            if (!isNaN(order_amount_uah) && (order_amount_usd || this.form.controls.order_amount_uah.value)) {
                this.form.controls.order_amount_uah.setValue(order_amount_uah.toFixed(2), { emitEvent: false });
            }
            this.old_order_amount_usd = order_amount_usd || '';
            updateDonate();
        };

        this.form.controls.order_cex.valueChanges.subscribe({ next: updateUah });
        this.form.controls.order_amount_usd.valueChanges.subscribe({ next: updateUah });

        this.form.controls.order_recipient.valueChanges.subscribe(() => this.updateRecipients());

        const value = this.router.getCurrentNavigation()?.extras.state;

        if (value) {
            delete value['order_date'];
            delete value['order_time'];
            delete value['order_number'];
            this.form.patchValue(value);
        }

        const $orders = this.data.admin ? this.data.getAllOrders() : this.data.getMyOrders();

        $orders.subscribe({
            next: orders => {
                this.recepients = orders
                    .map(o => o.order_recipient?.trim()).filter((v, i, s) => v && s.indexOf(v) === i);
                this.updateRecipients();
            }
        });

        this.data.getRequests().subscribe({
            next: requests => {

                this.request = requests.find(r => dialogData.request_id === r.request_id) || null;

                if (!this.request) {
                    this.data.showMessage('Запит не знайдено.');
                    this.dialogRef.close();
                    return;
                }

            }
        });

        this.data.getFullProducts().subscribe({
            next: products => {

                if (this.dialogData.product_id === 0) {
                    this.product = {
                        ...this.request,
                        sku_quantity: 1,
                        sku_last_price: this.request?.request_max_price || 0,
                        item_shipping_price: 0
                    } as any;
                } else {
                    this.product = products.find(p => dialogData.product_id === p.product_id) || null;
                }

                if (!this.product) {
                    this.data.showMessage('Продукт не знайдено.');
                    this.dialogRef.close();
                    return;
                }

                this.quantity_hint = ({
                    'stack': 'Одна одиниця в замовленні - один стек',
                    'frame': 'Одна одиниця в замовленні - одна рама',
                    'camera': 'Одна одиниця в замовленні - одна камера',
                    'antenna': 'Одна одиниця в замовленні - одна антена',
                    'rx': 'Одна одиниця в замовленні - один RX',
                    'vtx': 'Одна одиниця в замовленні - один VTX',
                    'motors': 'Одна одиниця в замовленні - 4 мотори',
                    'props': 'Одиниця - це комплект (4 пропелера, або 2 пари)',
                } as Record<string, string>)[this.product.detail_name.toLowerCase()] || '';

                const order_delivery_price_usd = this.product.item_shipping_price.toFixed(2);
                const order_delivery_price_uah = (this.product.item_shipping_price * this.data.usd_uah_rate).toFixed(2);

                this.form.controls.order_delivery_usd.setValue(order_delivery_price_usd);
                this.form.controls.order_delivery_uah.setValue(order_delivery_price_uah);

            }
        });

    }

    public send() {

        const report_product = this.product;
        const report_request = this.request;

        if (!this.form.valid || !report_product || !report_request || this.loading) {
            this.snackbar.open('Форма заповнена некоректно', 'OK');
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        const dto: OrderCreateDto = {
            product_id: report_product.product_id || null,
            request_id: report_request.request_id,
            order_date: stringsToDate(value.order_date!, value.order_time!).getTime() / 1000,
            order_quantity: Number(value.order_quantity),
            order_amount_usd: Number(value.order_amount_usd),
            order_amount_uah: Number(value.order_amount_uah),
            order_delivery_usd: Number(value.order_delivery_usd),
            order_delivery_uah: Number(value.order_delivery_uah),
            order_number: value.order_number!,
            order_recipient: value.order_recipient || '',
            order_comment: value.order_comment || '',
            order_donate_amount_uah: value.order_donate ? Number(value.order_donate_amount_uah) : 0,
        };

        this.data.createOrder(dto).subscribe({
            next: order => {
                report_request.request_quantity -= Number(value.order_quantity);
                this.loading = false;
                this.data.setFilters('orders', null);
                ReportDoneDialogComponent.open(this.dialog, { order, form_value: this.form.value });
                this.dialogRef.close();
            },
            error: error => {
                this.loading = false;
                console.log(error);
                this.snackbar.open('Сталася помилка!', 'OK');
            },
        })
    }

    public get order_quantity() {
        const order_quantity = Number(this.form.controls.order_quantity.value);
        return isNaN(order_quantity) ? 0 : order_quantity;
    }

    public async paste(control: keyof typeof this.form.controls, event: Event) {
        event.stopPropagation();
        const text = await navigator.clipboard.readText() || '';
        this.form.controls[control].setValue(text.trim() as any);
        this.form.controls[control].markAsTouched();
    }

    public onSave() {
        this.send();
    }

    public setQuantity(q: number, event: Event) {
        event.stopPropagation();
        if (!this.product) return;
        this.form.controls.order_quantity.setValue(String(q));
        if (this.product.sku_full_sd_price) {
            this.form.controls.order_amount_usd.setValue((q * this.product.sku_full_sd_unit_price).toFixed(2));
        } else {
            this.form.controls.order_amount_usd.setValue((q * this.product.sku_full_unit_price).toFixed(2));
        }
    }

}

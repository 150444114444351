import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentFull, DataService, Detail, ModelFull, ProductFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { ModelEditDialogComponent } from '../model-edit-dialog/model-edit-dialog.component';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexDataLabels,
    ApexStroke,
    ApexYAxis,
    ApexMarkers,
    ApexFill,
    ApexForecastDataPoints,
    ApexLegend
} from "ng-apexcharts";
import { MOTORS, Motor } from '../motors';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    stroke: ApexStroke;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    title: ApexTitleSubtitle;
    markers: ApexMarkers;
    colors: string[];
    fill: ApexFill;
    forecastDataPoints: ApexForecastDataPoints;
    legend: ApexLegend;
};

// TODO: currency
import { environment } from './../../environments/environment';

export interface ModelDialogData {
    model_id: number;
    model?: ModelFull | null;
    extra?: string;
}

@Component({
    selector: 'app-model',
    templateUrl: './model.component.html',
    styleUrl: './model.component.scss'
})
export class ModelDialogComponent implements OnInit {
    public details: Detail[] = [];
    public models: ModelFull[] = [];
    public products: ProductFull[] = [];

    public model: ModelFull | null;
    public products_data_source?: MatTableDataSource<ProductFull>;

    @ViewChild(MatSort) set sort(sort: MatSort) {
        if (sort && this.products_data_source) {
            this.products_data_source.sort = sort;
            sort.sort({
                id: 'price',
                start: 'asc',
                disableClear: false
            });
        }
    }

    @ViewChild("chart") chart?: ChartComponent;
    public chartOptions?: ChartOptions;

    public static open(dialog: MatDialog, data: ModelDialogData) {
        return dialog.open(ModelDialogComponent, { data, width: '800px', autoFocus: false, restoreFocus: false, panelClass: 'mobile-fullscreen', });
    }

    public readonly products_columns = ['seller_name', 'sku_full_unit_price', 'sku_last_available', 'urls'];

    public motor: Motor | null = null;
    public comments: CommentFull[] | null = null;
    public comments_swarm: CommentFull[] | null = null;

    constructor(
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        public dialogRef: MatDialogRef<ModelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ModelDialogData,
        public readonly sanitizer: DomSanitizer,
        public readonly layout: LayoutService,
        private readonly snackbar: MatSnackBar,
    ) {
        this.model = dialogData.model || null;
    }

    public control_comment_text = new FormControl<string>('');
    public comment_loading = false;
    public comment_edited: CommentFull | null = null;

    public commentEdit(comment: CommentFull) {
        this.comment_edited = comment;
        this.control_comment_text.setValue(comment.comment_text);
    }

    public clearComment() {
        this.comment_edited = null;
        this.control_comment_text.setValue('');
    }

    public commentPin(comment_to_pin: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_pin,
            comment_pinned: comment_to_pin.comment_pinned ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_pin, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public commentDelete(comment_to_delete: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_delete,
            comment_deleted: comment_to_delete.comment_deleted ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_delete, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public upsertComment() {

        const model = this.model;

        const comment_text = this.control_comment_text.value;

        if (!model) return;

        if (!comment_text || comment_text.length < 10) {
            this.snackbar.open('Введіть текст коментаря', 'OK');
            return;
        }

        if (comment_text.length >= 4096) {
            this.snackbar.open('Коментар занадто довний', 'OK');
            return;
        }

        if (comment_text.includes('<script') || comment_text.includes('<link') || comment_text.includes('<object')) {
            this.snackbar.open('Некоректний коментар', 'OK');
            return;
        }

        this.comment_loading = true;

        this.data.upsertComment({
            comment_id: this.comment_edited?.comment_id || null,
            model_id: model.model_id,
            drone_id: null,
            seller_id: null,
            product_id: null,
            comment_text: comment_text,
            comment_pinned: this.comment_edited?.comment_pinned ?? 0,
            comment_deleted: this.comment_edited?.comment_deleted ?? 0,
        }).subscribe({
            next: comment => {

                this.comment_loading = false;

                if (this.comment_edited) {
                    Object.assign(this.comment_edited, comment);
                    this.comment_edited = null;
                } else {
                    this.comments?.push(comment);
                }

                this.sortComments();

                this.control_comment_text.setValue('');

            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    private sortComments() {
        this.comments?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }

    private sortCommentsSWARM() {
        this.comments_swarm?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }


    ngOnInit(): void {

        this.data.getDetails().subscribe(details => this.details = details);

        this.data.getComments({ model_id: this.dialogData.model_id })
            .subscribe({
                next: comments => {
                    this.comments = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortComments();
                }
            });

        this.data.getCommentsSWARM({ model_id: this.dialogData.model_id })
            .subscribe({
                next: comments => {
                    this.comments_swarm = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortCommentsSWARM();
                }
            });

        this.data.getModels().subscribe(models => {

            this.models = models;

            if (!this.model) this.model = models.find(m => m.model_id === this.dialogData.model_id) || null;

            this.data.getFullProducts().subscribe({

                next: response => {
                    this.products = response
                        .filter(p => p.model_id === this.model?.model_id && !p.product_deleted)
                        .sort((a, b) => a.sku_last_available && !b.sku_last_available ? -1 : b.sku_last_available && !a.sku_last_available ? 1 : a.sku_min_unit_price_usd - b.sku_min_unit_price_usd);

                    this.products_data_source = new MatTableDataSource(this.products);
                }
            });

            if (!this.model) return;

            if (this.dialogData.extra === 'motor-tests') {
                this.motor = MOTORS.find(m => m.model_id === this.model?.model_id) || null;
            }

            this.data.getModelUpdates(this.model.model_id).subscribe({
                next: updates => {

                    let min_price = Math.min(
                        updates.map(u => u.price_min).reduce((a, c) => c < a ? c : a, Number.POSITIVE_INFINITY),
                        updates.map(u => u.price_sd_min || Number.POSITIVE_INFINITY).reduce((a, c) => c ? c < a ? c : a : a, Number.POSITIVE_INFINITY),
                    );

                    let max_price = Math.min(
                        updates.map(u => u.price_max).reduce((a, c) => c > a ? c : a, Number.NEGATIVE_INFINITY),
                        updates.map(u => u.price_sd_max || Number.POSITIVE_INFINITY).reduce((a, c) => c ? c > a ? c : a : a, Number.NEGATIVE_INFINITY),
                    );

                    min_price = Math.floor(Math.max(0, min_price - min_price * 0.1));
                    max_price = Math.ceil(max_price + max_price * 0.1);

                    const offset = - new Date().getTimezoneOffset() * 60 * 1000;

                    updates.forEach(u => u.update_date = u.update_date.substring(0, 10));

                    this.chartOptions = {
                        xaxis: {
                            type: "datetime"
                        },
                        yaxis: [
                            {
                                min: min_price,
                                max: max_price,
                                decimalsInFloat: 2,
                            },
                        ],
                        series: [
                            {
                                decimalsInFloat: 2,
                                type: "rangeArea",
                                name: "Звичайна ціна",
                                data: updates.map(u => ({ x: u.update_date, y: [u.price_min.toFixed(2), u.price_max.toFixed(2)] }))
                            },
                            {
                                decimalsInFloat: 2,
                                type: "rangeArea",
                                name: "Ціна SuperDeals",
                                data: updates.map(u => ({ x: u.update_date, y: [u.price_sd_min?.toFixed(2) || null, u.price_sd_max?.toFixed(2) || null] }))
                            },
                            {
                                decimalsInFloat: 2,
                                type: "line",
                                name: "Середня ціна",
                                data: updates.map(u => ({ x: u.update_date, y: u.price_avg.toFixed(2) }))
                            },
                            {
                                decimalsInFloat: 2,
                                type: "line",
                                name: "Середнє SuperDeals",
                                data: updates.map(u => ({ x: u.update_date, y: u.price_sd_avg?.toFixed(2) || null }))
                            }
                        ],
                        chart: {
                            height: 256,
                            type: "rangeArea",
                            animations: {
                                speed: 100
                            },
                            toolbar: {
                                tools: {
                                    download: false,
                                    selection: true,
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: true,
                                    customIcons: []
                                },
                            }
                        },
                        colors: ["#333333", "#fd384f", "#333333", "#fd384f"],
                        dataLabels: {
                            enabled: false
                        },
                        fill: {
                            opacity: [0.3, 0.3, 1, 1]
                        },
                        stroke: {
                            curve: "straight",
                            width: [0, 0, 2, 2]
                        },
                        legend: {
                            show: true,
                            customLegendItems: ["Ціна", "SuperDeals"],
                            horizontalAlign: "center",
                            position: 'top',
                        },
                        markers: {
                            hover: {
                                sizeOffset: 5
                            }
                        }
                    } as any;

                    // this.chartOptions = {
                    //     title: {},
                    //     series: [
                    //         {
                    //             name: "Ціна",
                    //             type: "rangeArea",
                    //             data: updates.map(u => ({ x: u.update_date, y: [u.price_min, u.price_max] })),
                    //             color: '#333',
                    //         },
                    //         // {
                    //         //     name: "SuperDeals",
                    //         //     type: "rangeArea",
                    //         //     data: updates.map(u => [u.price_sd_min || null, u.price_sd_max || null]) as any,
                    //         //     color: '#fd384f',
                    //         // },
                    //         // {
                    //         //     name: "Наявність",
                    //         //     type: "area",
                    //         //     data: updates.map(u => u.sku_available),
                    //         //     color: '#5be96c',
                    //         // },
                    //     ],
                    //     chart: {
                    //         height: 256,
                    //         type: "line",
                    //         stacked: false,
                    //         toolbar: {
                    //             tools: {
                    //                 download: false,
                    //                 selection: true,
                    //                 zoom: false,
                    //                 zoomin: false,
                    //                 zoomout: false,
                    //                 pan: true,
                    //                 customIcons: []
                    //             },
                    //         }
                    //     },
                    //     dataLabels: {
                    //         enabled: false
                    //     },
                    //     stroke: {
                    //         width: [2, 4, 2]
                    //     },
                    //     xaxis: {
                    //         type: "datetime"
                    //     },
                    //     // labels: updates.map(u => u.update_date),
                    //     yaxis: [
                    //         {
                    //             seriesName: 'Ціна',
                    //             min: min_price,
                    //             max: max_price,
                    //             decimalsInFloat: 2,
                    //             axisTicks: {
                    //                 show: true
                    //             },
                    //             axisBorder: {
                    //                 show: true,
                    //                 color: "#333"
                    //             },
                    //             labels: {
                    //                 style: {
                    //                     colors: "#333"
                    //                 }
                    //             },
                    //             title: {
                    //                 text: "Ціна, $",
                    //                 style: {
                    //                     color: "#333"
                    //                 }
                    //             },
                    //             tooltip: {
                    //                 enabled: true,
                    //             }
                    //         },
                    //         // {
                    //         //     min: min_price,
                    //         //     max: max_price,
                    //         //     seriesName: 'SuperDeals',
                    //         //     show: false,
                    //         // },
                    //         // {
                    //         //     seriesName: 'Наявність',
                    //         //     min: Math.max(0, min_available - min_available * 0.1),
                    //         //     decimalsInFloat: 0,
                    //         //     opposite: true,
                    //         //     axisTicks: {
                    //         //         show: true
                    //         //     },
                    //         //     axisBorder: {
                    //         //         show: true,
                    //         //         color: "#5be96c"
                    //         //     },
                    //         //     labels: {
                    //         //         style: {
                    //         //             colors: "#5be96c"
                    //         //         }
                    //         //     },
                    //         //     title: {
                    //         //         text: "Наявність, шт",
                    //         //         style: {
                    //         //             color: "#5be96c"
                    //         //         }
                    //         //     }
                    //         // }
                    //     ],
                    //     tooltip: {
                    //         fixed: {
                    //             enabled: true,
                    //             position: "topLeft",
                    //             offsetY: 30,
                    //             offsetX: 60
                    //         },
                    //         y: [
                    //             {
                    //                 formatter: (y: number) => y === null ? '' : '$' + y.toFixed(2),
                    //             },
                    //             // {
                    //             //     formatter: (y: number) => y === null ? null as any as string : '$' + y.toFixed(2),
                    //             // },
                    //             // {
                    //             //     formatter: (y: number) => y === null ? '' : y.toString(),
                    //             // }
                    //         ]
                    //     },
                    //     legend: {
                    //         show: true,
                    //         horizontalAlign: "center",
                    //         position: 'top',
                    //         offsetX: 40,
                    //     },
                    // };

                }
            });

        });

    }

    public readonly domainName = environment.domainName;

    public edit() {
        if (!this.model) return;
        ModelEditDialogComponent.open(this.dialog, this.model);
    }
}

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {

            if (typeof value === 'string') {
                value = Date.parse(value);
            }

            const seconds = Math.floor((+new Date() - +new Date(value * 1000)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'щойно';
            const intervals: { [key: string]: number; } = {
                'р': 31536000,
                'міс': 2592000,
                'тиж': 604800,
                'дн': 86400,
                'год': 3600,
                'хв': 60,
                'с': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' тому'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + ' тому'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}

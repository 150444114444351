import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CexRates, DataService, Detail, Model, ModelFull, StockResponse, Warehouse } from '../../data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dateToLocalDate, dateToLocalTime, stringsToDate } from '../../utils';
import { AlertDialogComponent } from '../../shared/dialogs/alert/alert.component';

type DetailGroup = Detail & { models: Model[] };

interface WarehousesData {
    warehouses: Warehouse[];
}

@Component({
    selector: 'app-add-stock-dialog',
    templateUrl: './add-stock-dialog.component.html',
    styleUrl: './add-stock-dialog.component.scss'
})
export class AddStockDialogComponent {

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public details: DetailGroup[] = [];
    public models: ModelFull[] = [];

    public model: ModelFull | null = null;

    public static open(dialog: MatDialog, data: WarehousesData) {
        return dialog.open<AddStockDialogComponent, WarehousesData, StockResponse>(AddStockDialogComponent, { data: data, autoFocus: 'input', width: '680px' });
    }

    public loading = false;

    public form = new FormGroup({
        model_id: new FormControl<number | undefined>(undefined),
        dst_warehouse_id: new FormControl<number | undefined>(undefined, Validators.required),
        transfer_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        transfer_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        transfer_quantity: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        transfer_comment: new FormControl(''),
        order_amount_usd: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_amount_uah: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_cex: new FormControl(String(this.data.usd_uah_rate), [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_cex_src: new FormControl<'custom_usd' | 'mono_usd' | 'privat_usd'>('custom_usd', []),
    });

    public cex: CexRates | null = null;
    public old_order_amount_uah = '';
    public old_order_amount_usd = '';

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<AddStockDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly warehouses_data: WarehousesData,
    ) {

        this.data.getCex().subscribe({
            next: cex => {
                this.cex = cex;
                this.form.controls.order_cex_src.setValue('mono_usd', { emitEvent: true });
            }
        });

        this.form.controls.order_cex_src.valueChanges.subscribe(src => {
            if (this.cex && src)
                this.form.controls.order_cex.setValue(String(this.cex[src]), { emitEvent: true });
        });


        const getCex = () => !isNaN(Number(this.form.controls.order_cex.value))
            ? Number(this.form.controls.order_cex.value) || this.data.usd_uah_rate
            : this.data.usd_uah_rate;

        this.form.controls.order_amount_uah.valueChanges.subscribe({
            next: order_amount_uah => {
                if (this.form.controls.order_cex_src.value === 'custom_usd') return;
                const old_order_amount_usd = (Number(this.old_order_amount_uah.replace(/(^\.)|(\.$)/g, '')) / getCex()).toFixed(2);
                if (old_order_amount_usd === this.form.value.order_amount_usd || !this.form.value.order_amount_usd) {
                    const order_amount_usd = Number(order_amount_uah?.replace(/(^\.)|(\.$)/g, '') || 0) / getCex();
                    if (!isNaN(order_amount_usd)) {
                        this.form.controls.order_amount_usd.setValue(order_amount_usd.toFixed(2), { emitEvent: false });
                    }
                }
                this.old_order_amount_uah = order_amount_uah || '';
            }
        });

        const updateUah = () => {
            if (this.form.controls.order_cex_src.value === 'custom_usd') {
                return;
            }
            const order_amount_usd = this.form.controls.order_amount_usd.value;
            const order_amount_uah = Number(order_amount_usd?.replace(/(^\.)|(\.$)/g, '') || 0) * getCex();
            if (!isNaN(order_amount_uah) && (order_amount_usd || this.form.controls.order_amount_uah.value)) {
                this.form.controls.order_amount_uah.setValue(order_amount_uah.toFixed(2), { emitEvent: false });
            }
            this.old_order_amount_usd = order_amount_usd || '';
        };

        this.form.controls.order_cex.valueChanges.subscribe({ next: updateUah });
        this.form.controls.order_amount_usd.valueChanges.subscribe({ next: updateUah });

        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                })
            }
        });

        this.form.controls.model_id.valueChanges
            .subscribe(id => this.model = this.models.find(m => m.model_id === id) || null);

    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        this.data.transfer([{
            drone_id: null,
            model_id: value.model_id!,
            transfer_quantity: Number(value.transfer_quantity),
            src_warehouse_id: null,
            dst_warehouse_id: value.dst_warehouse_id!,
            transfer_comment: value.transfer_comment || '',
            transfer_date: stringsToDate(value.transfer_date!, value.transfer_time!).getTime() / 1000,
            transfer_amount_usd: Number(value.order_amount_usd),
            transfer_amount_uah: Number(value.order_amount_uah),
        }]).subscribe({
            next: stock => {
                this.ref.close(stock);
                AlertDialogComponent.open(this.dialog, {
                    title: "Готово!",
                    messages: ['Товари внесено на склад.']
                });
            }
        });

    }
}
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService, Membership } from '../../data.service';
import { UpsertTenantDialogComponent } from '../../dialogs/upsert-tenant-dialog/upsert-tenant-dialog.component';

@Component({
  selector: 'app-tenant-menu',
  templateUrl: './tenant-menu.component.html',
  styleUrl: './tenant-menu.component.scss'
})
export class TenantMenuComponent {

  constructor(public readonly data: DataService, private readonly dialog: MatDialog) { }

  public upsertTenant(membership: Membership | null = null) {
    UpsertTenantDialogComponent.open(this.dialog, membership);
  }
}

<app-sidebar #sidebar>

    <div class="products-sidebar" role="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До продуктів' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_products.length}}</span>
                    <small>/{{products.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div class="products-scroll">

        <div class="request-info" *ngIf="request">
            <a mat-fab [extended]="layout.is_desktop | async" routerLink="/requests">
                <mat-icon>chevron_left</mat-icon>
                <span *ngIf="layout.is_desktop | async">До запитів</span>
            </a>
            <div class="request-info-title">
                <img [src]="request.model_image_url" />
                <div>{{request.model_name}}</div>
            </div>
            <div class="request-info-details">
                <div class="request-info-detail">
                    <div class="request-info-detail-label">Потрібно закупити</div>
                    <div class="request-info-detail-value">{{request.request_quantity}}</div>
                </div>
                <div class="request-info-detail">
                    <div class="request-info-detail-label">Максимальна ціна</div>
                    <div class="request-info-detail-value">{{request.request_max_price | currency}}</div>
                </div>
            </div>
        </div>

        <div class="flex-view">

            <ng-container *ngIf="!products.length">
                <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
            </ng-container>

            <ng-container *ngIf="products.length">
                <product-card *ngFor="let p of filtered_products" [product]="p">
                    <a [queryParams]="{view:'report',request_id:request?.request_id,product_id:p.product_id}"
                        mat-flat-button color="accent" class="report-order-button" [routerLink]="[]"
                        (click)="$event.stopPropagation()">
                        Внести покупку
                    </a>
                </product-card>
            </ng-container>

        </div>
    </div>

    <div class="products-empty-message" *ngIf="products.length && !filtered_products.length">
        Жоден продукт не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-buttons">

        <button mat-fab extended color="accent" *ngIf="products.length && (layout.is_mobile | async)"
            (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_products.length}}</b><small>/{{products.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <app-promo-button></app-promo-button>

        <a mat-fab [extended]="layout.is_desktop | async" color="accent" [routerLink]="[]"
            [queryParams]="{view:'report',request_id:request?.request_id,product_id:0}">
            <mat-icon>add</mat-icon>
            <span *ngIf="layout.is_desktop | async">Поза AliExpress</span>
        </a>

    </div>

</app-sidebar>

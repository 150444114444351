import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService, ProductFull } from '../../data.service';

@Component({
    selector: 'app-affiliates-dialog',
    templateUrl: './affiliates-dialog.component.html',
    styleUrl: './affiliates-dialog.component.scss'
})
export class AffiliatesDialogComponent {

    public static open(dialog: MatDialog, data: ProductFull) {
        return dialog.open<AffiliatesDialogComponent, ProductFull, any>(AffiliatesDialogComponent, { data: data, autoFocus: 'input', width: '680px' });
    }

    public loading = false;

    public form = new FormGroup({
        product_url: new FormControl('', [Validators.pattern(/^https:\/\/s\.click\.aliexpress\.com\/e\/\w{8}$/)]),
        product_sd_url: new FormControl('', [Validators.pattern(/^https:\/\/s\.click\.aliexpress\.com\/e\/\w{8}$/)]),
        product_lo_url: new FormControl('', [Validators.pattern(/^https:\/\/s\.click\.aliexpress\.com\/e\/\w{8}$/)]),
        product_cd_url: new FormControl('', [Validators.pattern(/^https:\/\/s\.click\.aliexpress\.com\/e\/\w{8}$/)]),
    });

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<AffiliatesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly product: ProductFull,
    ) {
        this.form.patchValue({
            product_url: product.sku_url.includes('s.aliexpress.com') ? product.sku_url : '',
            product_sd_url: product.sku_sd_url.includes('s.aliexpress.com') ? product.sku_sd_url : '',
            product_lo_url: product.sku_lo_url.includes('s.aliexpress.com') ? product.sku_lo_url : '',
            product_cd_url: product.sku_cd_url.includes('s.aliexpress.com') ? product.sku_cd_url : '',
        });
    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        this.data.upsertAffiliate({
            product_id: this.product.product_id,
            product_url: value.product_url || '',
            product_sd_url: value.product_sd_url || '',
            product_lo_url: value.product_lo_url || '',
            product_cd_url: value.product_cd_url || '',
        }).subscribe({
            next: () => {
                this.ref.close();
                this.snackbar.open('Посилання збережені.')
            }
        });

    }
}

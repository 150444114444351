<div mat-dialog-title style="border-bottom: 1px solid #ddd;" class="flex">
    <div class="flex">
        <span>Користувачі</span>
        <div class="spacer"></div>
        <input (input)="applyFilter($event)" placeholder="Пошук..."
            style="line-height: 24px;border:1px solid #ddd;padding: 0 8px;border-radius: 4px;outline: 0;" />
    </div>
</div>

<mat-dialog-content class="mat-typography" style="max-height: calc(100vh - 96px);padding: 0;">

    <table mat-table [dataSource]="data_source" matSort>

        <ng-container matColumnDef="user_id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.user_id}} </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> Юзер </th>
            <td mat-cell *matCellDef="let row">
                <div class="product-extra product-seller">
                    <img class="product-seller-logo" [src]="row.user_image_url" />
                    <span class="product-seller-name">{{row.user_name}} ({{row.user_email}})</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="tenants">
            <th mat-header-cell *matHeaderCellDef> Організації </th>
            <td mat-cell *matCellDef="let row"> {{row.tenants}} </td>
        </ng-container>

        <ng-container matColumnDef="impersonate">
            <th mat-header-cell *matHeaderCellDef> Увійти </th>
            <td mat-cell *matCellDef="let row">
                <button mat-flat-button color="primary" (click)="impersonate(row)"><mat-icon>login</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayd_columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayd_columns;"></tr>
    </table>

</mat-dialog-content>
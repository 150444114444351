import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Notification, Rule, RuleDto } from "./api.models";
import { OAUTH_JWT } from "./consts";

const JSON_HEADER = { 'content-type': 'application/json' };

@Injectable()
export class ApiService {

    constructor(private readonly http: HttpClient) { }

    private get tenant_id() {
        const tenant_id = localStorage.getItem('tenant_id');
        if (!tenant_id) return null;
        if (isNaN(Number(tenant_id))) return null;
        return Number(tenant_id);
    }

    private getOptions(headers: {} = {}) {
        const jwt = localStorage.getItem(OAUTH_JWT) || '';
        if (jwt) {
            return {
                headers: {
                    ...headers,
                    tenant: String(this.tenant_id),
                    authorization: 'Bearer ' + jwt,
                },
            };
        } else {
            return {};
        }
    }

    public notificationsList() {
        const url = `${environment.apiUrl}/notifications/list`;
        return this.http.get<Notification[]>(`${url}`, this.getOptions());
    }

    public notificationsRead() {
        const url = `${environment.apiUrl}/notifications/read`;
        return this.http.post<any>(`${url}`, null, this.getOptions());
    }

    public rulesList() {
        const url = `${environment.apiUrl}/rules/list`;
        return this.http.get<Rule[]>(`${url}`, this.getOptions());
    }

    public rulesUpsert(dto: RuleDto) {
        const url = `${environment.apiUrl}/rules/upsert`;
        return this.http.post<Rule>(`${url}`, dto, this.getOptions(JSON_HEADER));
    }

}

<div mat-dialog-title>
    Редагувати посилання на товар
</div>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">

        <p>
            В даному вікні можна редагувати афіліейтні посилання, що будуть показуватися всім учасникам вашої
            організації. Товари, для яких афіліейтні посилання не вказані, будуть мати стандартні посилання
            (неафіліейтні).
        </p>

        <mat-form-field>
            <mat-label>Звичайне посилання</mat-label>
            <input matInput placeholder="https://s.click.aliexpress.com/e/_on5tnEN" formControlName="product_url">
            <mat-error>Введіть коректне посилання</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання SuperDeals</mat-label>
            <input matInput placeholder="https://s.click.aliexpress.com/e/_on5tnEN" formControlName="product_sd_url">
            <mat-error>Введіть коректне посилання</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання LimitedDeal</mat-label>
            <input matInput placeholder="https://s.click.aliexpress.com/e/_on5tnEN" formControlName="product_lo_url">
            <mat-error>Введіть коректне посилання</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання CoinsDeals</mat-label>
            <input matInput placeholder="https://s.click.aliexpress.com/e/_on5tnEN" formControlName="product_cd_url">
            <mat-error>Введіть коректне посилання</mat-error>
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AccountButtonComponent } from './account-button/account-button.component';
import { ProfileDialogComponent } from './profile-dialog/profile-dialog.component';
import { TelegramButtonComponent } from './telegram-button/telegram-button.component';

@NgModule({
    declarations: [
        ProfileDialogComponent,
        AccountButtonComponent,
        TelegramButtonComponent,
    ],
    exports: [
        ProfileDialogComponent,
        AccountButtonComponent,
        TelegramButtonComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ]
})
export class ProfileModule { }

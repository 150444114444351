<mat-dialog-content class="mat-typography" *ngIf="seller">

    <div class="product-info-wrapper">

        <div class="spacer">

            <seller-chip [data]="seller"></seller-chip>

            <div class="product-seller-grid">

                <div class="product-seller-attr" *ngIf="seller.seller_source=='aliexpress'">
                    <div>Позитивних відгуків</div>
                    <b>{{(seller.seller_positive_rate||0).toFixed()}}<small>%</small></b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_source=='aliexpress'">
                    <div>Підписники</div>
                    <b>{{seller.seller_followers}}</b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_buyers">
                    <div>Покупці</div>
                    <b>{{seller.seller_buyers}}</b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_sold">
                    <div>Продано магазином</div>
                    <b>{{seller.seller_sold}}</b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_rating">
                    <div>Рейтинг</div>
                    <b>{{seller.seller_rating.toFixed(1)}}<small>/5</small></b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_communication">
                    <div>Комунікація</div>
                    <b>{{seller.seller_communication.toFixed(1)}}<small>/5</small></b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_match">
                    <div>Згідно опису</div>
                    <b>{{seller.seller_match.toFixed(1)}}<small>/5</small></b>
                </div>

                <div class="product-seller-attr" *ngIf="seller.seller_open_time">
                    <div>Засновано</div>
                    <b>{{timestampToLocalDate(seller.seller_open_time)}}</b>
                </div>
            </div>

            <div class="seller-info">

                <div class="seller-row" *ngIf="seller.seller_company">
                    <div class="seller-row-name">Компанія</div>
                    <div>{{seller.seller_company}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_vat">
                    <div class="seller-row-name">VAT</div>
                    <div>{{seller.seller_vat}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_license">
                    <div class="seller-row-name">Ліцензія</div>
                    <div>{{seller.seller_license}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_representative">
                    <div class="seller-row-name">Представник</div>
                    <div>{{seller.seller_representative}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_established">
                    <div class="seller-row-name">Засновано</div>
                    <div>{{seller.seller_established}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_address">
                    <div class="seller-row-name">Адреса</div>
                    <div>{{seller.seller_address}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_scope">
                    <div class="seller-row-name">Сфера</div>
                    <div>{{seller.seller_scope}}</div>
                </div>

                <div class="seller-row" *ngIf="seller.seller_authority">
                    <div class="seller-row-name">Орган</div>
                    <div>{{seller.seller_authority}}</div>
                </div>

            </div>

            <div class="product-urls" style="margin-top: 16px;">

                <a mat-button color="primary" target="_blank" *ngIf="seller.seller_source=='aliexpress'"
                    [href]="'https://www.aliexpress.com/store/'+seller.seller_number" class="product-url">
                    Відкрити магазин на AliExpress
                </a>

                <a mat-button color="primary" target="_blank" *ngIf="seller.seller_source=='custom'"
                    [href]="seller.seller_url" class="product-url">
                    Відкрити магазин
                </a>

            </div>
        </div>

        <div class="control-buttons">
            <button mat-mini-fab mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="!seller">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<div class="product-comments-wrapper">

    <div class="product-comments-dummy" *ngIf="!seller || !comments">
        <div class="skeleton-block"></div>
    </div>

    <ng-container *ngIf="seller && comments">

        <div *ngIf="!comments.length && !comments_swarm?.length" class="product-comments-empty">
            Коментарі відсутні
        </div>

        <div class="comments" *ngIf="comments_swarm?.length">
            <div class="comment" *ngFor="let comment of comments_swarm" [class.pinned]="comment.comment_pinned"
                [class.deleted]="comment.comment_deleted">

                <div class="product-attrs">

                    <div class="product-extra product-seller">
                        <img class="product-seller-logo" [src]="comment.user_image_url" />
                        <span class="product-seller-name">{{comment.user_name}}</span>
                    </div>

                    <small class="product-available">
                        <b>{{comment.comment_date_string}}</b>
                    </small>

                    <small class="product-available link" *ngIf="comment.model_name" [routerLink]="[]"
                        [queryParams]="{view:'product',product_id:comment.product_id}" matRipple>
                        <b>щодо {{comment.model_name}}</b>
                    </small>

                    <small class="product-available" *ngIf="comment.seller_id">
                        <b>щодо цього мазазину</b>
                    </small>

                    <small class="product-available pinned" *ngIf="comment.comment_pinned">
                        <b>закріплено</b>
                        <mat-icon>push_pin</mat-icon>
                    </small>

                    <div class="spacer"></div>

                    <ng-container *ngIf="data.superadmin||data.profile?.user_id==comment.user_id">
                        <button mat-icon-button (click)="commentPin(comment)" *ngIf="data.superadmin">
                            <mat-icon>{{comment.comment_pinned?'highlight_off':'push_pin'}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="commentEdit(comment)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="commentDelete(comment)">
                            <mat-icon>{{comment.comment_deleted?'restore':'delete'}}</mat-icon>
                        </button>
                    </ng-container>

                </div>

                <div class="comment-text">
                    <div [innerHTML]="comment.comment_text"></div>
                </div>

            </div>
        </div>

        <div class="comments" *ngIf="comments.length">
            <div class="comment" *ngFor="let comment of comments" [class.pinned]="comment.comment_pinned"
                [class.deleted]="comment.comment_deleted">

                <div class="product-attrs">

                    <div class="product-extra product-seller">
                        <img class="product-seller-logo" [src]="comment.user_image_url" />
                        <span class="product-seller-name">{{comment.user_name}}</span>
                    </div>

                    <small class="product-available">
                        <b>{{comment.comment_date_string}}</b>
                    </small>

                    <small class="product-available link" *ngIf="comment.model_name" [routerLink]="[]"
                        [queryParams]="{view:'product',product_id:comment.product_id}" matRipple>
                        <b>щодо {{comment.model_name}}</b>
                    </small>

                    <small class="product-available" *ngIf="comment.seller_id">
                        <b>щодо цього мазазину</b>
                    </small>

                    <small class="product-available pinned" *ngIf="comment.comment_pinned">
                        <b>закріплено</b>
                        <mat-icon>push_pin</mat-icon>
                    </small>

                    <div class="spacer"></div>

                    <ng-container *ngIf="data.superadmin||data.profile?.user_id==comment.user_id">
                        <button mat-icon-button (click)="commentPin(comment)" *ngIf="data.superadmin">
                            <mat-icon>{{comment.comment_pinned?'highlight_off':'push_pin'}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="commentEdit(comment)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="commentDelete(comment)">
                            <mat-icon>{{comment.comment_deleted?'restore':'delete'}}</mat-icon>
                        </button>
                    </ng-container>

                </div>

                <div class="comment-text">
                    <div [innerHTML]="comment.comment_text"></div>
                </div>

            </div>
        </div>

        <div *ngIf="!data.profile" class="product-comments-auth">
            <a (click)="data.signin()">Авторизуйтесь</a>, щоб додати коментар
        </div>

        <div *ngIf="data.profile" class="product-comments-comment">

            <div contenteditable class="product-comments-editor" [formControl]="control_comment_text">
            </div>

            <div class="product-comments-footer">
                <button mat-flat-button color="primary" [buttonLoading]="comment_loading"
                    (click)="upsertComment()">{{comment_edited ? 'Оновити коментар' : 'Залишити коментар'}}</button>
                <button mat-button (click)="clearComment()" *ngIf="comment_edited">Відміна</button>
                <div class="spacer"></div>
                <mat-button-toggle-group [formControl]="control_comment_target">
                    <mat-button-toggle value="seller">До продавця</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

        </div>

    </ng-container>

</div>

<mat-dialog-content class="mat-typography" *ngIf="!seller">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="seller&&sellers.length">

    <h2>Інші магазини цього продавця</h2>

    <div class="product-seller-grid">
        <div *ngFor="let s of sellers">
            <seller-chip [data]="s"></seller-chip>
        </div>
    </div>

</mat-dialog-content>


<mat-dialog-content class="mat-typography" *ngIf="!seller" style="border-top:1px solid #ddd;">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="seller&&products.length" style="border-top:1px solid #ddd;">

    <h2>Найпопулярніші товари в системі</h2>

    <div class="seller-products">
        <product-card [product]="p" *ngFor="let p of products"></product-card>
    </div>

</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="seller_sdua_url" style="border-top:1px solid #ddd;">
    <div class="spacer"></div>
    <h2>Коментарі з <a [href]="'http://sdua.tech/shops/'+seller?.seller_sdua_name" target="_blank">sdua.tech/shops/{{seller?.seller_sdua_name}}</a> </h2>
    <div class="sdua_comments">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(seller_sdua_url)" name="{}" frameborder="0" allowtransparency="true" scrolling="no" tabindex="0" title="Comments | Remark42" horizontalscrolling="no" verticalscrolling="no" style="height: 1705px; width: 100%; border: medium; padding: 0px; margin: 0px; overflow: hidden; color-scheme: ;"></iframe>
    </div>
</mat-dialog-content>

import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User, Payout, UserMoney, DataService, Membership } from '../../data.service';
import { MatDialogRef } from '@angular/material/dialog';
import { timestampToLocalDate } from '../../utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-profile-dialog',
    templateUrl: './profile-dialog.component.html',
    styleUrl: './profile-dialog.component.scss'
})
export class ProfileDialogComponent {

    public user: User | null = null;
    public membership: Membership | null = null;

    public loading = false;

    public payouts: Payout[] = [];
    public money: UserMoney | null = null;
    public readonly timestampToLocalDate = timestampToLocalDate;

    constructor(
        public dialogRef: MatDialogRef<ProfileDialogComponent>,
        public readonly data: DataService,
        public readonly snackbar: MatSnackBar,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
    ) {
        this.data.getProfile(true).subscribe({
            next: profile => {
                this.payouts = profile.payouts.sort((a, b) => b.payout_date - a.payout_date);
                this.money = profile.profile;
            }
        });
        this.user = this.data.profile;
        this.membership = this.data.memberships.find(m => m.tenant_id === this.data.tenant_id) || null;
    }

    public form = new FormGroup({
        user_email: new FormControl('', [Validators.required, Validators.email]),
        user_name: new FormControl('', Validators.required),
        user_nick: new FormControl('', Validators.required),
        user_role: new FormControl('', Validators.required),
        user_card_name: new FormControl('', []),
        user_card_number: new FormControl('', [Validators.pattern(/^\d{16}$/)]),
        user_iban_name: new FormControl('', []),
        user_iban_number: new FormControl('', [Validators.pattern(/^[A-Z]{2}\d{16,28}$/)]),
    });

    public ngOnInit() {
        if (this.user) {
            this.form.patchValue(this.user);
        }
    }

    public save() {

        const user = this.user?.user_id ? this.user : null;

        if (!this.user || !this.form.valid || this.loading) {
            this.form.markAsTouched();
            return;
        }

        this.loading = true;

        this.data.upsertUser({ ...user, ...this.form.value as any }).subscribe({
            next: u => {

                if (user) {
                    Object.assign(user, u);
                }

                this.loading = false;
                this.dialogRef.close();

            }, error: error => {
                this.loading = false;
                this.data.showMessage('Сталася помилка!');
            }
        });

    }

    public roles_dict: { [k: string]: string } = {
        guest: 'Гість',
        buyer: 'Закупівельник',
        accountant: 'Бухгалтер',
        admin: 'Адмін',
        superadmin: 'Суперадмін',
    };

    public get canShowMoney(): boolean {
        return this.data.buyer || this.data.accountant || this.data.admin;
    }

    public onSave() {
        this.save();
    }

    public onLogout() {
        this.data.signout();
        this.dialogRef.close();
    }

    public get canSave(): boolean {
        return this.form.valid && this.form.dirty && !this.loading;
    }

    public copyJwt() {
        navigator.clipboard.writeText(this.data.getJwt()).then(() => {
            this.snackbar.open('Токен авторизації скопійовано.');
        });
    }
}

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" (submit)="save()">

        <div class="product-info-wrapper">

            <div class="product-image-wrapper">

                <img class="product-image" [src]="form.controls.seller_image_url.value">

            </div>

            <div class="product-info">

                <div class="product-name">
                    <span>{{ seller.seller_name || 'Новий продавець' }}</span>
                </div>

            </div>

        </div>

        <mat-form-field>
            <mat-label>Продавець</mat-label>
            <input matInput [placeholder]="'Напр., Новий магазин'" formControlName="seller_name"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання на зображення</mat-label>
            <input matInput [placeholder]="'Напр., https://___.png'"
                   formControlName="seller_image_url"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Посилання на магазин</mat-label>
            <input matInput [placeholder]="'Напр., https://___.com'"
                   formControlName="seller_url"/>
        </mat-form-field>

    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Відміна</button>
    <button mat-flat-button color="accent" (click)="save()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>

<mat-accordion multi>
    <mat-expansion-panel *ngFor="let filter of filters" class="mat-elevation-z0" [expanded]="filter.expanded">

        <mat-expansion-panel-header>
            <mat-panel-title>
                {{filter.name}}
            </mat-panel-title>
            <mat-panel-description>
                <ng-container *ngIf="hasFilterAll(filter)">
                    <span class="mat-small">Вибрано всі</span>
                </ng-container>
                <ng-container *ngIf="!hasFilterAll(filter)">
                    <span class="mat-small">
                        {{ selectedCount(filter) }}/{{ availableFiltersCount(filter) }} вибрано
                    </span>
                </ng-container>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-chip-set>
            <ng-container *ngFor="let option of filter.options">
                <mat-chip-option color="secondary" [selected]="filter.dict[option.id]" [matTooltip]="option.tooltip||''"
                    (click)="onOptionChange(filter, option);"
                    (contextmenu)="onSelectOption(filter, option);$event.preventDefault()">

                    <span *ngIf="option.name" class="filter-name">{{option.name}}</span>

                    <small *ngIf="option.extra">{{option.extra}}</small>

                    <img *ngIf="option.image" [src]="option.image" [class.avatar]="filter.avatar" />

                </mat-chip-option>
            </ng-container>
        </mat-chip-set>

    </mat-expansion-panel>
</mat-accordion>
<table mat-table matSort matSortActive="balance" matSortDirection="desc" [dataSource]="dataSource"
    class="mat-elevation-z0 mat-table-condensed">


    <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Закупівельник</th>
        <td mat-cell *matCellDef="let element">
            <div class="flex">
                <img class="user-pic" [src]="element.user_image_url||'/favicon.ico'" />
                <span>{{element.user_name}}</span>
            </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="user_card_number">
        <th mat-header-cell *matHeaderCellDef>Номер карти</th>
        <td mat-cell *matCellDef="let element">
            {{element.user_card_number}}
            <span *ngIf="element.user_card_name" class="ms-1">
                ({{element.user_card_name}})
            </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="user_iban_number">
        <th mat-header-cell *matHeaderCellDef>IBAN</th>
        <td mat-cell *matCellDef="let element">
            {{element.user_iban_number}}
            <span *ngIf="element.user_iban_name" class="ms-1">
                ({{element.user_iban_name}})
            </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="order_amount_uah">
        <th mat-header-cell *matHeaderCellDef class="text-end" mat-sort-header arrowPosition="before">Покупки</th>
        <td mat-cell *matCellDef="let element" class="text-end">
            {{element.order_amount_uah | currency }}
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-end">
            {{getTotal('order_amount_uah') | currency}}
        </th>
    </ng-container>

    <ng-container matColumnDef="order_donate_amount_uah">
        <th mat-header-cell *matHeaderCellDef class="text-end" mat-sort-header arrowPosition="before">Донати</th>
        <td mat-cell *matCellDef="let element" class="text-end">
            {{element.order_donate_amount_uah | currency }}
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-end">
            {{getTotal('order_donate_amount_uah') | currency}}
        </th>
    </ng-container>

    <ng-container matColumnDef="payout_amount_uah">
        <th mat-header-cell *matHeaderCellDef class="text-end" mat-sort-header arrowPosition="before">Виплати</th>
        <td mat-cell *matCellDef="let element" class="text-end">
            {{element.payout_amount_uah | currency }}
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-end">
            {{getTotal('payout_amount_uah') | currency}}
        </th>
    </ng-container>

    <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef class="text-end" mat-sort-header arrowPosition="before">Баланс</th>
        <td mat-cell *matCellDef="let element" class="text-end">
            {{element.balance | currency }}
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-end">
            {{getTotal('balance') | currency}}
        </th>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-end">
            <button mat-button (click)="onEdit(element);$event.stopPropagation()">Редагувати</button>
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-end">
            <button mat-button (click)="add.emit()">
                <mat-icon>person_add</mat-icon>
                <span>Додати</span>
            </button>
        </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onPayout(row)" class="user-row"
        [class.user-row-red]="row.balance >= 1" [class.user-row-green]="row.balance <= -1">
    </tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

    <tr *matNoDataRow>
        <td *ngFor="let _ of [].constructor(displayedColumns.length)">
            <div *ngFor="let _ of [].constructor(3)">
                <div class="skeleton-text my-1"></div>
            </div>
        </td>
    </tr>
</table>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { TenantMenuComponent } from './tenant-menu/tenant-menu.component';
import { UpsertTenantDialogComponent } from '../dialogs/upsert-tenant-dialog/upsert-tenant-dialog.component';
import { UpsertRequestDialogComponent } from '../dialogs/upsert-request-dialog/upsert-request-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
    declarations: [
        TenantMenuComponent,
        UpsertTenantDialogComponent,
        UpsertRequestDialogComponent,
    ],
    exports: [
        TenantMenuComponent,
        UpsertTenantDialogComponent,
        UpsertRequestDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
    ]
})
export class TenantModule { }

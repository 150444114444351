<mat-dialog-content #scroll class="mat-typography" *ngIf="data.europe && data.tenant_id !== 1 ">

    <p>Вітаю на <b>ua-drones.de!</b></p>

    <p>
        Цей сайт є адаптованою для Німіччини версією
        <a target="_blank" href="https://goods.swarm.army">
            <img src="https://goods.swarm.army/assets/swarm-black-t.png" />
            <span>goods.swarm.army</span>
        </a>.

        Подальша мова йде від <b>адміна</b> спільноти <b>swarm.army</b>
    </p>

    <p>
        На зв'язку ваш адмін. Хочу трохи розказати про проєкт, та про те, що тут можна знайти.
        На сайті розміщені кращі пропозиції комплектуючих з АліЕкспресу для ФПВ дронів та не тільки,
        дбайливо відібрані як мною особисто, так і додані учасниками спільноти.
        Ціни та наявність періодично оновлюються,
        тож можна зекономити трохи часу на моніторинг, і зайнятися збіркою, чи іншими корисними справами.
    </p>

    <p>
        Деякі ресурси та матеріали від адміна, про які ви могли не знати:
    </p>

    <ul>

        <li>
            <a target="_blank" href="https://t.me/+jPtYcbbg-t1mZmYy">
                <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                <span>Telegram-канал</span>
            </a>
            <span>
                з нотифікаціями про падіння цін на товари з Алі,
                та з іноді корисними публікаціями на тему ФПВ та закупівель
            </span>
        </li>

        <li>
            <a target="_blank"
                href="https://chromewebstore.google.com/detail/ua-dronesde/pmijhibgbgkjmmccojjkohnhigkamjkk">
                <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg" />
                <span>Chrome-розширення</span>
            </a>
            <span>
                з виправленням в'єтнамської мови, додатковою статистикою по товарам та продавцям,
                та можливістю пропонувати товари для ресурсу
            </span>
        </li>

        <li>
            <a target="_blank" href="/info/erp">
                <mat-icon>warehouse</mat-icon>
                <span>Система обліку замовлень</span>
            </a>
            <span>
                що дозволяє залучати до закупівель з Алі більше людей (а значить, більше промокодів),
                трекати замовлення, провадити базовий складський облік комплектуючих та зібраних дронів
            </span>
        </li>

        <li>
            <a target="_blank" href="/info/purchase">
                <mat-icon>savings</mat-icon>
                <span>Велика інструкція з закупівель на AliExpress</span>
            </a>
            <span>
                що є систематизацією досвіду вашого адміна з роботи з AliExpress
            </span>
        </li>

        <li>
            <a target="_blank" href="/info/motors">
                <mat-icon>settings</mat-icon>
                <span>Тести моторів</span>
            </a>
            <span>
                на стенді
                <a target="_blank" href="https://www.aliexpress.com/item/32997870524.html">Mayatech MT10 Pro</a>,
                багато 2807 1300KV, і трішки - інших розмірів
            </span>
        </li>

        <li>
            <a target="_blank" href="/?kit">
                <mat-icon>construction</mat-icon>
                <span>Набори комплектуючих</span>
            </a>
            <span>
                для збірки, зокрема <a target="_blank" href="/?kit=sd-7-optimal-kit">SD 7" Оптимальний</a>
                та <a target="_blank" href="/?kit=sd-7-budjet-kit">SD 7" Бюджетний</a>
            </span>
        </li>

        <li>
            <a target="_blank" href="https://t.me/swarm_army">
                <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                <span>Майстерня</span>
            </a>
            <span>
                де адмін та його близькі люди збирають трохи дронів (тут тихо, та, зазвичай, нічого цікавого,
                стопки дронів, багато коробок, та раз на місяць - відео з бойовою роботою)
            </span>
        </li>

        <li>
            <a target="_blank" href="/info">
                <mat-icon>info</mat-icon>
                <span>Розділ "Довідка"</span>
            </a>
            <span>
                де можна почитати історію виникнення проєкту, знайти інструкції з використання даного ресурсу,
                та де я дуже хочу, але не дуже можу (бо доба мала), публікувати корисні матеріали по закупівлям
                (монети, суперділси, і оце от усе)
            </span>
        </li>

    </ul>

    <p>
        Сайт містить афілійовані посилання - купуючи товари за посиланнями звідси, невеликий відсоток з
        купівлі відраховується вашому адміну. Це не додає додаткової вартості до покупок; купуючи напряму з Алі -
        Алі просто забирає цей відсоток собі. Планую використовувати ці кошти на підтримку роботи нашої майстерні,
        а також на підтримку спільноти Social Drone запасними комплектуючими для ремонту проблемних дронів
        (звіти за <a target="_blank" href="https://t.me/swarmalerts/299">березень</a>,
        <a target="_blank" href="https://t.me/swarmalerts/422">квітень</a>).
        Варто зазначити, що за принципом афілійованих посилань працюють сервіси типу LetyShops чи Фішка,
        якщо користуєтесь такими - <b>ua-drones.de</b> не буде їх заміняти собою, тож ваш кешбек нікуди не дівається.
    </p>

    <p>
        Ваш адмін старався зробити цей ресурс зручним та корисним.
        Якщо маєте зауваження, ідеї, пропозиції, покращення, або ж хочете долучитися до розробки ресурсу -
        в описі телеграм-каналу можна знайти мої контакти.
    </p>

    <p>
        Бонус для тих, хто дочитав аж сюди: головний інженер в нашій майстерні, та тримач кукухи вашого адміна
        [<a (click)="gallery_shown=true;scroll.scrollTo({top: scroll.scrollHeight, behavior: 'smooth'})">показати</a>]
    </p>

    <p>
        Дякую, що дочитали всю писанину, та дякую що небайдужі до долі нашої країни.
        Дякуємо Богу та ЗСУ що живі, та підтримуємо чим можемо наших хлопців.
    </p>

    <div class="gallery" [class.shown]="gallery_shown"
        (click)="gallery_shown=true;scroll.scrollTo({top: scroll.scrollHeight, behavior: 'smooth'})">

        <img src="https://assets.swarm.army/photos/cat1.jpg" />
        <img src="https://assets.swarm.army/photos/cat2.jpg" />
        <img src="https://assets.swarm.army/photos/cat3.jpg" />
        <img src="https://assets.swarm.army/photos/cat4.jpg" />

    </div>

    <button mat-fab mat-dialog-close extended>
        <mat-icon>check</mat-icon>
        <span>Зрозуміло!</span>
    </button>

</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="model">

    <div class="product-info-wrapper">

        <div class="product-image-wrapper">

            <img class="product-image" [src]="model.model_image_url">

        </div>

        <div class="product-info">

            <div class="product-name">
                <span>{{model.model_name}}</span>
            </div>

            <mat-chip-set *ngIf="model.tags_array.length">
                <mat-chip *ngFor="let tag of model.tags_array">{{tag}}</mat-chip>
            </mat-chip-set>

            <div class="product-price-wrapper">

                <span class="product-price">{{ model.model_price_min | currency }} - {{ model.model_price_max |
                    currency }}</span>

                <small class="product-available">
                    <b>{{model.model_count_available}}</b>/{{model.model_count_total}} лишилося
                </small>
            </div>

        </div>

        <div class="control-buttons">
            <button mat-mini-fab mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
            <button mat-mini-fab *ngIf="data.superadmin" (click)="edit()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="!model">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<mat-dialog-content class="motor-tests" *ngIf="motor">

    <div class="motor-stats-title">Результати тестування</div>
    <div class="motor-stats-subtitle">Умови тестування: батарея 6s2p Molicell 42A, пропелер HQProp
        {{model?.tags_array?.includes('10"') ? '1050' : '7040'}}</div>

    <div class="motor-gallery">
        <img *ngFor="let p of motor.photos" [src]="p" />
    </div>

    <div class="table-wrapper">
        <table>
            <tr>
                <td>Пульс, мкс</td>
                <td>Напруга, В</td>
                <td>Струм, А</td>
                <td>Потужність, Вт</td>
                <td>Тяга, г</td>
                <td>Ефективність, г/Вт</td>
            </tr>
            <tr *ngFor="let s of motor.stats">
                <td>{{s.t.toFixed(0)}}</td>
                <td>{{s.u.toFixed(1)}}</td>
                <td>{{s.i.toFixed(1)}}</td>
                <td>{{s.p.toFixed(1)}}</td>
                <td>{{s.f.toFixed(0)}}</td>
                <td>{{(s.p ? s.f/s.p : 0).toFixed(1)}}</td>
            </tr>
        </table>
    </div>

    <div class="motor-video-title">Відео тестування</div>

    <div class="frame-wrapper">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(motor.video)" width="616" height="462"></iframe>
    </div>


</mat-dialog-content>

<div class="product-chart-wrapper">

    <apx-chart *ngIf="chartOptions" [series]="chartOptions.series" [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels" [markers]="chartOptions.markers" [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis" [stroke]="chartOptions.stroke" [title]="chartOptions.title"
        [colors]="chartOptions.colors" [fill]="chartOptions.fill" [forecastDataPoints]="chartOptions.forecastDataPoints"
        [legend]="chartOptions.legend"></apx-chart>

    <div class="product-chart-dummy" *ngIf="!chartOptions">
        <div class="skeleton-block"></div>
    </div>

</div>


<div class="product-comments-wrapper">

    <div class="product-comments-dummy" *ngIf="!model || !comments">
        <div class="skeleton-block"></div>
    </div>

    <ng-container *ngIf="model && comments">

        <div *ngIf="!comments.length" class="product-comments-empty">
            Коментарі відсутні
        </div>

        <div class="comments" *ngIf="comments.length">
            <div class="comment" *ngFor="let comment of comments" [class.pinned]="comment.comment_pinned"
                [class.deleted]="comment.comment_deleted">

                <div class="product-attrs">

                    <div class="product-extra product-seller">
                        <img class="product-seller-logo" [src]="comment.user_image_url" />
                        <span class="product-seller-name">{{comment.user_name}}</span>
                    </div>

                    <small class="product-available">
                        <b>{{comment.comment_date_string}}</b>
                    </small>

                    <small class="product-available" *ngIf="comment.model_id">
                        <b>щодо {{model.model_name}}</b>
                    </small>

                    <small class="product-available pinned" *ngIf="comment.comment_pinned">
                        <b>закріплено</b>
                        <mat-icon>push_pin</mat-icon>
                    </small>

                    <div class="spacer"></div>

                    <ng-container *ngIf="data.superadmin||data.sdua||data.profile?.user_id==comment.user_id">
                        <button mat-icon-button (click)="commentPin(comment)" *ngIf="data.superadmin">
                            <mat-icon>{{comment.comment_pinned?'highlight_off':'push_pin'}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="commentEdit(comment)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="commentDelete(comment)">
                            <mat-icon>{{comment.comment_deleted?'restore':'delete'}}</mat-icon>
                        </button>
                    </ng-container>

                </div>

                <div class="comment-text">
                    <div [innerHTML]="comment.comment_text"></div>
                </div>

            </div>
        </div>

        <div *ngIf="!data.profile" class="product-comments-auth">
            <a (click)="data.signin()">Авторизуйтесь</a>, щоб додати коментар
        </div>

        <div *ngIf="data.profile" class="product-comments-comment">

            <div contenteditable class="product-comments-editor" [formControl]="control_comment_text">
            </div>

            <div class="product-comments-footer">
                <button mat-flat-button color="primary" [buttonLoading]="comment_loading"
                    (click)="upsertComment()">{{comment_edited ? 'Оновити коментар' : 'Залишити коментар'}}</button>
                <button mat-button (click)="clearComment()" *ngIf="comment_edited">Відміна</button>
                <div class="spacer"></div>
            </div>

        </div>

    </ng-container>

</div>

<mat-dialog-content class="mat-typography" *ngIf="!model">
    <div class="product-info-dummy">
        <div class="skeleton-block"></div>
    </div>
</mat-dialog-content>

<div class="mat-typography product-model" *ngIf="model">

    <div class="model-title-wrapper">

        <div class="model-title">Наявність в магазинах</div>

        <div class="model-tags">
            <div class="model-tag">
                <b>{{model.model_count_available}}</b><small>/{{model.model_count_total}} доступно</small>
            </div>
            <div class="model-tag red" *ngIf="model.model_top_price_usd">
                TOP-ціна: <b>€{{model.model_top_price_usd.toFixed(2)}}</b>
            </div>
            <div class="model-tag price" *ngIf="model.model_price_min&&model.model_price_max">
                <b>€{{model.model_price_min.toFixed(2)}} - €{{model.model_price_max.toFixed(2)}}</b>
            </div>
            <div class="model-tag price" *ngIf="model.model_price_average">
                Середня ціна: <b>{{model.model_price_average | currency}}</b>
            </div>
        </div>

    </div>

    <div *ngIf="layout.is_mobile | async" class="product-list">

        <div *ngFor="let product of products" class="product-list-item" [routerLink]="[]"
            [queryParams]="product.view_query_params" [class.product-row-sold-out]="!product.sku_last_available"
            (click)="data.click(data.CS.ModelDialogList, data.CT.OpenProductDialog, product)">

            <div class="product-list-item-row">

                <div class="seller-chip-wrapper">

                    <seller-chip [data]="product"
                        (open)="data.click(data.CS.ModelDialogList, data.CT.OpenSellerDialog, product)"></seller-chip>

                    <div class="product-status-icon" *ngIf="product.product_status=='caution'"
                        matTooltip="Цей товар підозрілий, чи має негативні відгуки">
                        <mat-icon color="primary">error</mat-icon>
                    </div>

                    <div class="product-status-icon" *ngIf="product.product_status=='suggestion'"
                        matTooltip="Цей товар було додано учасником спільноти">
                        <mat-icon color="primary">group</mat-icon>
                    </div>

                    <div class="product-status-icon" *ngIf="product.product_status=='unknown'"
                        matTooltip="Адмін не може нічого сказати з приводу надійності товару">
                        <mat-icon color="primary">help_outline</mat-icon>
                    </div>

                    <div class="product-status-icon green" *ngIf="product.product_status=='verified'"
                        matTooltip="Товар надійний, АЛЕ зберігайте обачність, все може статися 😊">
                        <mat-icon color="primary">check_circle</mat-icon>
                    </div>

                    <div class="product-status-icon" *ngIf="product.product_status=='invalid'"
                        matTooltip="Товар більше недоступний на Ali, чи має помилки оновлення">
                        <mat-icon color="primary">bug_report</mat-icon>
                    </div>

                </div>

                <div class="product-price-wrapper">

                    <span class="product-quantity"
                        *ngIf="product.sku_quantity!=1">{{product.sku_quantity}}{{product.sku_units}}</span>

                    <small class="product-unit-price" *ngIf="product.sku_quantity!=1||product.product_currency!='USD'">
                        {{ product.sku_full_unit_price_usd | currency }}/{{product.sku_units}}
                    </small>

                    <span class="product-price">{{ product.sku_full_price | currency: product.product_currency }}</span>

                </div>

            </div>

            <div class="product-list-item-row">

                <small class="product-available" [class.product-available-running-out]="product.sku_running_out">
                    {{product.sku_last_available}} лишилося
                </small>

                <div class="product-list-urls">

                    <a mat-stroked-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                        *ngIf="product.product_source=='aliexpress'"
                        (click)="$event.stopPropagation();data.click(data.CS.ModelDialogList, data.CT.OpenAliexpress, product)">
                        <mat-icon>link</mat-icon>
                        AliExpress
                    </a>

                    <a mat-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                        *ngIf="product.product_source=='custom'"
                        (click)="$event.stopPropagation();data.click(data.CS.ModelDialogList, data.CT.OpenCustomProduct, product)">
                        Відкрити магазин
                    </a>

                    <a mat-flat-button color="warn" *ngIf="product.sku_last_sd_price" target="_blank"
                        [href]="product.sku_sd_url"
                        (click)="$event.stopPropagation();data.click(data.CS.ModelDialogList, data.CT.OpenSuperDeals, product)">
                        <mat-icon>sell</mat-icon>
                        <i>SuperDeals</i>
                        <span> за </span>
                        <strong>{{product.sku_last_sd_price | currency: product.product_currency}}</strong>
                    </a>

                    <a mat-flat-button color="primary" *ngIf="product.sku_last_lo_price" target="_blank"
                        [href]="product.sku_lo_url"
                        (click)="$event.stopPropagation();data.click(data.CS.ModelDialogList, data.CT.OpenLimidetDeal, product)">
                        <i>LimitedDeal</i>
                        <span> за </span>
                        <strong>{{product.sku_last_lo_price | currency: product.product_currency}}</strong>
                    </a>

                    <a mat-flat-button color="primary" *ngIf="product.sku_last_bs_price" target="_blank"
                        [href]="product.sku_bs_url"
                        (click)="$event.stopPropagation();data.click(data.CS.ModelDialogList, data.CT.OpenBigSave, product)">
                        <i>BigSave</i>
                        <span> за </span>
                        <strong>{{product.sku_last_bs_price | currency: product.product_currency}}</strong>
                    </a>

                </div>
            </div>

        </div>

    </div>


    <table mat-table matSort *ngIf="products_data_source && (layout.is_desktop | async)"
        [dataSource]="products_data_source">

        <ng-container matColumnDef="seller_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Магазин </th>
            <td mat-cell *matCellDef="let product">
                <div class="seller-chip-wrapper">

                    <seller-chip [data]="product"
                        (open)="data.click(data.CS.ModelDialogList, data.CT.OpenSellerDialog, product)"></seller-chip>

                    <div class="product-status-icon" *ngIf="product.product_status=='caution'"
                        matTooltip="Цей товар підозрілий, чи має негативні відгуки">
                        <mat-icon color="primary">error</mat-icon>
                    </div>

                    <div class="product-status-icon" *ngIf="product.product_status=='suggestion'"
                        matTooltip="Цей товар було додано учасником спільноти">
                        <mat-icon color="primary">group</mat-icon>
                    </div>

                    <div class="product-status-icon" *ngIf="product.product_status=='unknown'"
                        matTooltip="Адмін не може нічого сказати з приводу надійності товару">
                        <mat-icon color="primary">help_outline</mat-icon>
                    </div>

                    <div class="product-status-icon green" *ngIf="product.product_status=='verified'"
                        matTooltip="Товар надійний, АЛЕ зберігайте обачність, все може статися 😊">
                        <mat-icon color="primary">check_circle</mat-icon>
                    </div>

                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="sku_full_unit_price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before"> Ціна </th>
            <td mat-cell *matCellDef="let row">
                <div class="product-price-cell-wrapper">
                    <span class="product-quantity"
                        *ngIf="row.sku_quantity!=1">{{row.sku_quantity}}{{row.sku_units}}</span>
                    <div>
                        <span>{{ row.sku_full_price | currency: row.product_currency }}</span>
                        <small class="product-unit-price" *ngIf="row.sku_quantity!=1||row.product_currency!='USD'">
                            {{ row.sku_full_unit_price_usd | currency }}/{{row.sku_units}}
                        </small>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="sku_last_available">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before"> Залишок </th>
            <td mat-cell *matCellDef="let row">
                <span>{{ row.product_source === 'aliexpress' ? row.sku_last_available : '∞' }} шт</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="urls">
            <th mat-header-cell *matHeaderCellDef> Посилання </th>
            <td mat-cell *matCellDef="let row">

                <a class="product-link" [routerLink]="[]" [queryParams]="row.view_query_params"
                    (click)="data.click(data.CS.ModelDialogList, data.CT.OpenProductDialog, row)">ua-drones.de</a>

                <ng-container *ngIf="row.sku_full_sd_price">
                    <small>&nbsp;&bull;&nbsp;</small>
                    <a class="product-link ali" [href]="row.sku_sd_url" target="_blank"
                        (click)="data.click(data.CS.ModelDialogList, data.CT.OpenSuperDeals, row)">
                        <i>SuperDeals</i>
                        <span> за {{ row.sku_full_sd_price | currency: row.product_currency }}</span>
                    </a>
                </ng-container>

                <ng-container *ngIf="row.sku_full_lo_price">
                    <small>&nbsp;&bull;&nbsp;</small>
                    <a class="product-link ali" [href]="row.sku_lo_url" target="_blank"
                        (click)="data.click(data.CS.ModelDialogList, data.CT.OpenLimidetDeal, row)">
                        <i>LimitedDeal</i>
                        <span> за {{ row.sku_full_lo_price | currency: row.product_currency }}</span>
                    </a>
                </ng-container>

                <ng-container *ngIf="row.sku_full_bs_price">
                    <small>&nbsp;&bull;&nbsp;</small>
                    <a class="product-link ali" [href]="row.sku_bs_url" target="_blank"
                        (click)="data.click(data.CS.ModelDialogList, data.CT.OpenBigSave, row)">
                        <i>BigSave</i>
                        <span> за {{ row.sku_full_bs_price | currency: row.product_currency }}</span>
                    </a>
                </ng-container>

                <small>&nbsp;&bull;&nbsp;</small>

                <a class="product-link ali" [href]="row.sku_url" target="_blank"
                    *ngIf="row.product_source=='aliexpress'"
                    (click)="data.click(data.CS.ModelDialogList, data.CT.OpenAliexpress, row)">
                    AliExpress
                </a>

                <a class="product-link ali" [href]="row.sku_url" target="_blank" *ngIf="row.product_source=='custom'"
                    (click)="data.click(data.CS.ModelDialogList, data.CT.OpenCustomProduct, row)">
                    Відкрити магазин
                </a>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="products_columns"></tr>
        <tr mat-row *matRowDef="let row; columns: products_columns;"
            [class.product-row-sold-out]="!row.sku_last_available"></tr>

    </table>

</div>

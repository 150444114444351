import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, zip } from 'rxjs';
import { Rule, RuleTelegram, RuleType, RuleVerification, RuleWebsite } from '../../api.models';
import { ApiService } from '../../api.service';
import { DataService, ModelFull, ProductFull } from '../../data.service';
import { UpsertRuleDialogComponent } from '../upsert-rule-dialog/upsert-rule-dialog.component';

interface RuleVm extends Rule {
    rule_type_local: string;
    rule_website_local: string;
    rule_telegram_local: string;
    rule_verification_local: string;
    model: ModelFull | null;
    product: ProductFull | null;
}

function createRuleVm(rule: Rule, models: ModelFull[], products: ProductFull[]): RuleVm {
    const rule_type_local = {
        [RuleType.Admin]: 'Адмін',
        [RuleType.Price]: 'Падіння ціни',
        [RuleType.Availability]: 'Поява в наявності'
    }[rule.rule_type];
    const rule_website_local = {
        [RuleWebsite.Disabled]: 'Вимкнені',
        [RuleWebsite.Enabled]: 'Увімкнені',
    }[rule.rule_website];
    const rule_telegram_local = {
        [RuleTelegram.Disabled]: 'Вимкнені',
        [RuleTelegram.Silent]: 'Завжди беззвучні',
        [RuleTelegram.ActiveHours]: 'Беззвучні вночі',
        [RuleTelegram.Always]: 'Завжди зі звуком',
    }[rule.rule_telegram];
    const rule_verification_local = {
        [RuleVerification.OnlyVerified]: 'Тільки верифіковані',
        [RuleVerification.VerifiedUnknown]: 'Верифіковані та невідомі',
        [RuleVerification.NotCaution]: 'Не підозрілі',
        [RuleVerification.All]: 'Всі',
    }[rule.rule_verification];
    const model = rule.model_id ? models.find(p => p.model_id === rule.model_id) || null : null;
    const product = rule.product_id ? products.find(p => p.product_id === rule.product_id) || null : null;
    return {
        ...rule, product, model, rule_verification_local,
        rule_type_local, rule_website_local, rule_telegram_local,
    };
}

@Component({
    selector: 'app-rules-dialog',
    templateUrl: './rules-dialog.component.html',
    styleUrl: './rules-dialog.component.scss'
})
export class RulesDialogComponent {

    public static open(dialog: MatDialog) {
        dialog.open(
            RulesDialogComponent,
            { autoFocus: false, width: '720px', panelClass: 'mobile-fullscreen' },
        );
    }

    public loading = true;

    public rules: RuleVm[] = [];
    private models: ModelFull[] = [];
    private products: ProductFull[] = [];

    constructor(
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<RulesDialogComponent>,
        private readonly api: ApiService,
    ) {
        zip(this.data.getModels(), this.data.getFullProducts(), this.api.rulesList()).subscribe({
            next: ([models, products, rules]) => {
                this.loading = false;
                this.models = models;
                this.products = products;
                this.rules = rules.filter(r => r.rule_type !== RuleType.Admin).map(r => createRuleVm(r, models, products));
            }
        });
    }

    public createRule() {
        UpsertRuleDialogComponent.open(this.dialog, null)
            .afterClosed().pipe(filter(r => !!r)).subscribe(rule => {
                this.rules.push(createRuleVm(rule!, this.models, this.products));
            });
    }

    public updateRule(rule: RuleVm) {
        UpsertRuleDialogComponent.open(this.dialog, rule)
            .afterClosed().pipe(filter(r => !!r)).subscribe(rule => {
                const index = this.rules.findIndex(r => r.rule_id === rule!.rule_id);
                this.rules[index] = createRuleVm(rule!, this.models, this.products);
            });
    }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DataService } from './data.service';

@Injectable()
export class TenantGuard {

    constructor(private router: Router, private data: DataService) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.data.profile && this.data.tenant_id) return true;
        this.router.navigate(['/']);
        return false;
    }

}
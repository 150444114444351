import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService, DroneFull, Filter } from '../data.service';
import { LayoutService } from '../layout.service';
import { dateToLocalDate, dateToLocalTime, timestampToLocalDate, timestampToLocalShortTime } from '../utils';
import { AddDroneDialogComponent } from './add-drone-dialog/add-drone-dialog.component';
import { filenamify } from './filenamify';

const FILTERS_KEY = 'drones';

function save(filename: string, data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

@Component({
    selector: 'app-drones',
    templateUrl: './drones.component.html',
    styleUrl: './drones.component.scss'
})
export class DronesComponent {

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public loading = false;
    public drones: DroneFull[] = [];
    public filtered_drones: DroneFull[] = [];

    public empty_rows = new FormControl(false);

    public form = new FormGroup({
        dst_warehouse_id: new FormControl<number | undefined>(undefined, Validators.required),
        transfer_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        transfer_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        transfer_comment: new FormControl(''),
    });

    public readonly status_filter: Filter = {
        key: 'status',
        name: 'Статус',
        dict: { 'all': false, draft: true, done: true, sent: false, deleted: false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'draft', name: 'Збираються', image: '' },
            { id: 'done', name: 'Готові', image: '' },
            { id: 'sent', name: 'Передані', image: '' },
            { id: 'deleted', name: 'Видалені', image: '' },
        ],
        expanded: true,
        update: () => this.updateFilters(),
    };

    public readonly frame_filter: Filter = { key: 'Frame', name: 'Рама', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: true, update: () => this.updateFilters(), avatar: true };
    public readonly motors_filter: Filter = { key: 'Motors', name: 'Мотори', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly stack_filter: Filter = { key: 'Stack', name: 'Стек', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly props_filter: Filter = { key: 'Props', name: 'Пропелери', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly camera_filter: Filter = { key: 'Camera', name: 'Камера', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly antenna_filter: Filter = { key: 'VTX Antenna', name: 'VTX Антена', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly rx_filter: Filter = { key: 'RX', name: 'RX', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly vtx_filter: Filter = { key: 'VTX', name: 'VTX', dict: { 'all': true, 'none': false, }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: 'none', name: 'Немає', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };

    public filters: Filter[] = [
        this.status_filter,
        this.frame_filter,
        this.motors_filter,
        this.stack_filter,
        this.props_filter,
        this.camera_filter,
        this.antenna_filter,
        this.rx_filter,
        this.vtx_filter,
    ];

    private details_filters: Filter[] = [
        this.frame_filter,
        this.motors_filter,
        this.stack_filter,
        this.props_filter,
        this.camera_filter,
        this.antenna_filter,
        this.rx_filter,
        this.vtx_filter,
    ];


    constructor(private readonly data: DataService,
        private readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.data.tenant_id_cnange.subscribe(() => {
            this.loading = true;
            this.data.getDrones(true).subscribe({
                next: drones => {
                    this.loading = false;
                    this.updateDrones(drones.drones);
                    this.updateDetailFilter(this.frame_filter);
                    this.updateDetailFilter(this.motors_filter);
                    this.updateDetailFilter(this.stack_filter);
                    this.updateDetailFilter(this.props_filter);
                    this.updateDetailFilter(this.camera_filter);
                    this.updateDetailFilter(this.antenna_filter);
                    this.updateDetailFilter(this.vtx_filter);
                    this.updateDetailFilter(this.rx_filter);
                    this.data.restoreFilter(FILTERS_KEY, this.filters);
                },
            })
        });

    }

    public updateDetailFilter(filter: Filter) {

        const all_old_options_selected = filter.options.length && filter.options.every(o => filter.dict[o.id]);

        filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'none', name: 'Нема', image: '' },
            ...this.drones.map(d => d.details.find(e => e.detail_name === filter.key)).filter(d => d)
                .map(d => ({ id: d!.model_id, name: d!.model_name, image: d!.model_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i)
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = filter.options.every(o => filter.dict[o.id]);
        const no_options_selected = filter.options.every(o => !filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(filter.dict).forEach(k => filter.dict[k] = false);
            filter.dict['all'] = true;
        }

    }

    private updateFilters() {

        this.filtered_drones = this.drones
            .filter(d => this.droneVisible(d))
            .sort((a, b) => b.drone_date - a.drone_date);

        this.data.setFilters(FILTERS_KEY, this.filters);
    }

    public droneVisible(drone: DroneFull) {

        if (!(this.status_filter.dict['all'] || this.status_filter.dict[drone.drone_status])) {
            return false;
        }

        for (let detail_filter of this.details_filters) {

            if (detail_filter.dict['all']) continue;

            if (detail_filter.dict['none'] && !drone.details.some(d => d.detail_name === detail_filter.key)) continue;

            if (drone.details.some(d => d.detail_name === detail_filter.key && detail_filter.dict[d.model_id])) continue;

            return false;

        }

        return true;

    }

    public updateDrones(drones: DroneFull[]) {
        this.drones = drones;
        this.updateFilters();
    }

    public addDrone() {
        AddDroneDialogComponent.open(this.dialog).afterClosed().subscribe(drones => {
            if (drones) {
                this.updateDrones(drones);
            }
        });
    }

    public export() {

        const tenant_name = this.data.tenant?.tenant_name;

        if (!tenant_name) return;

        const date = timestampToLocalDate(Date.now() / 1000) + ' ' + timestampToLocalShortTime(Date.now() / 1000);

        const statuses = this.status_filter.options.filter(o => this.status_filter.dict[o.id]).map(o => o.name).join(', ');

        const filename = filenamify(`Дрони - ${statuses} - "${tenant_name}" - ${date}.csv`);

        const details = ['Frame', 'Motors', 'Props', 'Stack', 'Camera', 'VTX Antenna', 'RX', 'VTX'];

        const rows = [
            details,
            ...this.filtered_drones.map(drone => details.map(detail => drone.details.find(d => d.detail_name === detail)?.model_name || ''))
        ];

        save(filename, rows.map(r => r.map(c => `"${c}"`).join(',')).join('\n'));

    }

}

import { Component, Input } from '@angular/core';
import { ProductFull } from '../../data.service';

@Component({
    selector: 'app-product-stars',
    templateUrl: './product-stars.component.html',
    styleUrl: './product-stars.component.scss'
})
export class ProductStarsComponent {

    public stars = Array<string>(5).fill('star_outline');

    @Input() public set product(p: ProductFull | undefined) {
        if (p) {
            const rating = p.item_rating;
            this.stars = [0, 1, 2, 3, 4]
                .map(s => rating - s)
                .map(r => r <= 0 ? 'star_outline' : 0.3 <= r && r <= 0.7 ? 'star_half' : 'star_grade')
        } else {
            this.stars = Array(5).fill('star_outline');
        }
    }

}

<ng-container *ngIf="product">

    <mat-card routerLink="." [queryParams]="product.view_query_params"
        (click)="data.click(data.current_click_source, data.CT.OpenProductDialog, product)">
        <mat-card-content>

            <div class="product-list-wrapper">

                <img class="product-image" [src]="product.model_image_url" />

                <div class="product-list-info spacer flex-truncated me-2">

                    <div class="product-name">
                        <span class="product-quantity"
                            *ngIf="product.sku_quantity!=1">{{product.sku_quantity}}{{product.sku_units}}</span>
                        <span>{{product.model_name}}</span>
                    </div>

                    <div class="product-stats" *ngIf="product.product_source==='aliexpress'">
                        <app-product-stars [product]="product"></app-product-stars>
                        <small>|</small>
                        <small>{{product.item_reviews}} відгуків</small>
                        <small>|</small>
                        <small>{{product.item_sold}} продано</small>
                    </div>

                    <div class="product-list-extras">

                        <seller-chip [data]="product"
                            (open)="data.click(data.current_click_source, data.CT.OpenSellerDialog, product)"></seller-chip>

                        <ng-container *ngIf="layout.is_desktop | async">

                            <div class="product-extra" *ngIf="product.item_shipping_price">
                                Доставка: {{product.item_shipping_price | currency: product.product_currency}}
                            </div>

                            <div class="product-extra" *ngIf="product.item_coins_discount">
                                -{{product.item_coins_discount}}% з монетами
                            </div>

                            <div class="product-extra" *ngIf="product.sku_extra_tips">
                                {{product.sku_extra_tips}}
                            </div>

                            <div class="product-extra" *ngIf="product.item_max_limit">
                                Ліміт: {{product.item_max_limit}} шт
                            </div>

                            <div class="product-extra product-future-price" *ngIf="product.sku_future_sale_price">
                                <b>{{ product.sku_future_sale_price | currency: product.product_currency }}</b>
                                <span *ngIf="product.sku_future_sale_datetime"> з
                                    {{product.sku_future_sale_datetime}}</span>
                            </div>

                            <div class="tag" *ngIf="product.model_top_price_usd">
                                Краща цінa: {{product.model_top_price_usd}}
                            </div>

                            <div class="tag" *ngIf="product.detail_name">
                                {{product.detail_name}}
                            </div>

                            <div class="tag" *ngIf="product.product_status">
                                {{product.product_status}}
                            </div>

                            <small *ngFor="let tag of product.model_tags.split(';')" >
                                <div class="tag"  *ngIf="!tag.endsWith('KIT')">
                                    <span>
                                        {{ tag }}
                                    </span>
                                </div>
                            </small>

                        </ng-container>

                    </div>

                </div>

                <div class="product-list-end text-end">

                    <div class="product-list-extras" *ngIf="layout.is_mobile| async">

                        <div class="product-extra" *ngIf="product.item_shipping_price">
                            Доставка: {{product.item_shipping_price | currency: product.product_currency}}
                        </div>

                        <div class="product-extra" *ngIf="product.item_coins_discount">
                            -{{product.item_coins_discount}}% з монетами
                        </div>

                        <div class="product-extra" *ngIf="product.sku_extra_tips">
                            {{product.sku_extra_tips}}
                        </div>

                        <div class="product-extra" *ngIf="product.item_max_limit">
                            Ліміт: {{product.item_max_limit}} шт
                        </div>

                        <div class="product-extra product-future-price" *ngIf="product.sku_future_sale_price">
                            <b>{{ product.sku_future_sale_price | currency: product.product_currency }}</b>
                            <span *ngIf="product.sku_future_sale_datetime"> з
                                {{product.sku_future_sale_datetime}}</span>
                        </div>

                    </div>

                    <div class="product-price-urls-wrapper">

                        <div class="product-price-wrapper">

                            <span class="product-price">{{ product.sku_full_price | currency: product.product_currency
                                }}</span>

                            <div class="product-price-wrapper-flex">

                                <small class="product-available" *ngIf="product.product_source === 'aliexpress'"
                                    [class.product-available-running-out]="product.sku_running_out">
                                    {{product.sku_last_available}} лишилося
                                </small>

                                <small class="product-unit-price" *ngIf="product.sku_quantity!=1">
                                    {{ product.sku_full_unit_price | currency: product.product_currency
                                    }}/{{product.sku_units}}
                                </small>

                            </div>

                        </div>

                        <div class="product-list-urls">

                            <a mat-stroked-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                                *ngIf="product.product_source=='aliexpress'"
                                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenAliexpress, product)">
                                <mat-icon>link</mat-icon>
                                Відкрити AliExpress
                            </a>

                            <a mat-button color="primary" target="_blank" [href]="product.sku_url" class="product-url"
                                *ngIf="product.product_source=='custom'"
                                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenCustomProduct, product)">
                                <mat-icon>link</mat-icon>
                                Відкрити магазин
                            </a>

                            <a mat-flat-button color="warn" (click)="$event.stopPropagation()" target="_blank"
                                [href]="product.sku_sd_url"
                                *ngIf="product.sku_full_sd_price && (!product.sku_full_lo_price || product.sku_full_sd_price <= product.sku_full_lo_price)"
                                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenSuperDeals, product)">
                                <mat-icon>sell</mat-icon>
                                <i>SuperDeals</i>
                                <span> за </span>
                                <strong>{{product.sku_full_sd_price | currency: product.product_currency}}</strong>
                            </a>

                            <a mat-flat-button color="primary" target="_blank" [href]="product.sku_lo_url"
                                *ngIf="product.sku_full_lo_price && (!product.sku_full_sd_price || product.sku_full_lo_price < product.sku_full_sd_price)"
                                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenLimidetDeal, product)">
                                <i>LimitedDeal</i>
                                <span> за </span>
                                <strong>{{product.sku_full_lo_price | currency: product.product_currency}}</strong>
                            </a>

                            <a mat-flat-button color="primary" target="_blank" [href]="product.sku_bs_url"
                                *ngIf="product.sku_full_bs_price && (!product.sku_full_sd_price || product.sku_full_bs_price < product.sku_full_sd_price)"
                                (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenBigSave, product)">
                                <i>BigSave</i>
                                <span> за </span>
                                <strong>{{product.sku_full_bs_price | currency: product.product_currency}}</strong>
                            </a>

                        </div>

                    </div>

                </div>
            </div>

        </mat-card-content>
    </mat-card>

</ng-container>

<mat-card>
    <div mat-card-image>
        <div class="skeleton-image"></div>
    </div>

    <mat-card-content>
        <div class="skeleton-title my-2"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
    </mat-card-content>
</mat-card>
<main *ngIf="!dash">
    Loading...
</main>

<main *ngIf="dash">

    <div class="card" (click)="openTenants()">

        <header>
            Організації
        </header>

        <div class="stats">
            <div class="stat">
                <div class="stat-value">{{dash.tenants_stats.tenants_all}}</div>
                <div class="stat-label">Всього</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.tenants_stats.tenants_with_orders}}</div>
                <div class="stat-label">Активних</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.tenants_stats.tenants_with_ten_orders}}</div>
                <div class="stat-label">>= 10 замовлень</div>
            </div>
        </div>

    </div>

    <div class="card" (click)="openUsers()">

        <header>
            Користувачі
        </header>

        <div class="stats">
            <div class="stat">
                <div class="stat-value">{{dash.users_stats.users_all}}</div>
                <div class="stat-label">Всього</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.users_stats.users_with_orders}}</div>
                <div class="stat-label">Активних</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.users_stats.users_with_ten_orders}}</div>
                <div class="stat-label">>= 10 замовлень</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.users_stats.users_with_trackings}}</div>
                <div class="stat-label">З трекінгами</div>
            </div>
        </div>

    </div>

    <div class="card" style="flex:2">

        <header>
            Продукти
        </header>

        <div class="stats">
            <div class="stat">
                <div class="stat-value">{{dash.products_stats.products_all}}</div>
                <div class="stat-label">Всього</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.products_stats.products_queued}}</div>
                <div class="stat-label">В черзі</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.products_stats.products_active}}</div>
                <div class="stat-label">Активних</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.products_stats.products_invalid}}</div>
                <div class="stat-label">Проблемних</div>
            </div>
            <div class="stat">
                <div class="stat-value">{{dash.products_stats.products_deleted}}</div>
                <div class="stat-label">Видалених</div>
            </div>
        </div>

    </div>

    <div class="card" style="min-width: 600px;">

        <header>
            Скрапери
        </header>

        <div class="scrappers-list">

            <div class="scrapper" *ngFor="let s of dash.scrappers_list"
                [class.scrapper-alert]="now - s.updates_date > 60">
                <img class="scrapper-image" [src]="s.user_image_url" />
                <div class="scrapper-name">{{s.user_name}}</div>
                <div class="scrapper-speed" *ngIf="s.updates_speed*1">
                    <div>{{s.updates_speed}}</div>
                    <small>он/год</small>
                </div>
                <div class="scrapper-updates">{{s.updates_count}}</div>
                <div class="scrapper-date">{{s.updates_date | dateAgo}}</div>
            </div>

        </div>

    </div>



    <div class="card" style="min-width: 600px;">

        <header>
            Продукти в черзі
        </header>

        <div class="scrappers-list">

            <div mat-ripple class="scrapper" *ngFor="let s of products_queue"
                [class.scrapper-alert]="now - s.product_last_updated > 86400" [routerLink]="[]"
                [queryParams]="s.view_query_params">
                <img class="scrapper-image" [src]="s.model_image_url" />
                <div class="scrapper-name">
                    <div>{{s.model_name}}</div>
                    <seller-chip [data]="s"></seller-chip>
                </div>
                <div class="scrapper-date">{{s.product_last_updated | dateAgo}}</div>
            </div>

        </div>

    </div>

</main>
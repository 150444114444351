import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    constructor(private readonly data: DataService) { }

    public menu_items = [
        {
            name: 'Дошка',
            url: '/dashboard',
            visibility: () => this.data.superadmin || this.data.scrapper
        },
        {
            name: 'Продукти',
            url: '/products',
            visibility: () => true
        },
        {
            name: 'Моделі',
            url: '/models',
            visibility: () => true
        },
        {
            name: 'Продавці',
            url: '/sellers',
            visibility: () => this.data.superadmin || this.data.sdua
        },
        {
            name: 'Трекінг',
            url: '/track',
            visibility: () => true
        },
        {
            name: 'Запити',
            url: '/requests',
            visibility: () => this.data.profile && this.data.tenant_id
        },
        {
            name: 'Замовлення',
            url: '/orders',
            visibility: () => this.data.profile && this.data.tenant_id
        },
        // {
        //     name: 'Барахолка',
        //     url: '/marketplace',
        //     visibility: () => true
        // },
        {
            name: 'Інфо',
            url: '/info',
            visibility: () => true
        },
    ]

}

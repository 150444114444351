import { Component, Input } from '@angular/core';
import { DataService } from '../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderRequestVm } from '../models/index';
import { UpsertRequestDialogComponent } from '../../dialogs/upsert-request-dialog/upsert-request-dialog.component';

@Component({
    selector: 'app-request-card',
    templateUrl: './request-card.component.html',
    styleUrl: './request-card.component.scss'
})
export class RequestCardComponent {

    @Input()
    public request: OrderRequestVm | null = null;

    constructor(
        public readonly data: DataService,
        private readonly dialog: MatDialog,
    ) { }

    onEdit(event: Event) {
        event.stopPropagation();
        UpsertRequestDialogComponent.open(this.dialog, this.request);
    }

}

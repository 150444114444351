import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { zip } from 'rxjs';
import { DataService, KitFull, ProductFull, StockResponse } from '../../data.service';

interface RequestKitData {
    products: ProductFull[];
    kit: KitFull;
}

interface ModelVm {
    model_id: number;
    model_name: string;
    request_price: number;
    request_quantity: number;
    additional_quantity: number;
}

@Component({
    selector: 'app-request-kit-dialog',
    templateUrl: './request-kit-dialog.component.html',
    styleUrl: './request-kit-dialog.component.scss'
})
export class RequestKitDialogComponent {

    public models: ModelVm[] = [];

    public static open(dialog: MatDialog, data: RequestKitData) {
        return dialog.open<RequestKitDialogComponent, RequestKitData, StockResponse>(RequestKitDialogComponent, { data: data, autoFocus: false, restoreFocus: false, width: '720px', panelClass: 'mobile-fullscreen', });
    }

    public loading = false;

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<RequestKitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly dialog_data: RequestKitData,
    ) {

        this.models = this.dialog_data.products.map(p => ({
            ...p,
            request_quantity: 0,
            additional_quantity: 1,
            request_price: p.sku_min_unit_price_usd,
        }));

        this.data.getRequests().subscribe({
            next: requests => {
                this.models.forEach(m => {
                    const request = requests.find(r => r.model_id === m.model_id);
                    m.request_quantity = request?.request_quantity || 0;
                    m.request_price = request?.request_max_price || m.request_price;
                });
            }
        });

    }

    public addAll(amount: number) {
        this.models.forEach(m => this.addModel(m, amount));
    }

    public addModel(vm: ModelVm, amount: number) {
        vm.additional_quantity += amount;
        if (vm.additional_quantity < 0) {
            vm.additional_quantity = 0;
        }
    }

    public onSave() {

        if (this.loading) return;

        this.loading = true;

        zip(this.models.filter(m => m.additional_quantity)
            .map(m => this.data.upsertRequest({
                model_id: m.model_id,
                request_quantity: Math.max(0, m.request_quantity) + m.additional_quantity,
                request_max_price: m.request_price, request_rule: 'all', request_products: '[]',
            })))
            .subscribe({
                next: () => {
                    this.data.getRequests(true).subscribe({
                        next: () => {
                            this.snackbar.open('Вибрані товари додані до запитів', 'OK');
                            this.ref.close();
                        }
                    });
                }
            });

    }
}

import { DateTime } from "luxon";

export const PROMOS = {
    start: DateTime.fromISO('2024-11-11T01:00:00+02:00'),
    end: DateTime.fromISO('2024-11-18T01:00:00+02:00'),
    promocodes: [
        {
            title: 'Промокоди від swarm.army',
            subtitle: 'Починають діяти 11 листопана о 1 ночі за Києвом!',
            currency: '$',
            codes: [
                {
                    promo_amount: 39,
                    promo_discount: 5,
                    promo_codes: ['SWRM05',],
                },
                {
                    promo_amount: 89,
                    promo_discount: 12,
                    promo_codes: ['SWRM12',],
                },
                {
                    promo_amount: 169,
                    promo_discount: 25,
                    promo_codes: ['SWRM25',],
                },
                {
                    promo_amount: 269,
                    promo_discount: 40,
                    promo_codes: ['SWRM40',],
                },
                {
                    promo_amount: 359,
                    promo_discount: 60,
                    promo_codes: ['SWRM60',],
                },
                {
                    promo_amount: 499,
                    promo_discount: 80,
                    promo_codes: ['SWRM80',],
                },
            ],
        },
        {
            title: 'Промокоди від AliExpress',
            subtitle: 'Починають діяти 11 листопада о 1 ночі за Києвом!',
            currency: '$',
            codes: [
                {
                    promo_amount: 39,
                    promo_discount: 5,
                    promo_codes: ['AEAFUA05',],
                },
                {
                    promo_amount: 89,
                    promo_discount: 12,
                    promo_codes: ['AEAFUA12',],
                },
                {
                    promo_amount: 169,
                    promo_discount: 25,
                    promo_codes: ['AEAFUA25',],
                },
                {
                    promo_amount: 269,
                    promo_discount: 40,
                    promo_codes: ['AEAFUA40',],
                },
                {
                    promo_amount: 359,
                    promo_discount: 60,
                    promo_codes: ['AEAFUA60',],
                },
                {
                    promo_amount: 499,
                    promo_discount: 80,
                    promo_codes: ['AEAFUA80',],
                },
            ],
        },
        {
            title: 'Купонні коди від AliExpress',
            subtitle: 'Починають діяти 11 листопада о першій ночі за Києвом!',
            currency: '$',
            codes: [
                {
                    promo_amount: 39,
                    promo_discount: 5,
                    promo_codes: ['DLUA05',],
                },
                {
                    promo_amount: 89,
                    promo_discount: 12,
                    promo_codes: ['DLUA12',],
                },
                {
                    promo_amount: 169,
                    promo_discount: 25,
                    promo_codes: ['DLUA25',],
                },
                {
                    promo_amount: 269,
                    promo_discount: 40,
                    promo_codes: ['DLUA40',],
                },
                {
                    promo_amount: 359,
                    promo_discount: 60,
                    promo_codes: ['DLUA60',],
                },
                {
                    promo_amount: 499,
                    promo_discount: 80,
                    promo_codes: ['DLUA80',],
                },
                {
                    promo_amount: 899,
                    promo_discount: 120,
                    promo_codes: ['11B120',],
                },
                {
                    promo_amount: 1199,
                    promo_discount: 160,
                    promo_codes: ['11B160',],
                },
            ],
        },
    ]
}
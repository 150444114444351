<div mat-dialog-title>
    {{ creatingNewRequest ? 'Створити запит' : 'Редагувати запит' }}
</div>

<mat-dialog-content class="mat-typography">

    <form [formGroup]="form" class="my-2">
        <mat-form-field>
            <mat-label>Модель</mat-label>
            <mat-select formControlName="model_id" (closed)="search.value=''" (opened)="search.focus()">
                <mat-option class="mat-select-search-option">
                    <input #search (click)="$event.stopPropagation()" (input)="detector.detectChanges()"
                        placeholder="Почніть вводити для пошуку..." />
                </mat-option>
                <ng-container *ngIf="search.value">
                    <ng-container *ngFor="let m of models">
                        <mat-option [value]="m.model_id"
                            *ngIf="m.model_name.toLowerCase().includes(search.value.trim().toLowerCase())">
                            {{m.model_name}}
                        </mat-option>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!search.value">
                    <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                        <mat-option *ngFor="let m of d.models" [value]="m.model_id">{{m.model_name}}</mat-option>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
            <mat-error>Виберіть модель</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Кількість в запиті</mat-label>
            <input matInput placeholder="123" formControlName="request_quantity">
            <mat-error>Введіть ціле число</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Ціна за одиницю, €</mat-label>
            <input matInput placeholder="50.00" formControlName="request_max_price">
            <mat-error>Введіть число</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Продукти в цьому запиті</mat-label>
            <mat-select formControlName="request_rule">
                <mat-option value="all">Всі</mat-option>
                <mat-option value="except">Всі, окрім вибраних</mat-option>
                <mat-option value="only">Лише вибрані</mat-option>
            </mat-select>
            <mat-error>Поле обовʼязкове</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="form.controls.request_rule.value=='except'||form.controls.request_rule.value=='only'">
            <mat-label>Вибрані продукти</mat-label>
            <mat-select multiple formControlName="request_products_ids">
                <mat-option [value]="p.product_id" *ngFor="let p of filtered_products">
                    Від <b>{{p.seller_name_short}}</b> за
                    <b>{{p.sku_last_price|currency:p.product_currency}}<span
                            *ngIf="p.sku_quantity!=1">/{{p.sku_quantity}}{{p.sku_units}}</span></b>
                </mat-option>
            </mat-select>
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="onDeactivate()" [buttonLoading]="loading"
        *ngIf="request?.request_quantity||0>0">Деактивувати</button>
    <div class="spacer"></div>
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [disabled]="!canSave"
        [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
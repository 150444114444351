import {Component, Input} from '@angular/core';
import {DataService, SellerFull} from "../../data.service";
import {MatDialog} from "@angular/material/dialog";
import {SellerEditDialogComponent} from "../../seller-edit-dialog/seller-edit-dialog.component";

@Component({
    selector: 'seller-card',
    templateUrl: './seller-card.component.html',
    styleUrl: './seller-card.component.scss'
})
export class SellerCardComponent {

    @Input()
    public item: SellerFull | null = null;

    constructor(public readonly data: DataService, private readonly dialog: MatDialog) {
    }

    onEdit(event: Event) {
        event.stopPropagation();
        if (!this.item) return;

        SellerEditDialogComponent.open(this.dialog, this.item);
    }
}

import { Component, OnInit } from '@angular/core';
import { DataService, StockResponse, Warehouse } from '../data.service';
import { dateToLocalDate, dateToLocalTime, stringsToDate, timestampToLocalDate, timestampToLocalShortTime, timestampToLocalTime } from '../utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StockVm, WarehouseVm } from './models/index';
import { AlertDialogComponent } from '../shared/dialogs/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { UpsertWarehouseDialogComponent } from '../dialogs/upsert-warehouse-dialog/upsert-warehouse-dialog.component';
import { LayoutService } from '../layout.service';
import { AddStockDialogComponent } from '../dialogs/add-stock-dialog/add-stock-dialog.component';

export interface TransferVm {
    drone_id: number | null;
    transfer_date: number;
    src_warehouse_id: number | null;
    dst_warehouse_id: number | null;
    transfer_date_string: string;
    src_warehouse_name: string;
    dst_warehouse_name: string;
    user_name: string;
    user_image_url: string;
    order_id: number | null;
    transfer_comment: string[];
    transfers: {
        model_id: number;
        model_name: string;
        transfer_quantity: number;
        transfer_id: number;
        sku_units: string;
    }[];
}

@Component({
    selector: 'app-stock',
    templateUrl: './stock.component.html',
    styleUrl: './stock.component.scss'
})
export class StockComponent {

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public warehouses: WarehouseVm[] = [];
    public transfers: TransferVm[] = [];

    public cart: WarehouseVm | null = null;

    public loading = false;

    public empty_rows = new FormControl(false);

    public form = new FormGroup({
        dst_warehouse_id: new FormControl<number | undefined>(undefined, Validators.required),
        transfer_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        transfer_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        transfer_comment: new FormControl(''),
    });

    public sidebar_mode_control = new FormControl<'cart' | 'transfers'>('transfers');

    constructor(private readonly data: DataService,
        private readonly dialog: MatDialog,
        public readonly layout: LayoutService,
    ) {
        this.data.tenant_id_cnange.subscribe(() => {
            this.loading = true;
            this.data.getStock(true).subscribe({
                next: stock => {
                    this.loading = false;
                    this.updateStock(stock);
                },
                error: (e) => {
                    this.loading = false;
                }
            });
        });
    }

    private updateStock(stock: StockResponse) {

        this.data.getModels().subscribe({
            next: models => {

                this.warehouses = stock.warehouses.filter(w => !w.warehouse_deleted).map(w => ({
                    ...w,
                    stock: stock.stock.filter(s => s.warehouse_id === w.warehouse_id)
                        .map(s => ({ ...s, ...stock.models.find(m => m.model_id === s.model_id)!, cart_quantity: 0, sku_units: models.find(m => m.model_id === s.model_id)?.sku_units || '' }))
                        .sort((a, b) => b.stock_quantity - a.stock_quantity)
                }));

                this.transfers = [];

                stock.transfers.forEach(st => {

                    let transfer: TransferVm | null = null;

                    if (!st.order_id) {
                        transfer = this.transfers.find(t => t.transfer_date === st.transfer_date
                            && st.src_warehouse_id === t.src_warehouse_id
                            && st.dst_warehouse_id === t.dst_warehouse_id) || null;
                    }

                    if (!transfer) {
                        const user = stock.users.find(u => u.user_id === st.user_id);
                        transfer = {
                            transfer_date: st.transfer_date,
                            transfer_date_string: timestampToLocalDate(st.transfer_date) + ' ' + timestampToLocalShortTime(st.transfer_date),
                            src_warehouse_name: stock.warehouses.find(w => w.warehouse_id === st.src_warehouse_id)?.warehouse_name || '',
                            dst_warehouse_name: stock.warehouses.find(w => w.warehouse_id === st.dst_warehouse_id)?.warehouse_name || '',
                            src_warehouse_id: st.src_warehouse_id,
                            dst_warehouse_id: st.dst_warehouse_id,
                            user_name: user?.user_name || '',
                            user_image_url: user?.user_image_url || '',
                            order_id: st.order_id,
                            drone_id: st.drone_id,
                            transfers: [],
                            transfer_comment: [],
                        };
                        this.transfers.unshift(transfer);
                    }

                    if (st.transfer_comment && !transfer.transfer_comment.includes(st.transfer_comment)) {
                        transfer.transfer_comment.push(st.transfer_comment);
                    }

                    const model = models.find(m => m.model_id === st.model_id);

                    transfer.transfers.push({
                        transfer_quantity: st.transfer_quantity,
                        model_name: model?.model_name || '',
                        model_id: model?.model_id || 0,
                        transfer_id: st.transfer_id,
                        sku_units: model?.sku_units || '',
                    });

                    transfer.transfers.sort((a, b) => b.transfer_quantity - a.transfer_quantity);

                });

                this.transfers.sort((a, b) => b.transfer_date - a.transfer_date);

            }
        });

    }

    public updateCart(s: StockVm) {

        const warehouse = this.warehouses.find(w => w.stock.includes(s));

        if (!warehouse) return;

        this.warehouses.forEach(w => {
            if (w === warehouse) return;
            w.stock.forEach(s => s.cart_quantity = 0);
        });

        if (warehouse.stock.some(s => s.cart_quantity)) {
            this.cart = {
                ...warehouse,
                stock: warehouse.stock.filter(s => s.cart_quantity),
            };
        } else {
            this.cart = null;
        }

        this.sidebar_mode_control.setValue('cart');

    }

    public transfer() {

        const cart = this.cart;

        if (this.form.invalid || !cart || !cart.stock.length) {
            this.form.markAllAsTouched();
            return;
        }

        const value = this.form.value;

        this.loading = true;

        this.data.transfer(cart.stock.map(s => ({
            drone_id: null,
            model_id: s.model_id,
            transfer_quantity: s.cart_quantity,
            src_warehouse_id: cart.warehouse_id,
            dst_warehouse_id: value.dst_warehouse_id || null,
            transfer_comment: value.transfer_comment || '',
            transfer_date: stringsToDate(value.transfer_date!, value.transfer_time!).getTime() / 1000,
        }))).subscribe({
            next: stock => {
                this.form.patchValue({
                    dst_warehouse_id: undefined,
                    transfer_date: this.now_date,
                    transfer_time: this.now_time,
                    transfer_comment: '',
                });
                this.updateStock(stock);
                this.loading = false;
                this.cart = null;

                AlertDialogComponent.open(this.dialog, {
                    title: "Готово!",
                    messages: [!value.dst_warehouse_id
                        ? 'Вибрані товари списані.'
                        : 'Вибрані товари передані на інший склад.'
                    ]
                })
            }
        });
    }

    public upsertWarehouse(warehouse: WarehouseVm | null) {
        UpsertWarehouseDialogComponent.open(this.dialog, warehouse).afterClosed().subscribe(w => {
            if (!w) return;
            if (warehouse) {
                if (w.warehouse_deleted) {
                    this.warehouses.splice(this.warehouses.indexOf(warehouse), 1);
                } else {
                    warehouse.warehouse_name = w.warehouse_name;
                    warehouse.warehouse_default = w.warehouse_default;
                    if (w.warehouse_default) {
                        this.warehouses.filter(w => w.warehouse_id !== warehouse.warehouse_id)
                            .forEach(w => w.warehouse_default = false);
                    }
                }
            } else {
                this.warehouses.push({ ...w, stock: [] });
                if (w.warehouse_default) {
                    this.warehouses.forEach(w => w.warehouse_default = false);
                }
            }
        });
    }

    public addGoods() {
        AddStockDialogComponent.open(this.dialog, { warehouses: this.warehouses }).afterClosed().subscribe(stock => {
            if (stock) this.updateStock(stock);
        });
    }

}
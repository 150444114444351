<button mat-button [mat-menu-trigger-for]="menuTenant">
    <span *ngIf="!data.tenant_id">Без організації</span>
    <span *ngIf="data.tenant_id">{{data.tenant?.tenant_name||'...'}}</span>
    <mat-icon iconPositionEnd>expand_more</mat-icon>
</button>

<mat-menu #menuTenant>

    <button mat-menu-item *ngFor="let m of data.memberships" (click)="data.updateTenantId(m.tenant_id)">
        <span>{{m.tenant_name}}</span>
        <mat-icon *ngIf="data.tenant_id===m.tenant_id">check</mat-icon>
    </button>

    <mat-divider *ngIf="data.memberships?.length"></mat-divider>

    <ng-container *ngIf="data.tenant&&(data.admin||data.accountant||data.craftsman)">

        <button mat-menu-item (click)="upsertTenant(data.tenant)" *ngIf="data.admin">
            <span>Редагувати {{data.tenant.tenant_name}}</span>
            <mat-icon iconPositionEnd>edit</mat-icon>
        </button>

        <a mat-menu-item routerLink="/orders/all" *ngIf="data.admin" routerLinkActive="link-active">
            <span>Всі замовлення</span>
            <mat-icon iconPositionEnd>list_alt</mat-icon>
        </a>

        <a mat-menu-item routerLink="/payouts" *ngIf="data.admin||data.accountant" routerLinkActive="link-active">
            <span>Користувачі та виплати</span>
            <mat-icon iconPositionEnd>people</mat-icon>
        </a>

        <a mat-menu-item routerLink="/stock" *ngIf="data.admin||data.craftsman" routerLinkActive="link-active">
            <span>Склад</span>
            <mat-icon iconPositionEnd>warehouse</mat-icon>
        </a>

        <a mat-menu-item routerLink="/drones" *ngIf="data.admin||data.craftsman" routerLinkActive="link-active">
            <span>Дрони</span>
            <mat-icon iconPositionEnd>rocket_launch</mat-icon>
        </a>

        <mat-divider></mat-divider>

    </ng-container>

    <button mat-menu-item (click)="upsertTenant()">
        <span>Додати організацію</span>
        <mat-icon iconPositionEnd>add</mat-icon>
    </button>

</mat-menu>
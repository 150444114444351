import { Component, Input } from '@angular/core';
import { DataService, ProductFull } from '../../data.service';
import { LayoutService } from '../../layout.service';

@Component({
    selector: 'app-product-line',
    templateUrl: './product-line.component.html',
    styleUrl: './product-line.component.scss'
})
export class ProductLineComponent {

    @Input() product: ProductFull | undefined;

    get isSoldOut(): boolean {
        return !this.product?.sku_last_available;
    }

    constructor(public readonly layout: LayoutService, public readonly data: DataService,) { }
}

import { Component } from '@angular/core';
import { DataService, DetailUnits, Filter, ModelFull, OrderRequest, OrderRequestFull } from '../data.service';
import { MatDialog } from '@angular/material/dialog';
import { ModelDialogComponent } from '../model/model.component';
import { LayoutService } from '../layout.service';
import { ModelEditDialogComponent } from '../model-edit-dialog/model-edit-dialog.component';


const FILTERS_KEY = 'models';

@Component({
    selector: 'app-models',
    templateUrl: './models.component.html',
    styleUrl: './models.component.scss'
})
export class ModelsComponent {

    public readonly top_price_filter: Filter = {
        key: 'price',
        name: 'Ціна',
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'all': true, 'top': false, },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'top', name: 'TOP-ціна', image: '' },
        ],
    };

    public readonly tags_filter: Filter = {
        key: 'tag',
        name: 'Теги',
        expanded: true,
        update: () => this.updateDetails(),
        dict: { 'all': false, '7"': false, '8"': false, '10"': false, 'SD 7"': true, 'SD 8"': false, 'SD 10"': false, 'VD 7"': false, 'VD 8"': false, 'VD 10"': false, 'SOLDERING': false, '3D': false, 'FILAMENT': false, 'Ukraine': false, 'Tools': false, 'unsorted': false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: '7"', name: '7"', image: '' },
            { id: '8"', name: '8"', image: '' },
            { id: '10"', name: '10"', image: '' },
            { id: 'SD 7"', name: 'SD 7"', image: '/assets/tags/sdua-logo.png' },
            { id: 'SD 8"', name: 'SD 8"', image: '/assets/tags/sdua-logo.png' },
            { id: 'SD 10"', name: 'SD 10"', image: '/assets/tags/sdua-logo.png' },
            { id: 'VD 7"', name: 'VD 7"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'VD 8"', name: 'VD 8"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'VD 10"', name: 'VD 10"', image: '/assets/tags/vd-logo.jpg' },
            { id: 'SOLDERING', name: 'Паяння', image: '' },
            { id: '3D', name: '3D-друк', image: '' },
            { id: 'FILAMENT', name: '3D-друк Філамент', image: '' },
            { id: 'Ukraine', name: 'Ukraine', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/200px-Flag_of_Ukraine.svg.png' },
            { id: 'Tools', name: 'Tools', image: '' },
            { id: 'unsorted', name: 'Без тега', image: '' },
            { id: 'DIGITAL VIDEO', name: 'Цифра', image: '' },
            { id: 'BATTERIES', name: 'Батареї', image: '' },
        ],
        // dict: { 'all': false, 'SDUA 7"': true, 'SDUA 8"': false, 'SDUA 10"': false, 'VD 7"': false, 'VD 8"': false, 'VD 10"': false, 'Soldering': false, '3D': false, 'Ukraine': false, 'unsorted': false },
        // options: [
        //     { id: 'all', name: 'Всі', image: '' },
        //     { id: 'SDUA 7"', name: 'SDUA 7"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'SDUA 8"', name: 'SDUA 8"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'SDUA 10"', name: 'SDUA 10"', image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAMAAAAVHr4VAAAAb1BMVEX///+3t7mcnJ/u7u4AAAcAAADc3N1HR0wICBUQEBscHCX8/Pz5+fnU1NYXFyC9vb7j4+SLi47AwMGSkpVXV1vMzM1gYGSFhYijo6Vzc3bGxsd7e34mJi4AAA1paWwAABETEx2wsLJfX2M+PkQyMjmgAJIbAAABEklEQVR4AXWSBZbEIAxA00KqkApDXcbuf8aFN2Uz+isQPg7wQhTDT4TE5KdMs7wo4R2lwKGRCKsQ81j1q6wjYJr2VVYGmFMMpQ3SlmA6YPoBxjbIaYahAWYoW6yDVDiVQzDVoo1C5DELVEYvfnDbjUZDuqYsN0xBm3Gzx2xxH1nGEp/GtEhyYDkhoQ1O7GcXsRRI511wQ8ILS59y09hHvJTzmdA87dBO+RKkC7L0Ua6jKVElnWndEi+tQTqvQiVTZJ3dcKh9ubxmRPlVEuFUR3h9jCpEpwZnnXPWuR5OqoaAnqCT57OX53N2A2uB6TYo70vhu6WFBPQpMHH8f4cUOKIZmHZ6vX3JBRghjkrDEdfg+QP2MxN2J5mDIgAAAABJRU5ErkJggg==' },
        //     { id: 'VD 7"', name: 'VD 7"', image: 'https://scontent-fra5-1.xx.fbcdn.net/v/t39.30808-1/318043295_145448531581939_6828632755146268235_n.jpg?stp=dst-jpg_p480x480&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ChILHN1nlcMAb6HAfbC&_nc_ht=scontent-fra5-1.xx&oh=00_AfBI53llmoCtGGJO7aWxIVbFOKNzjTcmTr4vZ_sJi1UanQ&oe=6624FD3F' },
        //     { id: 'VD 8"', name: 'VD 8"', image: 'https://scontent-fra5-1.xx.fbcdn.net/v/t39.30808-1/318043295_145448531581939_6828632755146268235_n.jpg?stp=dst-jpg_p480x480&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ChILHN1nlcMAb6HAfbC&_nc_ht=scontent-fra5-1.xx&oh=00_AfBI53llmoCtGGJO7aWxIVbFOKNzjTcmTr4vZ_sJi1UanQ&oe=6624FD3F' },
        //     { id: 'VD 10"', name: 'VD 10"', image: 'https://scontent-fra5-1.xx.fbcdn.net/v/t39.30808-1/318043295_145448531581939_6828632755146268235_n.jpg?stp=dst-jpg_p480x480&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ChILHN1nlcMAb6HAfbC&_nc_ht=scontent-fra5-1.xx&oh=00_AfBI53llmoCtGGJO7aWxIVbFOKNzjTcmTr4vZ_sJi1UanQ&oe=6624FD3F' },
        //     { id: 'Soldering', name: 'Паяння', image: '' },
        //     { id: '3D', name: '3D-друк', image: '' },
        //     { id: 'Ukraine', name: 'Ukraine', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/200px-Flag_of_Ukraine.svg.png' },
        //     { id: 'unsorted', name: 'Без тега', image: '' },
        // ],
    };

    public readonly details_filter: Filter = { key: 'detail', name: 'Деталі', dict: { all: true }, expanded: true, options: [{ id: 'all', name: 'Всі', image: '' },], update: () => this.updateFilters() };

    public filters: Filter[] = [
        this.top_price_filter,
        this.tags_filter,
        this.details_filter,
    ];


    public models: ModelFull[] = [];
    public filtered_models: ModelFull[] = [];

    constructor(public readonly data: DataService, private readonly dialog: MatDialog, public readonly layout: LayoutService) {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.data.getModels().subscribe(models => {
            this.models = models;
            this.updateDetails();
            this.data.restoreFilter(FILTERS_KEY, this.filters);
            this.updateFilters();
        });

    }

    public updateDetails() {

        const all_tags = this.tags_filter.dict['all'];
        const tags = Object.keys(this.tags_filter.dict).filter(k => k && this.tags_filter.dict[k]);

        this.details_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.models.map(m => ({ id: m.detail_id, name: m.detail_name, image: ''}))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.models.some(m => m.detail_id === v.id && (all_tags || tags.some(t => m.model_tags.includes(t)))))
        ];

        const all_options_selected = this.details_filter.options.every(o => this.details_filter.dict[o.id]);
        const no_options_selected = this.details_filter.options.every(o => !this.details_filter.dict[o.id]);

        if (this.details_filter.dict['all'] && all_options_selected || no_options_selected) {
            Object.keys(this.details_filter.dict).forEach(k => this.details_filter.dict[k] = false);
            this.details_filter.dict['all'] = true;
        }

        this.updateFilters();

    }

    private updateFilters() {
        this.data.setFilters(FILTERS_KEY, this.filters);
        this.filtered_models = this.models.filter(m => this.modelVisible(m));
    }

    private modelVisible(m: ModelFull) {

        const tags_dict = this.tags_filter.dict;
        const tags = tags_dict['all'] ? true : m.model_tags && m.tags_array.some(t => tags_dict[t]);

        const top_price_dict = this.top_price_filter.dict;
        const top_price = top_price_dict['all'] || top_price_dict['top'] && m.model_top_price_usd;

        return tags && top_price && (this.details_filter.dict['all'] || this.details_filter.dict[m.detail_id]);
    }

    public getUrl(r: OrderRequestFull) {
        return '/requests/' + r.model_name.replace(/ /g, '-').replace(/[^\w\-]/g, '').toLowerCase();
    }

    public onOpenDetails(model: ModelFull) {
        if (this.data.admin) {
            ModelDialogComponent.open(this.dialog, { model_id: model.model_id, model: model })
        }
    }

    public addNew() {
        if (this.data.superadmin) {
            ModelEditDialogComponent.open(this.dialog, {
                model_id: 0,
                detail_id: 0,
                model_name: '',
                model_image_url: '',
                model_tags: '',
                model_top_price_usd: 0,
                detail_name: '',
                detail_name_local: '',
                detail_units: DetailUnits.Pieces,
                model_count_total: 0,
                model_count_available: 0,
                model_price_min: 0,
                model_price_max: 0,
                model_price_average: 0,
                model_future_price_min: 0,
                model_future_price_max: 0,
                model_future_price_average: 0,
                model_comments: 0,
                model_clicks_daily: 0,
                model_clicks_weekly: 0,
                model_clicks_monthly: 0,
                tags_array: [],
                tags_dict: {},
                view_query_params: {},
                sku_units: '',
                detail_bulk: 0,
                model_image_hash: '',
            });
        }
    }

}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface CommentDialogData {
  comment?: string;
}

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrl: './comment-dialog.component.scss'
})
export class CommentDialogComponent {

  public static open(dialog: MatDialog, data: CommentDialogData) {
      dialog.open(CommentDialogComponent, { data, minWidth: '300px', autoFocus: false,})
  }

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialogData,
  ) { }

  get comment(): string {
    return this.data.comment || 'відсутній';
  }
}

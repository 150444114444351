import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CommentFull, DataService, ProductFull, SellerFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { timestampToLocalDate } from '../utils';
import { DomSanitizer } from '@angular/platform-browser';


export interface SellerDialogData {
    seller_id: number;
    // TODO: fix seller_swarm_id
    seller_swarm_id: number;
}

@Component({
    selector: 'app-seller-dialog',
    templateUrl: './seller-dialog.component.html',
    styleUrl: './seller-dialog.component.scss'
})
export class SellerDialogComponent {

    public static open(dialog: MatDialog, data: SellerDialogData) {
        return dialog.open(SellerDialogComponent, { data, width: '848px', autoFocus: false, restoreFocus: false, panelClass: 'mobile-fullscreen', });
    }

    public seller?: SellerFull;
    public sellers: SellerFull[] = [];
    public products: ProductFull[] = [];
    public comments: CommentFull[] | null = null
    public comments_swarm: CommentFull[] | null = null
    public seller_sdua_url = "";
    public timestampToLocalDate = timestampToLocalDate;

    public control_comment_target = new FormControl<'model' | 'seller' | 'product'>('seller');
    public control_comment_text = new FormControl<string>('');
    public comment_loading = false;
    public comment_edited: CommentFull | null = null;

    public commentEdit(comment: CommentFull) {
        this.comment_edited = comment;
        this.control_comment_text.setValue(comment.comment_text);
        this.control_comment_target.setValue(comment.seller_id ? 'seller' : comment.model_id ? 'model' : 'product');
    }

    public clearComment() {
        this.comment_edited = null;
        this.control_comment_text.setValue('');
        this.control_comment_target.setValue('seller');
    }

    public commentPin(comment_to_pin: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_pin,
            comment_pinned: comment_to_pin.comment_pinned ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_pin, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public commentDelete(comment_to_delete: CommentFull) {

        this.comment_loading = true;

        this.data.upsertComment({
            ...comment_to_delete,
            comment_deleted: comment_to_delete.comment_deleted ? 0 : 1,
        }).subscribe({
            next: comment => {
                this.comment_loading = false;
                Object.assign(comment_to_delete, comment);
                this.sortComments();
            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public upsertComment() {

        const seller = this.seller;
        const comment_text = this.control_comment_text.value;

        if (!seller) return;

        if (!comment_text || comment_text.length < 10) {
            this.snackbar.open('Введіть текст коментаря', 'OK');
            return;
        }

        if (comment_text.length >= 4096) {
            this.snackbar.open('Коментар занадто довгий', 'OK');
            return;
        }

        if (comment_text.includes('<script') || comment_text.includes('<link') || comment_text.includes('<object')) {
            this.snackbar.open('Некоректний коментар', 'OK');
            return;
        }

        const comment_target = this.control_comment_target.value || 'product';

        this.comment_loading = true;

        this.data.upsertComment({
            comment_id: this.comment_edited?.comment_id || null,
            model_id: null,
            drone_id: null,
            seller_id: seller.seller_id,
            product_id: null,
            comment_text: comment_text,
            comment_pinned: this.comment_edited?.comment_pinned ?? 0,
            comment_deleted: this.comment_edited?.comment_deleted ?? 0,
        }).subscribe({
            next: comment => {

                this.comment_loading = false;

                if (this.comment_edited) {
                    Object.assign(this.comment_edited, comment);
                    this.comment_edited = null;
                } else {
                    this.comments?.push(comment);
                }

                this.sortComments();

                this.control_comment_text.setValue('');
                this.control_comment_target.setValue('product');

            },
            error: () => {
                this.comment_loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    constructor(
        public readonly data: DataService,
        @Inject(MAT_DIALOG_DATA) public dialog_data: SellerDialogData,
        private readonly route: ActivatedRoute,
        public readonly layout: LayoutService,
        public readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        public readonly sanitizer: DomSanitizer,
    ) {

        this.initSeller();

    }

    private sortComments() {
        this.comments?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }

    private sortCommentsSWARM() {
        this.comments_swarm?.sort((a, b) => a.comment_pinned && b.comment_pinned
            ? (a.seller_id && !b.seller_id ? -1 : !a.seller_id && b.seller_id ? 1 : a.model_id && !b.model_id ? -1 : !a.model_id && b.model_id ? 1 : a.comment_date - b.comment_date)
            : a.comment_pinned && !b.comment_pinned ? -1
                : !a.comment_pinned && b.comment_pinned ? -1
                    : a.comment_date - b.comment_date
        );
    }

    private initSeller() {

        const seller_id = this.dialog_data.seller_id;
        // TODO: fix seller_swarm_id
        const seller_swarm_id = this.dialog_data.seller_swarm_id;

        this.data.getComments({ seller_id })
            .subscribe({
                next: comments => {
                    this.comments = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortComments();
                }
            });

        // TODO: fix seller_swarm_id
        this.data.getCommentsSWARM({ seller_id: seller_swarm_id })
            .subscribe({
                next: comments => {
                    this.comments_swarm = this.data.superadmin
                        ? comments : comments.filter(c => !c.comment_deleted || c.user_id === this.data.profile?.user_id);
                    this.sortCommentsSWARM();
                }
            });

        this.data.getFullProducts().subscribe({
            next: products => {

                this.seller = products.find(p => p.seller_id === seller_id);
                this.seller_sdua_url = `https://remark.sdua.tech/web/iframe.html?host=https://remark.sdua.tech&site_id=sdua-shops-remark42&theme=light&locale=ua&url=https://sdua.tech/shops/${this.seller?.seller_sdua_name}`;

                this.sellers = products.filter(p => p.seller_id !== seller_id && p.seller_family && p.seller_family === this.seller?.seller_family).filter((v, i, s) => i === s.findIndex(e => e.seller_id === v.seller_id))

                this.products = products.filter(p => p.seller_id === seller_id)
                    .sort((a, b) => (b.product_orders_completed - a.product_orders_completed) || b.item_sold.replace(/\w/g, '').localeCompare(a.item_sold.replace(/\w/g, ''))).slice(0, 9);
            }
        });

    }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { PromosModule } from '../promos/promos.module';
import { SharedModule } from '../shared/shared.module';
import { TrackDialogComponent } from './track-dialog/track-dialog.component';
import { TrackComponent } from './track.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule } from 'ng-recaptcha';
import { CaptchaDialogComponent } from './captcha-dialog/captcha-dialog.component';


@NgModule({
    declarations: [
        TrackComponent,
        TrackDialogComponent,
        // TODO: fix recaptcha
        // CaptchaDialogComponent,
    ],
    exports: [
        TrackComponent,
        TrackDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        PromosModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        RecaptchaModule,
        RecaptchaV3Module,
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lc1IlEqAAAAAMmf1MoN9WqcLo-f0-k2q1q6gsvt' },
    ]
})
export class TrackModule { }

<main>

    <section class="skeleton-block" *ngIf="loading">Завантаження...</section>

    <section class="drone-dummy" *ngIf="!loading&&!drone">
        <span>Нічого не знайдено</span>
        <button mat-button routerLink="/">Відвідати goods.ua-drones.de</button>
    </section>

    <section class="drone-wrapper" *ngIf="!loading&&drone">

        <div class="table">

            <div class="header">
                <mat-icon>info</mat-icon>
                <span>{{drone.drone_type_local}} #{{drone.drone_id}}</span>
                <div class="spacer"></div>
                <a target="_blank" *ngIf="sdua" [href]="drone.drone_sdua_url">
                    <span>{{sdua.did}}</span>
                    <mat-icon>open_in_new</mat-icon>
                </a>
            </div>

            <div class="rows">
                <div class="row">
                    <div class="name">Дата збирання</div>
                    <div class="value">{{drone.drone_date_string}}</div>
                </div>
                <div class="row" *ngIf="drone.drone_name">
                    <div class="name">Назва дрону</div>
                    <div class="value">{{drone.drone_name}}</div>
                </div>
                <div class="row" *ngIf="drone.drone_name">
                    <div class="name">Зовнішній номер</div>
                    <div class="value">{{drone.drone_number}}</div>
                </div>
                <div class="row" *ngIf="tenant">
                    <div class="name">Організація</div>
                    <div class="value">{{tenant.tenant_name}}</div>
                </div>
                <div class="row" *ngIf="drone.user_name">
                    <div class="name">Збиральник</div>
                    <div class="value">
                        <div class="product-extra product-seller">
                            <img class="product-seller-logo" [src]="drone.user_image_url" />
                            <span class="product-seller-name">{{drone.user_name}}</span>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="drone.drone_sdua_url && sdua">
                    <div class="name">Статус на SDUA</div>
                    <div class="value">{{sdua_statuses[sdua.status]}}</div>
                </div>
                <div class="row" *ngIf="drone.drone_price_usd">
                    <div class="name">Вартість, EUR</div>
                    <div class="value"><b>{{drone.drone_price_usd | currency}}</b></div>
                </div>
                <!-- <div class="row" *ngIf="drone.drone_price_uah">
                    <div class="name">Вартість, грн</div>
                    <div class="value"><b>{{drone.drone_price_uah | currency: 'UAH'}}</b></div>
                </div> -->
            </div>

        </div>

        <div class="table">

            <div class="header">
                <mat-icon>settings</mat-icon>
                <span>Комплектуючі</span>
            </div>

            <div class="rows">
                <div class="row" *ngFor="let d of getMainDetails()">
                    <div class="name">
                        <div class="flex">
                            <div>{{d.detail_name_local}}</div>
                            <span *ngIf="drone.drone_price_usd">-</span>
                            <div class="detail-price" *ngIf="drone.drone_price_usd">
                                {{d.detail_price_usd|currency}}
                            </div>
                        </div>
                    </div>
                    <div class="value">{{d.model_name}}</div>
                </div>
            </div>

        </div>

        <div class="table" *ngIf="getAdditionalDetails()?.length">

            <div class="header">
                <mat-icon>add_to_photos</mat-icon>
                <span>Додатково</span>
            </div>

            <div class="rows">
                <div class="row" *ngFor="let d of getAdditionalDetails()">
                    <div class="name">
                        <div class="flex">
                            <div>{{d.detail_name_local}}</div>
                            <span *ngIf="drone.drone_price_usd">-</span>
                            <div class="detail-price" *ngIf="drone.drone_price_usd">
                                {{d.detail_price_usd|currency}}
                            </div>
                        </div>
                    </div>
                    <div class="value">{{d.model_name}}</div>
                </div>
            </div>

        </div>

        <div class="table" *ngIf="drone.drone_public_comment">

            <div class="header">
                <mat-icon>contact_support</mat-icon>
                <span>Про цей дрон</span>
            </div>

            <div class="rows">
                <div class="drone-comment" [innerHtml]="drone.drone_publi_comment_html">
                </div>
            </div>

        </div>

        <div class="table">

            <div class="header">
                <mat-icon>question_answer</mat-icon>
                <span>Зворотній звʼязок</span>
            </div>

            <div class="product-comments-wrapper">

                <ng-container *ngIf="comments">

                    <div *ngIf="!comments.length" class="product-comments-empty">
                        Зворотній звʼязок відсутній
                    </div>

                    <div class="comments" *ngIf="comments.length">
                        <div class="comment" *ngFor="let comment of comments" [class.pinned]="comment.comment_pinned"
                            [class.deleted]="comment.comment_deleted">

                            <div class="product-attrs">

                                <div class="product-extra product-seller">
                                    <img class="product-seller-logo"
                                        [src]="comment.user_image_url || '/assets/logo.png'" />
                                    <span class="product-seller-name">{{comment.user_name || 'Анонім'}}</span>
                                </div>

                                <small class="product-available">
                                    <b>{{comment.comment_date_string}}</b>
                                </small>

                                <small class="product-available pinned" *ngIf="comment.comment_pinned">
                                    <b>закріплено</b>
                                    <mat-icon>push_pin</mat-icon>
                                </small>

                                <div class="spacer"></div>

                                <ng-container
                                    *ngIf="data.superadmin||data.sdua||data.profile&&data.profile.user_id==comment.user_id">
                                    <button mat-icon-button (click)="commentPin(comment)" *ngIf="data.superadmin">
                                        <mat-icon>{{comment.comment_pinned?'highlight_off':'push_pin'}}</mat-icon>
                                    </button>
                                    <button mat-icon-button
                                        (click)="commentEdit(comment)"><mat-icon>edit</mat-icon></button>
                                    <button mat-icon-button (click)="commentDelete(comment)">
                                        <mat-icon>{{comment.comment_deleted?'restore':'delete'}}</mat-icon>
                                    </button>
                                </ng-container>

                            </div>

                            <div class="comment-text">
                                <div [innerHTML]="comment.comment_text"></div>
                            </div>

                        </div>
                    </div>

                    <div class="product-comments-comment">

                        <div contenteditable class="product-comments-editor" [formControl]="control_comment_text">
                        </div>

                        <div class="product-comments-footer">
                            <button mat-flat-button color="primary" [buttonLoading]="comment_loading"
                                (click)="upsertComment()">
                                {{comment_edited ? 'Оновити коментар' : 'Залишити коментар'}}
                            </button>
                            <button mat-button (click)="clearComment()" *ngIf="comment_edited">Відміна</button>
                            <div class="spacer"></div>
                        </div>

                    </div>

                </ng-container>

            </div>

        </div>

        <div class="powered">
            <span>Powered by </span>
            <a routerLink="/">goods.ua-drones.de</a>
        </div>

    </section>

</main>
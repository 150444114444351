import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService, Filter, SellerFull } from "../data.service";
import { LayoutService } from "../layout.service";
import { SellerEditDialogComponent } from "../seller-edit-dialog/seller-edit-dialog.component";

const FILTERS_KEY = 'sellers';

@Component({
    selector: 'app-sellers',
    templateUrl: './sellers.component.html',
    styleUrl: './sellers.component.scss'
})
export class SellersComponent {

    public readonly sellerSourceFilter: Filter = {
        key: 'source',
        name: 'Джерело',
        expanded: true,
        update: () => this.updateFilters(),
        dict: {'all': true, 'aliexpress': false, 'custom': false},
        options: [
            {id: 'all', name: 'Всі', image: ''},
            {id: 'aliexpress', name: 'AliExpress', image: ''},
            {id: 'custom', name: 'Custom', image: ''},
        ],
    };

    public sellers: SellerFull[] = [];
    public filtered_sellers: SellerFull[] = [];
    public filters: Filter[] = [
        this.sellerSourceFilter,
    ];

    constructor(public readonly data: DataService, private readonly dialog: MatDialog, public readonly layout: LayoutService) {

        this.data.restoreFilter(FILTERS_KEY, this.filters);

        this.data.getSellers().subscribe(sellers => {
            this.sellers = sellers;
            this.updateFilters();
        });
    }

    private updateFilters() {
        this.data.setFilters(FILTERS_KEY, this.filters);
        this.filtered_sellers = this.sellers.filter(s => this.sellerVisible(s));
    }

    private sellerVisible(s: SellerFull) {
        const source_dict = this.sellerSourceFilter.dict;
        return source_dict['all'] ? true : (s.seller_source && source_dict[s.seller_source]);
    }

    public addNew() {
        if (this.data.superadmin) {
            SellerEditDialogComponent.open(this.dialog, {
                seller_id: 0,
                seller_name: '',
                seller_number: 0,
                seller_flag_url: '',
                seller_image_url: '',
                seller_open_time: 0,
                seller_positive_rate: 0,
                seller_followers: 0,
                seller_sold: '',
                seller_buyers: '',
                seller_communication: 0,
                seller_rating: 0,
                seller_match: 0,
                seller_name_short: '',
                seller_family: '',
                seller_company: '',
                seller_vat: '',
                seller_license: '',
                seller_address: '',
                seller_representative: '',
                seller_scope: '',
                seller_established: '',
                seller_authority: '',
                seller_source: 'custom',
                seller_view_query_params:{},
                seller_url: '',
                seller_comments: 0,
                seller_swarm_id: 0,
                seller_sdua_name: '',
                seller_currency: 'EUR',
            });
        }
    }
}

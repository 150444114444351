import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DataService, ProductFull } from '../../data.service';
import { MatSort } from '@angular/material/sort';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
    selector: 'app-products-table',
    templateUrl: './products-table.component.html',
    styleUrl: './products-table.component.scss'
})
export class ProductsTableComponent implements AfterViewInit, OnChanges {

    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @Input() products: ProductFull[] = [];

    public displayedColumns: string[] = [
        'model_name',
        'seller_name',
        // 'product_status',
        // 'item_reviews',
        'tags_array',
        // 'product_status',
        'product_orders_completed',
        // 'product_orders_failed',
        'sku_last_available',
        // 'item_max_limit',
        'sku_top_price',
        'product_orders_avg_price_usd',
        'sku_future_sale_price',
        'sku_full_unit_price',
        'sku_full_price',
        'urls'
    ];

    public dataSource: TableVirtualScrollDataSource<ProductFull> = new TableVirtualScrollDataSource();

    @Input()
    public skeleton = false;

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['products']) {
            this.dataSource.data = this.products;
        }
    }

    constructor(public readonly data: DataService,) { }
}

<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До запитів' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_requests.length}}</span>
                    <small>/{{requests.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div class="products-scroll">
        <div class="flex-view">

            <ng-container *ngIf="!requests.length && loading">
                <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
            </ng-container>

            <ng-container *ngIf="requests.length">
                <app-request-card *ngFor="let m of filtered_requests" [request]="m"></app-request-card>
            </ng-container>

        </div>
    </div>

    <div class="products-empty-message" *ngIf="requests.length && !filtered_requests.length">
        Жоден запит не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-empty-message" *ngIf="!loading && !requests.length">
        <div>Запитів ще немає.</div>

        <button mat-flat-button *ngIf="data.admin" (click)="upsertRequest()">
            <mat-icon>add</mat-icon>
            <span>Створити запит</span>
        </button>

    </div>

    <div class="products-buttons">

        <button mat-fab extended color="accent" *ngIf="requests.length && (layout.is_mobile | async)"
            (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_requests.length}}</b><small>/{{requests.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <app-promo-button></app-promo-button>

        <button mat-fab *ngIf="data.admin" (click)="upsertRequest()">
            <mat-icon>add</mat-icon>
        </button>

    </div>

</app-sidebar>
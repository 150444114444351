import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { LayoutService } from '../layout.service';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss'
})
export class AboutComponent implements AfterViewInit {

    public gallery_shown = false;

    public readonly sub = this.route.params.pipe(map(p => p['sub'] as ('' | 'project' | 'purchase' | 'erp' | 'contribute')));

    constructor(
        private readonly title: Title,
        private readonly route: ActivatedRoute,
        public readonly layout: LayoutService,
    ) {
        this.route.fragment.subscribe(fragment => {
            document.querySelector(`[href$="#${fragment}"].anchor`)?.scrollIntoView({ behavior: 'smooth' });
        });
        this.sub.subscribe(sub => {
            setTimeout(() => {
                title.setTitle({
                    '': 'Інфо | ua-drones.de',
                    erp: 'Система обліку | Інфо | ua-drones.de',
                    project: 'Про проєкт | Інфо | ua-drones.de',
                    purchase: 'Закупівлі на AliExpress | Інфо | ua-drones.de',
                    contribute: 'Контрибуція | Інфо | ua-drones.de',
                }[sub] || 'Інфо | ua-drones.de');
            });
        });
    }

    ngAfterViewInit() {
        if (this.route.snapshot.fragment)
            document.querySelector(`[href$="#${this.route.snapshot.fragment}"].anchor`)?.scrollIntoView({ behavior: 'instant' });
    }

}

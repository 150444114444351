import { Component, computed, Inject, model, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService, Detail, KitFull, ModelFull } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

type DetailGroup = Detail & { models: ModelFull[] };

@Component({
    selector: 'app-kit-edit-dialog',
    templateUrl: './kit-edit-dialog.component.html',
    styleUrl: './kit-edit-dialog.component.scss',
})
export class KitEditDialogComponent {

    public models: ModelFull[] = [];
    public details: DetailGroup[] = [];
    public loading = false;
    public models_dict: { [k: number]: ModelFull | null } = {};

    readonly separator_keys_codes: number[] = [ENTER, COMMA];

    public current_model = '';

    public filtered_models: ModelFull[] = [];

    public static open(dialog: MatDialog, data: KitFull) {
        return dialog.open(KitEditDialogComponent, {
            data, minWidth: '720px',
            autoFocus: false,
            restoreFocus: false,
            panelClass: 'mobile-fullscreen',
        });
    }

    filterModels() {
        const current_model = (this.current_model || '').toLowerCase();
        this.filtered_models = current_model
            ? this.models.filter(m => m.model_name.toLowerCase().includes(current_model))
            : this.models.slice();
    }

    addModel(input: HTMLInputElement,) {
        if (this.filtered_models.length === 1) {
            const model = this.filtered_models[0];
            this.form.controls.kit_models_ids.value?.push(model.model_id);
            this.current_model = '';
            input.value = '';
            this.filterModels();
        }
    }

    removeModel(index: number) {
        this.form.controls.kit_models_ids.value?.splice(index, 1);
    }

    selectModel(input: HTMLInputElement, event: MatAutocompleteSelectedEvent) {
        const model = event.option.value as ModelFull;
        this.form.controls.kit_models_ids.value?.push(model.model_id);
        this.current_model = '';
        input.value = '';
        event.option.deselect();
        this.filterModels();
    }

    constructor(
        public readonly data: DataService,
        public ref: MatDialogRef<KitEditDialogComponent>,
        private readonly snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public readonly kit: KitFull,
    ) {

        this.form.patchValue({
            ...kit,
            kit_order: String(kit.kit_order),
            kit_risky: Boolean(kit.kit_risky),
            kit_public: Boolean(kit.kit_public),
            kit_full: Boolean(kit.kit_full),
            kit_verified: !kit.tenant_id,
        });

        if (kit.kit_id) this.form.controls.kit_verified.disable();

        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    this.models_dict[m.model_id] = m;
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                });
                this.filterModels();
            }
        });

    }

    public form = new FormGroup({

        kit_order: new FormControl('', [Validators.required, Validators.pattern(/^-?\d+$/)]),
        kit_public: new FormControl(false),
        kit_full: new FormControl(false),
        kit_verified: new FormControl(false),
        kit_risky: new FormControl(false),
        kit_name: new FormControl('', [Validators.required]),
        kit_slug: new FormControl('', [Validators.required, Validators.pattern(/^[\w-]+$/)]),
        kit_comment: new FormControl('', []),
        kit_description: new FormControl('', []),
        kit_models_ids: new FormControl<number[]>([], [Validators.required]),

    });

    public save() {

        if (!this.form.valid || this.loading) {
            this.form.markAsTouched();
            console.log(this.form);
            return;
        }

        this.loading = true;

        const value = this.form.value;

        this.data.upsertKit({
            kit_id: this.kit.kit_id || 0,
            kit_order: Number(value.kit_order),
            kit_deleted: false,
            kit_risky: value.kit_risky ?? false,
            kit_public: value.kit_public ?? false,
            kit_full: value.kit_full ?? false,
            kit_name: value.kit_name || '',
            kit_slug: value.kit_slug || '',
            kit_models: JSON.stringify(value.kit_models_ids || []),
            kit_comment: value.kit_comment || '',
            kit_description: value.kit_description || '',
            kit_verified: (this.data.superadmin || this.data.sdua) ? value.kit_public || false : false,
        }).subscribe({
            next: kit => {
                Object.assign(this.kit, kit);
                this.ref.close(kit);
                this.snackbar.open('Набір збережено.', 'OK');
            }
        });

    }

    public copyLink() {
        navigator.clipboard.writeText(location.origin + '/products?kit=' + this.form.controls.kit_slug.value?.trim()).then(() => {
            this.snackbar.open('Посилання на набір скопійовано.')
        });
    }

}

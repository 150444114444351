import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";

@Injectable()
export class LayoutService {

    public readonly is_mobile: Observable<boolean>;
    public readonly is_desktop: Observable<boolean>;

    constructor(private breakpointObserver: BreakpointObserver,) {

        const mobile = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
        ]);

        this.is_mobile = mobile.pipe(map(result => result.matches));
        this.is_desktop = mobile.pipe(map(result => !result.matches));

    }

}
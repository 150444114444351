<ng-container *ngIf="item">

    <mat-card [routerLink]="[]" [queryParams]="item.view_query_params"
        (click)="data.click(data.current_click_source, data.CT.OpenModelDialog, item)">
        <div mat-card-image class="product-image-wrapper">

            <img class="product-image" [src]="item.model_image_url" />

            <div class="product-extras">

                <div class="product-extra">
                    {{item.detail_name}}
                </div>

                <div class="product-extra">
                    <b>{{item.model_count_available}}</b>/{{item.model_count_total}} лишилося
                </div>

                <div class="product-extra product-future-price" *ngIf="item.model_top_price_usd">
                    <span>ТОР-ціна: </span>
                    <b>{{ item.model_top_price_usd | currency }}</b>
                </div>

                <span class="product-extra product-future-price"
                    *ngIf="item.model_future_price_min&&item.model_future_price_max">
                    <span>Заплановано: {{ item.model_future_price_min | currency }}</span>
                    <span> - </span>
                    <span>{{ item.model_future_price_max | currency }}</span>
                </span>

            </div>

            <div class="product-card-actions-container">
                <button mat-mini-fab class="product-card-action" (click)="onEdit($event)"
                    *ngIf="data.superadmin||data.sdua">
                    <mat-icon>edit</mat-icon>
                </button>

                <upsert-request-action-button [model_id]="item.model_id" />
            </div>

        </div>

        <mat-card-content>

            <div class="product-name">
                <span>{{item.model_name}}</span>
            </div>

            <mat-chip-set class="product-tags">
                <mat-chip *ngFor="let t of item.tags_array">{{t}}</mat-chip>
            </mat-chip-set>

            <div class="product-price-wrapper">

                <span class="product-price">
                    <span>{{ item.model_price_min | currency }}</span>
                    <span> - </span>
                    <span>{{ item.model_price_max | currency }}</span>
                </span>

            </div>

            <button mat-stroked-button color="primary" target="_blank" class="product-url">
                <mat-icon>trending_down</mat-icon>
                Статистика
            </button>

        </mat-card-content>

    </mat-card>

</ng-container>
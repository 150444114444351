import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, zip } from 'rxjs';
import { DataService, DroneFull, DronePublicFlag, DroneStatus, ModelFull, UserShort, Warehouse } from '../../data.service';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm/confirm.component';
import { dateToLocalDate, dateToLocalTime } from '../../utils';
import { DetailVm, getAnalogBomberDroneDetailsList, getAnalogDroneDetailsList, getDigitalBomberDroneDetailsList, getDigitalDroneDetailsList, ModelStock } from '../details';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


export interface EditDroneDialogData {
    drone_id: number;
}

@Component({
    selector: 'app-edit-drone-dialog',
    templateUrl: './edit-drone-dialog.component.html',
    styleUrl: './edit-drone-dialog.component.scss'
})
export class EditDroneDialogComponent {

    public drone_public_flags: DronePublicFlag[] = ['price', 'craftsman', 'organization'];
    public drone_public_flags_dict: { [k: string]: string } = {
        price: 'Ціна',
        craftsman: 'Збиральник',
        organization: 'Організація',
    };

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public models: ModelFull[] = [];
    public users: UserShort[] = [];

    public static open(dialog: MatDialog, data: EditDroneDialogData) {
        return dialog.open<EditDroneDialogComponent, EditDroneDialogData, DroneFull[]>(
            EditDroneDialogComponent,
            { data, autoFocus: false, width: '960px', maxHeight: '100%', panelClass: 'mobile-fullscreen', },
        );
    }

    public loading = false;

    public form = new FormGroup({
        user_id: new FormControl(this.data.profile?.user_id || 0),
        drone_number: new FormControl(''),
        drone_name: new FormControl(''),
        drone_comment: new FormControl(''),
        drone_public_comment: new FormControl(''),
        drone_status: new FormControl<DroneStatus>('draft'),
        drone_public: new FormControl(false),
        drone_public_flags: new FormControl<DronePublicFlag[]>([]),
        drone_sdua_url: new FormControl('', Validators.pattern(/^https:\/\/sdua.tech\/d\/\w{8}$/)),
    });

    public drone: DroneFull | null = null;
    public details: DetailVm[] = [];

    private update(init: boolean, cb?: Function) {

        zip(this.data.getDrones(!init), this.data.getModels(), this.data.getStock()).subscribe(([drones, models, stock]) => {

            this.users = drones.users;

            const drone = drones.drones.find(d => d.drone_id === this.dialog_data.drone_id);

            if (drone) {

                this.drone = drone;

                if (!this.details.length) {
                    switch (drone.drone_type) {
                        case 'drone-analog-bomber':
                            this.details = getAnalogBomberDroneDetailsList();
                            break;
                        case 'drone-digital-bomber':
                            this.details = getDigitalBomberDroneDetailsList();
                            break;
                        case 'drone-digital':
                            this.details = getDigitalDroneDetailsList();
                            break;
                        case 'drone-analog':
                        default:
                            this.details = getAnalogDroneDetailsList();
                            break;
                    }
                }

                if (init) this.form.patchValue(drone);

                drone.details.forEach(detail => {

                    const this_detail = this.details.find(d => d.detail_name === detail.detail_name && !d.model_id.value);

                    if (!this_detail) return;

                    this_detail.model_name = detail.model_name;
                    this_detail.warehouse_name = detail.warehouse_name;
                    this_detail.model_image_url = detail.model_image_url;
                    this_detail.model_id.setValue(detail.model_id);
                    this_detail.warehouse_id.setValue(detail.warehouse_id);
                    this_detail.detail_price_usd = detail.detail_price_usd;
                    this_detail.detail_price_uah = detail.detail_price_uah;

                });

            }

            this.details.forEach(detail => {
                detail.stock = [];
                detail.models = models.filter(m => m.detail_name === detail.detail_name);
                stock.warehouses.filter(w => !w.warehouse_deleted).forEach(w => {
                    const curent_stock: Warehouse & { stock: ModelStock[] } = { ...w, stock: [] };
                    detail.stock.push(curent_stock);
                    stock.stock.filter(s => s.warehouse_id === w.warehouse_id && s.stock_quantity > 0).forEach(s => {
                        const model = models.find(m => m.model_id === s.model_id && m.detail_name === detail.detail_name);
                        if (model) {
                            curent_stock.stock.push({
                                ...model, ...s,
                                stock_price_usd: drones.model_price_sequences_usd[model.model_id]?.[0] || 0,
                                stock_price_uah: drones.model_price_sequences_uah[model.model_id]?.[0] || 0,
                            });
                        }
                    });
                });
                detail.stock = detail.stock.filter(s => s.stock.length);
            });

            cb?.call(null);

        });
    }

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<EditDroneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialog_data: EditDroneDialogData,
    ) {
        this.update(true);
    }

    public removeStockItem(detail: DetailVm) {
        ConfirmDialogComponent.open(this.dialog, {
            title: 'Відкріпити ' + detail.model_name + '?',
            messages: ['Дійсно хочете відкріпити деталь від дрона? Її буде повернуто на склад ' + detail.warehouse_name + '.'],
            yes: 'Відкріпити',
            no: 'Відміна'
        }).afterClosed().pipe(filter(ok => !!ok)).subscribe(() => {
            this.loading = true;
            this.data.transfer([{
                drone_id: this.dialog_data.drone_id,
                model_id: detail.model_id.value!,
                transfer_quantity: 1,
                src_warehouse_id: null,
                dst_warehouse_id: detail.warehouse_id.value!,
                transfer_date: Math.round(Date.now() / 1000),
                transfer_comment: ''
            }]).subscribe({
                next: () => {
                    this.update(true, () => {
                        this.loading = false;
                        this.snackbar.open('Деталь відкріплено.', 'OK');
                        detail.model_name = '';
                        detail.warehouse_name = '';
                        detail.model_image_url = '';
                        detail.model_id.setValue(null);
                        detail.warehouse_id.setValue(null);
                    });
                }
            });
        });
    }

    public selectStockItem(detail: DetailVm, w: Warehouse, s: ModelStock) {

        ConfirmDialogComponent.open(this.dialog, {
            title: 'Доукомплектувати дрон з ' + s.model_name + '?',
            messages: ['Дійсно хочете доукомплектувати дрон? Деталь буде списано зі складу ' + w.warehouse_name + '.'],
            yes: 'Доукомплектувати',
            no: 'Відміна'
        }).afterClosed().pipe(filter(ok => !!ok)).subscribe(() => {
            this.loading = true;
            this.data.transfer([{
                drone_id: this.dialog_data.drone_id,
                model_id: s.model_id,
                transfer_quantity: 1,
                src_warehouse_id: w.warehouse_id,
                dst_warehouse_id: null,
                transfer_date: Math.round(Date.now() / 1000),
                transfer_comment: ''
            }]).subscribe({
                next: () => {
                    this.update(true, () => {
                        this.loading = false;
                        this.snackbar.open('Дрон доукомплектовано.', 'OK');
                        detail.model_name = s.model_name;
                        detail.warehouse_name = w.warehouse_name;
                        detail.model_image_url = s.model_image_url;
                        detail.model_id.setValue(s.model_id);
                        detail.warehouse_id.setValue(w.warehouse_id);
                        detail.detail_price_usd = s.stock_price_usd;
                        detail.detail_price_uah = s.stock_price_uah;
                    });
                }
            });

        });


    }

    public onSave() {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form.value;

        this.data.updateDrone({
            user_id: value.user_id || 0,
            drone_id: this.dialog_data.drone_id,
            drone_name: value.drone_name || '',
            drone_number: value.drone_number || '',
            drone_comment: value.drone_comment || '',
            drone_status: value.drone_status || 'draft',
            drone_public: value.drone_public || false,
            drone_public_flags: value.drone_public_flags || [],
            drone_sdua_url: value.drone_sdua_url || '',
            drone_public_comment: value.drone_public_comment || '',
        }).subscribe({
            next: drones => {
                this.ref.close(drones);
                this.snackbar.open('Дрон оновлено.', 'OK');
            },
            error: () => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }

    public onPublicChange(e: MatSlideToggleChange, content: HTMLElement) {
        if (!e.checked) return;
        setTimeout(() => {
            content.scrollTo({ top: content.clientHeight, behavior: 'smooth' });
        });
    }

    public copyLink() {
        navigator.clipboard.writeText(location.origin + '/drone/' + this.drone?.drone_uuid_short).then(() => {
            this.snackbar.open('Посилання на дрон скопійовано.')
        });
    }

}

<div mat-dialog-title>
    Внести товар
</div>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">

        <mat-form-field>
            <mat-label>Модель</mat-label>
            <mat-select formControlName="model_id" (closed)="search.value=''" (opened)="search.focus()">
                <mat-option class="mat-select-search-option">
                    <input #search (click)="$event.stopPropagation()" (input)="detector.detectChanges()"
                        placeholder="Почніть вводити для пошуку..." />
                </mat-option>
                <ng-container *ngIf="search.value">
                    <ng-container *ngFor="let m of models">
                        <mat-option [value]="m.model_id"
                            *ngIf="m.model_name.toLowerCase().includes(search.value.trim().toLowerCase())">
                            {{m.model_name}}
                        </mat-option>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!search.value">
                    <mat-optgroup *ngFor="let d of details" [label]="d.detail_name">
                        <mat-option *ngFor="let m of d.models" [value]="m.model_id">{{m.model_name}}</mat-option>
                    </mat-optgroup>
                </ng-container>
            </mat-select>
            <mat-error>Виберіть модель</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Кількість товару<span *ngIf="model">, {{model.sku_units}}</span></mat-label>
            <input matInput placeholder="123" formControlName="transfer_quantity">
            <mat-error>Введіть число<span *ngIf="model"> (одиниці вимірювання - {{model.sku_units}})</span></mat-error>
        </mat-form-field>

        <div class="flex">

            <mat-form-field class="spacer">
                <mat-label>Вартість всього внесеного товару, грн</mat-label>
                <input matInput inputmode="decimal" [placeholder]="'2000.00'" formControlName="order_amount_uah"
                    oninput="this.value=this.value.replace(',','.')" />
            </mat-form-field>

            <mat-form-field class="spacer">
                <mat-label>Вартість всього внесеного товару, $</mat-label>
                <input matInput inputmode="decimal" [placeholder]="'50.00'" formControlName="order_amount_usd"
                    oninput="this.value=this.value.replace(',','.')" />
            </mat-form-field>

        </div>

        <mat-button-toggle-group formControlName="order_cex_src" *ngIf="cex">
            <mat-button-toggle value="mono_usd">Mono <small>{{cex.mono_usd.toFixed(4)}}</small></mat-button-toggle>
            <mat-button-toggle value="privat_usd">Privat
                <small>{{cex.privat_usd.toFixed(4)}}</small></mat-button-toggle>
            <mat-button-toggle value="custom_usd">Власний</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-form-field>
            <mat-label>Склад призначення</mat-label>
            <mat-select formControlName="dst_warehouse_id">
                <mat-option *ngFor="let w of warehouses_data.warehouses" [value]="w.warehouse_id">
                    {{w.warehouse_name}}
                </mat-option>
            </mat-select>
            <mat-error>Виберіть склад призначення</mat-error>
        </mat-form-field>

        <div class="flex">
            <mat-form-field>
                <mat-label>
                    Дата внесення
                </mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_date"
                    formControlName="transfer_date">
            </mat-form-field>
            <mat-form-field>
                <mat-label>
                    Час внесення
                </mat-label>
                <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_time"
                    formControlName="transfer_time">
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>Коментар</mat-label>
            <input matInput placeholder="Коментар..." formControlName="transfer_comment">
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Внести
        товар</button>
</mat-dialog-actions>
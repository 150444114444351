import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService, Detail, Model, ModelFull, OrderRequest, ProductFull, RequestRule } from '../../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


type DetailGroup = Detail & { models: Model[] };

@Component({
    selector: 'app-upsert-request-dialog',
    templateUrl: './upsert-request-dialog.component.html',
    styleUrl: './upsert-request-dialog.component.scss',
})
export class UpsertRequestDialogComponent {

    public loading = false;

    public details: DetailGroup[] = [];
    public models: ModelFull[] = [];

    public static open(dialog: MatDialog, request: OrderRequest | null) {
        return dialog.open(UpsertRequestDialogComponent, { data: request, autoFocus: 'input', width: '420px' })
    }

    public filtered_products: ProductFull[] = [];

    private filterProducts() {
        const model_id = this.form.value.model_id;
        if (!model_id) return;
        this.data.getFullProducts().subscribe({
            next: products => {
                this.filtered_products = products
                    .filter(p => !p.product_deleted && p.product_status !== 'invalid' && p.model_id === model_id);
            }
        });
    }

    constructor(
        public readonly detector: ChangeDetectorRef,
        private readonly snackbar: MatSnackBar,
        public readonly data: DataService,
        public readonly ref: MatDialogRef<UpsertRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly request: OrderRequest | null,

    ) {

        this.form.patchValue(request || {});
        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                })
            }
        });

        this.form.controls.request_rule.valueChanges.subscribe(() => this.filterProducts());
        this.form.controls.model_id.valueChanges.subscribe(() => this.filterProducts());

        if (this.request) {
            this.form.controls.model_id.clearValidators();
        }

        if (!this.creatingNewRequest) {
            this.form.controls.model_id.disable();
        }

    }

    public form = new FormGroup({
        model_id: new FormControl<number | null>(null, [Validators.required]),
        request_quantity: new FormControl(0, [Validators.required, Validators.pattern(/^\d+$/)]),
        request_max_price: new FormControl(0, [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        request_rule: new FormControl<RequestRule>('all', [Validators.required]),
        request_products_ids: new FormControl<number[]>([], []),
    });

    public onDeactivate() {

        const request = this.request;

        if (!request) return;

        this.loading = true;

        this.data.upsertRequest({
            model_id: request.model_id,
            request_quantity: 0,
            request_max_price: request.request_max_price,
            request_rule: request.request_rule,
            request_products: request.request_products,
        }).subscribe({
            next: () => {
                this.snackbar.open('Запит деактивовано!', 'OK');
                request.request_quantity = 0;
                this.ref.close(request);
            },
            error: error => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            },
        });
    }

    public onSave() {

        if (!this.form.valid || this.loading) return;

        const request_quantity = Number(this.form.controls.request_quantity.value || '');
        const request_max_price = Number(this.form.controls.request_max_price.value || '');
        const model_id = Number(this.form.controls.model_id.value || '');
        const request_rule = this.form.controls.request_rule.value || 'all';
        const request_products = JSON.stringify(this.form.controls.request_products_ids.value || [])

        this.loading = true;

        this.data.upsertRequest({
            model_id, request_quantity, request_max_price, request_rule, request_products
        }).subscribe({
            next: request => {

                if (this.request) {
                    this.snackbar.open('Запит збережено!', 'OK');
                    this.request.request_rule = request_rule;
                    this.request.request_products = request_products;
                    this.request.request_quantity = request_quantity;
                    this.request.request_max_price = request_max_price;
                    this.request.request_products_ids = JSON.parse(request_products);
                } else {
                    this.snackbar.open('Запит створено!', 'OK');
                }

                this.ref.close(request);
            },
            error: error => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            },
        });

    }

    get canSave(): boolean {
        return this.form.valid && this.form.dirty && !this.loading;
    }

    get creatingNewRequest(): boolean {
        return this.request?.request_id === undefined;
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { RequestCardComponent } from './request-card/request-card.component';
import { RequestsComponent } from './requests.component';
import { PromosModule } from '../promos/promos.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    RequestCardComponent,
    RequestsComponent,
  ],
  exports: [
    RequestCardComponent,
    RequestsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PromosModule,
    SharedModule,
    RouterModule,
  ]
})
export class RequestsModule { }

import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { DataService, Filter, Model, ModelFull, OrderFull } from '../data.service';
import { LayoutService } from '../layout.service';
import { timestampToLocalDate } from '../utils';
import { OrderUpdateStatusDialogComponent } from './order-update-status-dialog/order-update-status-dialog.component';


interface Stats {
    detail_id: number;
    detail_name: string;
    model_id: number;
    model_name: string;
    model_image_url: string;
    count_total: number;
    count_ordered: number;
    count_shipped: number;
    count_delivered: number;
    count_picked: number;
    count_received: number;
    price_uah: number;
    price_usd: number;
    donates_uah: number;
    amount_uah: number;
    amount_usd: number;
    sku_units: string;
}

const VIEW_MODE_KEY = 'orders-viewmode';

enum ViewModes {
    grid = 'grid',
    table = 'table',
}

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.scss'
})
export class OrdersComponent {

    public orders: OrderFull[] = [];
    public filtered_orders: OrderFull[] = [];
    public filtered_orders_groups: OrderFull[][] = [];
    public loading = false;

    public view_mode = ViewModes.grid;
    public readonly ViewModes = ViewModes;

    public card_height = 420;

    public amount_uah = 0;
    public amount_usd = 0;
    public orders_count = 0;
    public orders_quantity = 0;

    public donates_uah = 0;
    public unit_uah = 0;
    public unit_usd = 0;
    public detail_units = '';

    public stats: Stats[] = [];

    public stats_total = {
        total_price_usd: 0,
        total_donates_uah: 0,
        total_price_uah: 0,
        total_count_total: 0,
        total_count_ordered: 0,
        total_count_shipped: 0,
        total_count_delivered: 0,
        total_count_picked: 0,
        total_count_received: 0
    };

    public readonly status_filter: Filter = {
        key: 'status',
        name: 'Статус',
        expanded: true,
        update: () => this.updateFilters(),
        dict: { all: false, 'ordered': true, 'shipped': true, 'picked': true, 'delivered': true, 'received': true, 'cancelled': false, problem: false },
        options: [
            { id: 'all', name: 'Всі', image: '' },
            { id: 'ordered', name: 'Замовлені', image: '' },
            { id: 'shipped', name: 'Відправлені', image: '' },
            { id: 'delivered', name: 'Доставлені', image: '' },
            { id: 'picked', name: 'Отримані', image: '' },
            { id: 'partial', name: 'Частково отримані', image: '' },
            { id: 'received', name: 'Передані', image: '' },
            { id: 'cancelled', name: 'Відмінені', image: '' },
            { id: 'problem', name: 'Проблема', image: '' },
        ],
    };

    public readonly details_filter: Filter = { key: 'detail', name: 'Деталі', dict: { all: true }, expanded: true, options: [{ id: 'all', name: 'Всі', image: '' },], update: () => this.updateSellers() };
    public readonly sellers_filter: Filter = { key: 'seller', name: 'Продавці', dict: { all: true }, options: [{ id: 'all', name: 'Всі', image: '' },], expanded: false, update: () => this.updateModels(), avatar: true };
    public readonly models_filter: Filter = { key: 'model', name: 'Моделі', dict: { all: true }, options: [{ id: 'all', name: 'Всі', image: '' },], expanded: false, update: () => this.updateFilters(), avatar: true };
    public readonly users_filter: Filter = { key: 'user', name: 'Користувачі', dict: { all: true }, options: [{ id: 'all', name: 'Всі', image: '' },], expanded: true, update: () => this.updateFilters(), avatar: true };
    public readonly recipients_filter: Filter = { key: 'user', name: 'Отримувачі', dict: { all: true }, options: [{ id: 'all', name: 'Всі', image: '' }, { id: '', name: 'Не вказано', image: '' }], expanded: false, update: () => this.updateFilters(), avatar: true };

    public filters: Filter[] = [
        this.status_filter,
        this.details_filter,
        this.sellers_filter,
        this.models_filter,
        this.users_filter,
        this.recipients_filter,
    ];

    public readonly timestampToLocalDate = timestampToLocalDate;

    public filters_key = '';

    public controlFrom = new FormControl(DateTime.now(), [Validators.required]);
    public controlTo = new FormControl(DateTime.now(), [Validators.required]);
    public controlQuery = new FormControl('');

    private get view_key() { return this.filters_key + '-view'; }

    constructor(
        public readonly data: DataService,
        public readonly layout: LayoutService,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        private readonly detector: ChangeDetectorRef,
    ) {

        this.view_mode = localStorage.getItem(VIEW_MODE_KEY) as ViewModes;

        if (!ViewModes[this.view_mode]) {
            this.view_mode = ViewModes.grid;
        }

        this.data.tenant_id_cnange.subscribe(() => {

            const all = location.pathname.startsWith('/orders/all');

            this.filters_key = all ? 'ordersall' : 'orders';

            this.data.restoreFilter(this.filters_key, this.filters);

            const user_id = this.data.profile?.user_id || 0;

            const $orders = this.data.admin
                ? all ? this.data.getAllOrders() : this.data.getAllOrders().pipe(map(orders => orders.filter(o => o.user_id === user_id)))
                : this.data.getMyOrders();

            this.loading = true;

            $orders.subscribe({
                next: orders => {
                    this.loading = false;
                    this.orders = orders;

                    const min_timestamp = orders.length
                        ? orders.reduce((a, c) => c.order_date < a ? c.order_date : a, Number.POSITIVE_INFINITY)
                        : new Date(2024, 0, 1).getDate() / 1000;

                    this.controlFrom.setValue(DateTime.fromMillis(min_timestamp * 1000) as any);

                    this.controlTo.valueChanges.subscribe(() => this.updateFilters());
                    this.controlFrom.valueChanges.subscribe(() => this.updateFilters());
                    this.controlQuery.valueChanges.subscribe(() => this.updateFilters());

                    this.updateDetails();
                    this.updateSellers();
                    this.updateModels();
                    this.updateModels();

                }
            });

        });

    }
    @HostListener('window:resize', ['$event'])
    private updateGroups() {

        const style = getComputedStyle(document.documentElement);

        const card_gap = Number(style.getPropertyValue('--card-gap').replace('px', ''));
        const card_width = Number(style.getPropertyValue('--card-width').replace('px', ''));

        let group_size = 2;

        function screen_width($n: number) {
            return ($n + 1) * card_width + card_gap * ($n + 1);
        }

        for (let i = 3; i < 10; ++i) {
            if (window.innerWidth >= screen_width(i)) {
                group_size = i;
            } else {
                break;
            }
        }

        this.filtered_orders_groups = [...Array(Math.ceil(this.filtered_orders.length / group_size))]
            .map((_, i) => this.filtered_orders.slice(i * group_size, i * group_size + group_size));

        this.card_height = window.innerWidth > 959.98 ? 404 : (window.innerWidth - 24) / 2 + 136;

    }

    private updateFilters() {

        if (this.controlFrom.invalid || this.controlTo.invalid) return;

        this.deselectAll();

        this.amount_uah = 0;
        this.amount_usd = 0;
        this.donates_uah = 0;
        this.orders_count = 0;
        this.orders_quantity = 0;

        this.filtered_orders = this.orders.filter(o => this.orderVisible(o));

        this.filtered_orders.forEach(o => {
            this.amount_uah += o.order_amount_uah;
            this.amount_usd += o.order_amount_usd;
            this.donates_uah += o.order_donate_amount_uah;
            this.orders_quantity += o.order_quantity;
        });

        this.orders_count = this.filtered_orders.length;

        const stats_dict: { [k: number]: Stats } = {};

        this.stats = this.filtered_orders.filter((v, i, s) => i === s.findIndex(o => o.model_id === v.model_id)).map(o => {
            const stats = {
                detail_id: o.detail_id,
                detail_name: o.detail_name,
                model_id: o.model_id,
                model_name: o.model_name,
                model_image_url: o.model_image_url,
                count_total: 0,
                count_ordered: 0,
                count_shipped: 0,
                count_delivered: 0,
                count_picked: 0,
                count_received: 0,
                amount_uah: 0,
                amount_usd: 0,
                price_uah: 0,
                price_usd: 0,
                donates_uah: 0,
                sku_units: '',
            };
            stats_dict[o.model_id] = stats;
            return stats;
        });

        this.filtered_orders.forEach(o => {
            const stats = stats_dict[o.model_id];
            stats.sku_units = o.sku_units;
            stats.count_total += o.order_quantity;
            // stats.count_total += o.order_status === 'cancelled' ? 0 : o.order_quantity;
            stats.price_uah += o.order_amount_uah;
            stats.price_usd += o.order_amount_usd;
            stats.donates_uah += o.order_donate_amount_uah;
            stats.amount_uah += o.order_amount_uah;
            stats.amount_usd += o.order_amount_usd;
            stats.count_ordered += o.order_status === 'ordered' ? o.order_quantity : 0;
            stats.count_shipped += o.order_status === 'shipped' ? o.order_quantity : 0;
            stats.count_delivered += o.order_status === 'delivered' ? o.order_quantity : 0;
            stats.count_picked += o.order_status === 'picked' ? o.order_quantity : 0;
            stats.count_received += o.order_status === 'received' ? o.order_quantity : 0;
        });

        this.stats.forEach(s => {
            s.price_uah = s.amount_uah / s.count_total;
            s.price_usd = s.amount_usd / s.count_total;
            s.donates_uah /= s.count_total;
        });
        this.stats.sort((a, b) => b.count_total - a.count_total);

        this.stats_total.total_price_usd = 0;
        this.stats_total.total_donates_uah = 0;
        this.stats_total.total_price_uah = 0;
        this.stats_total.total_count_total = 0;
        this.stats_total.total_count_ordered = 0;
        this.stats_total.total_count_shipped = 0;
        this.stats_total.total_count_delivered = 0;
        this.stats_total.total_count_picked = 0;
        this.stats_total.total_count_received = 0;

        this.stats.forEach(s => {
            this.stats_total.total_price_usd += s.price_usd * s.count_total;
            this.stats_total.total_donates_uah += s.donates_uah * s.count_total;
            this.stats_total.total_price_uah += s.price_uah * s.count_total;
            this.stats_total.total_count_total += s.count_total;
            this.stats_total.total_count_ordered += s.count_ordered;
            this.stats_total.total_count_shipped += s.count_shipped;
            this.stats_total.total_count_delivered += s.count_delivered;
            this.stats_total.total_count_picked += s.count_picked;
            this.stats_total.total_count_received += s.count_received;
        });

        this.data.setFilters(this.filters_key, this.filters);

        this.updateGroups();

        const multiple_detail = this.filtered_orders.length
            && this.filtered_orders.some(o => o.detail_id !== this.filtered_orders[0].detail_id);

        if (!multiple_detail && this.filtered_orders.length) {
            this.detail_units = this.filtered_orders[0].sku_units;
            let orders_amount_usd = 0;
            let orders_amount_uah = 0;
            let quantity = 0;
            this.filtered_orders.forEach(o => {
                if (o.order_status === 'cancelled') return;
                quantity += o.order_quantity;
                orders_amount_usd += o.order_amount_usd;
                orders_amount_uah += o.order_amount_uah;
            });
            this.unit_usd = orders_amount_usd / quantity;
            this.unit_uah = orders_amount_uah / quantity;
        } else {
            this.detail_units = '';
        }
    }

    public updateDetails() {

        this.details_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.orders.map(p => ({ id: p.detail_id, name: p.detail_name, image: '' }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i)
        ];

        const all_options_selected = this.details_filter.options.every(o => this.details_filter.dict[o.id]);
        const no_options_selected = this.details_filter.options.every(o => !this.details_filter.dict[o.id]);

        if (all_options_selected || no_options_selected) {
            Object.keys(this.details_filter.dict).forEach(k => this.details_filter.dict[k] = false);
            this.details_filter.dict['all'] = true;
        }

    }

    public updateSellers() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);

        const all_old_options_selected = this.sellers_filter.options.length && this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);

        this.sellers_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.orders.map(p => ({ id: p.seller_id || '', name: p.seller_name_short || 'Не AliExpress', image: p.seller_image_url || '' }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.orders.some(p => p.seller_id === v.id && (this.details_filter.dict['all'] || detail_ids.includes(p.detail_id))))
                .sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : b.name?.toLowerCase() > a.name?.toLowerCase() ? -1 : 0)
                // TODO: fix ^^
        ];

        const all_options_selected = this.sellers_filter.options.every(o => this.sellers_filter.dict[o.id]);
        const no_options_selected = this.sellers_filter.options.every(o => !this.sellers_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.sellers_filter.dict).forEach(k => this.sellers_filter.dict[k] = false);
            this.sellers_filter.dict['all'] = true;
        }

        this.updateModels();

    }

    public updateModels() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);
        const seller_ids = Object.keys(this.sellers_filter.dict).map(numberOrNull).filter(id => this.sellers_filter.dict[id as any]);

        const all_old_options_selected = this.models_filter.options.length && this.models_filter.options.every(o => this.models_filter.dict[o.id]);

        this.models_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.orders.map(p => ({ id: p.model_id, name: p.model_name, image: p.model_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.orders.some(p => p.model_id === v.id
                        && (this.details_filter.dict['all'] || detail_ids.includes(p.detail_id))
                        && (this.sellers_filter.dict['all'] || p.seller_id && seller_ids.includes(p.seller_id))))
                .sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : b.name?.toLowerCase() > a.name?.toLowerCase() ? -1 : 0)
                // TODO: fix ^^
        ];

        const all_options_selected = this.models_filter.options.every(o => this.models_filter.dict[o.id]);
        const no_options_selected = this.models_filter.options.every(o => !this.models_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.models_filter.dict).forEach(k => this.models_filter.dict[k] = false);
            this.models_filter.dict['all'] = true;
        }

        this.updateRecipients();
        this.updateUsers();

    }

    public updateRecipients() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);
        const seller_ids = Object.keys(this.sellers_filter.dict).map(numberOrNull).filter(id => this.sellers_filter.dict[id as any]);
        const models_ids = Object.keys(this.models_filter.dict).map(Number).filter(id => this.models_filter.dict[id]);

        const all_old_options_selected = this.recipients_filter.options.length && this.recipients_filter.options.every(o => this.recipients_filter.dict[o.id]);

        this.recipients_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            { id: '', name: 'Не вказано', image: '' },
            ...this.orders.filter(o => o.order_recipient?.trim()).map(p => ({ id: p.order_recipient, name: p.order_recipient, image: '' }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.orders.some(p => p.order_recipient === v.id
                        && (this.details_filter.dict['all'] || detail_ids.includes(p.detail_id))
                        && (this.sellers_filter.dict['all'] || p.seller_id && seller_ids.includes(p.seller_id))
                        && (this.models_filter.dict['all'] || models_ids.includes(p.model_id))))
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = this.recipients_filter.options.every(o => this.recipients_filter.dict[o.id]);
        const no_options_selected = this.recipients_filter.options.every(o => !this.recipients_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.recipients_filter.dict).forEach(k => this.recipients_filter.dict[k] = false);
            this.recipients_filter.dict['all'] = true;
        }

    }

    public updateUsers() {

        const detail_ids = Object.keys(this.details_filter.dict).map(Number).filter(id => this.details_filter.dict[id]);
        const seller_ids = Object.keys(this.sellers_filter.dict).map(numberOrNull).filter(id => this.sellers_filter.dict[id as any]);
        const models_ids = Object.keys(this.models_filter.dict).map(Number).filter(id => this.models_filter.dict[id]);

        const all_old_options_selected = this.users_filter.options.length && this.users_filter.options.every(o => this.users_filter.dict[o.id]);

        this.users_filter.options = [
            { id: 'all', name: 'Всі', image: '' },
            ...this.orders.map(p => ({ id: p.user_id, name: p.user_name, image: p.user_image_url }))
                .filter((v, i, s) => s.findIndex(d => d.id === v.id) === i
                    && this.orders.some(p => p.user_id === v.id
                        && (this.details_filter.dict['all'] || detail_ids.includes(p.detail_id))
                        && (this.sellers_filter.dict['all'] || p.seller_id && seller_ids.includes(p.seller_id))
                        && (this.models_filter.dict['all'] || models_ids.includes(p.model_id))))
                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)
        ];

        const all_options_selected = this.users_filter.options.every(o => this.users_filter.dict[o.id]);
        const no_options_selected = this.users_filter.options.every(o => !this.users_filter.dict[o.id]);

        if (all_old_options_selected || all_options_selected || no_options_selected) {
            Object.keys(this.users_filter.dict).forEach(k => this.users_filter.dict[k] = false);
            this.users_filter.dict['all'] = true;
        }

        this.updateFilters();

    }


    public orderVisible(o: OrderFull) {

        const visible = (this.status_filter.dict['all'] || this.status_filter.dict[o.order_status] || this.status_filter.dict['problem'] && o.order_problematic)
            && (this.details_filter.dict['all'] || this.details_filter.dict[o.detail_id])
            && (this.sellers_filter.dict['all'] || o.seller_id && this.sellers_filter.dict[o.seller_id])
            && (this.models_filter.dict['all'] || this.models_filter.dict[o.model_id])
            && (this.users_filter.dict['all'] || this.users_filter.dict[o.user_id])
            && (this.recipients_filter.dict['all'] || this.recipients_filter.dict[o.order_recipient]);

        if (visible) {

            const from = Math.round((this.controlFrom.value?.startOf('day').toMillis() || 0) / 1000);
            const to = Math.round((this.controlTo.value?.endOf('day').toMillis() || 0) / 1000);

            const date_visible = from <= o.order_date && o.order_date <= to;

            if (date_visible) {

                const query = this.controlQuery.value?.trim().toLowerCase();

                if (query) {
                    return o.order_search_description.includes(query);
                }

                return true;

            }

            return false;
        }

        return false;
    }

    private view_modes = [
        { mode: ViewModes.grid, icon: 'grid_view', name: 'Переглянути змовлення' },
        { mode: ViewModes.table, icon: 'table_rows', name: 'Переглянути статистику' },
        // TODO: додати перегляд у вигляді статистики
        // { mode: ViewModes.list, icon: 'view_stats', name: 'Переглянути статистику' }
    ]

    public toggleViewMode() {
        this.view_mode = this.next_view_mode.mode;
        localStorage.setItem(VIEW_MODE_KEY, this.view_mode);
    }

    public get next_view_mode() {
        const curr_index = this.view_modes.findIndex(m => m.mode === this.view_mode);
        const next_index = (curr_index + 1) % this.view_modes.length;
        return this.view_modes[next_index];
    }

    public group_actions = false;
    public selected_models: (Model & { selected_quantity: number; model_image_url: string; })[] = [];
    public bulk_allow_pick = false;
    public bulk_allow_cancel = false;
    public bulk_allow_receive = false;

    public cancel() {

        const selected_orders = this.filtered_orders.filter(f => f.selected);

        if (!selected_orders.length) {
            this.snackbar.open('Нічого не вибрано', 'OK');
            return;
        }

        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'cancelled', orders: selected_orders }).finally(() => {
            this.updateSelection();
            this.detector.detectChanges();
        });
    }

    public pick() {

        const selected_orders = this.filtered_orders.filter(f => f.selected);

        if (!selected_orders.length) {
            this.snackbar.open('Нічого не вибрано', 'OK');
            return;
        }

        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'picked', orders: selected_orders }).finally(() => {
            this.updateSelection();
            this.detector.detectChanges();
        });
    }

    public receive() {

        const selected_orders = this.filtered_orders.filter(f => f.selected);

        if (!selected_orders.length) {
            this.snackbar.open('Нічого не вибрано', 'OK');
            return;
        }

        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'received', orders: selected_orders }).finally(() => {
            this.updateSelection();
            this.detector.detectChanges();
        });
    }

    public enableGroupActions() {
        this.deselectAll();
        this.group_actions = true;
    }

    public disableGroupActions() {
        this.deselectAll();
        this.group_actions = false;
    }

    public selectAll() {
        this.filtered_orders.forEach(o => o.selected = true);
        this.updateSelection();
    }

    public deselectAll() {
        this.orders.forEach(o => o.selected = false);
        this.updateSelection();
    }

    public updateSelection() {
        this.bulk_allow_pick = true;
        this.bulk_allow_cancel = true;
        this.bulk_allow_receive = true;
        this.selected_models = [];
        this.filtered_orders.filter(o => o.selected).forEach(order => {
            if (['cancelled'].includes(order.order_status)) this.bulk_allow_cancel = false;
            if (['picked', 'cancelled', 'received'].includes(order.order_status)) this.bulk_allow_pick = false;
            if (['cancelled', 'received'].includes(order.order_status)) this.bulk_allow_receive = false;
            let selected_model = this.selected_models.find(m => m.model_id === order.model_id);
            if (!selected_model) {
                selected_model = { ...order, selected_quantity: 0 };
                this.selected_models.push(selected_model);
            }
            selected_model.selected_quantity += order.order_quantity;
        });
    }

}

function numberOrNull(str: string | null) {
    if (str === null || str === undefined || str === 'null') return null;
    return Number(str);
}

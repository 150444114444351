import { Component } from '@angular/core';
import { DataService, KitFull, ProductFull } from '../data.service';
import { zip } from 'rxjs';
import { getKitProductsAndPrice } from '../utils';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {

    public kits_public: KitFull[] = [];
    public products_top: ProductFull[] = [];
    public products_trending: ProductFull[] = [];
    public products_local: ProductFull[] = [];

    public control_trending_period = new FormControl<'daily' | 'weekly' | 'monthly'>('daily');

    private kits_all: KitFull[] = [];
    private products_all: ProductFull[] = [];

    public stats = [
        {
            name: 'Товари в системі',
            desc: 'Всього унікальних позицій',
            value: 0,
            format: 'numeric',
            role: '',
        },
        {
            name: 'Активні користувачі',
            desc: 'Більше 5 покупок',
            value: 0,
            format: 'numeric',
            role: 'superadmin',
        },
        {
            name: 'Сума всіх замовлень',
            desc: 'Всіма користувачами через ERP',
            value: 0,
            format: 'currency',
            role: 'superadmin',
        },
        {
            name: 'Дронів зібрано',
            desc: 'Через ERP систему goods.ua-drones.de',
            value: 0,
            format: 'numeric',
            role: 'superadmin',
        },
        {
            name: 'Посилок відслідковано',
            desc: 'Системою поштового трекінгу',
            value: 0,
            format: 'numeric',
            role: '',
        },
    ];

    constructor(
        public readonly data: DataService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {

        this.data.getStats().subscribe({
            next: stats => {
                this.stats[0].value = stats.products_count;
                this.stats[1].value = stats.users_count;
                this.stats[2].value = stats.orders_amount;
                this.stats[3].value = stats.drones_count;
                this.stats[4].value = stats.trackings_count;
            },
        });

        if (Object.keys(this.route.snapshot.queryParams).length) {
            this.router.navigate(['/products'], { queryParams: this.route.snapshot.queryParams });
            return;
        }

        this.control_trending_period.valueChanges.subscribe(() => this.updateTrendingProducts());

        zip(this.data.getKits(), this.data.getFullProducts()).subscribe({
            next: ([kits, products]) => {

                this.kits_all = kits;
                this.products_all = products;

                this.kits_public = this.kits_all.filter(k => !k.tenant_id);
                this.kits_public.forEach(k => k.kit_price = getKitProductsAndPrice(k, products, false, false).price);

                this.updateTopProducts();
                this.updateTrendingProducts();

                setTimeout(() => {

                    document.querySelectorAll('.products-carousel-wrapper').forEach(carousel => {

                        let interval = 0;

                        function initInterval() {
                            clearInterval(interval);
                            interval = setInterval(tryScroll, 10000) as any;
                        }

                        function tryScroll() {

                            const width = carousel.clientWidth;

                            if (!width) {
                                clearInterval(interval);
                                return;
                            }

                            if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
                                carousel.scroll({ left: 0, behavior: 'smooth' });
                            } else {
                                carousel.scroll({ left: carousel.scrollLeft + width - 8, behavior: 'smooth' });
                            }

                        }

                        initInterval();
                        carousel.addEventListener('mousemove', initInterval);
                        carousel.addEventListener('scroll', initInterval);

                    });

                }, 100);

            }
        });

        this.data.models_updated.subscribe(() => this.updateTopProducts());

    }

    public updateTopProducts() {

        const filtered_products = this.products_all
            .filter(p => p.sku_last_available && p.product_status === 'verified')
            .sort((a, b) => a.sku_min_unit_price - b.sku_min_unit_price)
            .filter((v, i, s) => s.findIndex(p => p.model_id === v.model_id) === i);

        this.products_top = filtered_products
            .filter(p => p.model_top_price_usd && p.sku_min_unit_price <= p.model_top_price_usd)
            .sort((a, b) => (b.model_top_price_usd - b.sku_min_unit_price) / b.sku_min_unit_price - (a.model_top_price_usd - a.sku_min_unit_price) / a.sku_min_unit_price)
            .slice(0, 16);

    }

    public updateTrendingProducts() {

        // TODO: check currency
        this.products_local = this.products_all.filter(p => p.seller_currency === 'UAH')
            .sort((a, b) => b.sku_full_price - a.sku_full_price).slice(0, 16);

        const filtered_products = this.products_all
            .filter(p => p.sku_last_available && p.product_status === 'verified')
            .sort((a, b) => a.sku_min_unit_price - b.sku_min_unit_price)
            .filter((v, i, s) => s.findIndex(p => p.model_id === v.model_id) === i);

        switch (this.control_trending_period.value) {
            case 'monthly':
                filtered_products.sort((a, b) => b.model_clicks_monthly - a.model_clicks_monthly)
                break;
            case 'weekly':
                filtered_products.sort((a, b) => b.model_clicks_weekly - a.model_clicks_weekly)
                break;
            case 'daily':
            default:
                filtered_products.sort((a, b) => b.model_clicks_daily - a.model_clicks_daily)
                break;
        }

        this.products_trending = filtered_products.slice(0, 16);

    }

}
<mat-drawer-container>
    <mat-drawer #drawer autoFocus="false" [mode]="(layout.is_mobile | async) ? 'over' : 'side'"
        [opened]="layout.is_desktop| async">
        <div class="sidebard-content">
            <ng-content select="[role=sidebar]"></ng-content>
        </div>
    </mat-drawer>

    <mat-drawer-content #content>
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>
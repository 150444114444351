<div mat-dialog-title>
    {{ membership ? 'Редагувати організацію' : 'Створити організацію' }}
</div>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
        <div class="my-2">
            <mat-form-field>
                <mat-label>Назва організації</mat-label>
                <input matInput placeholder="БФ Пес Патрон" formControlName="tenant_name">
                <mat-error>Поле обов'язкове до заповнення</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Скасувати</button>
    <button type="submit" mat-flat-button color="accent" (click)="onSave()" [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>
<main>

    <h1 style="margin-top: 0;">Барахолка</h1>

    <p>
        На цій сторінці зібраний хлам (і не тільки), який може бути комусь корисний.
    </p>

    <p>
        Більшість позицій з цієї сторінки розповсюджуються безкоштовно, якщо не вказано іншого.
    </p>

    <p>
        Для замовлення - заповнюйте <a href="https://forms.gle/CMbktZ8b2kmjQU8v8" target="_blank">Google-форму</a>.
    </p>

    <p>
        Прохання, не зловживати можливістю замовити собі мілкотню безкоштовно, залиште трохи для людей, кому це може
        знадобитися тут і зараз. (НЕ РОЗПОВСЮДЖУЄТЬСЯ НА РОЗДІЛ ХЛАМ - забирайте хоч і все 😊)
    </p>

    <p>
        Якщо маєте якісь питання - пишіть адміністратору спільноти.
        Якщо буде бажання віддячити адміністратору за впорядкування цього каталогу -
        адмін буде дуже вдячний (<a href="https://send.monobank.ua/jar/9K717zoL1V" target="_blank">банка</a>)
    </p>

    <p style="margin-bottom: 24px;">
        В списку нижче наведені всі наявні позиції, ще нижче - фото кожної з позицій, на випадок якщо виникнуть питання.
        Також де відомо - наведені посилання на AliExpress, де це було закуплено.
    </p>

    <ul>
        <li>
            <p><b>Пігтейли</b></p>
            <ul class="padded">
                <li>
                    <span>Пігтейл MMCX тато кутовий - SMA мама - 10см </span>
                    <a href="https://s.click.aliexpress.com/e/_oCs2D9T" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Пігтейл MMCX тато - SMA мама - 7см </span>
                    <a href="https://s.click.aliexpress.com/e/_oo9HPpF" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Пігтейл MMCX тато - SMA мама - 5см </span>
                    <a href="https://s.click.aliexpress.com/e/_Dd76I71" target="_blank">AliExpress</a>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Стійки</b></p>
            <ul class="padded">
                <li>
                    <span>Стійки М3*18 (коротка стійка рами Mark4 7") </span>
                    <a href="https://s.click.aliexpress.com/e/_DlaEHaT" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Стійки М3*25 (довга стійка рами Mark4 7") </span>
                    <a href="https://s.click.aliexpress.com/e/_DlaEHaT" target="_blank">AliExpress</a>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Гайки М3</b></p>
            <ul class="padded">
                <li>
                    <span>Гайки М3 </span>
                    <a href="https://s.click.aliexpress.com/e/_DF2Ek1d" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Гайки запресовочні М3-2 (підходять для Mark4, Mark4 V2) </span>
                    <a href="https://www.aliexpress.com/item/4001234434448.html" target="_blank">AliExpress</a>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Болти М3</b></p>
            <ul class="padded">
                <li>
                    <span>М3*8 потай (верхня кришка рами Mark4) </span>
                    <a href="https://www.aliexpress.com/item/32788446987.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*8 (для двигунів на Mark4 7") </span>
                    <a href="https://s.click.aliexpress.com/e/_DmalYV5" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*10 (для двигунів на Mark4 V2 10") </span>
                    <a href="https://s.click.aliexpress.com/e/_DmalYV5" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*11 (для двигунів на Mark4 V2 10") </span>
                    <a href="https://www.aliexpress.com/item/32546591157.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*12 (коротші болти для низу рами Mark4) </span>
                    <a href="https://www.aliexpress.com/item/1005002413652525.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*14 (довші болти для низу рами Mark4) </span>
                    <a href="https://www.aliexpress.com/item/1005002413652525.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*25 (болти для стеку рами Mark4) </span>
                    <a href="https://www.aliexpress.com/item/1005002413652525.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>М3*30 (кріплення скидів тощо) </span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Демпфери</b></p>
            <ul class="padded">
                <li>
                    <span>Демпфери для стеку (SpeedyBee) </span>
                </li>
                <li>
                    <span>Демпфери для стеку </span>
                    <a href="https://s.click.aliexpress.com/e/_DmWEPzp" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Шайби гумові М2 </span>
                </li>
                <li>
                    <span>Шайби гумові М3 </span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Болти М4</b></p>
            <ul class="padded">
                <li>
                    <span>Болти М4*8 </span>
                </li>
                <li>
                    <span>Болти М4*30 </span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>VTX, VRX, TX</b></p>
            <ul class="padded">
                <li>
                    <span>Сірий 3.3 ГГц VTX + VRX - 1шт - <b>3 500 грн </b></span>
                    <a href="https://s.click.aliexpress.com/e/_DFJFgmX" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Чорний 3.3 ГГц VTX + VRX - 1шт - <b>3 000 грн </b></span>
                    <a href="https://www.aliexpress.com/item/1005007293747964.html" target="_blank">AliExpress</a>
                </li>
                <li>
                    <span>Cyclone ELRX 915M TX (закирпичений прошивкою, нема часу на нього) - 1шт - <b>1000 грн </b></span>
                </li>
                <li>
                    <span>AxisFlying Terk 3W 5.8G 56CH - 1шт - <b>1800 грн </b></span>
                </li>
                <li>
                    <span>iFlight Blitz 1.6W 5.8G 40CH - 15шт - <b>900 грн </b></span>
                </li>
                <li>
                    <span>Panda VTX 5.8G 2.5W 40CH - 1шт - <b>1200 грн </b></span>
                </li>
                <li>
                    <span>ReadyToSky ULTRA1600 4.9-5.8G 1.6W 56CH - 1шт - <b>600 грн </b></span>
                </li>
                <li>
                    <span>ReadyToSky ULTRA3000 4.9-5.8G 3W 56CH - 1шт - <b>800 грн </b></span>
                </li>
                <li>
                    <span>AocodaRC 5.8G 1.6 48CH - 1шт - <b>700 грн</b></span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Пропелери</b></p>
            <ul class="padded">
                <li>
                    <span>AMMProp 7*3.5*3</span>
                </li>
                <li>
                    <span>Noname 7*4*3</span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Антени</b></p>
            <ul class="padded">
                <li>
                    <span>Антена Mapple Wireless 1.2 ГГц RHCP SMA 15см - 20шт - <b>350 грн</b></span>
                </li>
            </ul>
        </li>

        <li>
            <p><b>Хлам</b></p>
            <ul class="padded">
                <li>
                    <span>Комплектні антени від VTX 5.8 ГГц</span>
                </li>
                <li>
                    <span>Конденсатори від стеків SpeedyBee</span>
                </li>
                <li>
                    <span>Кнопки від камер Caddx Ratel</span>
                </li>
                <li>
                    <span>Стрепи 20см</span>
                </li>
            </ul>
        </li>

    </ul>

    <h2>Пігтейли</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/pigtail-10cm.jpg" />
                <div>Пігтейл MMCX тато кутовий - SMA мама - 10см</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/pigtail-7cm.jpg" />
                <div>Пігтейл MMCX тато - SMA мама - 7см</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/pigtail-5cm.jpg" />
                <div>Пігтейл MMCX тато - SMA мама - 5см</div>
            </div>
        </div>

    </div>

    <h2>Стійки</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-18-stand.jpg" />
                <div>М3*18 (коротка стійка рами Mark4 7")</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-25-stand.jpg" />
                <div>М3*25 (довга стійка рами Mark4 7")</div>
            </div>
        </div>

    </div>

    <h2>Гайки М3</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-nut.jpg" />
                <div>Гайки М3</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-2-nut.jpg" />
                <div>Гайки запресовочні М3-2 (підходять для Mark4, Mark4 V2)</div>
            </div>
        </div>

    </div>

    <h2>Болти М3</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-8-hide.jpg" />
                <div>М3*8 потай (верхня кришка рами Mark4)</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-8.jpg" />
                <div>М3*8 (для двигунів на Mark4 7")</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-10.jpg" />
                <div>М3*10 (для двигунів на Mark4 V2 10")</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-11.jpg" />
                <div>М3*11 (для двигунів на Mark4 V2 10")</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-12.jpg" />
                <div>М3*12 (коротші болти для низу рами Mark4)</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-14.jpg" />
                <div>М3*14 (довші болти для низу рами Mark4)</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-25.jpg" />
                <div>М3*25 (болти для стеку рами Mark4)</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-30.jpg" />
                <div>М3*30 (кріплення скидів тощо)</div>
            </div>
        </div>

    </div>

    <h2>Демпфери</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/stack-dempfer.jpg" />
                <div>Демпфери для стеку (SpeedyBee)</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/dempfer.jpg" />
                <div>Демпфери для стеку</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m2-washer.jpg" />
                <div>Шайби гумові М2</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m3-washer.jpg" />
                <div>Шайби гумові М3</div>
            </div>
        </div>

    </div>

    <h2>Болти М4</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m4-8.jpg" />
                <div>М4*8</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/m4-30.jpg" />
                <div>М4*30</div>
            </div>
        </div>

    </div>

    <h2>VTX, VRX</h2>

    <div class="pictures bigger">
        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/lst-vtx-vrx.jpg" />
                <div>Сірий 3.3 ГГц VTX + VRX</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/noname-vtx-vrx.jpg" />
                <div>Чорний 3.3 ГГц VTX + VRX</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/cyclone.jpg" />
                <div>Cyclone ELRS 915M TX</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/terk.jpg" />
                <div>AxisFlying Terk 3W 5.8G 56CH</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/blitz-16.jpg" />
                <div>iFlight Blitz 1.6W 5.8G 40CH</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/panda.jpg" />
                <div>Panda VTX 5.8G 2.5W 40CH</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/ultra-1600.jpg" />
                <div>ReadyToSky ULTRA1600 4.9-5.8G 1.6W 56CH</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/ultra-3000.jpg" />
                <div>ReadyToSky ULTRA3000 4.9-5.8G 3W 56CH</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/aocoda.jpg" />
                <div>AocodaRC 5.8G 1.6 48CH</div>
            </div>
        </div>

    </div>

    <h2>Пропелери</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/amm-7-3.5-3.jpg" />
                <div>AMMProp 7*3.5*3</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/noname-7040.jpg" />
                <div>Noname 7*4*3</div>
            </div>
        </div>

    </div>

    <h2>Антени</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/mapple-1.2.jpg" />
                <div>Антена Mapple Wireless 1.2 ГГц RHCP SMA 15см</div>
            </div>
        </div>

    </div>

    <h2>Хлам</h2>

    <div class="pictures bigger">

        <div class="pictures-wrapper">
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/antennas.jpg" />
                <div>Комплектні антени від VTX 5.8 ГГц</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/capacitors.jpg" />
                <div>Конденсатори від стеків SpeedyBee</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/buttons.jpg" />
                <div>Кнопки від камер Caddx Ratel</div>
            </div>
            <div class="picture">
                <img src="https://motors.swarm.army/assets/market/strap-20cm.jpg" />
                <div>Стрепа 20см</div>
            </div>
        </div>

    </div>

</main>
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataService, SellerFull} from "../data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-seller-edit-dialog',
    templateUrl: './seller-edit-dialog.component.html',
    styleUrl: './seller-edit-dialog.component.scss'
})
export class SellerEditDialogComponent {

    public loading = false;

    public static open(dialog: MatDialog, seller: SellerFull) {
        return dialog.open(SellerEditDialogComponent, {width: '520px', data: seller, autoFocus: false, restoreFocus: false});
    }

    constructor(
        public readonly data: DataService,
        public ref: MatDialogRef<SellerEditDialogComponent>,
        private readonly snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public readonly seller: SellerFull,
    ) {
        this.form.patchValue(this.seller);
        this.form.controls.seller_image_url.valueChanges
            .subscribe(url => url ? fetch(url, {cache: 'reload', mode: 'no-cors'}) : null);
    }

    public form = new FormGroup({
        seller_name: new FormControl('', Validators.required),
        seller_image_url: new FormControl('', Validators.required),
        seller_url: new FormControl('', Validators.required),
    });

    public save() {

        if (!this.form.valid || this.loading) {
            this.form.markAsTouched();
            return;
        }

        this.loading = true;

        this.data.upsertCustomSeller({...this.form.value as any, seller_id: this.seller?.seller_id || 0}).subscribe(seller => {
            if (this.seller) {
                this.seller.seller_name = seller.seller_name;
                this.seller.seller_image_url = seller.seller_image_url;
                this.seller.seller_url = seller.seller_url;
            } else {
                this.data.postInsertSeller({
                    ...seller
                });
            }
            this.snackbar.open('Зміни збережено.', '', {duration: 1000});
            this.loading = false;
            this.ref.close();
        }, error => {
            this.loading = false;
            this.data.showMessage('Сталася помилка!');
        });

    }
}

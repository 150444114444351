import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { FiltersPanelComponent } from './components/filters-panel/filters-panel.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotificationsButtonComponent } from './components/notifications-button/notifications-button.component';
import { ReportOrderActionButtonComponent } from './components/report-order-action-button/report-order-action-button.component';
import { SellerChipComponent } from './components/seller-chip/seller-chip.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SkeletonCardComponent } from './components/skeleton-card/skeleton-card.component';
import { SkeletonTableComponent } from './components/skeleton-table/skeleton-table.component';
import { UpsertRequestActionButtonComponent } from './components/upsert-request-action-button/upsert-request-action-button.component';
import { AlertDialogComponent } from './dialogs/alert/alert.component';
import { ConfirmDialogComponent } from './dialogs/confirm/confirm.component';
import { ButtonLoadingDirective } from './directives/button-loading.directive';
import { ClipboardCopyDirective } from './directives/clipboard-copy.directive';
import { ContenteditableValueAccessor } from './directives/contenteditable.directive';
import { RouteActiveDirective } from './directives/route-active.directive';
import { DateAgoPipe } from './pipes/date-ago.pipe';

const PIPES = [
    DateAgoPipe,
];

const DIRECTIVES = [
    ClipboardCopyDirective,
    RouteActiveDirective,
    ButtonLoadingDirective,
    ContenteditableValueAccessor,
];

const COMPONENTS = [
    SidebarComponent,
    FiltersPanelComponent,
    FooterComponent,
    AlertDialogComponent,
    SkeletonCardComponent,
    SkeletonTableComponent,
    SellerChipComponent,
    UpsertRequestActionButtonComponent,
    ReportOrderActionButtonComponent,
    ConfirmDialogComponent,
    NotificationsButtonComponent,
];

@NgModule({
    declarations: [
        ...PIPES,
        ...DIRECTIVES,
        ...COMPONENTS,
    ],
    exports: [
        ...PIPES,
        ...DIRECTIVES,
        ...COMPONENTS,
    ],
    imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
]
})
export class SharedModule { }


export interface Motor {
    model_id?: number;
    brand: string;
    model: string;
    kv: number;
    sz: number;
    visible: boolean;
    photos: string[];
    video: string;
    stats: {
        u: number;
        i: number;
        f: number;
        p: number;
        t: number;
        fi?: number;
    }[],
}

export const MOTORS: Motor[] = [

    {
        model_id: 7,
        brand: 'Emax',
        model: 'Eco II 2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133334438.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133345302.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133402632.jpg',
        ],
        video: 'https://drive.google.com/file/d/1egSIuWBG8dki-bISP5n_azbMKdFOBwVw/preview',
        stats: [
            {
                "u": 24.3,
                "i": 0.03,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.3,
                "i": 0.16,
                "f": 0,
                "p": 5,
                "t": 1153
            },
            {
                "u": 24.3,
                "i": 0.37,
                "f": 48,
                "p": 9,
                "t": 1183
            },
            {
                "u": 24.3,
                "i": 0.61,
                "f": 83,
                "p": 15,
                "t": 1213
            },
            {
                "u": 24.2,
                "i": 1.36,
                "f": 198,
                "p": 33,
                "t": 1279
            },
            {
                "u": 24.2,
                "i": 2.7,
                "f": 288,
                "p": 66,
                "t": 1333
            },
            {
                "u": 24.1,
                "i": 3.9,
                "f": 498,
                "p": 94,
                "t": 1375
            },
            {
                "u": 24,
                "i": 5.31,
                "f": 608,
                "p": 132,
                "t": 1441
            },
            {
                "u": 23.8,
                "i": 8.38,
                "f": 772,
                "p": 227,
                "t": 1537
            },
            {
                "u": 23,
                "i": 20.35,
                "f": 1480,
                "p": 471,
                "t": 1645
            },
            {
                "u": 22.6,
                "i": 25.67,
                "f": 1683,
                "p": 585,
                "t": 1723
            },
            {
                "u": 22.6,
                "i": 26.99,
                "f": 1758,
                "p": 637,
                "t": 1777
            },
            {
                "u": 22,
                "i": 32.32,
                "f": 1838,
                "p": 700,
                "t": 1849
            },
            {
                "u": 21.9,
                "i": 36.99,
                "f": 2063,
                "p": 806,
                "t": 1900
            },
            {
                "u": 21.8,
                "i": 38.88,
                "f": 2256,
                "p": 844,
                "t": 1909
            },
            {
                "u": 21.6,
                "i": 41.92,
                "f": 2337,
                "p": 906,
                "t": 2000
            }
        ],
    },

    {
        model_id: 7,
        brand: 'FAKE Emax',
        model: 'Eco II 2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133334438.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133345302.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133402632.jpg',
        ],
        video: 'https://drive.google.com/file/d/1egSIuWBG8dki-1IZJx8MMQMYVXCMk2Bnzll1zLRoNYqqpe/preview',
        stats: [
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1194
            },
            {
                "u": 24.6,
                "i": 0.45,
                "f": 29,
                "p": 13,
                "t": 1195
            },
            {
                "u": 24.6,
                "i": 1.09,
                "f": 98,
                "p": 26,
                "t": 1246
            },
            {
                "u": 24.5,
                "i": 1.97,
                "f": 263,
                "p": 48,
                "t": 1294
            },
            {
                "u": 24.5,
                "i": 3.65,
                "f": 352,
                "p": 101,
                "t": 1354
            },
            {
                "u": 24.3,
                "i": 5.56,
                "f": 598,
                "p": 135,
                "t": 1414
            },
            {
                "u": 24.2,
                "i": 6.61,
                "f": 657,
                "p": 160,
                "t": 1441
            },
            {
                "u": 24.2,
                "i": 7.84,
                "f": 738,
                "p": 191,
                "t": 1480
            },
            {
                "u": 24,
                "i": 10.91,
                "f": 968,
                "p": 262,
                "t": 1540
            },
            {
                "u": 23.3,
                "i": 21.37,
                "f": 1332,
                "p": 509,
                "t": 1638
            },
            {
                "u": 23.2,
                "i": 24.64,
                "f": 1488,
                "p": 569,
                "t": 1684
            },
            {
                "u": 22.3,
                "i": 35.89,
                "f": 2076,
                "p": 803,
                "t": 1825
            },
            {
                "u": 21.5,
                "i": 47.03,
                "f": 2329,
                "p": 1014,
                "t": 2000
            }
        ],
    },

    {
        model_id: 34,
        brand: 'BrotherHobby',
        model: 'Avenger 2806.5 1300KV',
        kv: 1300,
        sz: 2806.5,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132819845.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132826727.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132838441.jpg',
        ],
        video: 'https://drive.google.com/file/d/15U27HWWfzR47AyiPOKVWmdZ5PCzgYJ8j/preview',
        stats: [
            {
                "u": 24.3,
                "i": 0.2,
                "f": 0,
                "p": 5,
                "t": 1150
            },
            {
                "u": 24.3,
                "i": 0.43,
                "f": 96,
                "p": 10,
                "t": 1189
            },
            {
                "u": 24.3,
                "i": 0.61,
                "f": 98,
                "p": 15,
                "t": 1228
            },
            {
                "u": 24.2,
                "i": 1.25,
                "f": 188,
                "p": 30,
                "t": 1297
            },
            {
                "u": 24.1,
                "i": 3.33,
                "f": 445,
                "p": 80,
                "t": 1396
            },
            {
                "u": 24,
                "i": 4.96,
                "f": 590,
                "p": 118,
                "t": 1466
            },
            {
                "u": 23.9,
                "i": 6.8,
                "f": 888,
                "p": 164,
                "t": 1531
            },
            {
                "u": 23.7,
                "i": 9.55,
                "f": 926,
                "p": 226,
                "t": 1591
            },
            {
                "u": 23.4,
                "i": 14.25,
                "f": 1070,
                "p": 364,
                "t": 1645
            },
            {
                "u": 23,
                "i": 17.87,
                "f": 1208,
                "p": 413,
                "t": 1711
            },
            {
                "u": 22.8,
                "i": 21.7,
                "f": 1500,
                "p": 486,
                "t": 1766
            },
            {
                "u": 22.7,
                "i": 23.5,
                "f": 1608,
                "p": 533,
                "t": 1831
            },
            {
                "u": 22.3,
                "i": 29.32,
                "f": 1858,
                "p": 653,
                "t": 1931
            },
            {
                "u": 22.3,
                "i": 30.61,
                "f": 1890,
                "p": 682,
                "t": 2000
            }
        ],
    },

    {
        model_id: 48,
        brand: 'Ysido',
        model: 'V1 X2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132438530.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132458118.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132522039.jpg',
        ],
        video: 'https://drive.google.com/file/d/1aw0DaDWJIpEgT_xLIiCdRHuiO9NHqLx8/preview',
        stats: [
            {
                "u": 24.2,
                "i": 0.05,
                "f": 0,
                "p": 1,
                "t": 1000
            },
            {
                "u": 24.2,
                "i": 0.04,
                "f": 0,
                "p": 1,
                "t": 1111
            },
            {
                "u": 24.2,
                "i": 0.16,
                "f": 0,
                "p": 3,
                "t": 1150
            },
            {
                "u": 24.2,
                "i": 0.56,
                "f": 88,
                "p": 13,
                "t": 1217
            },
            {
                "u": 24.1,
                "i": 1.08,
                "f": 160,
                "p": 26,
                "t": 1274
            },
            {
                "u": 24.1,
                "i": 2,
                "f": 289,
                "p": 49,
                "t": 1322
            },
            {
                "u": 24,
                "i": 3.2,
                "f": 432,
                "p": 76,
                "t": 1373
            },
            {
                "u": 23.9,
                "i": 4.97,
                "f": 506,
                "p": 114,
                "t": 1436
            },
            {
                "u": 23.7,
                "i": 7.63,
                "f": 806,
                "p": 180,
                "t": 1532
            },
            {
                "u": 23.7,
                "i": 7.6,
                "f": 808,
                "p": 180,
                "t": 1547
            },
            {
                "u": 23.3,
                "i": 14.86,
                "f": 1160,
                "p": 365,
                "t": 1643
            },
            {
                "u": 23,
                "i": 19.24,
                "f": 1392,
                "p": 448,
                "t": 1697
            },
            {
                "u": 22.8,
                "i": 21.18,
                "f": 1488,
                "p": 476,
                "t": 1727
            },
            {
                "u": 22.4,
                "i": 25.07,
                "f": 1596,
                "p": 621,
                "t": 1841
            },
            {
                "u": 22.2,
                "i": 28.96,
                "f": 1798,
                "p": 697,
                "t": 1904
            },
            {
                "u": 21.9,
                "i": 32.98,
                "f": 1988,
                "p": 763,
                "t": 1979
            },
            {
                "u": 21.9,
                "i": 35.06,
                "f": 2068,
                "p": 769,
                "t": 2000
            }
        ],
    },

    {
        model_id: 48,
        brand: 'Ysido',
        model: 'V2 X2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132330853.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132340783.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132357436.jpg',
        ],
        video: 'https://drive.google.com/file/d/1RNnc_GwSB0Rnz-nQlf9US59QzOM8yn2m/preview',
        stats: [
            {
                "u": 24.3,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1153
            },
            {
                "u": 24.3,
                "i": 0.2,
                "f": 0,
                "p": 5,
                "t": 1168
            },
            {
                "u": 24.3,
                "i": 0.31,
                "f": 29,
                "p": 7,
                "t": 1180
            },
            {
                "u": 24.3,
                "i": 0.45,
                "f": 54,
                "p": 11,
                "t": 1210
            },
            {
                "u": 24.3,
                "i": 0.72,
                "f": 88,
                "p": 20,
                "t": 1257
            },
            {
                "u": 24.3,
                "i": 1.1,
                "f": 168,
                "p": 25,
                "t": 1279
            },
            {
                "u": 24.3,
                "i": 1.58,
                "f": 228,
                "p": 38,
                "t": 1294
            },
            {
                "u": 24.2,
                "i": 1.88,
                "f": 262,
                "p": 45,
                "t": 1308
            },
            {
                "u": 24.2,
                "i": 2.27,
                "f": 318,
                "p": 55,
                "t": 1375
            },
            {
                "u": 24.1,
                "i": 3.27,
                "f": 429,
                "p": 79,
                "t": 1390
            },
            {
                "u": 24.1,
                "i": 3.69,
                "f": 468,
                "p": 89,
                "t": 1393
            },
            {
                "u": 24.1,
                "i": 3.91,
                "f": 473,
                "p": 95,
                "t": 1409
            },
            {
                "u": 24.1,
                "i": 4.43,
                "f": 529,
                "p": 107,
                "t": 1432
            },
            {
                "u": 24,
                "i": 5.3,
                "f": 608,
                "p": 127,
                "t": 1462
            },
            {
                "u": 23.6,
                "i": 11.69,
                "f": 888,
                "p": 291,
                "t": 1579
            },
            {
                "u": 23.4,
                "i": 14.37,
                "f": 1086,
                "p": 351,
                "t": 1627
            },
            {
                "u": 23.2,
                "i": 18.34,
                "f": 1288,
                "p": 434,
                "t": 1675
            },
            {
                "u": 23,
                "i": 20.51,
                "f": 1428,
                "p": 470,
                "t": 1726
            },
            {
                "u": 22.8,
                "i": 24.86,
                "f": 1578,
                "p": 574,
                "t": 1813
            },
            {
                "u": 22.4,
                "i": 28.94,
                "f": 1846,
                "p": 648,
                "t": 1873
            },
            {
                "u": 22.1,
                "i": 34.7,
                "f": 2036,
                "p": 764,
                "t": 2000
            }
        ],
    },

    {
        model_id: 8,
        brand: 'FlashHobby',
        model: 'Arthur 2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240208_224001137.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240208_224017303.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240208_224100281.jpg',
        ],
        video: 'https://drive.google.com/file/d/1i8nQSFwhIpvclb4stq24LMIkPlegNwlm/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.03,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.5,
                "i": 0.15,
                "f": 4,
                "p": 3,
                "t": 1150
            },
            {
                "u": 24.5,
                "i": 0.39,
                "f": 29,
                "p": 10,
                "t": 1192
            },
            {
                "u": 24.4,
                "i": 0.59,
                "f": 78,
                "p": 14,
                "t": 1216
            },
            {
                "u": 24.4,
                "i": 1.28,
                "f": 131,
                "p": 41,
                "t": 1303
            },
            {
                "u": 24.4,
                "i": 1.72,
                "f": 236,
                "p": 44,
                "t": 1318
            },
            {
                "u": 24.3,
                "i": 4.08,
                "f": 428,
                "p": 106,
                "t": 1399
            },
            {
                "u": 24.2,
                "i": 4.39,
                "f": 529,
                "p": 106,
                "t": 1399
            },
            {
                "u": 24.1,
                "i": 6.62,
                "f": 638,
                "p": 164,
                "t": 1471
            },
            {
                "u": 24,
                "i": 7.71,
                "f": 707,
                "p": 191,
                "t": 1507
            },
            {
                "u": 23.8,
                "i": 10.6,
                "f": 947,
                "p": 258,
                "t": 1567
            },
            {
                "u": 23.2,
                "i": 19.2,
                "f": 1278,
                "p": 453,
                "t": 1666
            },
            {
                "u": 22.9,
                "i": 24.02,
                "f": 1548,
                "p": 555,
                "t": 1726
            },
            {
                "u": 22.6,
                "i": 28.97,
                "f": 1808,
                "p": 653,
                "t": 1825
            },
            {
                "u": 22.1,
                "i": 36.23,
                "f": 2056,
                "p": 797,
                "t": 1895
            },
            {
                "u": 21.8,
                "i": 40.73,
                "f": 2208,
                "p": 883,
                "t": 2000
            }
        ],
    },

    {
        brand: 'Foxeer',
        model: 'Black Hornet 2806.5 1300KV',
        kv: 1350,
        sz: 2806.5,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240210_121653102.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240210_121723066.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240210_121819686.jpg',
        ],
        video: 'https://drive.google.com/file/d/12alRWv8zTbCaNEffWFi8MEcZJZNkBp7i/preview',
        stats: [
            {
                "u": 24.2,
                "i": 0.05,
                "f": 0,
                "p": 1,
                "t": 1111
            },
            {
                "u": 24.2,
                "i": 0.46,
                "f": 58,
                "p": 10,
                "t": 1190
            },
            {
                "u": 24.2,
                "i": 0.58,
                "f": 88,
                "p": 14,
                "t": 1222
            },
            {
                "u": 24.2,
                "i": 0.9,
                "f": 129,
                "p": 21,
                "t": 1255
            },
            {
                "u": 24.2,
                "i": 1.1,
                "f": 150,
                "p": 26,
                "t": 1273
            },
            {
                "u": 24.2,
                "i": 1.35,
                "f": 188,
                "p": 33,
                "t": 1294
            },
            {
                "u": 24.1,
                "i": 1.72,
                "f": 191,
                "p": 45,
                "t": 1321
            },
            {
                "u": 24.1,
                "i": 2.88,
                "f": 283,
                "p": 70,
                "t": 1356
            },
            {
                "u": 24,
                "i": 4.35,
                "f": 529,
                "p": 105,
                "t": 1428
            },
            {
                "u": 23.8,
                "i": 5.83,
                "f": 642,
                "p": 143,
                "t": 1480
            },
            {
                "u": 23.8,
                "i": 7.83,
                "f": 782,
                "p": 191,
                "t": 1537
            },
            {
                "u": 23.6,
                "i": 9.95,
                "f": 883,
                "p": 237,
                "t": 1579
            },
            {
                "u": 23.3,
                "i": 15.58,
                "f": 1138,
                "p": 352,
                "t": 1624
            },
            {
                "u": 23.1,
                "i": 17.87,
                "f": 1288,
                "p": 421,
                "t": 1659
            },
            {
                "u": 22.9,
                "i": 21.14,
                "f": 1382,
                "p": 486,
                "t": 1729
            },
            {
                "u": 22.7,
                "i": 24.77,
                "f": 1593,
                "p": 564,
                "t": 1774
            },
            {
                "u": 22.5,
                "i": 27.63,
                "f": 1888,
                "p": 618,
                "t": 1825
            },
            {
                "u": 22.2,
                "i": 31.31,
                "f": 1824,
                "p": 696,
                "t": 1897
            },
            {
                "u": 21.9,
                "i": 36.53,
                "f": 2038,
                "p": 800,
                "t": 2000
            }
        ],
    },

    {
        brand: 'SkyStars',
        model: 'Koko',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133501169.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133507197.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133516124.jpg',
        ],
        video: 'https://drive.google.com/file/d/1kt2uc1vg7BaOIv5gohh7sb7Xv83tLYab/preview',
        stats: [
            {
                "u": 24.3,
                "i": 0.02,
                "f": 0,
                "p": 0,
                "t": 1108
            },
            {
                "u": 24.3,
                "i": 0.28,
                "f": 27,
                "p": 6,
                "t": 1174
            },
            {
                "u": 24.3,
                "i": 0.49,
                "f": 88,
                "p": 11,
                "t": 1204
            },
            {
                "u": 24.2,
                "i": 0.88,
                "f": 132,
                "p": 21,
                "t": 1246
            },
            {
                "u": 24.2,
                "i": 2.11,
                "f": 298,
                "p": 50,
                "t": 1309
            },
            {
                "u": 24,
                "i": 5.16,
                "f": 688,
                "p": 123,
                "t": 1435
            },
            {
                "u": 23.9,
                "i": 7.58,
                "f": 788,
                "p": 189,
                "t": 1519
            },
            {
                "u": 23.8,
                "i": 8.19,
                "f": 858,
                "p": 224,
                "t": 1552
            },
            {
                "u": 23.3,
                "i": 13.64,
                "f": 1088,
                "p": 309,
                "t": 1591
            },
            {
                "u": 23.3,
                "i": 16.67,
                "f": 1298,
                "p": 337,
                "t": 1621
            },
            {
                "u": 22.9,
                "i": 21.24,
                "f": 1398,
                "p": 500,
                "t": 1693
            },
            {
                "u": 22.9,
                "i": 22.76,
                "f": 1548,
                "p": 525,
                "t": 1729
            },
            {
                "u": 22.6,
                "i": 26.38,
                "f": 1678,
                "p": 641,
                "t": 1846
            },
            {
                "u": 22.1,
                "i": 35.02,
                "f": 2060,
                "p": 774,
                "t": 1909
            },
            {
                "u": 21.8,
                "i": 38.49,
                "f": 2168,
                "p": 839,
                "t": 2000
            }
        ],
    },

    {
        model_id: 10,
        brand: '9imod',
        model: 'X2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133223135.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133243201.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133252069.jpg',
        ],
        video: 'https://drive.google.com/file/d/1k4AMGW7eRVrpbSZ3ev84cfWyK4JRFc8Z/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.22,
                "f": 18,
                "p": 5,
                "t": 1171
            },
            {
                "u": 24.5,
                "i": 0.49,
                "f": 79,
                "p": 11,
                "t": 1213
            },
            {
                "u": 24.5,
                "i": 0.76,
                "f": 138,
                "p": 18,
                "t": 1249
            },
            {
                "u": 24.4,
                "i": 2.13,
                "f": 288,
                "p": 52,
                "t": 1321
            },
            {
                "u": 24.3,
                "i": 3.83,
                "f": 438,
                "p": 80,
                "t": 1380
            },
            {
                "u": 24.2,
                "i": 4.46,
                "f": 569,
                "p": 107,
                "t": 1423
            },
            {
                "u": 24.2,
                "i": 4.97,
                "f": 624,
                "p": 120,
                "t": 1453
            },
            {
                "u": 24.1,
                "i": 7.38,
                "f": 736,
                "p": 192,
                "t": 1531
            },
            {
                "u": 23.4,
                "i": 17.57,
                "f": 1328,
                "p": 406,
                "t": 1645
            },
            {
                "u": 23.2,
                "i": 20.25,
                "f": 1493,
                "p": 479,
                "t": 1681
            },
            {
                "u": 23,
                "i": 22.69,
                "f": 1598,
                "p": 532,
                "t": 1729
            },
            {
                "u": 22.9,
                "i": 24.66,
                "f": 1738,
                "p": 571,
                "t": 1806
            },
            {
                "u": 22.3,
                "i": 33.36,
                "f": 2058,
                "p": 743,
                "t": 1901
            },
            {
                "u": 22,
                "i": 37.7,
                "f": 2188,
                "p": 838,
                "t": 1967
            }
        ],
    },

    {
        model_id: 48,
        brand: 'Noname',
        model: 'X2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133102670.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133128595.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133139325.jpg',
        ],
        video: 'https://drive.google.com/file/d/15uGvQ0ZTNUWutTNp8zGg7IQp8cbU3798/preview',
        stats: [
            {
                "u": 24.4,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1117
            },
            {
                "u": 24.4,
                "i": 0.46,
                "f": 78,
                "p": 11,
                "t": 1204
            },
            {
                "u": 24.3,
                "i": 1.08,
                "f": 178,
                "p": 26,
                "t": 1276
            },
            {
                "u": 24.2,
                "i": 2.67,
                "f": 388,
                "p": 65,
                "t": 1338
            },
            {
                "u": 24.2,
                "i": 3.74,
                "f": 498,
                "p": 90,
                "t": 1378
            },
            {
                "u": 24.1,
                "i": 4.59,
                "f": 674,
                "p": 111,
                "t": 1417
            },
            {
                "u": 24,
                "i": 6.32,
                "f": 708,
                "p": 152,
                "t": 1486
            },
            {
                "u": 23.7,
                "i": 12.42,
                "f": 1042,
                "p": 294,
                "t": 1555
            },
            {
                "u": 23.6,
                "i": 14.34,
                "f": 1178,
                "p": 348,
                "t": 1591
            },
            {
                "u": 23.1,
                "i": 20.81,
                "f": 1384,
                "p": 494,
                "t": 1687
            },
            {
                "u": 22.9,
                "i": 22.85,
                "f": 1598,
                "p": 513,
                "t": 1732
            },
            {
                "u": 22.6,
                "i": 28.56,
                "f": 1888,
                "p": 649,
                "t": 1822
            },
            {
                "u": 22.3,
                "i": 35.53,
                "f": 2129,
                "p": 806,
                "t": 1913
            },
            {
                "u": 22,
                "i": 37.52,
                "f": 2188,
                "p": 826,
                "t": 2000
            }
        ],
    },

    {
        model_id: 117,
        brand: 'SurpassHobby',
        model: 'S2806.5 1300KV',
        kv: 1300,
        sz: 2806.5,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132949330.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132956652.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_133020782.jpg',
        ],
        video: 'https://drive.google.com/file/d/12rkYMaSz6jfrHThSzMrs4W6TcxK-CacQ/preview',
        stats: [
            {
                "u": 24.2,
                "i": 0.28,
                "f": 0,
                "p": 6,
                "t": 1174
            },
            {
                "u": 24.2,
                "i": 0.41,
                "f": 60,
                "p": 10,
                "t": 1201
            },
            {
                "u": 24.1,
                "i": 1.4,
                "f": 208,
                "p": 34,
                "t": 1291
            },
            {
                "u": 24.1,
                "i": 2.3,
                "f": 288,
                "p": 56,
                "t": 1330
            },
            {
                "u": 24,
                "i": 3.08,
                "f": 428,
                "p": 74,
                "t": 1366
            },
            {
                "u": 23.9,
                "i": 3.93,
                "f": 481,
                "p": 99,
                "t": 1414
            },
            {
                "u": 23.9,
                "i": 5.27,
                "f": 676,
                "p": 125,
                "t": 1459
            },
            {
                "u": 23.7,
                "i": 8.62,
                "f": 788,
                "p": 212,
                "t": 1553
            },
            {
                "u": 23.2,
                "i": 14.89,
                "f": 1196,
                "p": 355,
                "t": 1616
            },
            {
                "u": 22.8,
                "i": 20.09,
                "f": 1398,
                "p": 456,
                "t": 1691
            },
            {
                "u": 22.8,
                "i": 21.76,
                "f": 1528,
                "p": 491,
                "t": 1727
            },
            {
                "u": 22.5,
                "i": 25.47,
                "f": 1788,
                "p": 575,
                "t": 1805
            },
            {
                "u": 22.2,
                "i": 29.96,
                "f": 1929,
                "p": 662,
                "t": 1871
            },
            {
                "u": 22,
                "i": 32.03,
                "f": 1998,
                "p": 706,
                "t": 1904
            },
            {
                "u": 21.8,
                "i": 35.72,
                "f": 2178,
                "p": 779,
                "t": 2000
            }
        ],
    },

    {
        brand: 'NeebRC',
        model: 'V3 X2807 1300KV',
        kv: 1300,
        sz: 2806.5,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132631708.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132639346.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240207_132711845.jpg',
        ],
        video: 'https://drive.google.com/file/d/13T9NEtKYfqrgYjKoYnpk6Q9II9EaYhZJ/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.23,
                "f": 0,
                "p": 5,
                "t": 1153
            },
            {
                "u": 24.5,
                "i": 0.33,
                "f": 26,
                "p": 8,
                "t": 1174
            },
            {
                "u": 24.5,
                "i": 0.67,
                "f": 86,
                "p": 16,
                "t": 1225
            },
            {
                "u": 24.4,
                "i": 1.1,
                "f": 150,
                "p": 27,
                "t": 1276
            },
            {
                "u": 24.4,
                "i": 2.08,
                "f": 288,
                "p": 50,
                "t": 1315
            },
            {
                "u": 24.3,
                "i": 3.35,
                "f": 425,
                "p": 80,
                "t": 1372
            },
            {
                "u": 24.2,
                "i": 4.14,
                "f": 508,
                "p": 100,
                "t": 1411
            },
            {
                "u": 24.1,
                "i": 5.62,
                "f": 608,
                "p": 135,
                "t": 1462
            },
            {
                "u": 24,
                "i": 7.93,
                "f": 806,
                "p": 195,
                "t": 1525
            },
            {
                "u": 23.6,
                "i": 14.29,
                "f": 1088,
                "p": 370,
                "t": 1624
            },
            {
                "u": 23.3,
                "i": 18.71,
                "f": 1327,
                "p": 448,
                "t": 1705
            },
            {
                "u": 22.9,
                "i": 22.83,
                "f": 1584,
                "p": 525,
                "t": 1768
            },
            {
                "u": 22.5,
                "i": 30.62,
                "f": 1890,
                "p": 690,
                "t": 1873
            },
            {
                "u": 22.1,
                "i": 34.74,
                "f": 2006,
                "p": 800,
                "t": 2000
            }
        ],
    },

    {
        model_id: 48,
        brand: 'U-Angel',
        model: 'X2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240213_213821126.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240213_214016484.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240213_214006004.jpg',
        ],
        video: 'https://drive.google.com/file/d/1Z641o0L0_WYXFu8_MV5iEIqYEnOCcEzJ/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.03,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.5,
                "i": 0.11,
                "f": 0,
                "p": 2,
                "t": 1144
            },
            {
                "u": 24.5,
                "i": 0.27,
                "f": 40,
                "p": 6,
                "t": 1177
            },
            {
                "u": 24.5,
                "i": 0.32,
                "f": 53,
                "p": 8,
                "t": 1189
            },
            {
                "u": 24.5,
                "i": 0.5,
                "f": 88,
                "p": 12,
                "t": 1213
            },
            {
                "u": 24.5,
                "i": 0.66,
                "f": 108,
                "p": 16,
                "t": 1234
            },
            {
                "u": 24.5,
                "i": 0.93,
                "f": 132,
                "p": 24,
                "t": 1264
            },
            {
                "u": 24.5,
                "i": 0.98,
                "f": 161,
                "p": 25,
                "t": 1270
            },
            {
                "u": 24.5,
                "i": 1.2,
                "f": 181,
                "p": 31,
                "t": 1291
            },
            {
                "u": 24.5,
                "i": 1.28,
                "f": 206,
                "p": 32,
                "t": 1297
            },
            {
                "u": 24.4,
                "i": 1.87,
                "f": 286,
                "p": 45,
                "t": 1308
            },
            {
                "u": 24.4,
                "i": 2.22,
                "f": 329,
                "p": 54,
                "t": 1324
            },
            {
                "u": 24.4,
                "i": 2.75,
                "f": 391,
                "p": 67,
                "t": 1351
            },
            {
                "u": 24.3,
                "i": 3.42,
                "f": 465,
                "p": 83,
                "t": 1370
            },
            {
                "u": 24.3,
                "i": 4.2,
                "f": 536,
                "p": 102,
                "t": 1417
            },
            {
                "u": 24.2,
                "i": 5.37,
                "f": 625,
                "p": 128,
                "t": 1474
            },
            {
                "u": 24,
                "i": 7.76,
                "f": 829,
                "p": 186,
                "t": 1532
            },
            {
                "u": 24,
                "i": 9.08,
                "f": 929,
                "p": 218,
                "t": 1580
            },
            {
                "u": 23.6,
                "i": 14.32,
                "f": 1122,
                "p": 352,
                "t": 1628
            },
            {
                "u": 23.6,
                "i": 16.57,
                "f": 1257,
                "p": 391,
                "t": 1645
            },
            {
                "u": 23.1,
                "i": 21.42,
                "f": 1406,
                "p": 503,
                "t": 1732
            },
            {
                "u": 22.9,
                "i": 24.04,
                "f": 1638,
                "p": 549,
                "t": 1774
            },
            {
                "u": 22.7,
                "i": 27.12,
                "f": 1798,
                "p": 615,
                "t": 1837
            },
            {
                "u": 22.6,
                "i": 29.23,
                "f": 1886,
                "p": 659,
                "t": 1873
            },
            {
                "u": 22.4,
                "i": 32.13,
                "f": 1988,
                "p": 720,
                "t": 1912
            },
            {
                "u": 22.2,
                "i": 35.19,
                "f": 2078,
                "p": 780,
                "t": 2000
            }
        ],
    },

    {
        model_id: 114,
        brand: '9imod',
        model: 'B2810 1180KV',
        kv: 1180,
        sz: 2810,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151608012.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151557843.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151613917.jpg',
        ],
        video: 'https://drive.google.com/file/d/1GKHgcxgflsAKog-0wIwBrX_awWB9qAa1/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.05,
                "f": 0,
                "p": 1,
                "t": 1000
            },
            {
                "u": 24.5,
                "i": 0.35,
                "f": 0,
                "p": 9,
                "t": 1177
            },
            {
                "u": 24.5,
                "i": 0.5,
                "f": 32,
                "p": 13,
                "t": 1190
            },
            {
                "u": 24.5,
                "i": 0.85,
                "f": 105,
                "p": 19,
                "t": 1237
            },
            {
                "u": 24.5,
                "i": 1.59,
                "f": 189,
                "p": 40,
                "t": 1294
            },
            {
                "u": 24.4,
                "i": 3.06,
                "f": 367,
                "p": 74,
                "t": 1348
            },
            {
                "u": 24.3,
                "i": 3.85,
                "f": 448,
                "p": 93,
                "t": 1375
            },
            {
                "u": 24.2,
                "i": 5.16,
                "f": 573,
                "p": 125,
                "t": 1420
            },
            {
                "u": 24.2,
                "i": 6.38,
                "f": 664,
                "p": 160,
                "t": 1471
            },
            {
                "u": 24.2,
                "i": 7.21,
                "f": 660,
                "p": 181,
                "t": 1495
            },
            {
                "u": 24.1,
                "i": 7.66,
                "f": 749,
                "p": 196,
                "t": 1516
            },
            {
                "u": 24,
                "i": 8.63,
                "f": 841,
                "p": 207,
                "t": 1525
            },
            {
                "u": 24,
                "i": 9.8,
                "f": 898,
                "p": 243,
                "t": 1555
            },
            {
                "u": 23.8,
                "i": 12.86,
                "f": 1108,
                "p": 300,
                "t": 1603
            },
            {
                "u": 23.5,
                "i": 17.01,
                "f": 1296,
                "p": 407,
                "t": 1675
            },
            {
                "u": 23.1,
                "i": 22.23,
                "f": 1548,
                "p": 546,
                "t": 1752
            },
            {
                "u": 22.4,
                "i": 35.06,
                "f": 2060,
                "p": 819,
                "t": 1918
            },
            {
                "u": 21.9,
                "i": 40.76,
                "f": 2386,
                "p": 894,
                "t": 2000
            }
        ],
    },

    {
        model_id: 51,
        brand: 'RCinpower',
        model: '2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150707060.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150639019.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150721438.jpg',
        ],
        video: 'https://drive.google.com/file/d/1mXeBX_eyLoTe1xdQGGfsQS_1nz3XWnaK/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.06,
                "f": 0,
                "p": 1,
                "t": 1021
            },
            {
                "u": 24.5,
                "i": 0.34,
                "f": 0,
                "p": 8,
                "t": 1168
            },
            {
                "u": 24.5,
                "i": 0.73,
                "f": 95,
                "p": 18,
                "t": 1216
            },
            {
                "u": 24.5,
                "i": 1.3,
                "f": 177,
                "p": 32,
                "t": 1261
            },
            {
                "u": 24.4,
                "i": 1.96,
                "f": 258,
                "p": 48,
                "t": 1291
            },
            {
                "u": 24.4,
                "i": 2.99,
                "f": 368,
                "p": 73,
                "t": 1327
            },
            {
                "u": 24.3,
                "i": 4.18,
                "f": 485,
                "p": 102,
                "t": 1369
            },
            {
                "u": 24.2,
                "i": 5.48,
                "f": 608,
                "p": 133,
                "t": 1411
            },
            {
                "u": 24,
                "i": 8.79,
                "f": 837,
                "p": 211,
                "t": 1501
            },
            {
                "u": 23.2,
                "i": 21.58,
                "f": 1346,
                "p": 490,
                "t": 1615
            },
            {
                "u": 23,
                "i": 23.39,
                "f": 1383,
                "p": 547,
                "t": 1669
            },
            {
                "u": 22.7,
                "i": 25.55,
                "f": 1476,
                "p": 643,
                "t": 1717
            },
            {
                "u": 22.3,
                "i": 33.77,
                "f": 1834,
                "p": 750,
                "t": 1795
            },
            {
                "u": 22,
                "i": 38.68,
                "f": 2028,
                "p": 850,
                "t": 1867
            },
            {
                "u": 21.5,
                "i": 46.01,
                "f": 2228,
                "p": 989,
                "t": 1960
            },
            {
                "u": 21.5,
                "i": 44.58,
                "f": 2186,
                "p": 959,
                "t": 2000
            }
        ],
    },

    {
        model_id: 36,
        brand: 'SurpassHobby',
        model: 'B2806.5 1300KV',
        kv: 1300,
        sz: 2806.5,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151235583.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151225351.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151257073.jpg',
        ],
        video: 'https://drive.google.com/file/d/1MzICJlBJZ6wwDD0CtImOBovPZW5RInk7/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.05,
                "f": 0,
                "p": 1,
                "t": 1102
            },
            {
                "u": 24.5,
                "i": 0.24,
                "f": 0,
                "p": 6,
                "t": 1162
            },
            {
                "u": 24.5,
                "i": 0.48,
                "f": 31,
                "p": 13,
                "t": 1213
            },
            {
                "u": 24.4,
                "i": 0.68,
                "f": 67,
                "p": 21,
                "t": 1246
            },
            {
                "u": 24.4,
                "i": 1.55,
                "f": 186,
                "p": 44,
                "t": 1330
            },
            {
                "u": 24.3,
                "i": 3.18,
                "f": 350,
                "p": 86,
                "t": 1381
            },
            {
                "u": 24.2,
                "i": 3.8,
                "f": 450,
                "p": 93,
                "t": 1402
            },
            {
                "u": 24.2,
                "i": 4.57,
                "f": 524,
                "p": 110,
                "t": 1414
            },
            {
                "u": 24.1,
                "i": 6.28,
                "f": 652,
                "p": 152,
                "t": 1465
            },
            {
                "u": 24,
                "i": 7.73,
                "f": 742,
                "p": 185,
                "t": 1507
            },
            {
                "u": 24,
                "i": 8.23,
                "f": 778,
                "p": 197,
                "t": 1528
            },
            {
                "u": 23.7,
                "i": 12.04,
                "f": 1022,
                "p": 286,
                "t": 1585
            },
            {
                "u": 23.2,
                "i": 20,
                "f": 1238,
                "p": 488,
                "t": 1717
            },
            {
                "u": 23,
                "i": 22.35,
                "f": 1398,
                "p": 515,
                "t": 1729
            },
            {
                "u": 22.7,
                "i": 27.22,
                "f": 1576,
                "p": 617,
                "t": 1804
            },
            {
                "u": 22.3,
                "i": 32.94,
                "f": 1723,
                "p": 738,
                "t": 1885
            },
            {
                "u": 22,
                "i": 36.67,
                "f": 1842,
                "p": 804,
                "t": 2000
            }
        ],
    },

    {
        model_id: 58,
        brand: 'BrotherHobby Avenger',
        model: '2812 900KV',
        kv: 900,
        sz: 2812,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165835098.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165825828.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165845170.jpg',
        ],
        video: 'https://drive.google.com/file/d/1cltJ6fgEYdzhhqTvw56k5MvT66EKWaHj/preview',
        stats: [
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1188
            },
            {
                "u": 24.7,
                "i": 0.26,
                "f": 46,
                "p": 8,
                "t": 1189
            },
            {
                "u": 24.7,
                "i": 0.83,
                "f": 168,
                "p": 19,
                "t": 1237
            },
            {
                "u": 24.7,
                "i": 1.21,
                "f": 220,
                "p": 20,
                "t": 1267
            },
            {
                "u": 24.6,
                "i": 2.31,
                "f": 428,
                "p": 57,
                "t": 1324
            },
            {
                "u": 24.5,
                "i": 3.26,
                "f": 568,
                "p": 80,
                "t": 1369
            },
            {
                "u": 24.3,
                "i": 5.73,
                "f": 868,
                "p": 140,
                "t": 1444
            },
            {
                "u": 24.2,
                "i": 8.12,
                "f": 1056,
                "p": 198,
                "t": 1483
            },
            {
                "u": 24,
                "i": 10.99,
                "f": 1283,
                "p": 266,
                "t": 1534
            },
            {
                "u": 23.8,
                "i": 13.09,
                "f": 1518,
                "p": 312,
                "t": 1567
            },
            {
                "u": 23.6,
                "i": 16.19,
                "f": 1878,
                "p": 388,
                "t": 1615
            },
            {
                "u": 23.5,
                "i": 19.56,
                "f": 1952,
                "p": 459,
                "t": 1669
            },
            {
                "u": 32.2,
                "i": 23.76,
                "f": 2188,
                "p": 551,
                "t": 1723
            },
            {
                "u": 22.5,
                "i": 34.12,
                "f": 2652,
                "p": 773,
                "t": 1855
            },
            {
                "u": 22,
                "i": 42.8,
                "f": 2950,
                "p": 941,
                "t": 2000
            }
        ]
    },

    {
        model_id: 57,
        brand: 'FlashHobby Arthur',
        model: '2812 900KV',
        kv: 900,
        sz: 2812,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165549904.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165540206.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240512_165616616.jpg',
        ],
        video: 'https://drive.google.com/file/d/1ad1Yl0X53oNguCsXijBxavmwf8hxViA6/preview',
        stats: [
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1161
            },
            {
                "u": 24.7,
                "i": 0.13,
                "f": 0,
                "p": 9,
                "t": 1162
            },
            {
                "u": 24.7,
                "i": 0.2,
                "f": 17,
                "p": 4,
                "t": 1162
            },
            {
                "u": 24.7,
                "i": 0.38,
                "f": 67,
                "p": 9,
                "t": 1192
            },
            {
                "u": 24.7,
                "i": 0.79,
                "f": 174,
                "p": 19,
                "t": 1237
            },
            {
                "u": 24.6,
                "i": 1.34,
                "f": 275,
                "p": 34,
                "t": 1294
            },
            {
                "u": 24.6,
                "i": 2.53,
                "f": 432,
                "p": 64,
                "t": 1354
            },
            {
                "u": 24.5,
                "i": 3.5,
                "f": 593,
                "p": 93,
                "t": 1396
            },
            {
                "u": 24.4,
                "i": 4.92,
                "f": 760,
                "p": 122,
                "t": 1417
            },
            {
                "u": 24.3,
                "i": 6.82,
                "f": 976,
                "p": 174,
                "t": 1465
            },
            {
                "u": 24.1,
                "i": 9.89,
                "f": 1242,
                "p": 238,
                "t": 1504
            },
            {
                "u": 23.9,
                "i": 12.2,
                "f": 1418,
                "p": 292,
                "t": 1543
            },
            {
                "u": 23.7,
                "i": 15.98,
                "f": 1678,
                "p": 378,
                "t": 1603
            },
            {
                "u": 23.5,
                "i": 18.73,
                "f": 1848,
                "p": 443,
                "t": 1660
            },
            {
                "u": 23.2,
                "i": 23.4,
                "f": 2086,
                "p": 544,
                "t": 1717
            },
            {
                "u": 22.8,
                "i": 28.66,
                "f": 2262,
                "p": 659,
                "t": 1789
            },
            {
                "u": 22.4,
                "i": 37.14,
                "f": 2637,
                "p": 832,
                "t": 1897
            },
            {
                "u": 22,
                "i": 42.24,
                "f": 2774,
                "p": 928,
                "t": 2000
            }
        ],
    },

    {
        model_id: 115,
        brand: '9imod',
        model: 'B2812 900KV',
        kv: 900,
        sz: 2812,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151710574.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151658793.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151613917.jpg',
        ],
        video: 'https://drive.google.com/file/d/1HJG25MFE2yEsPZ6ITpGjN5YY7SuJIZez/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1168
            },
            {
                "u": 24.7,
                "i": 0.38,
                "f": 56,
                "p": 10,
                "t": 1169
            },
            {
                "u": 24.7,
                "i": 1.19,
                "f": 188,
                "p": 31,
                "t": 1264
            },
            {
                "u": 24.7,
                "i": 1.4,
                "f": 250,
                "p": 39,
                "t": 1297
            },
            {
                "u": 24.6,
                "i": 2.41,
                "f": 453,
                "p": 59,
                "t": 1327
            },
            {
                "u": 24.6,
                "i": 3.5,
                "f": 538,
                "p": 85,
                "t": 1372
            },
            {
                "u": 24.5,
                "i": 4.45,
                "f": 708,
                "p": 110,
                "t": 1414
            },
            {
                "u": 23.8,
                "i": 6.53,
                "f": 943,
                "p": 158,
                "t": 1444
            },
            {
                "u": 23.7,
                "i": 16.57,
                "f": 1342,
                "p": 392,
                "t": 1603
            },
            {
                "u": 23.3,
                "i": 23.48,
                "f": 2088,
                "p": 553,
                "t": 1706
            },
            {
                "u": 23,
                "i": 27.24,
                "f": 2308,
                "p": 628,
                "t": 1750
            },
            {
                "u": 22.6,
                "i": 33.34,
                "f": 2528,
                "p": 765,
                "t": 1831
            },
            {
                "u": 21.9,
                "i": 42.86,
                "f": 2880,
                "p": 945,
                "t": 1939
            },
            {
                "u": 21.8,
                "i": 42.87,
                "f": 2888,
                "p": 899,
                "t": 2000
            }
        ],
    },

    {
        model_id: 113,
        brand: '9imod',
        model: 'B3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151426014.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151419868.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151444683.jpg',
        ],
        video: 'https://drive.google.com/file/d/1oZPv3ACDFhXg2tdi64QU4RHA5ZCbuysY/preview',
        stats: [
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1158
            },
            {
                "u": 24.7,
                "i": 0.22,
                "f": 47,
                "p": 5,
                "t": 1159
            },
            {
                "u": 24.7,
                "i": 0.44,
                "f": 82,
                "p": 10,
                "t": 1183
            },
            {
                "u": 24.6,
                "i": 0.77,
                "f": 152,
                "p": 19,
                "t": 1210
            },
            {
                "u": 24.6,
                "i": 2.07,
                "f": 389,
                "p": 53,
                "t": 1282
            },
            {
                "u": 24.4,
                "i": 3.5,
                "f": 588,
                "p": 86,
                "t": 1339
            },
            {
                "u": 24.3,
                "i": 6.35,
                "f": 747,
                "p": 166,
                "t": 1414
            },
            {
                "u": 24,
                "i": 9.93,
                "f": 996,
                "p": 262,
                "t": 1465
            },
            {
                "u": 23.6,
                "i": 17.46,
                "f": 1688,
                "p": 409,
                "t": 1546
            },
            {
                "u": 22.8,
                "i": 27.14,
                "f": 2362,
                "p": 622,
                "t": 1651
            },
            {
                "u": 22.2,
                "i": 36.62,
                "f": 2658,
                "p": 852,
                "t": 1759
            },
            {
                "u": 21.3,
                "i": 49.68,
                "f": 3262,
                "p": 1058,
                "t": 1849
            },
            {
                "u": 20.4,
                "i": 62.74,
                "f": 3629,
                "p": 1278,
                "t": 2000
            }
        ],
    },

    {
        model_id: 11,
        brand: 'ReadyToSky',
        model: 'X3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150857811.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150850836.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_150908710.jpg',
        ],
        video: 'https://drive.google.com/file/d/1q5T0YCVAPooWmQULvys1s5E2NDzDJCxk/preview',
        stats: [
            {
                "u": 24.5,
                "i": 0.16,
                "f": 0,
                "p": 3,
                "t": 1144
            },
            {
                "u": 24.5,
                "i": 0.29,
                "f": 58,
                "p": 9,
                "t": 1189
            },
            {
                "u": 24.5,
                "i": 1,
                "f": 206,
                "p": 23,
                "t": 1240
            },
            {
                "u": 24.4,
                "i": 2.37,
                "f": 478,
                "p": 61,
                "t": 1321
            },
            {
                "u": 24.3,
                "i": 3.68,
                "f": 629,
                "p": 89,
                "t": 1372
            },
            {
                "u": 24.1,
                "i": 6.67,
                "f": 889,
                "p": 123,
                "t": 1429
            },
            {
                "u": 24,
                "i": 9.27,
                "f": 1198,
                "p": 237,
                "t": 1486
            },
            {
                "u": 23.6,
                "i": 14.29,
                "f": 1608,
                "p": 361,
                "t": 1573
            },
            {
                "u": 23.2,
                "i": 20.23,
                "f": 2028,
                "p": 478,
                "t": 1624
            },
            {
                "u": 22.9,
                "i": 22.94,
                "f": 2098,
                "p": 555,
                "t": 1666
            },
            {
                "u": 22.4,
                "i": 31.16,
                "f": 2588,
                "p": 732,
                "t": 1753
            },
            {
                "u": 22.1,
                "i": 38.14,
                "f": 2888,
                "p": 871,
                "t": 1822
            },
            {
                "u": 21.3,
                "i": 47.79,
                "f": 3088,
                "p": 1067,
                "t": 1915
            },
            {
                "u": 20.8,
                "i": 52.23,
                "f": 3388,
                "p": 1199,
                "t": 1978
            },
            {
                "u": 20.8,
                "i": 57.49,
                "f": 3660,
                "p": 1197,
                "t": 2000
            }
        ],
    },

    {
        model_id: 11,
        brand: 'ReadyToSky',
        model: 'B3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151045384.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151035383.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240508_151102358.jpg',
        ],
        video: 'https://drive.google.com/file/d/1bSgSiTi6WN0nvf4iciQCJmCTqb6erY6H/preview',
        stats: [
            {
                "u": 24.6,
                "i": 0.41,
                "f": 0,
                "p": 9,
                "t": 1177
            },
            {
                "u": 24.5,
                "i": 0.52,
                "f": 76,
                "p": 13,
                "t": 1195
            },
            {
                "u": 24.5,
                "i": 1.26,
                "f": 197,
                "p": 32,
                "t": 1249
            },
            {
                "u": 24.5,
                "i": 1.77,
                "f": 252,
                "p": 48,
                "t": 1282
            },
            {
                "u": 24.4,
                "i": 2.79,
                "f": 469,
                "p": 75,
                "t": 1345
            },
            {
                "u": 24.4,
                "i": 4.23,
                "f": 598,
                "p": 105,
                "t": 1375
            },
            {
                "u": 24.3,
                "i": 4.92,
                "f": 688,
                "p": 129,
                "t": 1402
            },
            {
                "u": 24,
                "i": 7.89,
                "f": 906,
                "p": 198,
                "t": 1438
            },
            {
                "u": 24,
                "i": 8.22,
                "f": 1098,
                "p": 196,
                "t": 1438
            },
            {
                "u": 23.6,
                "i": 14.25,
                "f": 1498,
                "p": 346,
                "t": 1534
            },
            {
                "u": 23.6,
                "i": 15.24,
                "f": 1618,
                "p": 385,
                "t": 1561
            },
            {
                "u": 23.2,
                "i": 21.77,
                "f": 2058,
                "p": 508,
                "t": 1630
            },
            {
                "u": 22.6,
                "i": 28.94,
                "f": 2288,
                "p": 710,
                "t": 1723
            },
            {
                "u": 22.4,
                "i": 32.9,
                "f": 2608,
                "p": 743,
                "t": 1738
            },
            {
                "u": 22,
                "i": 38.05,
                "f": 2788,
                "p": 849,
                "t": 1786
            },
            {
                "u": 21.1,
                "i": 52.6,
                "f": 3198,
                "p": 1161,
                "t": 1924
            },
            {
                "u": 20.7,
                "i": 58.04,
                "f": 3460,
                "p": 1200,
                "t": 2000
            }
        ],
    },

    {
        model_id: 127,
        brand: 'BrotherHobby',
        model: 'Tornado 3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240526_133742013.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240526_133726005.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240526_133811166.jpg',
        ],
        video: 'https://drive.google.com/file/d/1a2cvq7c7Rq4j-x5qyCt8RBr_75G_8B8b/preview',
        stats: [
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1170
            },
            {
                "u": 24.6,
                "i": 0.31,
                "f": 28,
                "p": 7,
                "t": 1171
            },
            {
                "u": 24.6,
                "i": 0.55,
                "f": 78,
                "p": 13,
                "t": 1196
            },
            {
                "u": 24.6,
                "i": 0.96,
                "f": 158,
                "p": 24,
                "t": 1234
            },
            {
                "u": 24.5,
                "i": 1.45,
                "f": 228,
                "p": 36,
                "t": 1267
            },
            {
                "u": 24.4,
                "i": 2.97,
                "f": 488,
                "p": 72,
                "t": 1327
            },
            {
                "u": 24.3,
                "i": 4.84,
                "f": 628,
                "p": 119,
                "t": 1399
            },
            {
                "u": 24.2,
                "i": 6.57,
                "f": 984,
                "p": 159,
                "t": 1432
            },
            {
                "u": 24,
                "i": 9.78,
                "f": 1248,
                "p": 235,
                "t": 1477
            },
            {
                "u": 23.8,
                "i": 12.75,
                "f": 1498,
                "p": 304,
                "t": 1519
            },
            {
                "u": 23.4,
                "i": 17.58,
                "f": 1878,
                "p": 416,
                "t": 1588
            },
            {
                "u": 23.3,
                "i": 21.97,
                "f": 2084,
                "p": 512,
                "t": 1639
            },
            {
                "u": 22.9,
                "i": 25.74,
                "f": 2368,
                "p": 617,
                "t": 1699
            },
            {
                "u": 22.4,
                "i": 34.75,
                "f": 2998,
                "p": 780,
                "t": 1768
            },
            {
                "u": 21.7,
                "i": 43.81,
                "f": 3278,
                "p": 958,
                "t": 1852
            },
            {
                "u": 21.1,
                "i": 51.13,
                "f": 3358,
                "p": 1121,
                "t": 1933
            },
            {
                "u": 20.9,
                "i": 55.5,
                "f": 3552,
                "p": 1160,
                "t": 2000
            }
        ],
    },

    {
        model_id: 144,
        brand: 'Vitrolom',
        model: '2807 1300KV',
        kv: 1300,
        sz: 2807,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240623_163126911.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240623_163059995.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240623_163205579.jpg',
        ],
        video: 'https://drive.google.com/file/d/1Touyc_vtWG6FVFn-MY3LQ9D5UjL3P02g/preview',
        stats:
            [
                {
                    "u": 24.8,
                    "i": 0,
                    "f": 0,
                    "p": 0,
                    "t": 1000
                },
                {
                    "u": 24.8,
                    "i": 0,
                    "f": 0,
                    "p": 0,
                    "t": 1156
                },
                {
                    "u": 24.8,
                    "i": 0.38,
                    "f": 57,
                    "p": 9,
                    "t": 1169
                },
                {
                    "u": 24.8,
                    "i": 0.78,
                    "f": 88,
                    "p": 23,
                    "t": 1234
                },
                {
                    "u": 24.8,
                    "i": 1.17,
                    "f": 116,
                    "p": 31,
                    "t": 1267
                },
                {
                    "u": 24.7,
                    "i": 1.87,
                    "f": 221,
                    "p": 52,
                    "t": 1315
                },
                {
                    "u": 24.7,
                    "i": 2.85,
                    "f": 364,
                    "p": 70,
                    "t": 1336
                },
                {
                    "u": 24.6,
                    "i": 3.6,
                    "f": 432,
                    "p": 89,
                    "t": 1363
                },
                {
                    "u": 24.5,
                    "i": 4.82,
                    "f": 542,
                    "p": 119,
                    "t": 1402
                },
                {
                    "u": 24.4,
                    "i": 6.36,
                    "f": 668,
                    "p": 157,
                    "t": 1447
                },
                {
                    "u": 24.3,
                    "i": 7.91,
                    "f": 758,
                    "p": 192,
                    "t": 1501
                },
                {
                    "u": 24.1,
                    "i": 10.96,
                    "f": 968,
                    "p": 266,
                    "t": 1543
                },
                {
                    "u": 24,
                    "i": 12.42,
                    "f": 1020,
                    "p": 296,
                    "t": 1570
                },
                {
                    "u": 23.6,
                    "i": 16.77,
                    "f": 1151,
                    "p": 407,
                    "t": 1621
                },
                {
                    "u": 23.3,
                    "i": 21.11,
                    "f": 1348,
                    "p": 491,
                    "t": 1669
                },
                {
                    "u": 23.1,
                    "i": 23.74,
                    "f": 1459,
                    "p": 545,
                    "t": 1726
                },
                {
                    "u": 22.3,
                    "i": 35.46,
                    "f": 1886,
                    "p": 792,
                    "t": 1894
                },
                {
                    "u": 22,
                    "i": 40.58,
                    "f": 2080,
                    "p": 896,
                    "t": 2000
                }
            ]
    },

    {
        model_id: 98,
        brand: 'FlashHobby',
        model: 'Arthur 3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240716_140009689.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240716_135947133.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240716_140058106.jpg',
        ],
        video: 'https://drive.google.com/file/d/1Qj3zDlbgbSgXCIqLebcwGG_N1GCN0cOv/preview',
        stats: [
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1146
            },
            {
                "u": 24.6,
                "i": 0.3,
                "f": 42,
                "p": 7,
                "t": 1147
            },
            {
                "u": 24.6,
                "i": 0.54,
                "f": 92,
                "p": 13,
                "t": 1201
            },
            {
                "u": 24.6,
                "i": 1,
                "f": 193,
                "p": 24,
                "t": 1243
            },
            {
                "u": 24.6,
                "i": 1.26,
                "f": 228,
                "p": 31,
                "t": 1261
            },
            {
                "u": 24.5,
                "i": 2.01,
                "f": 358,
                "p": 49,
                "t": 1297
            },
            {
                "u": 24.5,
                "i": 2.91,
                "f": 488,
                "p": 71,
                "t": 1342
            },
            {
                "u": 24.4,
                "i": 3.58,
                "f": 529,
                "p": 90,
                "t": 1369
            },
            {
                "u": 24.3,
                "i": 5.91,
                "f": 864,
                "p": 147,
                "t": 1420
            },
            {
                "u": 24.2,
                "i": 8.8,
                "f": 1088,
                "p": 213,
                "t": 1462
            },
            {
                "u": 23.9,
                "i": 11.61,
                "f": 1358,
                "p": 285,
                "t": 1510
            },
            {
                "u": 23.6,
                "i": 15.74,
                "f": 1788,
                "p": 374,
                "t": 1561
            },
            {
                "u": 23.1,
                "i": 24.06,
                "f": 2260,
                "p": 561,
                "t": 1663
            },
            {
                "u": 22.5,
                "i": 34.92,
                "f": 2686,
                "p": 799,
                "t": 1762
            },
            {
                "u": 21.9,
                "i": 42.07,
                "f": 2929,
                "p": 936,
                "t": 1828
            },
            {
                "u": 21.1,
                "i": 49.56,
                "f": 3238,
                "p": 1116,
                "t": 1933
            },
            {
                "u": 20.8,
                "i": 57.06,
                "f": 3529,
                "p": 1190,
                "t": 2000
            }
        ],
    },

    {
        model_id: 140,
        brand: 'FlashHobby',
        model: 'Mars 3115 900KV',
        kv: 900,
        sz: 3115,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_185327775.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_185305293.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_185352471.jpg',
        ],
        video: 'https://drive.google.com/file/d/19mtTjC38qJDjtf8bBEjzTujDdi8hf2ld/preview',
        stats: [
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.6,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1153
            },
            {
                "u": 24.6,
                "i": 0.4,
                "f": 60,
                "p": 9,
                "t": 1189
            },
            {
                "u": 24.6,
                "i": 0.52,
                "f": 108,
                "p": 12,
                "t": 1204
            },
            {
                "u": 24.6,
                "i": 1.02,
                "f": 202,
                "p": 25,
                "t": 1249
            },
            {
                "u": 24.5,
                "i": 1.9,
                "f": 362,
                "p": 46,
                "t": 1297
            },
            {
                "u": 24.5,
                "i": 2.68,
                "f": 483,
                "p": 67,
                "t": 1342
            },
            {
                "u": 24.4,
                "i": 4.03,
                "f": 654,
                "p": 101,
                "t": 1393
            },
            {
                "u": 24.1,
                "i": 7.7,
                "f": 1087,
                "p": 186,
                "t": 1444
            },
            {
                "u": 23.9,
                "i": 11.36,
                "f": 1378,
                "p": 282,
                "t": 1501
            },
            {
                "u": 23.5,
                "i": 17.38,
                "f": 1833,
                "p": 413,
                "t": 1600
            },
            {
                "u": 23,
                "i": 22.54,
                "f": 2072,
                "p": 533,
                "t": 1669
            },
            {
                "u": 22.2,
                "i": 35.45,
                "f": 2845,
                "p": 789,
                "t": 1792
            },
            {
                "u": 21.5,
                "i": 43.14,
                "f": 3097,
                "p": 947,
                "t": 1885
            },
            {
                "u": 21,
                "i": 52.4,
                "f": 3332,
                "p": 1120,
                "t": 1954
            },
            {
                "u": 21,
                "i": 52.39,
                "f": 3398,
                "p": 1102,
                "t": 2000
            }
        ],
    },

    {
        model_id: 165,
        brand: 'iFlight',
        model: 'Xing 2814 880KV',
        kv: 880,
        sz: 2814,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200253704.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200242442.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200311113.jpg',
        ],
        video: 'https://drive.google.com/file/d/1QetI4jFLu9eec-6Y5-yD5axBmjO1Ja8m/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1156
            },
            {
                "u": 24.8,
                "i": 0.4,
                "f": 57,
                "p": 10,
                "t": 1186
            },
            {
                "u": 24.7,
                "i": 0.89,
                "f": 139,
                "p": 21,
                "t": 1237
            },
            {
                "u": 24.7,
                "i": 1.78,
                "f": 298,
                "p": 45,
                "t": 1297
            },
            {
                "u": 24.6,
                "i": 2.97,
                "f": 467,
                "p": 73,
                "t": 1345
            },
            {
                "u": 24.5,
                "i": 4.07,
                "f": 588,
                "p": 100,
                "t": 1390
            },
            {
                "u": 24.4,
                "i": 5.6,
                "f": 758,
                "p": 136,
                "t": 1432
            },
            {
                "u": 24.1,
                "i": 9.82,
                "f": 1098,
                "p": 238,
                "t": 1504
            },
            {
                "u": 23.9,
                "i": 12.84,
                "f": 1388,
                "p": 309,
                "t": 1552
            },
            {
                "u": 23.7,
                "i": 17.15,
                "f": 1698,
                "p": 409,
                "t": 1618
            },
            {
                "u": 23.4,
                "i": 21.24,
                "f": 1947,
                "p": 497,
                "t": 1678
            },
            {
                "u": 22.9,
                "i": 27.89,
                "f": 2308,
                "p": 645,
                "t": 1750
            },
            {
                "u": 22.4,
                "i": 33.66,
                "f": 2571,
                "p": 778,
                "t": 1837
            },
            {
                "u": 21.9,
                "i": 42.48,
                "f": 2977,
                "p": 935,
                "t": 1918
            },
            {
                "u": 21.7,
                "i": 45.8,
                "f": 3071,
                "p": 991,
                "t": 2000
            }
        ],
    },

    {
        model_id: 165,
        brand: 'iFlight',
        model: 'Xing 2814 880KV (GF 8060)',
        kv: 880,
        sz: 2814,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200253704.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200242442.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200311113.jpg',
        ],
        video: 'https://drive.google.com/file/d/1QetI4jFLu9eec-6Y5-yD5axBmjO1Ja8m/preview',
        stats: [
            {
                "u": 24.9,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.9,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1169
            },
            {
                "u": 24.9,
                "i": 0.41,
                "f": 31,
                "p": 10,
                "t": 1192
            },
            {
                "u": 24.9,
                "i": 0.87,
                "f": 88,
                "p": 21,
                "t": 1258
            },
            {
                "u": 24.8,
                "i": 1.71,
                "f": 225,
                "p": 42,
                "t": 1312
            },
            {
                "u": 24.8,
                "i": 2.27,
                "f": 308,
                "p": 56,
                "t": 1348
            },
            {
                "u": 24.7,
                "i": 3.88,
                "f": 446,
                "p": 100,
                "t": 1405
            },
            {
                "u": 24.5,
                "i": 6.28,
                "f": 788,
                "p": 154,
                "t": 1480
            },
            {
                "u": 24.4,
                "i": 7.95,
                "f": 929,
                "p": 197,
                "t": 1537
            },
            {
                "u": 24.1,
                "i": 11.15,
                "f": 1198,
                "p": 267,
                "t": 1609
            },
            {
                "u": 23.8,
                "i": 14.66,
                "f": 1388,
                "p": 368,
                "t": 1696
            },
            {
                "u": 23.4,
                "i": 20.81,
                "f": 1828,
                "p": 495,
                "t": 1780
            },
            {
                "u": 23,
                "i": 27.01,
                "f": 2012,
                "p": 653,
                "t": 1870
            },
            {
                "u": 22.4,
                "i": 34.94,
                "f": 2508,
                "p": 784,
                "t": 2000
            }
        ],
    },

    {
        model_id: 165,
        brand: 'iFlight',
        model: 'Xing 2814 880KV (HQ 1045)',
        kv: 880,
        sz: 2814,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200253704.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200242442.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20240804_200311113.jpg',
        ],
        video: 'https://drive.google.com/file/d/1QetI4jFLu9eec-6Y5-yD5axBmjO1Ja8m/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1180
            },
            {
                "u": 24.8,
                "i": 0.53,
                "f": 84,
                "p": 13,
                "t": 1210
            },
            {
                "u": 24.8,
                "i": 0.8,
                "f": 137,
                "p": 19,
                "t": 1243
            },
            {
                "u": 24.7,
                "i": 1.53,
                "f": 249,
                "p": 37,
                "t": 1285
            },
            {
                "u": 24.6,
                "i": 2.66,
                "f": 429,
                "p": 65,
                "t": 1348
            },
            {
                "u": 24.5,
                "i": 3.91,
                "f": 588,
                "p": 96,
                "t": 1399
            },
            {
                "u": 24.3,
                "i": 6.83,
                "f": 940,
                "p": 170,
                "t": 1474
            },
            {
                "u": 24.1,
                "i": 9.68,
                "f": 1220,
                "p": 235,
                "t": 1531
            },
            {
                "u": 23.9,
                "i": 13.85,
                "f": 1482,
                "p": 339,
                "t": 1609
            },
            {
                "u": 23.5,
                "i": 18.75,
                "f": 1892,
                "p": 442,
                "t": 1687
            },
            {
                "u": 23.3,
                "i": 21.75,
                "f": 2107,
                "p": 512,
                "t": 1741
            },
            {
                "u": 22.5,
                "i": 30.32,
                "f": 2588,
                "p": 715,
                "t": 1858
            },
            {
                "u": 22.1,
                "i": 39.85,
                "f": 2985,
                "p": 899,
                "t": 1948
            },
            {
                "u": 21.9,
                "i": 40.92,
                "f": 3047,
                "p": 898,
                "t": 2000
            }
        ],
    },

    {
        model_id: 238,
        brand: 'Axisflying',
        model: '2810 1300KV',
        kv: 1300,
        sz: 2810,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20241115_011835416.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20241115_011820231.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20241115_011915597.jpg',
        ],
        video: 'https://drive.google.com/file/d/1LmufNDIDQcYhZSjgazTHlZeZLIjizZ7c/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1174
            },
            {
                "u": 24.8,
                "i": 0.52,
                "f": 58,
                "p": 13,
                "t": 1198
            },
            {
                "u": 24.8,
                "i": 1,
                "f": 124,
                "p": 26,
                "t": 1249
            },
            {
                "u": 24.7,
                "i": 1.95,
                "f": 208,
                "p": 51,
                "t": 1309
            },
            {
                "u": 24.6,
                "i": 3.47,
                "f": 376,
                "p": 88,
                "t": 1360
            },
            {
                "u": 24.5,
                "i": 4.83,
                "f": 512,
                "p": 126,
                "t": 1408
            },
            {
                "u": 24.4,
                "i": 6.3,
                "f": 628,
                "p": 155,
                "t": 1447
            },
            {
                "u": 24.3,
                "i": 8.29,
                "f": 888,
                "p": 202,
                "t": 1510
            },
            {
                "u": 24.1,
                "i": 10.49,
                "f": 998,
                "p": 264,
                "t": 1567
            },
            {
                "u": 23.9,
                "i": 13.43,
                "f": 1122,
                "p": 326,
                "t": 1618
            },
            {
                "u": 23.5,
                "i": 17.86,
                "f": 1395,
                "p": 431,
                "t": 1666
            },
            {
                "u": 23.3,
                "i": 21.63,
                "f": 1588,
                "p": 524,
                "t": 1720
            },
            {
                "u": 22.9,
                "i": 26.77,
                "f": 1818,
                "p": 632,
                "t": 1792
            },
            {
                "u": 22.5,
                "i": 32.73,
                "f": 2108,
                "p": 757,
                "t": 1852
            },
            {
                "u": 21.8,
                "i": 38.2,
                "f": 2258,
                "p": 879,
                "t": 1939
            },
            {
                "u": 21.7,
                "i": 42.64,
                "f": 2488,
                "p": 932,
                "t": 2000
            }
        ],
    },

    {
        model_id: 256,
        brand: 'Axisflying',
        model: '3215 730KV',
        kv: 3115,
        sz: 730,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/PXL_20241108_152244466.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20241108_152259073.jpg',
            'https://motors.swarm.army/assets/thumbs/PXL_20241108_152408178.jpg',
        ],
        video: 'https://drive.google.com/file/d/1IPrYoDG7Mn4PqSEqokEgKnISPvCPVKfn/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1171
            },
            {
                "u": 24.8,
                "i": 0.37,
                "f": 49,
                "p": 9,
                "t": 1177
            },
            {
                "u": 24.8,
                "i": 0.56,
                "f": 78,
                "p": 15,
                "t": 1207
            },
            {
                "u": 24.7,
                "i": 1.13,
                "f": 208,
                "p": 28,
                "t": 1255
            },
            {
                "u": 24.7,
                "i": 2.13,
                "f": 338,
                "p": 56,
                "t": 1312
            },
            {
                "u": 24.6,
                "i": 3.51,
                "f": 538,
                "p": 91,
                "t": 1369
            },
            {
                "u": 24.6,
                "i": 4.22,
                "f": 698,
                "p": 106,
                "t": 1396
            },
            {
                "u": 24.4,
                "i": 6.48,
                "f": 974,
                "p": 158,
                "t": 1462
            },
            {
                "u": 24.2,
                "i": 9.2,
                "f": 1257,
                "p": 222,
                "t": 1507
            },
            {
                "u": 24.1,
                "i": 12.8,
                "f": 1541,
                "p": 293,
                "t": 1552
            },
            {
                "u": 23.9,
                "i": 15.28,
                "f": 1756,
                "p": 376,
                "t": 1603
            },
            {
                "u": 23.6,
                "i": 20.51,
                "f": 2187,
                "p": 486,
                "t": 1699
            },
            {
                "u": 23.2,
                "i": 27.83,
                "f": 2425,
                "p": 665,
                "t": 1750
            },
            {
                "u": 22.8,
                "i": 35.12,
                "f": 2938,
                "p": 806,
                "t": 1815
            },
            {
                "u": 22.4,
                "i": 45.42,
                "f": 3398,
                "p": 1036,
                "t": 1906
            },
            {
                "u": 22,
                "i": 52.34,
                "f": 3833,
                "p": 1156,
                "t": 2000
            }
        ],
    },

    {
        model_id: 314,
        brand: 'Blackbird',
        model: '2807 1300KV',
        kv: 2807,
        sz: 1300,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.54.59.jpeg',
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.54.55.jpeg',
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.54.57.jpeg',
        ],
        video: 'https://drive.google.com/file/d/1UfU5L_z-vRm5QthHMWONc0mdIEnscdks/preview',
        stats: [
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.8,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1180
            },
            {
                "u": 24.8,
                "i": 0.43,
                "f": 54,
                "p": 11,
                "t": 1201
            },
            {
                "u": 24.8,
                "i": 1.04,
                "f": 198,
                "p": 25,
                "t": 1267
            },
            {
                "u": 24.7,
                "i": 1.46,
                "f": 200,
                "p": 37,
                "t": 1300
            },
            {
                "u": 24.6,
                "i": 2.9,
                "f": 376,
                "p": 73,
                "t": 1363
            },
            {
                "u": 24.5,
                "i": 4.35,
                "f": 528,
                "p": 107,
                "t": 1417
            },
            {
                "u": 24.4,
                "i": 5.3,
                "f": 588,
                "p": 125,
                "t": 1456
            },
            {
                "u": 24.3,
                "i": 7.05,
                "f": 725,
                "p": 175,
                "t": 1513
            },
            {
                "u": 24.1,
                "i": 8.73,
                "f": 860,
                "p": 213,
                "t": 1564
            },
            {
                "u": 24,
                "i": 11.77,
                "f": 1088,
                "p": 288,
                "t": 1615
            },
            {
                "u": 23.5,
                "i": 15.36,
                "f": 1288,
                "p": 366,
                "t": 1660
            },
            {
                "u": 23.3,
                "i": 19.18,
                "f": 1348,
                "p": 451,
                "t": 1720
            },
            {
                "u": 23,
                "i": 21.76,
                "f": 1458,
                "p": 536,
                "t": 1774
            },
            {
                "u": 22.6,
                "i": 29.33,
                "f": 1888,
                "p": 663,
                "t": 1879
            },
            {
                "u": 22.2,
                "i": 34.14,
                "f": 2058,
                "p": 756,
                "t": 1948
            },
            {
                "u": 22.2,
                "i": 34.91,
                "f": 2096,
                "p": 778,
                "t": 2000
            }
        ],
    },

    {
        model_id: 315,
        brand: 'Blackbird',
        model: '3115 900KV',
        kv: 3115,
        sz: 900,
        visible: true,
        photos: [
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.55.02.jpeg',
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.55.00.jpeg',
            'https://motors.swarm.army/assets/thumbs/photo_2024-11-15 02.55.03.jpeg',
        ],
        video: 'https://drive.google.com/file/d/1eRkrdwKry7hYWzn4ftLnuaP3On7SuhOC/preview',
        stats: [
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1000
            },
            {
                "u": 24.7,
                "i": 0,
                "f": 0,
                "p": 0,
                "t": 1153
            },
            {
                "u": 24.7,
                "i": 0.26,
                "f": 60,
                "p": 6,
                "t": 1171
            },
            {
                "u": 24.7,
                "i": 0.67,
                "f": 125,
                "p": 16,
                "t": 1213
            },
            {
                "u": 24.6,
                "i": 1.17,
                "f": 236,
                "p": 31,
                "t": 1255
            },
            {
                "u": 24.6,
                "i": 2.35,
                "f": 416,
                "p": 59,
                "t": 1312
            },
            {
                "u": 24.5,
                "i": 3.56,
                "f": 555,
                "p": 99,
                "t": 1366
            },
            {
                "u": 24.4,
                "i": 5.41,
                "f": 758,
                "p": 142,
                "t": 1414
            },
            {
                "u": 24.2,
                "i": 9.1,
                "f": 1196,
                "p": 221,
                "t": 1450
            },
            {
                "u": 24.1,
                "i": 12.23,
                "f": 1488,
                "p": 296,
                "t": 1504
            },
            {
                "u": 23.9,
                "i": 15.4,
                "f": 1678,
                "p": 375,
                "t": 1549
            },
            {
                "u": 23.8,
                "i": 18.36,
                "f": 1888,
                "p": 440,
                "t": 1597
            },
            {
                "u": 23.4,
                "i": 23.26,
                "f": 2098,
                "p": 556,
                "t": 1645
            },
            {
                "u": 23.2,
                "i": 29.19,
                "f": 2428,
                "p": 707,
                "t": 1711
            },
            {
                "u": 22.8,
                "i": 37.32,
                "f": 2878,
                "p": 868,
                "t": 1780
            },
            {
                "u": 22.2,
                "i": 47.1,
                "f": 3270,
                "p": 1086,
                "t": 1867
            },
            {
                "u": 22,
                "i": 54.62,
                "f": 3548,
                "p": 1209,
                "t": 1909
            },
            {
                "u": 21.7,
                "i": 60.65,
                "f": 3691,
                "p": 1315,
                "t": 2000
            }
        ],
    },

];

// function split(str = '') {
//     return str.trim().split('\n').map(s => ({ u: Number(s.split('\t')[0]), i: Number(s.split('\t')[1]), f: Number(s.split('\t')[2]), p: Number(s.split('\t')[3]), t: Number(s.split('\t')[4]) }))
// }

// split(`24.2	0.05	0	1	1000
// 24.2	0.04	0	1	1111
// 24.2	0.16	0	3	1150
// 24.2	0.56	88	13	1217
// 24.1	1.08	160	26	1274
// 24.1	2	289	49	1322
// 24	3.2	432	76	1373
// 23.9	4.97	506	114	1436
// 23.7	7.63	806	180	1532
// 23.7	7.6	808	180	1547
// 23.3	14.86	1160	365	1643
// 23	19.24	1392	448	1697
// 22.8	21.18	1488	476	1727
// 22.4	25.07	1596	621	1841
// 22.2	28.96	1798	697	1904
// 21.9	32.98	1988	763	1979
// 21.9	35.06	2068	769	2000`)

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromoButtonComponent } from './promo-button/promo-button.component';
import { PromoDialogComponent } from './promo-dialog/promo-dialog.component';
import { MaterialModule } from '../material/material.module';
import { PromosComponent } from './promos.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    PromoButtonComponent,
    PromoDialogComponent,
    PromosComponent,
  ],
  exports: [
    PromoButtonComponent,
    PromoDialogComponent,
    PromosComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ]
})
export class PromosModule { }

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { OrderFull } from '../../data.service';
import { timestampToLocalDate } from '../../utils';

export interface ReportDoneDialogData {
    order: OrderFull;
    form_value: any;
}

@Component({
    selector: 'app-report-done-dialog',
    templateUrl: './report-done-dialog.component.html',
    styleUrl: './report-done-dialog.component.scss',
})
export class ReportDoneDialogComponent {

    public order: OrderFull;

    public readonly timestampToLocalDate = timestampToLocalDate;

    public static open(dialog: MatDialog, data: ReportDoneDialogData): Promise<unknown> {
        const dialogRef = dialog.open(ReportDoneDialogComponent, { data, minWidth: '300px', maxWidth: '600px', autoFocus: false, });
        return firstValueFrom(dialogRef.afterClosed());
    }

    constructor(
        public dialogRef: MatDialogRef<ReportDoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReportDoneDialogData,
    ) {
        this.order = data.order;
    }

}

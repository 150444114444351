<a mat-button (click)="bindTelegram()" [buttonLoading]="!!telegram_bind_interval"
    *ngIf="!data.profile?.user_telegram_id" target="_blank" color="primary"
    [href]="'https://t.me/UADronesDEBot?start='+data.profile?.user_uuid">
    <img class="telegram-logo" src="https://desktop.telegram.org/img/website_icon.svg?4" />
    <span>Звʼязати з Telegram</span>
</a>

<button mat-button (click)="unbindTelegram()" [buttonLoading]="loading" *ngIf="data.profile?.user_telegram_id">
    <img class="telegram-logo" src="https://desktop.telegram.org/img/website_icon.svg?4" />
    <span>&commat;{{data.profile?.user_telegram_username||data.profile?.user_telegram_name}}</span>
</button>
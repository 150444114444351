<div mat-dialog-title>
    Ви часом не робот 🤖?
</div>

<!-- <mat-dialog-content class="mat-typography"> -->

    <p>Пройдіть просту перевірку, щоб пересвідчитися, що ви не робот.</p>

    <!-- TODO: fix recaptcha -->
    <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LewJFEqAAAAAI552sCjfTVD-fnnu4ah7RvrM9JL"></re-captcha> -->

<!-- </mat-dialog-content> -->
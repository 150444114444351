<mat-drawer-container>

    <mat-drawer mode="side" #drawer mode="over" position="end" class="main-menu">

        <div class="sidenav-account-wrapper">
            <app-account-button></app-account-button>
            <app-tenant-menu *ngIf="data.profile"></app-tenant-menu>
        </div>

        <mat-list>

            <ng-container *ngFor="let m of menuItems">
                <a mat-list-item [routerLink]="m.url" [routerLinkActiveOptions]="{exact:true}"
                    routerLinkActive="is-active">
                    <span>{{m.name}}</span>
                </a>
            </ng-container>

            <mat-divider></mat-divider>

            <a mat-list-item href="https://t.me/+jPtYcbbg-t1mZmYy" target="_blank">
                <div class="img-link">
                    <span>Telegram-канал</span>
                    <img src="https://desktop.telegram.org/img/website_icon.svg?4" />
                </div>
            </a>

            <a mat-list-item target="_blank" *ngIf="data.europe"
                href="https://chromewebstore.google.com/detail/ua-dronesde/pmijhibgbgkjmmccojjkohnhigkamjkk">
                <div class="img-link">
                    <span>Chrome-розширення</span>
                    <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg" />
                </div>
            </a>

            <a mat-button href="https://swarm.army" color="primary" target="_blank" class="mx-1" matTooltip="Для української спільноти (ціни в $, доставка в Україну)">
                <div class="tg-url-wrapper">
                    <span>swarm.army</span>
                    <img src="https://assets.swarm.army/swarm-black-t.png" />
                </div>
            </a>

        </mat-list>

    </mat-drawer>

    <mat-drawer-content class="main-wrapper">

        <app-toolbar class="main-nav" (menuClick)="drawer.toggle()">


            <notifications-button></notifications-button>
            <app-tenant-menu *ngIf="data.profile"></app-tenant-menu>
            <span class="mx-1"></span>
            <app-account-button></app-account-button>

            <!-- <button mat-icon-button class="btn-primary" (click)="toggleTheme()">
                <mat-icon> @if(isDark()) { light_mode }@else { dark_mode } </mat-icon>
            </button> -->
        </app-toolbar>

        <div class="main-content">
            <router-outlet></router-outlet>
        </div>

    </mat-drawer-content>
</mat-drawer-container>

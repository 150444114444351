<app-sidebar #sidebar>

    <div role="sidebar" class="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До дронів' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_drones.length}}</span>
                    <small>/{{drones.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div class="products-empty-message" *ngIf="drones.length && !filtered_drones.length">
        Жоден дрон не відповідає критеріям фільтра.
    </div>

    <div class="products-empty-message" *ngIf="!drones.length && !loading">
        Дронів не знайдено.
    </div>

    <div class="products-empty-message" *ngIf="loading">
        Завантаження...
    </div>

    <div class="drones-scroll">
        <div class="drones-wrapper">

            <div *ngFor="let drone of filtered_drones" class="drone" matRipple routerLink="."
                [queryParams]="{view:'drone',drone_id:drone.drone_id}"
                [class.drone-deleted]="drone.drone_status=='deleted'">

                <div class="drone-id-wrapper">
                    <div class="product-available" matTooltip="Внутрішній ID">
                        <mat-icon>settings</mat-icon>
                        <span>{{drone.drone_id}}</span>
                    </div>
                    <div class="product-available" matTooltip="Номер дрону">
                        <!-- <span>{{drone.drone_number}}</span> -->
                        <span>
                            <a target="_blank" [href]="'https://sdua.tech/drones/'+drone.drone_number">
                                <mat-icon>tag</mat-icon>
                                <span class="product-seller-name">{{drone.drone_number}}</span>
                            </a>
                        </span>
                    </div>
                    <div class="product-available" matTooltip="Тип дрону">
                        <mat-icon>tag</mat-icon>
                        <span>{{drone.drone_type_local}}</span>
                    </div>
                </div>

                <div class="drone-user-wrapper">

                    <div class="product-extra product-seller">
                        <img class="product-seller-logo" [src]="drone.user_image_url" />
                        <span class="product-seller-name">{{drone.user_name}}</span>
                    </div>

                    <div class="product-available">{{drone.drone_date_string}}</div>

                    <div class="product-available" *ngIf="drone.drone_public">
                        <mat-icon>lock_open</mat-icon>
                        <span>Публічний</span>
                    </div>

                    <div class="product-available" *ngIf="!drone.drone_public">
                        <mat-icon>lock</mat-icon>
                        <span>Непублічний</span>
                    </div>

                </div>

                <div class="product-attrs">
                    <div class="product-extra product-seller" *ngFor="let detail of drone.details">
                        <img class="product-seller-logo" [src]="detail.model_image_url" />
                        <span class="product-seller-name">{{detail.model_name}}</span>
                    </div>
                </div>

                <div class="drone-status-wrapper">
                    <div class="product-extra {{drone.drone_status}}">
                        <span>{{drone.drone_status_local}}</span>
                    </div>
                    <div class="product-available" *ngIf="drone.drone_name">
                        <mat-icon>badge</mat-icon>
                        <span>{{drone.drone_name}}</span>
                    </div>
                    <div class="product-price">
                        <span>{{drone.drone_price_usd|currency}}</span>
                    </div>
                    <div class="product-price">
                        <span>{{drone.drone_price_uah|currency:'UAH'}}</span>
                    </div>
                </div>

            </div>

        </div>
    </div>


    <div class="products-buttons">

        <button mat-fab extended color="accent" (click)="sidebar.openSidebar()" *ngIf="layout.is_mobile | async">
            <mat-icon>filter_list</mat-icon>
            <span>Фільтр</span>
        </button>

        <div class="spacer"></div>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="export()">
            <mat-icon>file_download</mat-icon>
            <span *ngIf="layout.is_desktop | async">Експорт</span>
        </button>

        <button mat-fab [extended]="layout.is_desktop | async" color="accent" (click)="addDrone()">
            <mat-icon>add</mat-icon>
            <span *ngIf="layout.is_desktop | async">Створити дрон</span>
        </button>

    </div>

</app-sidebar>
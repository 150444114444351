import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService, Detail, Model, ProductFull, ProductUpdateDto } from '../data.service';

type DetailGroup = Detail & { models: Model[] };

@Component({
    selector: 'app-edit-product-dialog',
    templateUrl: './edit-product-dialog.component.html',
    styleUrl: './edit-product-dialog.component.scss',
})
export class ProductEditDialogComponent {

    public static open(dialog: MatDialog, product: ProductFull) {
        return dialog.open(ProductEditDialogComponent, { width: '520px', data: product, autoFocus: false, restoreFocus: false });
    }

    public loading = false;
    public models: Model[] = [];
    public quantities = [0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 30, 33, 35, 40, 45, 50, 55, 60, 66, 65, 70, 75, 80, 85, 90, 95, 99, 100, 120, 150, 165, 200, 250, 264, 300, 330, 400, 500, 1000];
    public details: DetailGroup[] = [];

    constructor(
        public readonly data: DataService,
        @Inject(MAT_DIALOG_DATA) public readonly product: ProductFull,
        private readonly snackbar: MatSnackBar,
        private readonly ref: MatDialogRef<ProductEditDialogComponent>,
    ) {
        this.data.getModels().subscribe({
            next: models => {
                this.models = models;
                this.details = [];
                models.forEach(m => {
                    let detail = this.details.find(d => d.detail_id === m.detail_id);
                    if (!detail) {
                        detail = { ...m, models: [] };
                        this.details.push(detail);
                    }
                    detail.models.push(m);
                })
            }
        });

    }

    public form = new FormGroup({
        sku_id: new FormControl(this.product.sku_id || '', Validators.required),
        product_status: new FormControl(this.product.product_status || '', Validators.required),
        model_id: new FormControl(this.product.model_id || 0, Validators.required),
        sku_quantity: new FormControl(this.product.sku_quantity || 0, Validators.required),
        product_deleted: new FormControl(this.product.product_deleted),
        product_update_interval: new FormControl(this.product.product_update_interval, [Validators.required, Validators.pattern(/^\d+$/)]),
    });

    // public async pasteAliExpress() {
    //     const text = await navigator.clipboard.readText();
    //     this.form.controls.product_url.setValue(/(https:\/\/s\.click\.aliexpress.com\/e\/\w+)/.exec(text || '')?.[1] || '');
    // }

    // public async pasteSuperDeals() {
    //     const text = await navigator.clipboard.readText();
    //     this.form.controls.product_sd_url.setValue(/(https:\/\/s\.click\.aliexpress.com\/e\/\w+)/.exec(text || '')?.[1] || '');
    // }

    // public async pasteLimitedDeal() {
    //     const text = await navigator.clipboard.readText();
    //     this.form.controls.product_lo_url.setValue(/(https:\/\/s\.click\.aliexpress.com\/e\/\w+)/.exec(text || '')?.[1] || '');
    // }

    // public copy(url: string) {
    //     navigator.clipboard.writeText(url);
    // }


    public save() {

        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        const product = this.product;

        if (!product || this.loading) return;

        const product_update_interval = Number(this.form.controls.product_update_interval.value || 1800);

        this.loading = true;

        const dto: ProductUpdateDto = {
            product_id: product.product_id,
            model_id: this.form.value.model_id || 0,
            product_status: this.form.value.product_status || 'unknown',
            sku_quantity: this.form.value.sku_quantity || 0,
            sku_id: this.form.value.sku_id || '',
            product_deleted: this.form.value.product_deleted || 0,
            product_update_interval: product_update_interval,
        };

        this.data.updateProduct(dto).subscribe({
            next: res => {

                this.loading = false;

                if (!product) {
                    this.data.showMessage('Сталася помилка!');
                } else {

                    this.snackbar.open('Дані збережено.');

                    product.model_id = dto.model_id;
                    product.sku_quantity = dto.sku_quantity;
                    product.product_status = dto.product_status;
                    product.product_deleted = dto.product_deleted;
                    product.sku_id = dto.sku_id;
                    product.product_update_interval = dto.product_update_interval;
                    product.product_url = res.product_url;
                    product.sku_url = this.data.getUrl(product);
                    product.sku_sd_url = this.data.getSdUrl(product);
                    product.sku_cd_url = this.data.getCdUrl(product);
                    product.sku_lo_url = this.data.getLdUrl(product);

                    const model = this.models.find(m => m.model_id === dto.model_id);

                    if (model) {
                        Object.assign(product, model);
                    }

                    this.ref.close();

                }

            },
            error: () => {
                this.loading = false;
                this.data.showMessage('Сталася помилка!');
            },
        });
    }

}

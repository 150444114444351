<mat-dialog-content class="mat-typography">

    <form (ngSubmit)="save()" [formGroup]="form" *ngIf="user">

        <div style="margin-bottom:16px;">

            <img class="profile-avatar" [src]="user.user_image_url||'/assets/logo.png'" />

            <div class="product-attrs">
                <div class="product-extra">{{form.value.user_email}}</div>
                <div class="product-extra">{{roles_dict[membership?.membership_role||user.user_role]}}</div>
            </div>

        </div>

        <div *ngIf="canShowMoney && money" style="margin-bottom:16px;">

            <div class="product-attrs">
                <div class="product-extra">
                    Витрачено:
                    <b>
                        {{money.order_amount_uah | currency}}
                    </b>
                </div>

                <div class="product-extra">
                    Виплачено:
                    <b>
                        {{money.payout_amount_uah | currency}}
                    </b>
                </div>
            </div>

            <div class="product-attrs">
                <div class="product-extra green">
                    Донатів:
                    <b>
                        {{money.order_donate_amount_uah | currency}}
                    </b>
                </div>

                <div class="product-extra red">
                    До виплати:
                    <b>
                        {{(money.order_amount_uah-money.order_donate_amount_uah-money.payout_amount_uah) | currency}}
                    </b>
                </div>
            </div>
        </div>

        <mat-form-field>
            <mat-label>Нік</mat-label>
            <input matInput formControlName="user_nick" placeholder="omelka">
            <mat-hint>Власний нік публічний, згенерований за замовчуванням - ні</mat-hint>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Ім'я</mat-label>
            <input matInput formControlName="user_name" placeholder="Омелько Крутивус">
        </mat-form-field>

        <mat-form-field *ngIf="canShowMoney">
            <mat-label>Номер карти (опціонально)</mat-label>
            <input matInput formControlName="user_card_number" placeholder="1111222233334444">
        </mat-form-field>

        <mat-form-field *ngIf="canShowMoney">
            <mat-label>Назва карти (опціонально)</mat-label>
            <input matInput formControlName="user_card_name" placeholder="Карта Моно">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Рахунок IBAN (опціонально)</mat-label>
            <input matInput formControlName="user_iban_number" placeholder="UA000000000000000000000000000">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Назва рахунку (опціонально)</mat-label>
            <input matInput formControlName="user_iban_name" placeholder="Рахунок Приват">
        </mat-form-field>

        <mat-form-field *ngIf="data.superadmin||data.scrapper||data.profile?.user_api_access?.length">
            <mat-label>Токен авторизації</mat-label>
            <input matInput readonly [value]="data.getJwt()">
            <button mat-icon-button type="button" matSuffix (click)="copyJwt()">
                <mat-icon>copy</mat-icon>
            </button>
        </mat-form-field>

        <mat-expansion-panel hideToggle>

            <mat-expansion-panel-header>
                <mat-panel-title>
                    Виплати
                </mat-panel-title>
                <mat-panel-description>
                    <div class="events-count">{{payouts.length}}</div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="events-body">
                <div class="events-event" *ngFor="let e of payouts">
                    <div class="event-date">
                        <div class="product-available">
                            {{timestampToLocalDate(e.payout_date)}}
                        </div>
                    </div>
                    <div class="event-type">
                        {{e.payout_amount_uah | currency}}
                    </div>
                </div>
            </div>

        </mat-expansion-panel>

        <telegram-button></telegram-button>

    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="warn" (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Вийти</span>
    </button>
    <div style="flex: 1;"></div>
    <button mat-button mat-dialog-close>Закрити</button>
    <button mat-flat-button color="accent" [disabled]="!canSave" (click)="onSave()"
        [buttonLoading]="loading">Зберегти</button>
</mat-dialog-actions>

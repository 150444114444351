<h2>
    <span>Трохи статистики</span>
</h2>

<div class="products-carousel-wrapper">
    <div class="products-carousel">

        <ng-container *ngIf="stats[0].value">
            <mat-card class="kit-card" *ngFor="let s of stats">
                <!-- TODO: hide some statistic from public -->
                <!-- <mat-card-content *ngIf="s.role !== 'superadmin' || s.role === 'superadmin'"> -->
                <mat-card-content *ngIf="s.role !== 'superadmin' ">

                    <div class="product-name">
                        <span>{{s.name}}</span>
                    </div>

                    <div class="product-name" style="font-size: 10px;color:#999;margin-bottom: 4px;">
                        <span>{{s.desc}}</span>
                    </div>

                    <div class="product-price-wrapper">
                        <span class="product-price" *ngIf="s.format==='numeric'">{{s.value}}</span>
                        <span class="product-price" *ngIf="s.format==='currency'">{{s.value|currency}}</span>
                    </div>

                </mat-card-content>
            </mat-card>
        </ng-container>

        <ng-container *ngIf="!stats[0].value">
            <div class="skeleton-block skeleton-stats" *ngFor="let _ of [].constructor(5).fill(0)"></div>
        </ng-container>

    </div>
</div>

<h2>
    <span>Набори</span>
    <div class="spacer"></div>
    <a mat-button color="primary" routerLink="/products" [queryParams]="{kit:''}">
        <span>Всі набори</span>
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </a>
</h2>

<div class="products-carousel-wrapper">
    <div class="products-carousel">

        <mat-card class="kit-card" *ngFor="let k of kits_public" routerLink="/products" [queryParams]="{kit:k.kit_slug}"
            (click)="data.click(data.CS.HomePage, data.CT.OpenAliexpress, k)">
            <mat-card-content>

                <div class="product-name">
                    <span>{{ k.kit_name }}</span>
                </div>

                <div class="product-price-wrapper">
                    <span class="product-price">{{ k.kit_price | currency }}</span>
                </div>

                <a mat-button color="primary" class="product-url" routerLink="/products"
                    [queryParams]="{kit:k.kit_slug}">
                    До набору
                </a>

                <img class="kit-logo" *ngIf="k.kit_name.includes('SD')"
                    src="https://static.wixstatic.com/shapes/e9fa2c_c6b15243f678441fbd329962ab915d0b.svg" />

                <img class="kit-logo" *ngIf="k.kit_name.includes('VD')"
                    src="https://images.squarespace-cdn.com/content/v1/639ad8ad2dfb705d7768c20b/199e82c7-bf86-4397-8719-cc50ce4f8412/favicon.ico?format=100w" />

                <img class="kit-logo" *ngIf="k.kit_name.includes('DroneAid')"
                    src="/assets/logo/droneaid-logo.png" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('soldering')"
                    src="https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQz6_t_sLDcVpGg7IOlI3JlFHQp5fwMdOxl7sisC_AF1YjSyQpUAqDMrZP97anKCVmBIpPtRyiNxu7E8afUIKEuBCfcfVXltXdD7eDvGDGb2iMAMRWhNZxXcALUi_l-a8UUhmxApw&usqp=CAc" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('tools')"
                    src="https://ae-pic-a1.aliexpress-media.com/kf/S9f0c560b53574cd98912a31578f45772M/4Pcs-1-5-2-2-5-3-0mm-Black-Hex-Drivers-Allen-Wrench-Repair-Tool-Set.jpeg_.webp" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('pilot')"
                    src="https://ae-pic-a1.aliexpress-media.com/kf/S5cdd0122fd5b4032b5bdce755ad084ccd/HappyModel-Mobula8-1-2S-85mm-Micro-FPV-Whoop-Drone-X12-AIO-FC-400mW-OPENVTX-ELRS-FRSLY.jpg_.webp" />

                <img class="kit-logo" *ngIf="k.kit_slug.includes('batteries')"
                    src="https://modelistam.com.ua/images/female-amass-xt-60h.jpg" />

            </mat-card-content>
        </mat-card>

        <ng-container *ngIf="!kits_public.length">
            <div class="skeleton-block skeleton-kit" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </ng-container>

    </div>
</div>

<h2>
    <span>Найпопулярніше за&nbsp;</span>
    <span class="h2-dropdown" [mat-menu-trigger-for]="menuTrendingPeriod">
        <span>{{ { daily: 'день',weekly: 'тиждень',monthly: 'місяць' }[control_trending_period.value!] }}</span>
        <mat-icon>expand_more</mat-icon>
    </span>
    <mat-menu #menuTrendingPeriod="matMenu">
        <button mat-menu-item (click)="control_trending_period.setValue('daily')">день</button>
        <button mat-menu-item (click)="control_trending_period.setValue('weekly')">тиждень</button>
        <button mat-menu-item (click)="control_trending_period.setValue('monthly')">місяць</button>
    </mat-menu>
    <div class="spacer"></div>
    <a mat-button color="primary" routerLink="/products" [queryParams]="{tag:''}">
        <span>Всі продукти</span>
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </a>
</h2>

<div class="products-carousel-wrapper">
    <div class="products-carousel">
        <product-card *ngFor="let p of products_trending" [product]="p"></product-card>
        <ng-container *ngIf="!products_trending.length">
            <div class="skeleton-block skeleton-card" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </ng-container>
    </div>
</div>

<h2>
    <span>Найкращі пропозиції</span>
    <div class="spacer"></div>
    <a mat-button color="primary" routerLink="/products" [queryParams]="{price:'top'}">
        <span>Всі ТОР-ціни</span>
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </a>
</h2>

<div class="products-carousel-wrapper">
    <div class="products-carousel">
        <product-card *ngFor="let p of products_top" [product]="p"></product-card>
        <ng-container *ngIf="!products_top.length">
            <div class="skeleton-block skeleton-card" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </ng-container>
    </div>
</div>

<h2>
    <span>Товари в Європі</span>
    <div class="spacer"></div>
    <a mat-button color="primary" routerLink="/products" [queryParams]="{source:'custom'}">
        <span>Всі локальні товари</span>
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </a>
</h2>

<div class="products-carousel-wrapper">
    <div class="products-carousel">
        <product-card *ngFor="let p of products_local_eu" [product]="p"></product-card>
        <ng-container *ngIf="!products_local_eu.length">
            <div class="skeleton-block skeleton-card" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </ng-container>
    </div>
</div>

<h2>
    <span>Товари в Україні</span>
    <div class="spacer"></div>
    <a mat-button color="primary" routerLink="/products" [queryParams]="{source:'custom'}">
        <span>Всі локальні товари</span>
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </a>
</h2>


<div class="products-carousel-wrapper">
    <div class="products-carousel">
        <product-card *ngFor="let p of products_local" [product]="p"></product-card>
        <ng-container *ngIf="!products_local.length">
            <div class="skeleton-block skeleton-card" *ngFor="let _ of [].constructor(10).fill(0)"></div>
        </ng-container>
    </div>
</div>
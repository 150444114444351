import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataService, Payout, UserMoney } from '../../data.service';
import { MatTableDataSource } from '@angular/material/table';
import { PayoutComponent } from '../../payout/payout.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-users-table',
    templateUrl: './users-table.component.html',
    styleUrl: './users-table.component.scss'
})
export class UsersTableComponent implements OnChanges, AfterViewInit {

    @Input() users: UserMoney[] = [];
    @Input() payouts: Payout[] = [];

    public displayedColumns = ['user_name', 'balance', 'user_card_number', 'user_iban_number'];
    public dataSource: MatTableDataSource<UserMoney> = new MatTableDataSource();

    @Output() public edit = new EventEmitter<UserMoney>();

    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    @Output() public paid = new EventEmitter<Payout>();
    @Output() public add = new EventEmitter();

    constructor(
        private readonly dialog: MatDialog,
        private readonly data: DataService,
    ) {
        if (data.admin) {
            this.displayedColumns = ['user_name', 'balance', 'order_amount_uah', 'order_donate_amount_uah', 'payout_amount_uah', 'user_card_number', 'user_iban_number', 'actions'];
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['users']) {
            this.dataSource.data = this.users;
        }
    }

    onEdit(user: UserMoney) {
        this.edit.emit(user);
    }

    onPayout(user: UserMoney) {
        PayoutComponent.open(this.dialog, user).afterClosed().subscribe(payout => {
            if (payout) {
                this.payouts.unshift(payout);
                this.paid.emit(payout);
            }
        });
    }

    public getTotal(field: keyof UserMoney) {
        return this.users.reduce((a, c) => a + (c[field] as number), 0);
    }

}

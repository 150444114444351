import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, OrderEvent, OrderFull, OrderUpdateDto, Warehouse } from '../data.service';
import { dateToLocalDate, dateToLocalTime, stringsToDate, timestampToLocalDate, timestampToLocalTime } from '../utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderUpdateStatusDialogComponent } from '../orders/order-update-status-dialog/order-update-status-dialog.component';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { map } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackDialogComponent } from '../track/track-dialog/track-dialog.component';

export interface OrderData {
    order_id: number;
}

const arrayLengthValidator = (c: AbstractControl) => Array.isArray(c.value) && c.value.length ? null : { length: true };

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrl: './order.component.scss',
})
export class OrderComponent {

    public loading = false;

    public order: OrderFull | null = null;
    public readonly timestampToLocalDate = timestampToLocalDate;

    public events: OrderEvent[] = [];

    public old_order_amount_uah = '';
    public old_order_amount_usd = '';

    public static open(dialog: MatDialog, data: OrderData) {
        return dialog.open(OrderComponent, { data, width: '680px', autoFocus: false, panelClass: 'mobile-fullscreen' })
    }

    public get order_quantity() {
        const order_quantity = Number(this.form_edit.controls.order_quantity.value);
        return isNaN(order_quantity) ? 0 : order_quantity;
    }

    public event_types_dict: { [k: string]: string } = {
        created: 'Замовлено',
        ordered: 'Замовлено',
        shipped: 'Відправлено',
        delivered: 'Доставлено',
        picked: 'Отримано',
        partial: 'Частково отримано',
        received: 'Передано',
        cancelled: 'Відмінено',
        problem: 'Проблема',
        'became-problematic': 'Зазначено проблемним',
        'became-normal': 'Зазначено нормальним',
    };

    public recepients: string[] = [];
    public filtered_recipients: string[] = [];


    private getOrder(force = false) {

        const $orders = this.data.admin
            ? this.data.getAllOrders(force)
            : this.data.getMyOrders(force);

        return $orders.pipe(map(orders => {

            this.recepients = orders
                .map(o => o.order_recipient?.trim()).filter((v, i, s) => v && s.indexOf(v) === i);

            this.updateRecipients();

            return this.order = orders.find(o => o.order_id === this.dialogData.order_id) || null;

        }));
    }

    private updateRecipients() {
        const value = this.form_edit.controls.order_recipient.value?.trim()?.toLowerCase() || '';
        if (!value) this.filtered_recipients = this.recepients;
        else this.filtered_recipients = this.recepients.filter(r => r.toLowerCase().startsWith(value));
    }

    private init(order: OrderFull) {
        this.order = order;
        this.form_edit.patchValue({
            order_quantity: order.order_quantity.toFixed(0),
            order_amount_usd: order.order_amount_usd.toFixed(2),
            order_amount_uah: order.order_amount_uah.toFixed(2),
            order_delivery_usd: order.order_delivery_usd.toFixed(2),
            order_delivery_uah: order.order_delivery_uah.toFixed(2),
            order_date: timestampToLocalDate(order.order_date),
            order_time: timestampToLocalTime(order.order_date),
            order_number: order.order_number,
            order_comment: order.order_comment,
            order_donate: !!order.order_donate_amount_uah,
            order_donate_amount_uah: order.order_donate_amount_uah.toFixed(2),
            order_recipient: order.order_recipient || '',
        });
        this.form.controls.order_tracking_number.setValue((this.order?.order_tracking_number_array || []).map(t => (t.tracking_picked ? '+' : '') + t.tracking_number));
        if (this.order) {
            this.data.getEvents(this.order.order_id).subscribe({ next: events => this.events = events });
        }
    }

    constructor(
        public readonly data: DataService,
        public readonly route: ActivatedRoute,
        public readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        public dialogRef: MatDialogRef<OrderComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: OrderData,
    ) {

        this.form_edit.controls.order_recipient.valueChanges.subscribe(() => this.updateRecipients());

        this.getOrder().subscribe(order => {
            if (order) {
                this.init(order);
            } else {
                this.getOrder(true).subscribe(order => {
                    if (order) {
                        this.init(order);
                    }
                });
            }
        });

        const updateDonate = () => {
            setTimeout(() => {
                const value = this.form_edit.value;
                const order_donate = value.order_donate;

                if (order_donate) {

                    const order_amount_uah = Number(value.order_amount_uah);
                    const order_delivery_uah = Number(value.order_delivery_uah);
                    const order_donate_amount_uah = order_amount_uah + order_delivery_uah;

                    if (isNaN(order_donate_amount_uah)) {
                        this.form_edit.controls['order_donate_amount_uah'].setValue('0.00');
                    } else {
                        this.form_edit.controls['order_donate_amount_uah'].setValue(order_donate_amount_uah.toFixed(2));
                    }

                } else {
                    this.form_edit.controls['order_donate_amount_uah'].setValue('0.00');
                }
            });
        };

        this.form_edit.controls['order_amount_uah'].valueChanges.subscribe(updateDonate);
        this.form_edit.controls['order_delivery_uah'].valueChanges.subscribe(updateDonate);
        this.form_edit.controls['order_quantity'].valueChanges.subscribe(updateDonate);
        this.form_edit.controls['order_donate'].valueChanges.subscribe(updateDonate);

    }

    public form = new FormGroup({
        order_tracking_number: new FormControl([] as string[], arrayLengthValidator),
    });

    public now_date = dateToLocalDate(new Date());
    public now_time = dateToLocalTime(new Date());

    public form_edit = new FormGroup({
        order_quantity: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
        order_amount_usd: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_amount_uah: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_delivery_usd: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_delivery_uah: new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_date: new FormControl(this.now_date, [Validators.required, Validators.pattern(/^\d\d\.\d\d\.\d\d\d\d$/)]),
        order_time: new FormControl(this.now_time, [Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)]),
        order_number: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
        order_comment: new FormControl('', []),
        order_donate: new FormControl(false, []),
        order_donate_amount_uah: new FormControl('0.00', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]),
        order_recipient: new FormControl('', []),
    });

    public update() {

        const order = this.order;

        if (!this.form_edit.valid || !order || this.loading) {
            this.form_edit.markAllAsTouched();
            return;
        }

        this.loading = true;

        const value = this.form_edit.value;

        const dto: OrderUpdateDto = {
            order_id: order.order_id,
            order_date: stringsToDate(value.order_date!, value.order_time!).getTime() / 1000,
            order_quantity: Number(value.order_quantity),
            order_amount_usd: Number(value.order_amount_usd),
            order_amount_uah: Number(value.order_amount_uah),
            order_delivery_usd: Number(value.order_delivery_usd),
            order_delivery_uah: Number(value.order_delivery_uah),
            order_number: value.order_number!,
            order_recipient: value.order_recipient || '',
            order_comment: value.order_comment || '',
            order_donate_amount_uah: value.order_donate ? Number(value.order_donate_amount_uah) : 0,
        };

        this.data.updateOrder(dto).subscribe({
            next: order => {
                this.loading = false;
                this.snackbar.open('Замовлення оновлено.', 'OK');
            },
            error: error => {
                this.loading = false;
                console.log(error);
                this.snackbar.open('Сталася помилка!', 'OK');
            },
        })
    }

    public setQuantity(q: number, event: Event) {
        event.stopPropagation();
        if (!this.order) return;
        this.form_edit.controls.order_quantity.setValue(String(q));
        if (this.order.sku_full_sd_price) {
            this.form_edit.controls.order_amount_usd.setValue((q * (this.order.sku_full_sd_unit_price || 0)).toFixed(2));
        } else {
            this.form_edit.controls.order_amount_usd.setValue((q * (this.order.sku_full_unit_price || 0)).toFixed(2));
        }
    }

    public save(status: 'shipped' | 'partial' = 'shipped') {

        const order = this.order;
        const order_tracking_number = this.form.value.order_tracking_number || [];

        this.form.updateValueAndValidity();

        if (!this.form.valid || this.loading || !order) {
            this.form.markAsTouched();
            return;
        }

        if (order.order_status !== 'ordered'
            && order.order_status !== 'shipped'
            && order.order_status !== 'partial'
            && order.order_status !== 'delivered') {
            return;
        }

        this.loading = true;

        this.data.updateOrderStatus({
            order_id: order.order_id,
            order_status: order.order_status === 'delivered' ? 'delivered' : status,
            order_tracking_numbers: order_tracking_number,
            event_comment: status === 'partial'
                ? order_tracking_number.filter(n => n.startsWith('+')).map(n => n.replace('+', '')).join('; ')
                : order_tracking_number.join('; '),
        }).subscribe({
            next: updated_order => {
                order.order_status = updated_order.order_status;
                order.order_tracking_number = updated_order.order_tracking_number;
                this.loading = false;
                this.snackbar.open('Трекінговий номер оновлено!', 'OK');
                this.dialogRef.close();
            }, error: error => {
                this.loading = false;
                this.snackbar.open('Сталася помилка!', 'OK');
            }
        });

    }


    public cancel() {
        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'cancelled', orders: [this.order!] });
    }

    public pick() {
        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'picked', orders: [this.order!] });
    }

    public receive() {
        OrderUpdateStatusDialogComponent.open(this.dialog, { status: 'received', orders: [this.order!] });
    }

    public change_status: 'cancelled' | 'picked' | 'received' | null = null;

    public get allowCancel() {
        if (!this.order) return false;
        if (this.data.admin) return this.order.order_status != 'cancelled';
        return ['ordered', 'shipped', 'delivered', 'picked', 'partial'].includes(this.order.order_status);
    }

    public get allowShip() {
        if (!this.order) return false;
        return ['ordered', 'shipped', 'delivered'].includes(this.order.order_status);
    }

    public get allowPick() {
        if (!this.order) return false;
        return ['ordered', 'shipped', 'delivered', 'partial'].includes(this.order.order_status);
    }

    public get allowReceive() {
        if (!this.order) return false;
        return this.data.admin && ['ordered', 'shipped', 'delivered', 'picked', 'partial'].includes(this.order.order_status);
    }

    public get allowPartial() {
        if (!this.order) return false;
        return ['ordered', 'shipped', 'partial'].includes(this.order.order_status);
    }

    public get allowTrack() {
        if (!this.order) return false;
        return ['ordered', 'shipped', 'delivered'].includes(this.order.order_status);
    }

    public async paste() {

        const text = (await navigator.clipboard.readText() || '').trim();
        const array = this.form.controls.order_tracking_number.value;

        if (text && array && !array.includes(text)) {
            array.push(text);
            this.form.controls.order_tracking_number.markAsTouched();
            this.form.controls.order_tracking_number.setValue(this.form.controls.order_tracking_number.value);
        }

    }

    readonly separatorKeysCodes = [ENTER, COMMA, SEMICOLON] as const;

    problem(problem: boolean) {
        const order = this.order;
        if (!order) return;
        this.loading = true;
        this.data.updateOrderProblem(order.order_id, problem, order.order_comment).subscribe({
            next: () => {
                this.loading = false;
                order.order_problematic = problem;
                if (problem) {
                    this.snackbar.open('Замовлення зазначено як проблемне.', 'OK');
                } else {
                    this.snackbar.open('Замовлення зазначено як нормальне.', 'OK');
                }
            }
        });
    }

    add(event: MatChipInputEvent): void {

        const value = (event.value || '').trim();
        event.chipInput?.clear();

        const order_tracking_number = this.form.controls.order_tracking_number.value;

        if (!order_tracking_number) return;

        if (value) {
            order_tracking_number.push(value);
        }

        this.form.controls.order_tracking_number.setValue(this.form.controls.order_tracking_number.value);

    }

    remove(num: string) {

        const order_tracking_number = this.form.controls.order_tracking_number.value;

        if (!order_tracking_number) return;

        const index = order_tracking_number.indexOf(num);

        if (index >= 0) {
            order_tracking_number.splice(index, 1);
        }

        this.form.controls.order_tracking_number.setValue(this.form.controls.order_tracking_number.value);

    }

    edit(num: string, event: MatChipEditedEvent) {

        const order_tracking_number = this.form.controls.order_tracking_number.value;

        if (!order_tracking_number) return;

        const value = event.value.trim();

        if (!value) {
            this.remove(num);
            return;
        }

        const index = order_tracking_number.indexOf(num);

        if (index >= 0) {
            order_tracking_number[index] = value;
        }

        this.form.controls.order_tracking_number.setValue(this.form.controls.order_tracking_number.value);
    }

    checked(i: number, event: MatCheckboxChange) {
        const value = this.form.controls.order_tracking_number.value;
        if (!value) return;
        const raw = value[i].replace('+', '');
        value[i] = event.checked ? '+' + raw : raw;
        this.form.controls.order_tracking_number.setValue(value);
    }

    public trackNumber($event: Event, tracking_number: string) {
        $event.preventDefault();
        TrackDialogComponent.open(this.dialog, { tracking_number, order_id: this.order?.order_id });
    }

}

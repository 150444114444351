<mat-dialog-content class="mat-typography" *ngIf="!order">
    <div class="skeleton-block"></div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="order">

    <div class="product-info-wrapper">

        <div class="product-info">

            <a class="product-name" [routerLink]="[]" [queryParams]="order.product_view_query_params"
                (click)="data.click(data.CS.OrderDialog, data.CT.OpenProductDialog, order)">
                <span class="product-quantity">{{order.order_quantity}}x</span>
                <span class="product-model-name">{{order.model_name}}</span>
                <mat-icon>link</mat-icon>
            </a>

            <div class="product-attrs">

                <small class="product-available">
                    <b>{{timestampToLocalDate(order.order_date)}}</b>
                </small>

                <small class="product-available">
                    <b>{{order.detail_name}}</b>
                </small>

                <seller-chip [data]="order"></seller-chip>

                <div class="product-extra product-seller">
                    <img class="product-seller-logo" [src]="order.user_image_url" />
                    <span class="product-seller-name">{{order.user_name}}</span>
                </div>

                <div class="product-extra" *ngIf="order.order_recipient">
                    <mat-icon>person_pin</mat-icon>
                    <span>{{order.order_recipient}}</span>
                </div>

            </div>

            <div class="product-attrs">
                <a mat-ripple class="product-extra product-seller order-number" target="_blank"
                    [href]="'https://www.aliexpress.com/p/order/detail.html?&orderId='+order.order_number">
                    <img class="product-seller-logo"
                        src="https://ae01.alicdn.com/images/eng/wholesale/icon/aliexpress.ico" />
                    <span class="product-seller-name">Замовлення #{{order.order_number||'без номера'}}</span>
                    <mat-icon *ngIf="order.order_number">open_in_new</mat-icon>
                    <mat-icon matRipple class="copy-icon" *ngIf="order.order_number"
                        (click)="data.copy(order.order_number, 'Номер замовлення скопійовано', $event)">copy</mat-icon>
                </a>
            </div>
            <div class="product-attrs">
                <a mat-ripple class="product-extra product-seller order-number"
                    *ngFor="let track of order.order_tracking_number_array" target="_blank"
                    [href]="'/track/'+track.tracking_number" (click)="trackNumber($event, track.tracking_number)">
                    <img class="product-seller-logo" [src]="track.tracking_image_url" />
                    <span>{{track.tracking_number}}<span *ngIf="track.tracking_number_alt"> →
                            {{track.tracking_number_alt}}</span></span>
                    <span> | {{track.tracking_status_local}} | {{track.tracking_updated_local}}</span>
                </a>
            </div>
            <div class="product-attrs">
                <a mat-ripple class="product-extra product-seller order-number"
                    *ngFor="let track of order.order_tracking_number_array" target="_blank"
                    [href]="'https://posylka.net/uk/parcel/'+track.tracking_number">
                    <img class="product-seller-logo" src="https://posylka.net/favicon/favicon-196x196.png" />
                    <span class="product-seller-name">{{track.tracking_number}}</span>
                    <mat-icon>open_in_new</mat-icon>
                    <mat-icon matRipple class="copy-icon"
                        (click)="data.copy(track.tracking_number, 'Трекінговий номер скопійовано', $event)">copy</mat-icon>
                </a>
            </div>

            <div class="product-price-wrapper">

                <span class="product-price">{{ order.order_amount_usd | currency }}</span>

                <div class="product-price-extras" *ngIf="order.order_quantity!=1">
                    <small class="product-unit-price">
                        <b>{{ order.order_unit_price_usd | currency }}</b>/шт
                    </small>
                </div>

                <!-- <small class="product-available">
                    <b>{{(order.order_amount_uah) | number: '1.2-2'}}&nbsp;грн</b>
                </small>

                <small class="product-available" *ngIf="order.order_quantity!=1">
                    <b>{{(order.order_unit_price_uah) | number: '1.2-2'}}&nbsp;грн</b>/шт
                </small> -->

                <div class="product-available status donate" *ngIf="order.order_donate_amount_uah">
                    Донат <b>{{(order.order_donate_amount_uah) | currency }}</b>
                </div>

                <div class="product-available status" [ngClass]="order.order_status">
                    {{order.order_local_status}}
                </div>

            </div>

        </div>

        <button mat-mini-fab mat-dialog-close class="close-button">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form" *ngIf="order">

        <mat-form-field>

            <mat-label>Трекінговий номер</mat-label>

            <mat-chip-grid #chipGrid>

                @for (num of form.controls.order_tracking_number.value; track num; let index = $index) {
                <mat-chip-row (removed)="remove(num)" [editable]="allowShip" (edited)="edit(num, $event)">
                    <mat-checkbox *ngIf="order.order_status=='shipped'||order.order_status=='partial'"
                        (click)="$event.stopPropagation()" [checked]="num.startsWith('+')"
                        (change)="checked(index, $event)"></mat-checkbox>
                    <!-- <a [href]="'https://www.aliexpress.com/p/tracking/index.html?tradeOrderId='+order.order_number" -->
                    {{num.replace('+','')}}
                    <button matChipRemove *ngIf="allowShip">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                }

                <input placeholder="Напр., MGRAE00012345678YQ" [readonly]="!allowShip" [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)" />

            </mat-chip-grid>

            <button matSuffix mat-icon-button inputmode="numeric" type="button" class="grey"
                (click)="paste();$event.stopPropagation()" *ngIf="allowShip">
                <mat-icon>content_paste</mat-icon>
            </button>

            <mat-hint>Перелічіть всі, якщо замовлення було розділене</mat-hint>

        </mat-form-field>
    </form>

    <mat-expansion-panel hideToggle>

        <mat-expansion-panel-header>
            <mat-panel-title>
                Події
            </mat-panel-title>
            <mat-panel-description>
                <div class="events-count">{{events.length}}</div>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="events-body">
            <div class="events-event" *ngFor="let e of events">
                <div class="event-date">
                    <div class="product-available">
                        {{timestampToLocalDate(e.event_date)}}
                    </div>
                </div>
                <div class="event-type">
                    <div class="product-available status" [ngClass]="e.event_type">
                        {{event_types_dict[e.event_type]}}
                    </div>
                </div>
                <div class="event-comment">{{e.event_comment}}</div>
            </div>
        </div>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="data.admin">

        <mat-expansion-panel-header>
            <mat-panel-title>
                Редагувати
            </mat-panel-title>
        </mat-expansion-panel-header>

        <form (submit)="update()" [formGroup]="form_edit">

            <mat-form-field>

                <mat-label>Кількість в замовленні</mat-label>

                <input matInput inputmode="numeric" [placeholder]="'Напр., ' + order.sku_quantity"
                    formControlName="order_quantity" />

                <div matSuffix style="display: flex;align-items: center;">
                    <button mat-icon-button type="button" (click)="setQuantity(1, $event)">
                        <mat-icon>filter_1</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="setQuantity(2, $event)">
                        <mat-icon>filter_2</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="setQuantity(3, $event)">
                        <mat-icon>filter_3</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="setQuantity(4, $event)">
                        <mat-icon>filter_4</mat-icon>
                    </button>
                </div>

            </mat-form-field>

            <div class="flex">

                <!-- <mat-form-field class="spacer">
                    <mat-label>Сума<span *ngIf="order_quantity"> за {{order_quantity}}</span>, грн</mat-label>
                    <input matInput inputmode="decimal"
                        [placeholder]="'Напр., ' + ((order_quantity||1)*(order.sku_last_price||0)*data.usd_uah_rate).toFixed(2)"
                        formControlName="order_amount_uah" oninput="this.value=this.value.replace(',','.')" />
                </mat-form-field> -->

                <mat-form-field class="spacer">
                    <mat-label>Сума<span *ngIf="order_quantity"> за {{order_quantity}}</span>, €</mat-label>
                    <input matInput inputmode="decimal"
                        [placeholder]="'Напр., ' + ((order_quantity||1)*(order.sku_last_price||0)).toFixed(2)"
                        formControlName="order_amount_usd" oninput="this.value=this.value.replace(',','.')" />
                </mat-form-field>

            </div>

            <div class="checkbox-wrapper">
                <mat-checkbox class="donate-checkbox" formControlName="order_donate">
                    <div class="donate-checkbox-wrapper">
                        <span>Записати як донат</span>
                        <span style="font-size:20px;">🥰</span>
                    </div>
                </mat-checkbox>
            </div>

            <mat-form-field *ngIf="form_edit.controls.order_donate.value">
                <mat-label>Сума донату, грн</mat-label>
                <input matInput [placeholder]="'Від 0.00 грн до суми замовлення в грн'"
                    formControlName="order_donate_amount_uah" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Номер замовлення з AliExpress</mat-label>
                <input matInput inputmode="numeric" [placeholder]="'Напр., 3032101200073774'"
                    formControlName="order_number" />
            </mat-form-field>

            <div class="flex">

                <mat-form-field class="spacer">
                    <mat-label>Дата замовлення</mat-label>
                    <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_date"
                        formControlName="order_date" />
                </mat-form-field>

                <mat-form-field class="spacer">
                    <mat-label>Час замовлення</mat-label>
                    <input matInput inputmode="decimal" [placeholder]="'Напр., ' + now_time"
                        formControlName="order_time" />
                </mat-form-field>

            </div>

            <mat-form-field>
                <mat-label>Отримувач</mat-label>
                <input matInput [placeholder]="'...'" formControlName="order_recipient" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let rec of filtered_recipients" [value]="rec">{{rec}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Коментар</mat-label>
                <input matInput [placeholder]="'...'" formControlName="order_comment" />
            </mat-form-field>

            <button mat-flat-button color="accent" type="submit" [buttonLoading]="loading">
                Оновити дані
            </button>

        </form>


    </mat-expansion-panel>



</mat-dialog-content>

<mat-dialog-actions
    *ngIf="order && (allowPick || allowReceive || allowCancel || allowShip || data.admin || allowPartial)">

    <button mat-button type="button" class="more-actions" [mat-menu-trigger-for]="menuActions">
        <span>Більше</span>
        <mat-icon>expand_more</mat-icon>
    </button>

    <div style="flex: 1;"></div>

    <button mat-flat-button color="accent" (click)="save()" *ngIf="order.order_status=='ordered'||data.admin"
        [buttonLoading]="loading">
        Оновити трек-номер
    </button>

    <button mat-flat-button color="accent" (click)="pick()"
        *ngIf="order.order_status=='shipped'||order.order_status=='delivered'||order.order_status=='partial'"
        [buttonLoading]="loading">
        Зазначити отримання
    </button>

    <button mat-flat-button color="accent" (click)="receive()" *ngIf="order.order_status=='picked'&&data.admin"
        [buttonLoading]="loading">
        Зазначити передачу
    </button>

    <mat-menu #menuActions>

        <button mat-menu-item type="button" *ngIf="allowTrack" (click)="save()">
            <span>Оновити трек-номер</span>
            <mat-icon>tag</mat-icon>
        </button>

        <button mat-menu-item type="button" *ngIf="allowPick" (click)="pick()">
            <span>Зазначити отримання</span>
            <mat-icon>inventory</mat-icon>
        </button>

        <button mat-menu-item type="button" *ngIf="allowReceive" (click)="receive()">
            <span>Зазначити передачу</span>
            <mat-icon>local_shipping</mat-icon>
        </button>

        <button mat-menu-item type="button" *ngIf="allowPartial" (click)="save('partial')">
            <span>Зазначити часткове отримання</span>
            <mat-icon>group_work</mat-icon>
        </button>

        <button mat-menu-item type="button" *ngIf="data.admin && order.order_problematic" (click)="problem(false)">
            <span>Зазначити як нормальне</span>
            <mat-icon>check</mat-icon>
        </button>

        <button mat-menu-item class="menu-item-warn" type="button" *ngIf="data.admin && !order.order_problematic"
            (click)="problem(true)">
            <span>Зазначити як проблемне</span>
            <mat-icon>report_problem</mat-icon>
        </button>

        <button mat-menu-item class="menu-item-warn" type="button" (click)="cancel()" *ngIf="allowCancel">
            <mat-icon>cancel</mat-icon>
            <span>Відмінити замовлення</span>
        </button>

    </mat-menu>

</mat-dialog-actions>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './about.component';
import { PromosModule } from '../promos/promos.module';
import { RouterModule } from '@angular/router';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        SharedModule,
        PromosModule,
        TableVirtualScrollModule,
    ],
    declarations: [
        AboutComponent,
    ],
    exports: [
        AboutComponent,
    ]
})
export class AboutModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderUpdateStatusDialogComponent } from './order-update-status-dialog/order-update-status-dialog.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderCardComponent } from './order-card/order-card.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
    declarations: [
        OrderUpdateStatusDialogComponent,
        OrderCardComponent,
    ],
    exports: [
        OrderCardComponent,
        OrderUpdateStatusDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
    ]
})
export class OrdersModule { }

<cdk-virtual-scroll-viewport tvsItemSize>
    <div class="table-wrapper">
        <ng-content></ng-content>
        <table mat-table matSort [dataSource]="dataSource">

            <ng-container matColumnDef="model_name">
                <th mat-header-cell *matHeaderCellDef>Продукт</th>
                <td mat-cell *matCellDef="let product">
                    <div class="table-product-name-wrapper">

                        <img class="product-image" [attr.src]="product.model_image_url">

                        <div class="product-name">
                            <span class="product-quantity"
                                *ngIf="product.sku_quantity!=1">{{product.sku_quantity}}{{product.sku_units}}</span>
                            <span>{{product.model_name}}</span>
                        </div>

                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="seller_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Магазин</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-stats">
                        <seller-chip [data]="product"
                        (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenSellerDialog, product)"></seller-chip>
                    </div>
                    <div class="product-stats">
                        <app-product-stars [product]="product"></app-product-stars>
                        <small *ngIf="product.item_reviews">💬 {{product.item_reviews}}</small>
                        <small *ngIf="product.item_sold">|</small>
                        <small *ngIf="product.item_sold">🛍️ {{product.item_sold}}</small>
                    </div>
                    <div class="product-stats">
                        <small class="product-extra red" *ngIf="product.item_shipping_price">
                            🚚 {{product.item_shipping_price | currency}}
                        </small>
                        <small class="product-extra green" *ngIf="product.sku_extra_tips">
                            {{product.sku_extra_tips}}
                        </small>
                        <small class="product-extra green" *ngIf="product.item_coins_discount">
                            🪙 -{{product.item_coins_discount}}%
                        </small>
                    </div>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="item_reviews">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Відгуки</th>
                <td mat-cell *matCellDef="let product">

                    <app-product-stars [product]="product"
                        *ngIf="product.product_source==='aliexpress'"></app-product-stars>

                    <div class="product-stats" *ngIf="product.product_source==='aliexpress'">
                        <small>{{product.item_reviews}} відгуків</small>
                        <small>|</small>
                        <small>{{product.item_sold}} продано</small>
                    </div>

                </td>
            </ng-container> -->

            <ng-container matColumnDef="tags_array">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Теги</th>
                <td mat-cell *matCellDef="let product">
                    <div>
                        <div class="tags">
                            <small class="tag" *ngIf="product.detail_name">
                                {{product.detail_name}}
                                <!-- <a [href]="this.dataSource.getModelUrl(product)">{{product.detail_name}}</a> -->
                            </small>
                            <small *ngFor="let tag of product.model_tags.split(';')" >
                                <div class="tag"  *ngIf="!tag.endsWith('KIT')">
                                    <span>
                                        {{ tag }}
                                    </span>
                                </div>
                            </small>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="sku_last_available">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Залишок</th>
                <td mat-cell *matCellDef="let product">
                    <span [class.text-danger]="product.sku_last_available < 50">
                        {{ product.product_source === 'aliexpress' ? product.sku_last_available : '∞' }} шт
                        <mat-icon>shopping_cart</mat-icon>
                        <br>
                        <small class="product-unit-price product-extra" [class.red]="product.item_max_limit < 10"   *ngIf="product.item_max_limit>0">
                            {{ product.item_max_limit }} Ліміт
                        </small>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="product_orders_completed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Замовлень</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper">
                        <span *ngIf="product.product_orders_completed">
                            {{product.product_orders_completed}}
                            📦
                        </span>
                        <small class="product-unit-price product-extra red" *ngIf="product.product_orders_failed">
                            <mat-icon>warning</mat-icon>
                            {{ product.product_orders_failed }} Проблем
                        </small>
                    </div>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="product_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Статус</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper">
                        <small class="tag" *ngIf="product.product_status">
                            {{product.product_status}}
                        </small>
                    </div>
                </td>
            </ng-container> -->

            <ng-container matColumnDef="sku_top_price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Краща ціна</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper">
                        <div class="product-price-wrapper" *ngIf="product.model_top_price_usd!=0">
                            <span class="price">
                                {{ product.model_top_price_usd | currency }}
                                <mat-icon>star</mat-icon>
                            </span>
                        </div>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="product_orders_avg_price_usd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Середня Ціна</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper" *ngIf="product.product_orders_avg_price_usd">
                        <span class="price">
                            {{product.product_orders_avg_price_usd | currency}}
                            <mat-icon>equalizer</mat-icon>
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="sku_future_sale_price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Запланована ціна</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper" *ngIf="product.sku_future_sale_price>0">
                        <small class="price product-future-sale-price" matTooltip="з {{product.sku_future_sale_datetime}}">
                            {{ product.sku_future_sale_price | currency }}
                            <mat-icon>alarm</mat-icon>
                        </small>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="sku_full_unit_price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Ціна за одиницю</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper">
                        <small class="product-unit-price" *ngIf="product.sku_quantity!=1">
                            {{ product.sku_full_unit_price | currency: product.product_currency }}/{{product.sku_units}}
                        </small>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="sku_full_price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Ціна</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-price-wrapper">
                        <span>
                            <span class="product-price" [class.green]="product.sku_full_price < product.product_orders_avg_price_usd" [class.yellow]="product.sku_full_price > product.sku_future_sale_price && product.sku_future_sale_price > 0">
                                <mat-icon *ngIf="product.sku_full_price < product.sku_future_sale_price || product.sku_full_price < product.product_orders_avg_price_usd || product.sku_full_price < product.model_top_price_usd" matTooltip="Цікава ціна">thumb_up</mat-icon>
                                <mat-icon *ngIf="product.sku_full_price > product.sku_future_sale_price && product.sku_future_sale_price > 0" matTooltip="Буде дешевше {{ product.sku_future_sale_price  | currency }} з {{product.sku_future_sale_datetime}}">alarm</mat-icon>
                                <mat-icon *ngIf="product.sku_full_price < product.product_orders_avg_price_usd" matTooltip="Дешевще за середню ціну {{ product.product_orders_avg_price_usd }}">equalizer</mat-icon>
                                <mat-icon *ngIf="product.sku_full_price < product.model_top_price_usd" matTooltip="Дешевще за кращу ціну {{ product.model_top_price_usd | currency }}">star</mat-icon>
                                {{ product.sku_full_price | currency: product.product_currency }}
                            </span>
                        </span>
                        <!-- <small class="product-unit-price" *ngIf="product.sku_quantity!=1">
                            {{ product.sku_full_unit_price | currency: product.product_currency }}/{{product.sku_units}}
                        </small> -->
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="urls">
                <th mat-header-cell *matHeaderCellDef>Посилання</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-table-urls">

                        <a class="product-table-link" [href]="product.sku_url" target="_blank"
                            *ngIf="product.product_source==='aliexpress'"
                            (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenAliexpress, product)">
                            AliExpress
                        </a>

                        <a class="product-table-link" [href]="product.sku_url" target="_blank"
                            *ngIf="product.product_source==='custom'"
                            (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenAliexpress, product)">
                            Відкрити магазин
                        </a>

                        <a mat-ripple class="product-table-link sd" [href]="product.sku_sd_url" target="_blank"
                            (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenSuperDeals, product)"
                            *ngIf="product.sku_full_sd_price && (!product.sku_full_lo_price || product.sku_full_sd_price <= product.sku_full_lo_price)">
                            <i>SuperDeals</i>
                            <span> за </span>
                            <strong>{{product.sku_full_sd_price | currency: product.product_currency}}</strong>
                        </a>

                        <a mat-ripple class="product-table-link sd" [href]="product.sku_lo_url" target="_blank"
                            (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenLimidetDeal, product)"
                            *ngIf="product.sku_full_lo_price && (!product.sku_full_sd_price || product.sku_full_lo_price < product.sku_full_sd_price)">
                            <i>LimitedDeal</i>
                            <span> за </span>
                            <strong>{{product.sku_full_lo_price | currency: product.product_currency}}</strong>
                        </a>

                        <a mat-ripple class="product-table-link sd" [href]="product.sku_bs_url" target="_blank"
                            (click)="$event.stopPropagation();data.click(data.current_click_source, data.CT.OpenBigSave, product)"
                            *ngIf="product.sku_full_bs_price && (!product.sku_full_sd_price || product.sku_full_bs_price < product.sku_full_sd_price)">
                            <i>BigSave</i>
                            <span> за </span>
                            <strong>{{product.sku_full_bs_price | currency: product.product_currency}}</strong>
                        </a>

                    </div>
                </td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length" *ngIf="skeleton">
                    <div class="skeleton-block" *ngFor="let _ of [].constructor(20).fill(0)"></div>
                </td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let product; columns: displayedColumns;" routerLink="."
                [queryParams]="product.view_query_params"></tr>
        </table>
    </div>
</cdk-virtual-scroll-viewport>

import { HttpClient } from "@angular/common/http";
import { ApplicationRef, EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, ReplaySubject, Subject, combineLatest, map, tap, zip } from "rxjs";
import { environment } from './../environments/environment';
import { AlertDialogComponent } from "./shared/dialogs/alert/alert.component";
import { MatDialog } from "@angular/material/dialog";
import { getNumberLabel, timestampToLocalDate, timestampToLocalShortTime, timestampToLocalTime, uuidToBase62 } from "./utils";
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DRONE_DETAILS_ORDER, OAUTH_ERROR, OAUTH_JWT, OAUTH_PROFILE, PREFIXES_MEESTEXPRESS, PREFIXES_NOVAPOSHTA, PREFIXES_UKRPOSHTA, PREFIXES_ALI, PREFIXES_CHINA, UK_EN_TRANSLITERATION } from "./consts";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SigninDisabledDialogComponent } from "./dialogs/signin-disabled-dialog/signin-disabled-dialog.component";


export interface PublicStats {
    users_count: number;
    products_count: number;
    drones_count: number;
    trackings_count: number;
    orders_amount: number;
}

export enum ClickType {
    Unknown = 0,
    OpenAliexpress = 1,
    OpenSuperDeals = 2,
    OpenLimidetDeal = 3,
    OpenCoinsDeal = 4,
    OpenProductDialog = 5,
    OpenModelDialog = 6,
    OpenSellerDialog = 7,
    OpenHomePage = 8,
    OpenProductsPage = 9,
    OpenModelsPage = 11,
    OpenRequestsListPage = 12,
    OpenRequestPage = 13,
    OpenMarketplace = 14,
    OpenTracking = 15,
    OpenStock = 16,
    OpenPayouts = 17,
    OpenDrones = 18,
    OpenSellers = 19,
    OpenKitsPage = 20,
    OpenInfoPage = 21,
    OpenCustomProduct = 22,
}

export enum ClickSource {
    Unknown = 0,
    KitsPage = 1,
    ModelsPage = 2,
    ProductsPage = 3,
    ModelDialogInfo = 4,
    ProductDialogInfo = 5,
    ModelDialogList = 6,
    ProductDialogList = 7,
    SellerDialog = 8,
    RequestPage = 9,
    RequestsListPage = 10,
    MarketplacePage = 11,
    HomePage = 13,
    TrackingPage = 14,
    OrdersPage = 15,
    OrderDialog = 16,
    ReportDialog = 17,
    StockPage = 18,
    PayoutsPage = 19,
    DronesPage = 20,
    SellersPage = 21,
    InfoPage = 22,
}

export interface ClickDto {
    click_type: ClickType;
    click_source: ClickSource;
    model_id?: number | null;
    seller_id?: number | null;
    product_id?: number | null;
    kit_id?: number | null;
}

export interface ClickIds {
    model_id?: number | null;
    seller_id?: number | null;
    product_id?: number | null;
    kit_id?: number | null;
}

export interface ProductUpdateDto {
    product_id: number;
    model_id: number;
    product_status: 'unknown' | 'verified' | 'caution' | 'suggestion' | 'invalid';
    sku_quantity: number;
    sku_id: string;
    product_deleted: number;
    product_update_interval: number;
}

export type RequestRule = 'all' | 'only' | 'except';

export interface OrderRequest {

    detail_id: number;
    detail_name: string;
    detail_image_key: string;

    model_id: number;
    model_name: string;
    model_image_url: string;

    request_id: number;
    request_max_price: number;
    request_quantity: number;

    request_rule: RequestRule;
    request_products: string;

    request_products_ids: number[];

}

export enum DetailUnits {
    Pieces = 'pcs',
    Sets = 'set',
    Grams = 'grm',
    Meters = 'mtr',
    Liters = 'ltr',
}

export interface Detail {
    detail_id: number;
    detail_name: string;
    detail_name_local: string;
    detail_image_key: string;
    detail_units: DetailUnits;
    detail_bulk: number;
}

export interface Model {
    model_id: number;
    detail_id: number;
    model_name: string;
    model_image_url: string;
    model_tags: string;
    model_top_price_usd: number;
    model_comments: number;
    model_clicks_daily: number;
    model_clicks_weekly: number;
    model_clicks_monthly: number;
}

export interface ModelStats {
    model_count_total: number;
    model_count_available: number;
    model_price_min: number;
    model_price_max: number;
    model_price_average: number;
    model_future_price_min: number;
    model_future_price_max: number;
    model_future_price_average: number;
}

export type ModelFull = Model & Detail & ModelStats & {
    tags_array: string[];
    tags_dict: { [tag: string]: boolean };
    view_query_params: { [p: string]: any };
    sku_units: string;
};

export interface Product {

    product_url_processed: boolean;

    product_id: number;
    model_id: number;
    seller_id: number;
    user_id: number;

    product_url: string;
    product_sd_url: string;
    product_lo_url: string;
    product_cd_url: string;
    product_bs_url: string;
    product_last_updated: number;
    product_last_error: string;
    product_status: 'unknown' | 'verified' | 'caution' | 'suggestion' | 'invalid';
    product_orders_failed: number;
    product_orders_completed: number;
    product_orders_avg_price_usd: number;
    product_deleted: number;
    product_update_interval: number;
    product_source: 'aliexpress' | 'custom';
    product_comments: number;
    product_clicks_daily: number;
    product_clicks_weekly: number;
    product_clicks_monthly: number;

    item_id: string;
    item_name: string;
    item_sold: string;
    item_rating: number;
    item_reviews: number;
    item_image_url: string;
    item_delivery: string;
    item_shipping_price: number;
    item_coins_discount: number;
    item_coins_superdiscount: number;
    item_is_choice: number;
    item_big_save: number;
    item_sd_id: string;
    item_max_limit: number;

    sku_id: string;
    sku_name: string;
    sku_attr: string;
    sku_prop_ids: string;
    sku_quantity: number;
    sku_extra_tips: string;
    sku_image_url: string;
    sku_last_price: number;
    sku_last_sd_price: number;
    sku_last_lo_price: number;
    sku_last_bs_price: number;
    sku_last_available: number;
    sku_future_sale_date: number;
    sku_future_sale_price: number;
}

export interface Seller {
    seller_id: number;
    seller_name: string;
    seller_number: number;
    seller_flag_url: string;
    seller_image_url: string;
    seller_open_time: number;
    seller_positive_rate: number;
    seller_followers: number;
    seller_sold: string;
    seller_buyers: string;
    seller_communication: number;
    seller_rating: number;
    seller_match: number;
    seller_name_short: string;
    seller_family: string;
    seller_swarm_id: number;
    seller_sdua_name: string;
    seller_company: string;
    seller_vat: string;
    seller_license: string;
    seller_address: string;
    seller_representative: string;
    seller_scope: string;
    seller_established: string;
    seller_authority: string;
    seller_url: string;
    seller_source: 'aliexpress' | 'custom';
    seller_currency: string;
    seller_comments: number;
}

export type SellerFull = Seller & {
    seller_view_query_params: { [p: string]: any };
};

export type ProductFull = Product & SellerFull & Model & Detail & UserMin & {
    sku_running_out: boolean;
    sku_full_price: number;
    sku_min_price: number;
    sku_future_unit_price: number;
    sku_min_unit_price: number;
    sku_min_unit_price_usd: number;
    sku_full_unit_price: number;
    sku_full_unit_price_usd: number;
    sku_full_sd_price: number;
    sku_full_bs_price: number;
    sku_full_lo_price: number;
    sku_full_sd_unit_price: number;
    sku_full_lo_unit_price: number;
    sku_full_bs_unit_price: number;
    sku_units: string;
    tags_array: string[];
    sku_url: string;
    sku_sd_url: string;
    sku_lo_url: string;
    sku_cd_url: string;
    sku_bs_url: string;
    tags_dict: { [tag: string]: boolean };
    view_query_params: { [p: string]: any };
    seller_name_short: string;
    sku_future_sale_datetime: string;
    local_date_updated: string;
    product_currency: string;
    product_comments_label: string;
    sku_bulk_unit_price: number;
    sku_bulk_quantity: number;
    model_name_search: string;
    detail_name_search: string;
    detail_name_local_search: string;
};

export interface ProductAffiliate {
    tenant_id: number;
    product_id: number;
    product_url: string;
    product_sd_url: string;
    product_lo_url: string;
    product_cd_url: string;
    product_bs_url: string;
}

export interface ProductAffiliateResponse {
    [k: number]: ProductAffiliate
}

export interface UpsertProductAffiliateDto {
    product_id: number;
    product_url: string;
    product_sd_url: string;
    product_lo_url: string;
    product_cd_url: string;
}

export interface ProductUpdate {
    update_id: number;
    product_id: number;
    sku_price: number;
    sku_sd_price: number;
    sku_lo_price: number;
    sku_available: number;
    update_date: number;
    update_error: string;
}


export interface ModelUpdate {
    update_date: string;
    price_avg: number;
    price_min: number;
    price_max: number;
    price_sd_avg: number;
    price_sd_min: number;
    price_sd_max: number;
}

export interface OrderEvent {
    event_id: number;
    order_id: number;
    user_id: number;
    event_date: number;
    event_type: string;
    event_data: string;
    event_comment: string;
}




export interface FilterOption {
    id: number | string;
    name: string;
    image: string;
    extra?: string;
    tooltip?: string;
}

export interface FilterDict {
    [k: number | string]: boolean;
}

export interface Filter {
    key: string;
    name: string;
    dict: FilterDict;
    update: Function;
    expanded: boolean;
    options: FilterOption[];
    avatar?: boolean;
    radio?: boolean;
}

export interface OrderCreateDto {
    product_id: number | null;
    request_id: number;
    order_date: number;
    order_quantity: number;
    order_amount_usd: number;
    order_amount_uah: number;
    order_delivery_usd: number;
    order_delivery_uah: number;
    order_number: string;
    order_recipient: string;
    order_comment: string;
    order_donate_amount_uah: number;
}


export interface OrderUpdateDto {
    order_id: number;
    order_date: number;
    order_quantity: number;
    order_amount_usd: number;
    order_amount_uah: number;
    order_delivery_usd: number;
    order_delivery_uah: number;
    order_number: string;
    order_recipient: string;
    order_comment: string;
    order_donate_amount_uah: number;
}


export interface Order {
    order_id: number;
    user_id: number;
    product_id: number;
    request_id: number;
    order_date: number;
    order_quantity: number;
    order_amount_usd: number;
    order_amount_uah: number;
    order_unit_price_usd: number;
    order_unit_price_uah: number;
    order_delivery_usd: number;
    order_delivery_uah: number;
    order_donate_amount_uah: number;
    order_number: string;
    order_tracking_number: string;
    order_status: OrderStatus;
    order_comment: string;
    order_recipient: string;
    order_problematic: boolean;
}

export type OrderStatus = 'ordered' | 'shipped' | 'delivered' | 'picked' | 'received' | 'cancelled' | 'problem' | 'partial';

const FILTERS_KEY = 'filters-';

export type OrderFull = Order & ProductFull & User & {
    selected?: boolean;
    view_query_params: { [p: string]: any };
    product_view_query_params: { [p: string]: any };
    seller_name_short: string;
    order_local_date: string;
    order_local_status: string;
    order_search_description: string;
    order_tracking_number_array: {
        tracking_number: string;
        tracking_number_alt: string;
        tracking_picked: boolean;
        tracking_status: string;
        tracking_updated: number;
        tracking_image_url: string;
        tracking_status_local: string;
        tracking_updated_local: string;
    }[];
};

export interface ProductsResponse {
    products: Product[];
    models: Model[];
    details: Detail[];
    sellers: Seller[];
    users: UserMin[];
    cex: CexRates;
}

export interface UserMin {
    user_id: number;
    user_nick: string;
    user_image_url: string;
}

export interface User {
    user_id: number;
    user_uuid: string;
    user_name: string;
    user_nick: string;
    user_email: string;
    user_role: 'guest' | 'sdua' | 'scrapper' | 'superadmin';
    user_image_url: string;
    user_card_name: string;
    user_card_number: string;
    user_iban_name: string;
    user_iban_number: string;
    user_telegram_id: string;
    user_telegram_name: string;
    user_telegram_username: string;
    user_api_access: string[];
    user_notifications_new: number;
    user_notifications_read: number;
}

export interface PayoutDto {
    receiver_user_id: number;
    payout_date: number;
    payout_amount_uah: number;
    payout_comment: string;
}

type UserMembership = User & Membership;

export interface UserMoney extends UserMembership {
    payout_amount_uah: number;
    order_amount_uah: number;
    order_donate_amount_uah: number;
    balance: number;
}

export interface Payout extends User {
    receiver_user_id: number;
    sender_user_id: number;
    payout_date: number;
    payout_amount_uah: number;
    payout_comment: string;
    sender_user_name: string
}

export interface Warehouse {
    warehouse_id: number;
    warehouse_name: string;
    warehouse_default: boolean;
    warehouse_deleted: boolean;
}

export interface Profile {
    token: string;
    profile: UserMoney;
    payouts: Payout[];
    memberships: Membership[];
}

export interface Stock {
    model_id: number;
    warehouse_id: number;
    stock_quantity: number;
    income_quantity: number;
    outcome_quantity: number;
    drones_quantity: number;
}

export interface StockResponse {
    warehouses: Warehouse[];
    models: Model[];
    stock: Stock[];
    transfers: Transfer[];
    users: User[];
}

export interface Transfer {
    transfer_id: number;
    tenant_id: number;
    user_id: number;
    model_id: number;
    order_id: number | null;
    drone_id: number | null;
    src_warehouse_id: number | null;
    dst_warehouse_id: number | null;
    transfer_date: number;
    transfer_quantity: number;
    transfer_amount_usd: number;
    transfer_amount_uah: number;
    transfer_comment: string;
}

export interface TransferDto {
    model_id: number;
    transfer_quantity: number;
    drone_id: number | null;
    src_warehouse_id: number | null;
    dst_warehouse_id: number | null;
    transfer_date: number;
    transfer_comment: string;
    transfer_amount_usd?: number;
    transfer_amount_uah?: number;
}

export interface Tenant {
    tenant_id: number;
    tenant_name: string;
    tenant_affiliate: number;
}

export interface Membership extends Tenant {
    user_id: number;
    membership_card_name: string;
    membership_card_number: string;
    membership_iban_name: string;
    membership_iban_number: string;
    membership_role: 'buyer' | 'accountant' | 'craftsman' | 'admin';
    membership_deleted: boolean;
}

export interface DroneSdua {
    did: string;
    conf: {
        frameSize: {
            id: string;
            label: string;
        };
        rxUart: {
            id: string;
            label: string;
        };
        rxFreq: {
            id: string;
            label: string;
        };
        vtxModel: {
            id: string;
            label: string;
        };
        cameraModel: {
            id: string;
            label: string;
        };
        fcModel: {
            id: string;
            label: string;
        };
        fcFirmware: {
            id: string;
            label: string;
        }
    };
    status: string;
}


export interface OrderStatusRequest {
    order_id: number;
    warehouse_id?: number;
    order_status: string;
    event_comment?: string;
    order_tracking_numbers?: string[];
}

export interface UserShort {
    user_id: number;
    user_name: string;
    user_image_url: string;
}

export type DroneStatus = 'draft' | 'done' | 'sent' | 'deleted';

export interface DroneTransferDto {
    model_id: number;
    warehouse_id: number;
}

export type DroneType = 'drone-analog' | 'drone-digital' | 'drone-analog-bomber' | 'drone-digital-bomber';

export interface CreateDroneDto {

    user_id: number;
    drone_name: string;
    drone_number: string;
    drone_comment: string;
    drone_status: DroneStatus;
    drone_type: DroneType;
    drone_public: boolean;
    drone_public_flags: DronePublicFlag[];
    transfers: DroneTransferDto[];
    drone_sdua_url: string;
    drone_public_comment: string;

}

export interface UpdateDroneDto {

    user_id: number;
    drone_id: number;
    drone_name: string;
    drone_number: string;
    drone_comment: string;
    drone_status: DroneStatus;
    drone_public: boolean;
    drone_public_flags: DronePublicFlag[];
    drone_sdua_url: string;
    drone_public_comment: string;

}

export type DronePublicFlag = 'craftsman' | 'price' | 'organization';

export interface DronePublicResponse extends DronesResponse {
    tenant: Tenant;
}

export interface Drone {
    drone_id: number;
    drone_uuid: string;
    user_id: number;
    drone_date: number;
    drone_name: string;
    drone_number: string;
    drone_comment: string;
    drone_status: DroneStatus;
    drone_price_usd: number;
    drone_price_uah: number;
    drone_type: DroneType;
    drone_public: boolean;
    drone_public_flags: DronePublicFlag[];
    drone_sdua_url: string;
    drone_public_comment: string;
}

export interface DronesResponse {
    tenant?: Tenant;
    warehouses: Warehouse[];
    users: UserShort[];
    drones: Drone[];
    transfers: Transfer[];
    model_price_sequences_usd: { [k: number]: number[] };
    model_price_sequences_uah: { [k: number]: number[] };
}

export interface DronesResponseProcessed {
    tenant?: Tenant;
    drones: DroneFull[];
    users: UserShort[];
    model_price_sequences_usd: { [k: number]: number[] };
    model_price_sequences_uah: { [k: number]: number[] };
}

export interface DroneDetail {
    model_id: number;
    detail_id: number;
    warehouse_id: number;
    model_name: string;
    model_image_url: string;
    detail_name: string;
    detail_name_local: string;
    warehouse_name: string;
    detail_price_usd: number;
    detail_price_uah: number;
}

export type DroneFull = Drone & UserShort & {
    drone_uuid_short: string;
    drone_date_string: string;
    drone_status_local: string;
    drone_type_local: string;
    details: DroneDetail[];
    drone_publi_comment_html: SafeHtml;
};

export interface UpsertCommentDto {
    comment_id: number | null;
    drone_id: number | null;
    model_id: number | null;
    seller_id: number | null;
    product_id: number | null;
    comment_text: string;
    comment_pinned: number;
    comment_deleted: number;
}

export interface Comment {
    comment_id: number;
    user_id: number;
    drone_id: number | null;
    model_id: number | null;
    seller_id: number | null;
    product_id: number | null;
    comment_date: number;
    comment_pinned: number;
    comment_deleted: number;
    comment_text: string;
    model_name: string;
}

export type CommentFull = Comment & UserShort & { comment_date_string: string; };

export type TrackingStatus = 'created' | 'shipped' | 'customs' | 'cleared' | 'failed' | 'delivered' | 'picked';

export const TRACKING_STATUSES: { [k: string]: string } = {
    'created': 'Створено',
    'shipped': 'Надіслано',
    'customs': 'Розмитнюється',
    'cleared': 'Розмитнено',
    'failed': 'Не розмитнено',
    'delivered': 'Доставлено',
    'picked': 'Отримано',
    'anomaly': 'Аномалія',
};

export interface Tracking {
    tracking_id: number;
    user_id: number;
    order_id: number | null;
    tracking_date_created: number;
    tracking_date_updated: number;
    tracking_archived: number;
    tracking_visible: number;
    tracking_status: TrackingStatus;
    tracking_number: string;
    tracking_number_alt: string;
    tracking_comment: string;
    tracking_data: TrackingData;
}

export type TrackingFull = Tracking & {
    tracking_local_date_updated: string;
    tracking_local_status: string;
    tracking_image_url: string;

    // company_name: string;
    // company_url: string;
    // company_county_name: string;
}

export enum TrackingSource {
    cainiao = 'cainiao',
    meestukr = 'meestukr',
    meestint = 'meestint',
    novaint = 'novaint',
    ukrposhta = 'ukrposhta',
}

export interface TrackingData {

    success: boolean;
    time: number;
    query: string;

    parcel_status: string;
    parcel_status_desc: string;
    parcel_order: string;
    parcel_weight: string;
    parcel_source: string;
    parcel_number: string;
    parcel_number_alt: string;
    parcel_number_final: string;

    company_name: string;
    company_url: string;
    company_county_name: string;

    parcel_days: string;
    parcel_cost: string;
    parcel_dimensions: string;

    info_type: string;
    info_time: number;
    info_content: string;

    src_country_code: string;
    src_name: string;
    src_country: string;
    src_city: string;

    dst_country_code: string;
    dst_country: string;
    dst_city: string;
    dst_address: string;
    dst_name: string;
    dst_phone: string;
    dst_zip: string;

    events: {
        source: TrackingSource,
        status: string;
        action: string;
        time: number;
        desc: string;
        more: string;
        city: string;
        gcode: string;
        gdesc: string;
        country: string;
        country_code: string;
        info: string;
    }[],

}

export interface Kit {
    kit_id: number;
    user_id: number;
    tenant_id: number | null;
    kit_date: number;
    kit_order: number;
    kit_deleted: number;
    kit_public: number;
    kit_full: number;
    kit_risky: number;
    kit_name: string;
    kit_slug: string;
    kit_models: string;
    kit_comment: string;
    kit_description: string;
}

export type KitFull = Kit & {
    kit_price: number;
    kit_models_ids: number[];
    kit_description_html: SafeHtml;
};

export interface KitDto {
    kit_id: number;
    kit_order: number;
    kit_risky: boolean;
    kit_deleted: boolean;
    kit_name: string;
    kit_slug: string;
    kit_models: string;
    kit_comment: string;
    kit_description: string;
    kit_public: boolean;
    kit_full: boolean;
    kit_verified: boolean;
}

export interface DashboardResponse {
    users_stats: { users_all: number; users_with_orders: number; users_with_ten_orders: number; users_with_trackings: number; },
    tenants_stats: { tenants_all: number; tenants_with_orders: number; tenants_with_ten_orders: number; },
    products_stats: { products_all: number; products_deleted: number; products_invalid: number; products_active: number; products_queued: number; },
    scrappers_list: (User & { updates_count: number; updates_date: number; updates_speed: number; })[],
    products_queue: { product_id: number }[],
}

export interface CexRates {
    mono_usd: number;
    privat_usd: number;
    custom_usd: number;
    mono_eur: number;
    privat_eur: number;
    custom_eur: number;
}

export interface TenantStat {
    tenant_id: number;
    tenant_name: string;
    user_id: number;
    orders_amount: number;
    orders_count: number;
    drones_count: number;
    users_count: number;
    users_ids: string;
}

export interface UserShortExtended extends UserShort {
    user_email: string;
}

export interface TrackingRequest {
    token_v2?: string;
    token_v3?: string;
    order_id?: number;
    force: boolean;
    tracking_number: string;
    tracking_visible: boolean;
}

export interface TrackingResponse {
    tracking: TrackingFull;
    is_bot: boolean;
}

export interface CompressedData<T> {
    map: { [k: number]: number };
    cols: string[];
    rows: any[];
    records: T[];
}

export interface StaticData {
    date: number;
    details: CompressedData<Detail>;
    models: CompressedData<ModelFull>;
    sellers: CompressedData<SellerFull>;
    users: CompressedData<UserMin>;
    products: CompressedData<ProductFull>;
    cex: CexRates;
}

function decompress(data: CompressedData<any>) {
    data.records = data.rows.map(r => data.cols.reduce((a, c, i) => (a[c] = r[i] as any, a), {} as any));
}

@Injectable()
export class DataService {

    public readonly europe = new Date().getTimezoneOffset() <= 0 && new Date().getTimezoneOffset() >= -240;

    public usd_uah_rate = 41.8;

    private ms: Membership[] = [];

    public profile: User | null = null;
    public get memberships() { return this.ms; }
    public set memberships(ms: Membership[]) {
        this.ms = ms;
        const tenant_id = this.tenant_id;
        this.tenant = ms.find(m => m.tenant_id === tenant_id) || null;
    }

    public profile$ = new ReplaySubject<Profile>();

    public tenant: Membership | null = null;

    public readonly tenant_id_cnange = new ReplaySubject<number | null>(1);

    public get tenant_id() {
        const tenant_id = localStorage.getItem('tenant_id');
        if (!tenant_id) return null;
        if (isNaN(Number(tenant_id))) return null;
        return Number(tenant_id);
    }

    public set tenant_id(tenant_id: number | null) {

        const old_tenant_id = this.tenant_id;

        if (tenant_id) {
            localStorage.setItem('tenant_id', String(tenant_id));
        } else {
            localStorage.removeItem('tenant_id');
        }
        this.tenant = this.ms.find(m => m.tenant_id === tenant_id) || null;
        if (old_tenant_id !== tenant_id) this.tenant_id_cnange.next(tenant_id);
    }

    public updateTenantId(tenant_id: number) {
        this.tenant_id = tenant_id;
        this.all_orders = null;
        this.my_orders = null;
        this.requests = null;
    }

    private product_affiliate_map: { [id: number]: ProductAffiliate } = {};

    private updateAffiliates(affiliates?: ProductAffiliateResponse) {
        if (!this.tenant?.tenant_affiliate) return;
        if (affiliates) {
            this.product_affiliate_map = affiliates;
            this.getFullProducts().subscribe({
                next: products => products.forEach(p => {
                    p.sku_url = this.getUrl(p);
                    p.sku_sd_url = this.getSdUrl(p);
                    p.sku_lo_url = this.getLoUrl(p);
                    p.sku_cd_url = this.getCdUrl(p);
                })
            });
        } else {
            this.getAffiliates().subscribe({
                next: affiliates => {
                    this.product_affiliate_map = affiliates;
                    this.getFullProducts().subscribe({
                        next: products => products.forEach(p => {
                            p.sku_url = this.getUrl(p);
                            p.sku_sd_url = this.getSdUrl(p);
                            p.sku_lo_url = this.getLoUrl(p);
                            p.sku_cd_url = this.getCdUrl(p);
                        })
                    });
                }
            });
        }
    }

    public lang = 'uk';

    constructor(
        private readonly http: HttpClient,
        private readonly app: ApplicationRef,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly sanitizer: DomSanitizer,
        private readonly snackbar: MatSnackBar,
    ) {

        const query_lang = new URL(location.toString()).searchParams.get('lang') || '';
        const supported_langs = ['en', 'uk'];

        if (supported_langs.includes(query_lang)) {
            this.lang = query_lang;
            localStorage.setItem('lang', query_lang);
        } else {
            const local_lang = localStorage.getItem('lang') || '';
            if (supported_langs.includes(local_lang)) {
                this.lang = local_lang;
            }
        }

        this.tenant_id_cnange.subscribe(() => this.updateAffiliates());

        this.tenant_id_cnange.next(this.tenant_id);

        const jwt = localStorage.getItem(OAUTH_JWT) || '';
        const profile_json = localStorage.getItem(OAUTH_PROFILE) || '';
        const profile = profile_json ? JSON.parse(profile_json) as Profile : null;

        if (profile) {
            this.profile = profile.profile;
            this.memberships = profile.memberships;
            this.profile$.next(profile);
        } else {
            this.profile = this.parseJwt(jwt);
        }

        this.updateJwtCookie();

        this.reloadProfile();
    }

    public reloadProfile() {
        const subj = new Subject<Profile>();
        if (this.profile) {
            this.getProfile().subscribe({
                next: profile => {
                    if (profile.token) {
                        localStorage.setItem(OAUTH_JWT, profile.token);
                        this.updateJwtCookie();
                    }
                    localStorage.setItem(OAUTH_PROFILE, JSON.stringify(profile));
                    this.setProfile(profile);
                    if (this.memberships?.length && !this.tenant_id) {
                        this.tenant_id = this.memberships[0].tenant_id;
                    }
                    subj.next(profile);
                    this.profile$.next(profile);
                }
            })
        } else {
            subj.next(null!);
        }
        return subj;
    }

    public setProfile(profile: Profile) {
        this.profile = profile.profile;
        this.memberships = profile.memberships;
    }

    private requests: ReplaySubject<OrderRequest[]> | null = null;

    private details_loaded = false;
    private details = new ReplaySubject<Detail[]>();

    private sellers_loaded = false;
    private sellers = new ReplaySubject<SellerFull[]>();

    private models: ReplaySubject<ModelFull[]> | null = null;
    private kits: ReplaySubject<KitFull[]> | null = null;
    private warehouses: ReplaySubject<(Warehouse)[]> | null = null;
    private my_orders: ReplaySubject<(OrderFull)[]> | null = null;
    private all_orders: ReplaySubject<(OrderFull)[]> | null = null;
    private full_products: ReplaySubject<(ProductFull)[]> | null = null;
    private full_drones: ReplaySubject<DronesResponseProcessed> | null = null;
    private stock: ReplaySubject<StockResponse> | null = null;
    private stock_no_transfers: ReplaySubject<StockResponse> | null = null;
    private trackings: ReplaySubject<TrackingFull[]> | null = null;

    private cex: ReplaySubject<CexRates> | null = null;

    private data: ReplaySubject<StaticData> | null = null;

    public getPrices() {
        const url = `${environment.apiUrl}/prices`;
        return this.http.get<Product[]>(`${url}`);
    }

    public getData(force = false) {

        if (!this.data || force) {
            const subj = this.data = new ReplaySubject();
            const url = `https://static.swarm.army/data.json`;
            zip(this.getPrices(), this.http.get<StaticData>(url)).subscribe({
                next: ([prices, data]) => {

                    decompress(data.details);
                    decompress(data.users);
                    decompress(data.models);
                    decompress(data.sellers);
                    decompress(data.products);

                    prices.forEach(price => {
                        const prod = data.products.records[data.products.map[price.product_id]];
                        if (!prod) return;
                        prod.sku_last_price = price.sku_last_price;
                        prod.sku_last_available = price.sku_last_available;
                        prod.sku_last_sd_price = price.sku_last_sd_price;
                        prod.sku_last_lo_price = price.sku_last_lo_price;
                    });

                    data.models.records.forEach((m, i, s) => s[i] = { ...m, ...data.details.records[data.details.map[m.detail_id]] });

                    const res = {
                        details: data.details.records,
                        models: data.models.records,
                        sellers: data.sellers.records,
                        users: data.users.records,
                        products: data.products.records,
                        cex: data.cex,
                    };

                    data.products.records = data.products.records.map(p => this.makeFullProduct(p, res));

                    subj.next(data);

                    // subj.next(e.products.map(p => this.makeFullProduct(p, e)));

                    if (this.tenant?.tenant_affiliate) this.updateAffiliates();
                },
                error: e => subj.error(e),
            });
        }

        return this.data.asObservable();
    }



    private updateJwtCookie() {
        const jwt = localStorage.getItem(OAUTH_JWT) || '';
        if (jwt) {
            document.cookie = `${OAUTH_JWT}=${encodeURIComponent(jwt)}; max-age=63158400; path=/`;
        } else {
            document.cookie = `${OAUTH_JWT}=; max-age=0; path=/`;
        }
    }

    public getFilters(key: string): Filter[] | null {
        try {
            const filters_json = localStorage.getItem(FILTERS_KEY + key);
            if (filters_json) {
                const filters = JSON.parse(filters_json);
                if (Array.isArray(filters)) return filters;
                return null;
            } else {
                return null;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    public setFilters(key: string, filters: Filter[] | null) {
        if (filters) {
            localStorage.setItem(FILTERS_KEY + key, JSON.stringify(filters));
        } else {
            localStorage.removeItem(FILTERS_KEY + key);
        }
    }

    public makeFullProduct(product: Product, response: ProductsResponse): ProductFull {

        const model = response.models.find(m => m.model_id === product.model_id);
        if (!model) throw new Error('Model not found!');
        const seller = response.sellers.find(s => s.seller_id === product.seller_id);
        if (!seller) throw new Error('Seller not found!');
        const detail = response.details.find(d => d.detail_id === model.detail_id);
        if (!detail) throw new Error('Detail not found!');
        const user = response.users.find(u => u.user_id === product.user_id);
        if (!user) throw new Error('User not found!');

        product.sku_name = product.sku_name.split('; ').filter(r => r !== 'Ships From: China').join('; ');

        let tags_array = model.model_tags ? model.model_tags.split(';') : [];
        const tags_dict: { [tag: string]: boolean } = {};
        tags_array.forEach(t => tags_dict[t] = true);

        tags_array = tags_array.filter(t => !t.endsWith('KIT'));

        const sku_running_out = product.sku_last_available > 0 && product.sku_last_available < 50;

        const sku_full_price = product.sku_last_price + product.item_shipping_price;
        const sku_full_unit_price = sku_full_price / product.sku_quantity;

        const sku_full_sd_price = product.sku_last_sd_price ? product.sku_last_sd_price + product.item_shipping_price : 0;
        const sku_full_sd_unit_price = sku_full_sd_price / product.sku_quantity;

        const sku_full_lo_price = product.sku_last_lo_price ? product.sku_last_lo_price + product.item_shipping_price : 0;
        const sku_full_lo_unit_price = sku_full_lo_price / product.sku_quantity;

        const sku_full_bs_price = product.sku_last_bs_price ? product.sku_last_bs_price + product.item_shipping_price : 0;
        const sku_full_bs_unit_price = sku_full_bs_price / product.sku_quantity;

        const sku_min_price = Math.min(sku_full_price, sku_full_sd_price || sku_full_price, sku_full_lo_price || sku_full_price, sku_full_bs_price || sku_full_price);

        const sku_min_unit_price = Math.min(sku_full_unit_price, sku_full_sd_unit_price || sku_full_unit_price, sku_full_lo_unit_price || sku_full_unit_price, sku_full_bs_unit_price || sku_full_unit_price);

        const sku_future_unit_price = product.sku_future_sale_price ? product.sku_future_sale_price + product.item_shipping_price : 0;

        const sku_url = this.getUrl(product);
        const sku_sd_url = this.getSdUrl(product);
        const sku_cd_url = this.getCdUrl(product);
        const sku_lo_url = this.getLoUrl(product);
        const sku_bs_url = this.getBsUrl(product);

        const view = 'product';
        const product_id = product.product_id;
        const model_name = this.makeUrl(model.model_name);
        const seller_name_short = seller.seller_name.replace(' Store', '');
        const view_query_params = { view, seller: this.makeUrl(seller_name_short), model: model_name, product_id };
        const seller_view_query_params = { view: 'seller', seller: this.makeUrl(seller_name_short), seller_id: product.seller_id, seller_swarm_id: seller.seller_swarm_id };

        const sku_future_sale_datetime = product.sku_future_sale_date
            ? timestampToLocalDate(product.sku_future_sale_date) + ' ' + timestampToLocalShortTime(product.sku_future_sale_date)
            : '';

        const local_date_updated = timestampToLocalDate(product.product_last_updated) + ' ' + timestampToLocalTime(product.product_last_updated);

        // TODO: fix currency
        const product_currency = seller.seller_currency || (product.product_source === 'custom' ? 'UAH' : environment.defaultCurrency);

        const product_comments_label = getNumberLabel(
            product.product_comments,
            { one: 'коментар', few: 'коментарі', many: 'коментарі' }
        );

        let sku_units = 'шт';

        switch (detail.detail_units) {
            case DetailUnits.Grams:
                sku_units = 'г';
                break;
            case DetailUnits.Liters:
                sku_units = 'л';
                break;
            case DetailUnits.Meters:
                sku_units = 'м';
                break;
            case DetailUnits.Sets:
                sku_units = 'компл';
                break;
        }

        let sku_min_unit_price_usd = sku_min_unit_price;

        if (seller.seller_currency === 'UAH') sku_min_unit_price_usd = sku_min_unit_price / response.cex.mono_usd;
        else if (seller.seller_currency === 'EUR') sku_min_unit_price_usd = sku_min_unit_price * response.cex.mono_eur / response.cex.mono_usd;

        let sku_full_unit_price_usd = sku_full_unit_price;

        if (seller.seller_currency === 'UAH') sku_full_unit_price_usd = sku_full_unit_price / response.cex.mono_usd;
        else if (seller.seller_currency === 'EUR') sku_full_unit_price_usd = sku_full_unit_price * response.cex.mono_eur / response.cex.mono_usd;

        const model_name_search = model.model_name.toLowerCase();
        const detail_name_search = detail.detail_name.toLowerCase();
        const detail_name_local_search = detail.detail_name_local.toLowerCase();

        return {
            ...product, ...model, ...seller, ...detail, ...user, product_currency,
            tags_array, tags_dict, sku_running_out, sku_url, sku_sd_url, view_query_params,
            sku_full_price, sku_full_unit_price, sku_full_sd_price, sku_full_sd_unit_price,
            sku_future_sale_datetime, seller_name_short, local_date_updated,
            sku_min_price, sku_min_unit_price, sku_future_unit_price, sku_cd_url,
            sku_full_lo_price, sku_full_lo_unit_price, sku_lo_url, seller_view_query_params,
            product_comments_label, sku_units, sku_bulk_unit_price: 0, sku_bulk_quantity: 0,
            sku_bs_url, sku_full_bs_price, sku_full_bs_unit_price, sku_min_unit_price_usd,
            sku_full_unit_price_usd, model_name_search, detail_name_search, detail_name_local_search,
        };

    }


    private parseJwt(jwt: string) {
        try {
            const tokenString = decodeURIComponent(escape(atob((jwt.split(".")[1].replace(/-/g, "+").replace(/_/g, "/")))));
            const tokenData = JSON.parse(tokenString);
            console.log(tokenData);
            return tokenData;
        } catch (error) {
            return null;
        }
    }

    public getJwt() {
        return localStorage.getItem(OAUTH_JWT) || '';
    }

    private getOptions(headers: {} = {}) {
        const jwt = localStorage.getItem(OAUTH_JWT) || '';
        if (jwt) {
            return {
                headers: {
                    ...headers,
                    tenant: String(this.tenant_id),
                    authorization: 'Bearer ' + jwt,
                },
            };
        } else {
            return {};
        }
    }

    public getRequests(force = false) {
        if (!this.requests || force) {
            const subj = this.requests || new ReplaySubject(1);
            const url = `${environment.apiUrl}/requests`;
            this.http.get<OrderRequest[]>(url, this.getOptions()).subscribe({
                next: e => {
                    e.forEach(r => r.request_products_ids = JSON.parse(r.request_products || '[]'));
                    subj.next(e);
                },
                error: e => subj.error(e),
            });
            this.requests = subj;
        }

        return this.requests.asObservable();

    }

    public getFullProducts(force = false) {

        if (!this.full_products || force) {
            const subj = this.full_products = new ReplaySubject();
            const url = `${environment.apiUrl}/products`;
            this.http.get<ProductsResponse>(url).subscribe({
                next: e => {

                    if (!this.cex) this.cex = new ReplaySubject();
                    this.cex.next(e.cex);

                    const model_minimums: { [k: number]: ProductFull } = {};

                    const full_products: ProductFull[] = new Array(e.products.length);

                    for (let j = full_products.length - 1; j >= 0; --j) {
                        full_products[j] = this.makeFullProduct(e.products[j], e);
                    }

                    for (let j = full_products.length - 1; j >= 0; --j) {
                        const full_product = full_products[j];
                        let min: ProductFull | null = model_minimums[full_product.model_id];
                        if (!min) {
                            min = full_product.sku_last_available ? full_product : null;
                            for (let i = 0; i < full_products.length; i++) {
                                const min_candidate = full_products[i];
                                if (!min_candidate.product_deleted && min_candidate.product_status !== 'invalid'
                                    && min_candidate.model_id === full_product.model_id
                                    && (min?.sku_min_unit_price || !min)
                                    && (min_candidate.sku_min_unit_price < (min?.sku_min_unit_price || 0) || !min)
                                    && min_candidate.sku_last_available) {
                                    min = min_candidate;
                                }
                            }
                            if (min) model_minimums[full_product.model_id] = min;
                        }
                        if (min && min !== full_product) {
                            full_product.sku_bulk_quantity = min.sku_quantity;
                            full_product.sku_bulk_unit_price = min.sku_min_unit_price_usd;
                        }
                    }

                    subj.next(full_products);
                    if (this.tenant?.tenant_affiliate) this.updateAffiliates();
                },
                error: e => subj.error(e),
            });
        }

        return this.full_products.asObservable();
    }

    public getStats() {
        const url = `${environment.apiUrl}/stats`;
        return this.http.get<PublicStats>(`${url}`);
    }

    public getTenants() {
        const url = `${environment.apiUrl}/get-tenants`;
        return this.http.get<{ tenants: TenantStat[], users: UserShortExtended[] }>(`${url}`, this.getOptions());
    }

    public impersonate(user_id: number) {
        const url = `${environment.apiUrl}/impersonate`;
        const params = { user_id };
        this.http.post<Profile>(`${url}`, null, { ...this.getOptions(), params }).subscribe(profile => {
            this.tenant_id = null;
            localStorage.setItem(OAUTH_JWT, profile.token);
            localStorage.setItem(OAUTH_PROFILE, JSON.stringify(profile));
            window.location.reload();
        });
    }


    public getAffiliates() {
        const url = `${environment.apiUrl}/get-affiliates`;
        return this.http.get<ProductAffiliateResponse>(`${url}`, this.getOptions());
    }

    public upsertAffiliate(dto: UpsertProductAffiliateDto) {
        const url = `${environment.apiUrl}/upsert-affiliate`;
        return this.http.post<ProductAffiliateResponse>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).pipe(tap(afiliates => this.updateAffiliates(afiliates)));
    }

    public readonly CT = {
        Unknown: 0,
        OpenAliexpress: 1,
        OpenSuperDeals: 2,
        OpenLimidetDeal: 3,
        OpenCoinsDeal: 4,
        OpenProductDialog: 5,
        OpenModelDialog: 6,
        OpenSellerDialog: 7,
        OpenHomePage: 8,
        OpenProductsPage: 9,
        OpenModelsPage: 11,
        OpenRequestsListPage: 12,
        OpenRequestPage: 13,
        OpenMarketplace: 14,
        OpenTracking: 15,
        OpenStock: 16,
        OpenPayouts: 17,
        OpenDrones: 18,
        OpenSellers: 19,
        OpenKitsPage: 20,
        OpenInfoPage: 21,
        OpenCustomProduct: 22,
        OpenBigSave: 23,
    };
    public readonly CS = {
        Unknown: 0,
        KitsPage: 1,
        ModelsPage: 2,
        ProductsPage: 3,
        ModelDialogInfo: 4,
        ProductDialogInfo: 5,
        ModelDialogList: 6,
        ProductDialogList: 7,
        SellerDialog: 8,
        RequestPage: 9,
        RequestsListPage: 10,
        MarketplacePage: 11,
        HomePage: 13,
        TrackingPage: 14,
        OrdersPage: 15,
        OrderDialog: 16,
        ReportDialog: 17,
        StockPage: 18,
        PayoutsPage: 19,
        DronesPage: 20,
        SellersPage: 21,
        InfoPage: 22,
    };

    public current_kit_id: number | null = null;

    public get current_click_source(): ClickSource {

        const url = new URL(location.toString());

        const view = url.searchParams.get('view');

        if (view === 'seller') return ClickSource.SellerDialog;
        if (view === 'model') return ClickSource.ModelDialogList;
        if (view === 'product') return ClickSource.ProductDialogInfo;
        if (view === 'order') return ClickSource.OrderDialog;
        if (view === 'report') return ClickSource.ReportDialog;

        const path = location.pathname;

        if (path === '/') {
            const mode = localStorage.getItem('homepage-filtersmode');
            if (mode === 'products') return ClickSource.ProductsPage;
            if (mode === 'kits') return ClickSource.KitsPage;
            return ClickSource.Unknown;
        }

        if (path === '/models') return ClickSource.ModelsPage;
        if (path === '/sellers') return ClickSource.SellersPage;
        if (path === '/requests') return ClickSource.RequestsListPage;
        if (path === '/payouts') return ClickSource.PayoutsPage;
        if (path === '/drones') return ClickSource.DronesPage;
        if (path === '/stock') return ClickSource.StockPage;
        if (path === '/marketplace') return ClickSource.MarketplacePage;
        if (path.startsWith('/info')) return ClickSource.InfoPage;
        if (path.startsWith('/track/')) return ClickSource.TrackingPage;
        if (path.startsWith('/requests/')) return ClickSource.RequestPage;

        return ClickSource.Unknown;
    }

    public click(source: ClickSource, type: ClickType, ids: ClickIds) {
        const dto: ClickDto = {
            click_source: source,
            click_type: type,
            model_id: ids.model_id || null,
            seller_id: ids.seller_id || null,
            product_id: ids.product_id || null,
            kit_id: ids.kit_id || this.current_kit_id || null,
        };
        const url = `${environment.apiUrl}/click`;
        this.http.post<any>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).subscribe({
            next: () => { },
            error: console.log,
        });
    }

    public getDrones(force = false) {

        if (!this.full_drones || force) {
            const subj = this.full_drones = this.full_drones || new ReplaySubject(1);
            const url = `${environment.apiUrl}/drones`;
            this.processDrones(this.http.get<DronesResponse>(`${url}`, this.getOptions())).subscribe({
                next: e => subj.next(e),
                error: e => subj.error(e),
            });
        }

        return this.full_drones.asObservable();

    }

    public getDrone(drone_uuid: string) {
        const url = `${environment.apiUrl}/drone`;
        return this.processDrones(this.http.get<DronePublicResponse>(`${url}`, { params: { drone_uuid } }));
    }

    public getDroneSdua(drone: Drone) {
        return this.http.get<DroneSdua>(drone.drone_sdua_url + '?format=json');
    }

    private processDrones(obs: Observable<DronesResponse>) {
        return zip([obs, this.getModels()]).pipe(map(([response, models]) => {

            if (!response) return {} as DronesResponseProcessed;

            return {
                tenant: response.tenant,
                users: response.users,
                model_price_sequences_usd: response.model_price_sequences_usd,
                model_price_sequences_uah: response.model_price_sequences_uah,
                drones: response.drones.map(d => {

                    const user = response.users.find(u => u.user_id === d.user_id)!;

                    const details: DroneDetail[] = [];

                    response.transfers.filter(t => t.drone_id === d.drone_id).forEach(t => {

                        const model = models.find(m => m.model_id === t.model_id)!;
                        const warehouse = response.warehouses.find(m => m.warehouse_id === t.src_warehouse_id)!;

                        const detail = details.find(d => d.detail_id === model.detail_id);

                        if (detail) {
                            if (warehouse) {
                                details.push({
                                    ...model, ...warehouse,
                                    detail_price_usd: t.transfer_amount_usd,
                                    detail_price_uah: t.transfer_amount_uah,
                                });
                            } else {
                                const index = details.indexOf(detail);
                                if (index > -1) details.splice(index, 1);
                            }
                        } else {
                            details.push({
                                ...model, ...warehouse,
                                detail_price_usd: t.transfer_amount_usd,
                                detail_price_uah: t.transfer_amount_uah,
                            });
                        }

                    });

                    details.sort((a, b) => DRONE_DETAILS_ORDER.indexOf(a.detail_name) - DRONE_DETAILS_ORDER.indexOf(b.detail_name));

                    const drone_public_flags: DronePublicFlag[] = Array.isArray(d.drone_public_flags)
                        ? d.drone_public_flags : JSON.parse(d.drone_public_flags || '[]');

                    const drone_uuid_short = uuidToBase62(d.drone_uuid);

                    const html = marked.parse(d.drone_public_comment || '') as string;
                    const drone_publi_comment_html = this.sanitizer.bypassSecurityTrustHtml(html.replaceAll('<a ', '<a target="_blank" '));

                    const drone: DroneFull = {
                        ...d, ...(user || {}),
                        details: details,
                        drone_uuid_short,
                        drone_public_flags,
                        drone_publi_comment_html,
                        drone_date_string: timestampToLocalDate(d.drone_date) + ' ' + timestampToLocalShortTime(d.drone_date),
                        drone_status_local: { draft: 'Збирається', done: 'Готовий', sent: 'Переданий', deleted: 'Видалений', }[d.drone_status],
                        drone_type_local: {
                            'drone-analog': 'Камікадзе-аналог',
                            'drone-analog-bomber': 'Бомбер-аналог',
                            'drone-digital': 'Камікадзе-цифра',
                            'drone-digital-bomber': 'Бомбер-цифра',
                        }[d.drone_type]
                    };
                    return drone;

                }),
            };

        }));
    }

    public createDrone(dto: CreateDroneDto) {
        const url = `${environment.apiUrl}/create-drone`;
        return this.processDrones(this.http.post<DronesResponse>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        )).pipe(tap(drones => this.full_drones?.next(drones)));
    }

    public getCex(force = false) {

        if (!this.cex || force) {
            const subj = this.cex = new ReplaySubject();
            const url = `${environment.apiUrl}/get-cex`;
            this.http.get<CexRates>(url).subscribe({
                next: e => subj.next(e),
                error: e => subj.error(e),
            });
        }

        return this.cex.asObservable();

    }

    public updateDrone(dto: UpdateDroneDto) {
        const url = `${environment.apiUrl}/update-drone`;
        return this.processDrones(this.http.post<DronesResponse>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        )).pipe(tap(drones => this.full_drones?.next(drones)));
    }

    public upsertComment(dto: UpsertCommentDto) {
        const url = `${environment.apiUrl}/upsert-comment`;
        return this.http.post<CommentFull>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).pipe(tap(c => c.comment_date_string = timestampToLocalDate(c.comment_date) + ' ' + timestampToLocalShortTime(c.comment_date)));
    }

    public getUpdates(product_id: number) {
        const url = `${environment.apiUrl}/updates`;
        const params = {
            'product_id': product_id
        }
        return this.http.get<ProductUpdate[]>(`${url}`, { params });
    }

    public getComments(params: { model_id?: number; seller_id?: number; product_id?: number; drone_id?: number; }) {
        const url = `${environment.apiUrl}/get-comments`;
        return this.http.get<CommentFull[]>(`${url}`, { params }).pipe(tap(comments => comments
            .forEach(c => c.comment_date_string = timestampToLocalDate(c.comment_date) + ' ' + timestampToLocalShortTime(c.comment_date))
        ));
    }

    public getCommentsSWARM(params: { model_id?: number; seller_id?: number; product_id?: number; }) {
        const url = `https://api.ali.ua-drones.de/get-comments`;
        return this.http.get<CommentFull[]>(`${url}`, { params }).pipe(tap(comments => comments
            .forEach(c => c.comment_date_string = timestampToLocalDate(c.comment_date) + ' ' + timestampToLocalShortTime(c.comment_date))
        ));
    }

    public getModelUpdates(model_id: number) {
        const url = `${environment.apiUrl}/model-updates`;
        const params = {
            'model_id': model_id
        }
        return this.http.get<ModelUpdate[]>(`${url}`, { params });
    }

    public getEvents(order_id: number) {
        const url = `${environment.apiUrl}/events`;
        const params = {
            'order_id': order_id
        }
        return this.http.get<OrderEvent[]>(`${url}`, { ...this.getOptions(), params });
    }

    public getProfile(money = false) {
        const url = `${environment.apiUrl}/profile`;
        return this.http.get<Profile>(`${url}`, { ...this.getOptions(), params: { money } });
    }

    public getDashboard() {
        const url = `${environment.apiUrl}/get-dashboard`;
        return this.http.get<DashboardResponse>(`${url}`, { ...this.getOptions() });
    }

    public getStock(include_transfers = false, force = true) {

        const url = `${environment.apiUrl}/stock`;
        const params = { include_transfers };

        if (!include_transfers) {

            if (!this.stock_no_transfers || force) {
                const subj = this.stock_no_transfers || new ReplaySubject(1);
                this.http.get<StockResponse>(`${url}`, { ...this.getOptions(), params }).subscribe({
                    next: e => subj.next(e),
                    error: e => subj.error(e),
                });
                this.stock_no_transfers = subj;
            }

            return this.stock_no_transfers.asObservable();

        }

        if (!this.stock || force) {
            const subj = this.stock || new ReplaySubject(1);
            this.http.get<StockResponse>(`${url}`, { ...this.getOptions(), params }).subscribe({
                next: e => subj.next(e),
                error: e => subj.error(e),
            });
            this.stock = subj;
        }

        return this.stock.asObservable();

    }

    public getWarehouses(force = false) {

        if (!this.warehouses || force) {
            const subj = this.warehouses = new ReplaySubject();
            const url = `${environment.apiUrl}/warehouses`;
            this.http.get<Warehouse[]>(url, this.getOptions()).subscribe({
                next: e => subj.next(e),
                error: e => subj.error(e),
            });
        }

        return this.warehouses.asObservable();

    }

    public getTrackingCompany(tracking_number: string, tracking_company_name?: string) {

        if (!tracking_number) {
            return {
                name: 'Невідомо',
                icon: '/favicon.ico',
            };
        }

        if (tracking_company_name === 'DHL') {
            return {
                name: tracking_company_name,
                // icon: 'https://www.dhl.com/apple-touch-icon.png',
                icon: 'https://posylka.net/uploads/couriers/120/dhl@2x.png',
            };
        }
        if (tracking_company_name === 'Hermes') {
            return {
                name: tracking_company_name,
                icon: 'https://www.myhermes.de/apple-touch-icon.png',
            };
        }
        if (tracking_company_name === 'PostNL') {
            return {
                name: tracking_company_name,
                icon: 'https://www.postnl.nl/favicon.ico',
            };
        } if (PREFIXES_UKRPOSHTA.some(p => tracking_number.startsWith(p))) {
            return {
                name: 'Укрпошта',
                icon: 'https://assets.swarm.army/carriers/ukrposhta.png'
            };
        } if (PREFIXES_MEESTEXPRESS.some(p => tracking_number.startsWith(p))) {
            return {
                name: 'Meest Пошта',
                icon: 'https://assets.swarm.army/carriers/meest.png',
            };
        } if (PREFIXES_NOVAPOSHTA.some(p => tracking_number.startsWith(p))) {
            return {
                name: 'Нова Пошта',
                icon: 'https://assets.swarm.army/carriers/novaposhta.png',
            };
        } if (PREFIXES_CHINA.some(p => tracking_number.startsWith(p))) {
            return {
                name: 'China Post',
                icon: 'https://www.ems.com.cn/favicon.ico',
            };
        } if (PREFIXES_ALI.some(p => tracking_number.startsWith(p))) {
            return {
                name: 'China',
                icon: '/assets/ali_icon1.png',
            };
        }

        return {
            name: 'Невідомо',
            // icon: '/favicon.ico',
            icon: '/assets/china_pin.jpg',
            // icon: '/assets/china_package.png',
        };

    }

    public processOrder = (order: OrderFull) => {

        const statuses: { [k: string]: string } = {
            ordered: 'Замовлено',
            shipped: 'Надіслано',
            delivered: 'Доставлено',
            picked: 'Отримано',
            partial: 'Частково отримано',
            received: 'Передано',
            cancelled: 'Відмінено',
            problem: 'Проблема',
        };

        const view = 'order';

        const sku_running_out = order.sku_last_available > 0 && order.sku_last_available < 50;

        const sku_full_price = order.sku_last_price + order.item_shipping_price;
        const sku_full_unit_price = sku_full_price / order.sku_quantity;

        const sku_full_sd_price = order.sku_last_sd_price ? order.sku_last_sd_price + order.item_shipping_price : 0;
        const sku_full_sd_unit_price = sku_full_sd_price / order.sku_quantity;

        const sku_full_lo_price = order.sku_last_lo_price ? order.sku_last_lo_price + order.item_shipping_price : 0;
        const sku_full_lo_unit_price = sku_full_lo_price / order.sku_quantity;

        const sku_min_price = sku_full_sd_price && sku_full_sd_price < sku_full_price ? sku_full_sd_price : sku_full_price;
        const sku_min_unit_price = sku_full_sd_unit_price && sku_full_sd_unit_price < sku_full_unit_price ? sku_full_sd_unit_price : sku_full_unit_price;

        const sku_future_unit_price = order.sku_future_sale_price ? order.sku_future_sale_price + order.item_shipping_price : 0;

        const sku_url = this.getUrl(order);
        const sku_sd_url = this.getSdUrl(order);
        const sku_cd_url = this.getCdUrl(order);
        const sku_lo_url = this.getLoUrl(order);


        order.seller_name_short = order.seller_name?.replace(' Store', '') || '';
        order.view_query_params = { view, order_id: order.order_id };
        order.order_local_date = timestampToLocalDate(order.order_date);
        order.order_local_status = statuses[order.order_status];


        if (order.order_tracking_number) {
            // FORMAT: +LP1234567890|MGRAE123456789|picked|1722158297
            order.order_tracking_number_array = order.order_tracking_number.split(';').map(p => {
                const [tracking_number, tracking_number_alt, tracking_status, tracking_updated, company_name] = p.split('|');
                debugger;
                return {
                    tracking_number: tracking_number.replace('+', ''),
                    tracking_number_alt: tracking_number_alt,
                    tracking_picked: tracking_number.startsWith('+'),
                    tracking_status: tracking_status,
                    tracking_updated: Number(tracking_updated),
                    tracking_status_local: TRACKING_STATUSES[tracking_status],
                    tracking_image_url: this.getTrackingCompany(tracking_number_alt || tracking_number, company_name).icon,
                    tracking_updated_local: timestampToLocalDate(Number(tracking_updated)) + ' ' + timestampToLocalShortTime(Number(tracking_updated)),
                };
            });
        } else {
            order.order_tracking_number_array = [];
        }

        const sku_future_sale_datetime = order.sku_future_sale_date
            ? timestampToLocalDate(order.sku_future_sale_date) + ' ' + timestampToLocalShortTime(order.sku_future_sale_date)
            : '';

        const local_date_updated = timestampToLocalDate(order.product_last_updated) + ' ' + timestampToLocalTime(order.product_last_updated);

        let tags_array = order.model_tags ? (order.model_tags || '').split(';') : [];
        const tags_dict: { [tag: string]: boolean } = {};
        tags_array.forEach(t => tags_dict[t] = true);

        tags_array = tags_array.filter(t => !t.endsWith('KIT'));

        const seller_view_query_params = { view: 'seller', seller: this.makeUrl(order.seller_name_short), seller_id: order.seller_id };

        const order_search_description = `${order.order_number?.toLowerCase() || ''}
${order.detail_name?.toLowerCase() || ''}
${order.model_name?.toLowerCase() || ''}
${order.seller_name?.toLowerCase() || ''}
${order.order_tracking_number?.toLowerCase() || ''}
     `;

        const product_id = order.product_id;
        const model_name = this.makeUrl(order.model_name);
        const seller_name_short = (order.seller_name || '').replace(' Store', '');
        const product_view_query_params = { view: 'product', seller: this.makeUrl(seller_name_short), model: model_name, product_id };

        return {
            ...order, product_view_query_params,
            tags_array, tags_dict, sku_running_out, sku_url, sku_sd_url,
            sku_full_price, sku_full_unit_price, sku_full_sd_price, sku_full_sd_unit_price,
            sku_future_sale_datetime, local_date_updated, order_search_description,
            sku_min_price, sku_min_unit_price, sku_future_unit_price, sku_cd_url,
            sku_full_lo_price, sku_full_lo_unit_price, sku_lo_url, seller_view_query_params,
        };

    };

    public getMyOrders(force = false) {

        if (!this.my_orders || force) {
            const subj = this.my_orders = new ReplaySubject();
            const url = `${environment.apiUrl}/get-orders`;
            this.http.get<OrderFull[]>(url, this.getOptions()).subscribe({
                next: orders => {
                    zip(this.getFullProducts(), this.getModels()).subscribe({
                        next: ([products, models]) => {
                            orders.forEach(o => {
                                if (o.product_id) {
                                    const copy = { ...o };
                                    const product = products.find(p => p.product_id === o.product_id);
                                    Object.assign(o, product || {}, { ...o });
                                } else if (o.model_id) {
                                    const model = models.find(m => m.model_id === o.model_id);
                                    Object.assign(o, model || {});
                                }
                            });
                            subj.next(orders.map(this.processOrder));
                        },
                        error: e => subj.error(e),
                    });

                },
                error: e => subj.error(e),
            });
        }

        return this.my_orders.asObservable();

    }


    public getAllOrders(force = false) {
        if (!this.all_orders || force) {
            const subj = this.all_orders = new ReplaySubject();
            const url = `${environment.apiUrl}/get-all-orders`;
            this.http.get<OrderFull[]>(url, this.getOptions()).subscribe({
                next: orders => {
                    zip(this.getFullProducts(), this.getModels()).subscribe({
                        next: ([products, models]) => {
                            orders.forEach(o => {
                                if (o.product_id) {
                                    const product = products.find(p => p.product_id === o.product_id);
                                    Object.assign(o, product || {}, { ...o });
                                } else if (o.model_id) {
                                    const model = models.find(m => m.model_id === o.model_id);
                                    Object.assign(o, model || {});
                                }
                            });
                            subj.next(orders.map(this.processOrder));
                        },
                        error: e => subj.error(e),
                    });

                },
                error: e => subj.error(e),
            });
        }
        return this.all_orders.asObservable();
    }

    public getPayouts() {
        const url = `${environment.apiUrl}/get-payouts`;
        return this.http.get<{ payouts: Payout[]; users: UserMoney[] }>(url, this.getOptions());
    }

    public getSellers(force = false) {
        if (!this.sellers_loaded) {
            this.sellers_loaded = true;
            const url = `${environment.apiUrl}/sellers`;
            this.http.get<SellerFull[]>(url, this.getOptions()).subscribe({
                next: e =>
                    this.sellers.next(e.map(s => {
                        s.seller_view_query_params = { view: 'seller', seller: this.makeUrl(s.seller_name_short), seller_id: s.seller_id };
                        return s;
                    })),
                error: e => this.sellers.error(e),
            });
        }
        return this.sellers;
    }

    private processModel(m: ModelFull) {

        m.tags_array = m.model_tags ? m.model_tags.split(';') : [];
        const tags_dict: { [tag: string]: boolean } = {};
        m.tags_array.forEach(t => tags_dict[t] = true);
        m.tags_array = m.tags_array.filter(t => !t.endsWith('KIT'));
        const view = 'model';
        const model_id = m.model_id;
        const model_name = this.makeUrl(m.model_name);

        m.sku_units = 'шт';

        switch (m.detail_units) {
            case DetailUnits.Grams:
                m.sku_units = 'г';
                break;
            case DetailUnits.Liters:
                m.sku_units = 'л';
                break;
            case DetailUnits.Meters:
                m.sku_units = 'м';
                break;
            case DetailUnits.Sets:
                m.sku_units = 'компл';
                break;
        }

        m.view_query_params = { view, model: model_name, model_id };

    }

    public getModels(force = false) {
        if (!this.models || force) {
            const subj = this.models = new ReplaySubject();
            const url = `${environment.apiUrl}/models`;
            this.http.get<ModelFull[]>(url, this.getOptions()).subscribe({
                next: e => {
                    e.forEach(m => this.processModel(m));
                    subj.next(e);
                },
                error: e => subj.error(e),
            });
        }
        return this.models.asObservable();
    }

    private processKit(k: KitFull) {
        k.kit_models_ids = JSON.parse(k.kit_models || '[]');
        const html = marked.parse(k.kit_description || '') as string;
        k.kit_description_html = this.sanitizer.bypassSecurityTrustHtml(html.replaceAll('<a ', '<a target="_blank" '));
        return k;
    }

    public getKits(force = false) {
        if (!this.kits || force) {
            const subj = this.kits = new ReplaySubject();
            const url = `${environment.apiUrl}/kits`;
            this.http.get<KitFull[]>(url, this.getOptions()).subscribe({
                next: e => subj.next(e.map(k => this.processKit(k))),
                error: e => subj.error(e),
            });
        }
        return this.kits.asObservable();
    }

    public upsertKit(dto: KitDto) {
        const url = `${environment.apiUrl}/upsert-kit`;
        return this.http.post<KitFull>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).pipe(map(k => this.processKit(k)));
    }

    public getDetails() {
        if (!this.details_loaded) {
            this.details_loaded = true;
            const url = `${environment.apiUrl}/details`;
            this.http.get<Detail[]>(url).subscribe({
                next: e => this.details.next(e),
                error: e => this.details.error(e),
            });
        }
        return this.details;
    }

    public updateProduct(dto: ProductUpdateDto) {
        const url = `${environment.apiUrl}/update-product`;
        return this.http.post<Product>(url,
            dto,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        );
    }

    private processTracking = (t: Tracking): TrackingFull => {
        return {
            ...t,
            tracking_local_date_updated: timestampToLocalDate(t.tracking_date_updated) + ' ' + timestampToLocalShortTime(t.tracking_date_updated),
            tracking_local_status: TRACKING_STATUSES[t.tracking_status],
            tracking_image_url: this.getTrackingCompany(t.tracking_number_alt || t.tracking_number).icon
        };
    }

    public getTrackings(force = false) {
        if (!this.trackings || force) {
            const subj = this.trackings || new ReplaySubject(1);
            const url = `${environment.apiUrl}/get-trackings`;
            this.http.get<Tracking[]>(url, this.getOptions()).subscribe({
                next: e => subj.next(e.map(this.processTracking)),
                error: e => subj.error(e),
            });
            this.trackings = subj;
        }
        return this.trackings.asObservable();
    }

    public trackingComment(data: { tracking_id: number; tracking_comment: string; }) {
        const url = `${environment.apiUrl}/tracking-comment`;
        return this.http.post<TrackingFull>(url, data,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        );
    }

    public trackingArchive(tracking_id: number) {
        const url = `${environment.apiUrl}/tracking-archive`;
        return this.http.post<TrackingFull>(url, { tracking_id },
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        );
    }

    public getTracking(data: TrackingRequest) {
        const url = `${environment.apiUrl}/get-tracking`;
        return this.http.post<TrackingResponse>(url, data,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).pipe(map(res => ({ ...res, tracking: res.tracking ? this.processTracking(res.tracking) : null })));
    }

    public transfer(transfers: TransferDto[]) {
        const url = `${environment.apiUrl}/transfer`;
        return this.http.post<StockResponse>(url,
            transfers,
            { ...this.getOptions({ 'content-type': 'application/json' }) }
        ).pipe(tap(e => this.stock?.next(e)));
    }

    public updateOrderProblem(order_id: number, order_problematic: boolean, order_comment: string) {
        const url = `${environment.apiUrl}/update-order-problem`;
        const body = { order_id, order_problematic, order_comment };
        return this.http.post<any>(
            url,
            body,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public updateOrderStatus(request: OrderStatusRequest) {
        const url = `${environment.apiUrl}/update-order-status`;
        return this.http.post<Order>(
            url,
            request,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public upsertTenant(model: Tenant) {
        const url = `${environment.apiUrl}/upsert-tenant`;
        return this.http.post<{ tenant: Tenant, memberships: Membership[] }>(
            url,
            model,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public upsertWarehouse(model: Warehouse) {
        const url = `${environment.apiUrl}/upsert-warehouse`;
        return this.http.post<Warehouse>(
            url,
            model,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }


    public upsertModel(model: Model) {
        const url = `${environment.apiUrl}/upsert-model`;
        return this.http.post<Model>(
            url,
            model,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public postUpdateModel(model: ModelFull) {
        this.processModel(model);
        this.getFullProducts().subscribe({
            next: products => {
                products.filter(p => p.model_id === model.model_id).forEach(p => {
                    Object.assign(p, model);
                });
                this.getModels().subscribe({
                    next: models => {
                        this.models_updated.next(models);
                    }
                });
            }
        });
    }

    public models_updated = new EventEmitter();

    public postInsertModel(model: ModelFull) {
        this.getModels().subscribe({
            next: models => {
                this.processModel(model);
                models.push(model);
                this.models?.next(models);
                this.models_updated.next(models);
            }
        });
    }

    public upsertUser(user: User) {
        const url = `${environment.apiUrl}/upsert-user`;
        return this.http.post<User>(
            url,
            user,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public upsertCustomSeller(seller: SellerFull) {
        const url = `${environment.apiUrl}/upsert-custom-seller`;
        return this.http.post<SellerFull>(
            url,
            seller,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public postInsertSeller(seller: SellerFull) {
        this.getSellers().subscribe({
            next: sellers => {
                sellers.push(seller);
                this.sellers?.next(sellers);
            }
        });
    }

    public upsertCustomProduct(product: ProductFull) {
        const url = `${environment.apiUrl}/upsert-custom-product`;
        return this.http.post<ProductFull>(
            url,
            product,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public postInsertProduct(product: ProductFull) {
        this.getFullProducts().subscribe({
            next: products => {
                products.push(product);
                this.full_products?.next(products);
            }
        });
    }

    public updateProfile(user: User) {
        const url = `${environment.apiUrl}/upsert-profile`;
        return this.http.post<User>(
            url,
            user,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public upsertRequest(dto: { model_id: number; request_quantity: number; request_max_price: number; request_rule: RequestRule; request_products: string; }) {
        const url = `${environment.apiUrl}/upsert-request`;
        return this.http.post<OrderRequest>(
            url,
            dto,
            this.getOptions({ 'content-type': 'application/json' })
        ).pipe(tap(() => this.requests = null));
    }

    public createOrder(order: OrderCreateDto) {
        const url = `${environment.apiUrl}/create-order`;
        return this.http.post<OrderFull>(
            url,
            order,
            this.getOptions({ 'content-type': 'application/json' })
        ).pipe(tap(order => {
            if (!order) return;
            const orders = (this.my_orders as any)?._buffer as (OrderFull[] | null);
            if (orders) {
                order = this.processOrder(order);
                orders.unshift(order);
                this.my_orders?.next(orders);
            }
        }));
    }

    public updateOrder(order: OrderUpdateDto) {
        const url = `${environment.apiUrl}/update-order`;
        return this.http.post<OrderFull>(
            url,
            order,
            this.getOptions({ 'content-type': 'application/json' })
        ).pipe(tap(order => {
            if (!order) return;
            const orders = (this.my_orders as any)?._buffer as (OrderFull[] | null);
            if (orders) {
                const found_order = orders.find(o => o.order_id === order.order_id);
                if (!found_order) return;
                order = this.processOrder(order);
                Object.assign(found_order, order);
                this.my_orders?.next(orders);
            }
        }));
    }

    public createPayout(order: PayoutDto) {
        const url = `${environment.apiUrl}/create-payout`;
        return this.http.post<PayoutDto>(
            url,
            order,
            this.getOptions({ 'content-type': 'application/json' })
        );
    }

    public makeUrl(name: string) {

        name = name?.trim();
        if (!name) return '';

        let transliteration = '';

        for (let c of name) {
            transliteration += UK_EN_TRANSLITERATION[c] || c;
        }

        return transliteration.replace(/ /g, '-').replace(/[^\w\-]/g, '').toLowerCase();

    }

    public signout() {
        localStorage.removeItem(OAUTH_JWT);
        localStorage.removeItem(OAUTH_PROFILE);
        this.updateJwtCookie();
        this.profile = null;
        this.edit_profile = false;
        this.router.navigate(['/']);
    }

    public signin() {

        if ((window as any)['TelegramWebview']) {
            SigninDisabledDialogComponent.open(this.dialog);
            return;
        }

        const url = `${environment.apiUrl}/auth/challenge`;

        const w = 900, h = 600;
        const l = (screen.width / 2) - (w / 2);
        const t = (screen.height / 2) - (h / 2);

        localStorage.removeItem(OAUTH_ERROR);

        const modalClosed = () => {

            const error = localStorage.getItem(OAUTH_ERROR);
            const jwt = localStorage.getItem(OAUTH_JWT);

            localStorage.removeItem(OAUTH_ERROR);

            this.updateJwtCookie();

            if (jwt) {

                try {

                    this.profile = this.parseJwt(jwt);
                    this.app.tick();

                    if (this.profile?.user_role === 'guest') {
                        this.showMessage('Тепер ви можете пропонувати продукти через розширення браузера.');
                    } else {
                        this.router.navigate(['/requests']);
                    }

                    this.reloadProfile();

                } catch (e) {
                    this.showMessage('Невідома помилка!');
                }

            } else if (error) {
                this.showMessage(error);
            }
        };

        const settings = `width=${w},height=${h},top=${t},left=${l},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`;
        const modal = window.open(url, 'Login', settings);

        const checkInterval = setInterval(() => {
            if (!modal) {
                clearInterval(checkInterval);
            } else {
                try {
                    if (modal.closed) {
                        clearInterval(checkInterval);
                        modalClosed();
                    }
                } catch { }
            }
        }, 50);

    }

    public message: string[] | null = null;

    public showMessage(message: string | string[]) {
        AlertDialogComponent.open(this.dialog, {
            messages: Array.isArray(message) ? message : [message]
        })
    }

    public getUrl(p: Product) {
        const tenant_affiliate = this.tenant?.tenant_affiliate;
        if (tenant_affiliate) {
            const product = this.product_affiliate_map[p.product_id];
            if (product?.product_url) return product?.product_url;
        }
        if (p.product_url?.trim().length > 10 && !tenant_affiliate) return p.product_url;
        return `https://www.aliexpress.com/item/${p.item_id}.html?pdp_ext_f=%7B"sku_id":"${p.sku_id}"%7D`;
    }

    public getModelUrl(p: ProductFull) {
        return `/models/?view=model&model=${this.makeUrl(p.model_name)}&model_id=${p.model_id}`;
    }

    public getSdUrl(p: Product) {
        const tenant_affiliate = this.tenant?.tenant_affiliate;
        if (tenant_affiliate) {
            const product = this.product_affiliate_map[p.product_id];
            if (product?.product_sd_url) return product?.product_sd_url;
        }
        if (p.product_sd_url && !tenant_affiliate) return p.product_sd_url;
        return `https://www.aliexpress.com/item/${p.item_id}.html?sourceType=562&pdp_ext_f=%7B"sku_id":"${p.sku_id}"%7D`;
    }

    public getLoUrl(p: Product) {
        const tenant_affiliate = this.tenant?.tenant_affiliate;
        if (tenant_affiliate) {
            const product = this.product_affiliate_map[p.product_id];
            if (product?.product_lo_url) return product?.product_lo_url;
        }
        if (p.product_lo_url && !tenant_affiliate) return p.product_lo_url;
        return `https://www.aliexpress.com/item/${p.item_id}.html?sourceType=561&pdp_ext_f=%7B"sku_id":"${p.sku_id}"%7D`;
    }

    public getBsUrl(p: Product) {
        const tenant_affiliate = this.tenant?.tenant_affiliate;
        if (tenant_affiliate) {
            const product = this.product_affiliate_map[p.product_id];
            if (product?.product_bs_url) return product?.product_bs_url;
        }
        if (p.product_bs_url && !tenant_affiliate) return p.product_bs_url;
        return `https://www.aliexpress.com/item/${p.item_id}.html?sourceType=680&pdp_ext_f=%7B"sku_id":"${p.sku_id}"%7D`;
    }

    public getCdUrl(p: Product) {
        const tenant_affiliate = this.tenant?.tenant_affiliate;
        if (tenant_affiliate) {
            const product = this.product_affiliate_map[p.product_id];
            if (product?.product_cd_url) return product?.product_cd_url;
        }
        if (p.product_cd_url && !tenant_affiliate) return p.product_cd_url;
        return `https://www.aliexpress.com/item/${p.item_id}.html?sourceType=620&pdp_ext_f=%7B"sku_id":"${p.sku_id}"%7D`;
    }

    public get buyer() { return this.tenant?.membership_role === 'buyer'; }
    public get craftsman() { return this.tenant?.membership_role === 'craftsman'; }
    public get admin() { return this.tenant?.membership_role === 'admin'; }
    public get accountant() { return this.tenant?.membership_role === 'accountant'; }
    public get superadmin() { return this.profile?.user_role === 'superadmin'; }
    public get scrapper() { return this.profile?.user_role === 'scrapper'; }
    public get sdua() { return this.profile?.user_role === 'sdua'; }

    public suggest = false;
    public sidebar = false;
    public edit_profile = false;

    public restoreFilter(key: string, filters: Filter[]) {

        const url = new URL(location.href.includes('view=') ? location.origin : location.href);

        this.getFilters(key)?.forEach(filter => {
            const current_filter = filters.find(f => f.name === filter.name);
            if (!current_filter) return;
            Object.keys(filter.dict).forEach(k => {
                current_filter.dict[k] = filter.dict[k];
                if (k === '' && filter.dict['all'] === undefined) current_filter.dict['all'] = filter.dict[k];
            });
            const value = url.searchParams.get(current_filter.key)?.split(',');
            if (value?.length) {
                value.forEach(v => {
                    const option = current_filter.options.find(o => this.makeUrl(String(o.id)) === v || this.makeUrl(String(o.name)) === v);
                    if (option) {
                        current_filter.dict[option.id] = true;
                        current_filter.options.filter(o => !value.some(v => this.makeUrl(String(o.id)) === v || this.makeUrl(String(o.name)) === v))
                            .forEach(o => current_filter.dict[o.id] = false);
                    }
                });
            }
        });

        if (url.searchParams.size) {
            filters.forEach(f => {

                const all_option = f.options.find(o => o.id === null || o.id === 'all');

                if (!all_option) return;

                const search = url.searchParams.get(f.key);

                if (search) {
                    if (!search.split(',').includes(String(all_option.id)))
                        f.dict[all_option.id] = false;
                } else {
                    f.dict[all_option.id] = true;
                    Object.keys(f.dict).filter(k => k !== all_option.id).forEach(k => f.dict[k] = false);
                    f.options.filter(o => o !== all_option).forEach(o => f.dict[o.id] = false);
                }

            });
        }

    }

    public getStatsLink(p: ProductFull) {
        return `/${this.makeUrl(p.detail_name)}/${this.makeUrl(p.model_name)}/${this.makeUrl(p.seller_name)}/${p.item_id}/${p.sku_id}/${p.sku_prop_ids}`;
    }

    public copy(text: string, message: string, event?: Event) {
        navigator.clipboard.writeText(text).then(() => {
            this.snackbar.open(message);
        });
        event?.preventDefault();
        event?.stopImmediatePropagation();
    }

}

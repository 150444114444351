<div class="d-flex flex-column w-100">
    <div class="d-flex justify-content-between mb-2">
        <div class="spacer" *ngFor="let _ of [].constructor(this.columns)">
            <div class="skeleton-title"></div>
        </div>
    </div>
    <div class="d-flex justify-content-between" *ngFor="let _ of [].constructor(this.rows)">
        <div class="spacer" *ngFor="let _ of [].constructor(this.columns)">
            <div class="skeleton-text"></div>
        </div>
    </div>
</div>
<div mat-dialog-title>
    Увага!
</div>

<mat-dialog-content class="mat-typography">

    <p>
        Вхід в систему з вбудованого браузеру Telegram не підтримується. 
        Будь ласка, відкрийте сайт з основного браузера. Скористайтеся інструкцією нижче:
    </p>

    <img src="https://assets.swarm.army/photos/open-in.png" />

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Зрозуміло</button>
</mat-dialog-actions>
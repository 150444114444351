<app-sidebar #sidebar>
    <button class="sidebar-toggle-button" (click)="sidebar.toggleSidenav()" [class.is-flipped]="sidebar.isExpanded" matTooltip="{{ sidebar.isExpanded ? 'Сховати фільтри' : 'Показати фільтри'}}" *ngIf="layout.is_desktop | async">
        <mat-icon icon>chevron_right</mat-icon>
    </button>

    <div class="products-sidebar" role="sidebar">

        <app-filters-panel [filters]="filters"></app-filters-panel>

        <button mat-fab extended="" class="products-total" [disabled]="layout.is_desktop | async"
            (click)="sidebar.closeSidebar()">
            <mat-icon>filter_alt</mat-icon>
            <div class="products-total-text">
                <span>{{((layout.is_mobile | async) ? 'До моделей' : 'Показано')}}</span>
                <div>
                    <span>{{filtered_motors.length}}</span>
                    <small>/{{motors.length}}</small>
                </div>
            </div>
        </button>

    </div>

    <div *ngIf="models.length && filtered_motors.length" class="products-scroll">

        <!-- <ng-container *ngIf="!models.length">
                <div class="skeleton-block" *ngFor="let _ of [].constructor(10).fill(0)"></div>
            </ng-container> -->
        <div style="padding: 16px;">
            <apx-chart *ngIf="chartOptions" [series]="chartOptions.series" [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels"
                [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"></apx-chart>

            <div class="graph-type">
                <button (click)="apply('f')" [class.active]="graph=='f'">Тяга</button>
                <button (click)="apply('i')" [class.active]="graph=='i'">Струм</button>
                <button (click)="apply('p')" [class.active]="graph=='p'">Потужність</button>
                <button (click)="apply('q')" [class.active]="graph=='q'">Ефективність</button>
                <button (click)="apply('fi')" [class.active]="graph=='fi'">Тяга/Струм</button>
            </div>

            <h2>Вигляд зверху</h2>

            <div class="gallery">
                <ng-container *ngFor="let m of filtered_motors">
                    <div class="gallery-item" [routerLink]="[]"
                        [queryParams]="getViewParams(m.model.view_query_params)">
                        <img [src]="m.motor.photos[1]" />
                        <div class="gallery-panel">
                            <span class="gallery-name">{{m.motor.brand}} {{m.motor.model}}</span>
                            <span class="gallery-sz"><span>{{m.motor.sz}}</span></span>
                            <span class="gallery-kv"><span>{{m.motor.kv}}KV</span></span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <h2>Вигляд збоку</h2>

            <div class="gallery">
                <ng-container *ngFor="let m of filtered_motors">
                    <div class="gallery-item" [routerLink]="[]"
                        [queryParams]="getViewParams(m.model.view_query_params)">
                        <img [src]="m.motor.photos[0]" />
                        <div class="gallery-panel">
                            <span class="gallery-name">{{m.motor.brand}} {{m.motor.model}}</span>
                            <span class="gallery-sz"><span>{{m.motor.sz}}</span></span>
                            <span class="gallery-kv"><span>{{m.motor.kv}}KV</span></span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <h2>Вигляд знизу</h2>

            <div class="gallery">
                <ng-container *ngFor="let m of filtered_motors">
                    <div class="gallery-item" [routerLink]="[]"
                        [queryParams]="getViewParams(m.model.view_query_params)">
                        <img [src]="m.motor.photos[2]" />
                        <div class="gallery-panel">
                            <span class="gallery-name">{{m.motor.brand}} {{m.motor.model}}</span>
                            <span class="gallery-sz"><span>{{m.motor.sz}}</span></span>
                            <span class="gallery-kv"><span>{{m.motor.kv}}KV</span></span>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>

    </div>

    <div class="products-empty-message" *ngIf="models.length && !filtered_motors.length">
        Жоден мотор не відповідає вказаним в фільтрах критеріям.
    </div>

    <div class="products-buttons">

        <button mat-fab extended color="accent" *ngIf="models.length && (layout.is_mobile | async)"
            (click)="sidebar.openSidebar()">
            <mat-icon>filter_list</mat-icon>
            <span><b>{{filtered_motors.length}}</b><small>/{{models.length}}</small></span>
        </button>

        <div class="spacer"></div>

        <app-promo-button></app-promo-button>

    </div>

</app-sidebar>
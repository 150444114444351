import { Component } from '@angular/core';
import { DataService } from '../../data.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';


@Component({
    selector: 'app-account-button',
    templateUrl: './account-button.component.html',
    styleUrl: './account-button.component.scss'
})
export class AccountButtonComponent {
    constructor(public readonly data: DataService, private readonly dialog: MatDialog) { }

    public onShowProfile() {
        this.dialog.open(ProfileDialogComponent, {
            width: '400px',
            autoFocus: false
        });
    }
}